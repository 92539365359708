import React from 'react';
// import "./dashboard/dashboard.css"
import Row from "./row";

class MiddleMain extends React.Component {
    constructor(props) {
      super(props);
  //    this.state = {};
    }
  
    render() {
        var that = this;
        // console.log("=============================="+JSON.stringify(that.props.content));
      const rows = (Array.isArray(this.props.content) && this.props.web_filters.pageType.name !== "app") ? (this.props.content.map((obj,index) =>
          this.props.tag==="page_path_mapping" ? 
          ( 
          <Row key={index} data={obj} selectedRow={this.props.selectedRow} tag={that.props.tag} onRowClick={this.props.onRowClick} rightContents={this.props.rightDetails} actionButton={this.props.onAction} />)
          :
         ( <Row key={index} data={obj} selectedRow={this.props.selectedRow} tag={that.props.tag} onRowClick={this.props.onRowClick} rightContents={this.props.rightDetails} actionButton={this.props.onAction} />)
        )) 
        : 
        ((Array.isArray(this.props?.content?.list) && this.props.web_filters.pageType.name === "app") ? this.props?.content?.list.map((item, index) => {
              let question = item.question
              let answer = item.answer
              return (
                  <div className='slotBookContainer' style={{margin:"24px"}}>
                    <div>Question : {question}</div>
                    <div style={{marginTop: "12px"}}>Answer : {answer}</div>
                  </div>
                )
            }) : <div className="empty centeralign weight400 blackspan56">No Data Found</div>)
        // ""
  
      return (
          <div className="main border-box leftalign">
              {!this.props?.showContent ? (
                      <React.Fragment />
                  ) : (
  
                      this.props?.content?.length == 0 ? 
                          (<div className="empty centeralign weight400 blackspan56">No Data Found</div>)
                      : (
                          rows
                      )
                  )}
          </div>
      );
    }
  }

export default MiddleMain;