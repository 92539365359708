import React from "react";
import "./rateFilter.css";
import firebase from "../../firebase";
import MultiSelect from "../custom/MultiSelect/MultiSelect";
import API from "../../api";

// const filters = {"city":true,"brand":true,"model":false,"fuel":true};
class RateFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dealType: [],
      brands: [],
      models: [],
      segments:[],
      brandWiseModels: [],
      segmentWiseModels: [],
      fuelType: [],
      searchTerm: [],
      rateFilter: {
        deals: [],
        brands: [],
        fuelType: [],
        models: [],
        segments: [],
        lineItem: []
      },
      showFilterDiv: false,
    };

    this.handleApplyFilter = this.handleApplyFilter.bind(this);
    this.afterFilterChange = this.afterFilterChange.bind(this);
    this.brandWiseModel = this.brandWiseModel.bind(this);
    this.segmentWiseModel = this.segmentWiseModel.bind(this);
    this.getLineItems = this.getLineItems.bind(this);
  }
  componentWillMount() {
    if (localStorage.getItem("deal_type") != undefined) {
      this.state.dealType = JSON.parse(localStorage.getItem("deal_type"));
      this.state.showFilterDiv = true;
    }
    if (localStorage.getItem("brand_data") != undefined) {
      this.state.brands = JSON.parse(localStorage.getItem("brand_data"));
    }
    if (localStorage.getItem("fuel_data") != undefined) {
      this.state.fuelType = JSON.parse(localStorage.getItem("fuel_data"));
    }
    if (localStorage.getItem("model_data") != undefined) {
      this.state.models = JSON.parse(localStorage.getItem("model_data"));
      this.state.brandWiseModels =
        localStorage.getItem("rate_brand_wise_model") == null
          ? []
          : JSON.parse(localStorage.getItem("rate_brand_wise_model"));
    }
    if (localStorage.getItem("segment_wise_models") != undefined) {
      this.state.segmentWiseModels = JSON.parse(localStorage.getItem("segment_wise_models"));
    }
    if (localStorage.getItem("line_items") != undefined) {
      this.state.searchTerm = JSON.parse(localStorage.getItem("line_items"));
    }
    if (localStorage.getItem("segment_data") != undefined) {
      this.state.segments = JSON.parse(localStorage.getItem("segment_data"));
    }
  }
  componentDidMount() {
    /* Setting Filters Data from localstorage*/
    if (localStorage.getItem("rate_filters") != undefined) {
      this.setState({
        rateFilter: JSON.parse(localStorage.getItem("rate_filters")),
      });
    }
    // if (localStorage.getItem("deal_type") == undefined) {
    //   firebase
    //     .database()
    //     .ref("/cms/deal_zone")
    //     .on(
    //       "value",
    //       (snapshot) => {
    //         var dealType = [];
    //         dealType = [
    //           { id: "select", name: "Select Deals" },
    //           ...snapshot.val(),
    //         ];
    //         this.setState({ dealType: dealType });
    //         // console.log('++++++++++++++++page_type');
    //         localStorage.setItem(
    //           "deal_type",
    //           JSON.stringify(this.state.dealType)
    //         );
    //       },
    //       (err) => {
    //         console.log("deal type read failed from firebase", err.code);
    //       }
    //     );
    // }
    // if (localStorage.getItem("brand_data") == undefined) {
    //   firebase
    //     .database()
    //     .ref("/cms/brands")
    //     .on(
    //       "value",
    //       (snapshot) => {
    //         var brands = [];
    //         brands = [
    //           { id: "select", name: "Select Brands" },
    //           ...snapshot.val(),
    //         ];
    //         this.setState({ brands: brands });
    //         // console.log('++++++++++++++++brands');
    //         localStorage.setItem(
    //           "brand_data",
    //           JSON.stringify(this.state.brands)
    //         );
    //       },
    //       (err) => {
    //         console.log("brands read failed from firebase", err.code);
    //       }
    //     );
    // }
    // if (localStorage.getItem("fuel_data") == undefined) {
    //   firebase
    //     .database()
    //     .ref("/cms/fuel_type")
    //     .on(
    //       "value",
    //       (snapshot) => {
    //         var fuelArr = [];
    //         fuelArr = [
    //           { id: "select-all", name: "Select All" },
    //           ...snapshot.val(),
    //         ];
    //         this.setState({ fuelType: fuelArr });
    //         // console.log('++++++++++++++++fuel_type');
    //         localStorage.setItem(
    //           "fuel_data",
    //           JSON.stringify(this.state.fuelType)
    //         );
    //       },
    //       (err) => {
    //         console.log("fuel type read failed from firebase", err.code);
    //       }
    //     );
    // }
    // if (localStorage.getItem("model_data") == undefined) {
    //   firebase
    //     .database()
    //     .ref("/cms/model/")
    //     .on(
    //       "value",
    //       (snapshot) => {
    //         this.setState({ models: snapshot.val() });
    //         // console.log('++++++++++++++++model');
    //         localStorage.setItem(
    //           "model_data",
    //           JSON.stringify(this.state.models)
    //         );
    //         this.brandWiseModel();
    //       },
    //       (err) => {
    //         console.log("model read failed from firebase", err.code);
    //       }
    //     );
    // }
    // if (localStorage.getItem("segment_wise_models") == undefined) {
    //   firebase
    //     .database()
    //     .ref("/cms/segmentWiseModels/")
    //     .on(
    //       "value",
    //       (snapshot) => {
    //         this.setState({ segmentWiseModels: snapshot.val() });
    //         localStorage.setItem(
    //           "segment_wise_models",
    //           JSON.stringify(this.state.segmentWiseModels)
    //         );
    //       },
    //       (err) => {
    //         console.log("model read failed from firebase", err.code);
    //       }
    //     );
    // }
    // if (localStorage.getItem("segment_data") == undefined) {
    //   firebase
    //     .database()
    //     .ref("/cms/segment/")
    //     .on(
    //       "value",
    //       (snapshot) => {
    //         var segments = [];
    //         segments = [
    //           { id: "select", name: "Select Segments" },
    //           ...snapshot.val(),
    //         ];
    //         this.setState({ segments: segments });
    //         localStorage.setItem(
    //           "segment_data",
    //           JSON.stringify(this.state.segments)
    //         );
    //       },
    //       (err) => {
    //         console.log("segments read failed from firebase", err.code);
    //       }
    //     );
    // }
  }
  afterFilterChange(field) {
    localStorage.setItem(
      "rate_filters",
      JSON.stringify(this.state.rateFilter)
    );

    field = field || null;
    if (field == "brand") {
      this.brandWiseModel();
    }
    if (field == "segment") {
      this.segmentWiseModel();
    }
  }

  brandWiseModel() {
    let brands = this.state.rateFilter.brands;
    let models = this.props.fbData.brandWiseModel;
    let filterModel = this.state.rateFilter.models;
    let filterModelArr = [];

    let brand_id = brands.id;
    let slug_id = brands.compare_name;
    let brandModel = models[slug_id];

    if (brand_id && brandModel) {
      if (filterModel.length !== 0) {
        for (let j = 0; j < filterModel.length; j++) {
          if (brand_id == filterModel[j].brand_id)
            filterModelArr.push(filterModel[j]);
        }
      }

      var brandWiseModel = [];
      brandWiseModel = [
        { id: "select-all", name: "Select All" },
        ...brandModel.model,
      ];
      this.setState({
        brandWiseModels: brandWiseModel,
        rateFilter: { ...this.state.rateFilter, models: filterModelArr },
      });
      localStorage.setItem("rate_brand_wise_model", JSON.stringify(brandWiseModel));
    } else {
      this.setState({
        rateFilter: { ...this.state.rateFilter, models: [] },
      });
    }
    localStorage.setItem(
      "rate_filters",
      JSON.stringify(this.state.rateFilter)
    );
  }

  segmentWiseModel() {
    let segments = this.state.rateFilter.segments;
    let models = this.props.fbData.segmentWiseModel;
    let filterModel = this.state.rateFilter.models;
    let filterModelArr = [];

    // let slug_id = segments && segments.name ? segments.name.replace(' ', '-').toLowerCase() : "";
    let slug_id = segments.name
    let segmentModel = models[slug_id];

    if (slug_id && segmentModel) {
      if (filterModel.length !== 0) {
        for (let j = 0; j < filterModel.length; j++) {
          if (slug_id == filterModel[j].segment_name)
            filterModelArr.push(filterModel[j]);
        }
      }

      var segmentWiseModel = [];
      segmentWiseModel = [
        { id: "select-all", name: "Select All" },
        ...segmentModel.model,
      ];
      this.setState({
        brandWiseModels: segmentWiseModel,
        rateFilter: { ...this.state.rateFilter, models: filterModelArr },
      });
      localStorage.setItem("rate_brand_wise_model", JSON.stringify(segmentWiseModel));
    } else {
      this.setState({
        rateFilter: { ...this.state.rateFilter, models: [] },
      });
    }
    localStorage.setItem(
      "rate_filters",
      JSON.stringify(this.state.rateFilter)
    );
  }
  getLineItems(term){
    var that = this;
    if(term.length >=3){
        API.getLineItemForRateControl(term).then(function (response) {
          console.log('getLineItemForRateControl',response);
          if(response.data.status){
            // let lineItems = [{"id":"select","name":"Select Line Item"}];
            // response.data.data.map((item) => {
            //   lineItems.push({"id":item,"name":item});
            // })
            that.setState({searchTerm : response.data.data});
            localStorage.setItem("line_items",JSON.stringify(response.data.data));
          }
        })
    }else{
      that.setState({searchTerm : []});
    }
  }
  handleApplyFilter() {
    this.props.onApplyFilter(this.state.rateFilter, 'rate_filters');
  }

  handleChange(type, e) {
    this.setState({
      rateFilter: {
        ...this.state.rateFilter,
        lineItem: e.target.value,
      }
    })
    localStorage.setItem(
      "rate_filters",
      JSON.stringify(this.state.rateFilter)
    );
  }
  render() {
    const {
      showFilterDiv,
      dealType,
      brands,
      segments,
      fuelType,
      brandWiseModels,
      searchTerm,
      rateFilter,
    } = this.state;
    const {fbData} = this.props
    return (
      <div className="mainFilterContainer">
        <div
          className="row-5 margin_10 margin_b_20"
          style={{ gridTemplateColumns: "repeat(6,15%)" }}
        >
          <div className="margin_r_16">
            <MultiSelect
               options={[
                { id: "select", name: "Select Deals" },
                ...fbData.deal_zone
            ]}
              labelKey="name"
              checkbox_id="name"
              placeholder="Deal Type"
              disabledItems={[]}
              selectedOptions={this.state.rateFilter.deals}
              onChange={(value) =>
                this.setState(
                  {
                    rateFilter: {
                      ...this.state.rateFilter,
                      deals: value,
                    },
                    showFilterDiv: true,
                  },
                  () => this.afterFilterChange()
                )
              }
            />
          </div>
          {showFilterDiv && (
            <React.Fragment>
              {rateFilter.deals && rateFilter.deals.name && (
                <React.Fragment>
                    <React.Fragment>
                      <div className="margin_r_16">
                        <MultiSelect
                         options={[
                          { id: "select", name: "Select Brands" },
                          ...fbData.car_brands
                        ]}
                          labelKey="name"
                          checkbox_id="name"
                          placeholder="Select Brand"
                          disabledItems={[]}
                          selectedOptions={this.state.rateFilter.brands}
                          disabled={rateFilter && rateFilter.segments && rateFilter.segments.id ? true: false}
                          onChange={(value) =>
                            this.setState(
                              {
                                rateFilter: {
                                  ...this.state.rateFilter,
                                  brands: value,
                                },
                              },
                              () => this.afterFilterChange("brand")
                            )
                          }
                        />
                      </div>
                    </React.Fragment>

                    <React.Fragment>
                    <div className="margin_r_16">
                      <MultiSelect
                        options={[
                          { id: "select", name: "Select Segments" },
                          ...fbData.segment,
                        ]}
                        labelKey="name"
                        checkbox_id="name"
                        placeholder="Select Segments"
                        disabledItems={[]}
                        selectedOptions={this.state.rateFilter.segments}
                        disabled={rateFilter && rateFilter.brands.id ? true: false}
                        onChange={(value) =>
                          this.setState(
                            {
                              rateFilter: {
                                ...this.state.rateFilter,
                                segments: value,
                              },
                            },
                            () => this.afterFilterChange("segment")
                          )
                        }
                      />
                    </div>
                    </React.Fragment>
                </React.Fragment>
              )}
              {(rateFilter.brands.id || rateFilter.segments.id) && (
                <div className="margin_r_16">
                  <MultiSelect
                    multiple
                    options={brandWiseModels}
                    labelKey="name"
                    checkbox_id="id"
                    placeholder="Select Model"
                    disabledItems={[]}
                    selectedOptions={this.state.rateFilter.models}
                    onChange={(value) =>
                      this.setState(
                        {
                          rateFilter: {
                            ...this.state.rateFilter,
                            models: value,
                          },
                        },
                        () => this.afterFilterChange()
                      )
                    }
                  />
                </div>
              )}
              {rateFilter.models.length > 0 && (
                <div className="margin_r_16">
                  <MultiSelect
                    multiple
                    options={[
                      { id: "select-all", name: "Select All" },
                      ...fbData.fuelTypes
                    ]}
                    labelKey="name"
                    checkbox_id="id"
                    placeholder="Select Fuel Type"
                    disabledItems={[]}
                    selectedOptions={this.state.rateFilter.fuelType}
                    onChange={(value) =>
                      this.setState(
                        {
                          rateFilter: {
                            ...this.state.rateFilter,
                            fuelType: value,
                          },
                        },
                        () => this.afterFilterChange("fuel")
                      )
                    }
                  />
                </div>
              )}

              {rateFilter.fuelType.length > 0 && (
                <div className="margin_r_16" style={{width:'140%'}}>
                  <MultiSelect
                    options={searchTerm}
                    labelKey="name"
                    checkbox_id="id"
                    placeholder="Search Line Item"
                    disabledItems={[]}
                    selectedOptions={this.state.rateFilter.lineItem}
                    onSearch={this.getLineItems}
                    onChange={(value) =>
                      this.setState(
                        {
                          rateFilter: {
                            ...this.state.rateFilter,
                            lineItem: value,
                          },
                        },
                        () => this.afterFilterChange()
                      )
                    }
                  />
                </div>
              )}
              {/* <div className="border-box centeralign fieldbox double">
                <input type="text" name="otp" placeholder="Search Line Item" className="dashboardfields dashboardchangefields tiny" value={this.state.rateFilter.lineItem} onChange={(e) => this.handleChange("line_item", e)} style={{ fontSize: '12px', padding: '14px', background: "#fff" }} />
              </div> */}


              

            </React.Fragment>
          )}
        </div>
        <div className="blackspan65 weight700">
                <button
                  style={{
                    background: "rgb(105,118,148,1)",
                    color: "rgb(255, 255, 255)",
                    marginLeft: "50px",
                    marginTop: "15px"
                  }}
                  id="addleadbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={this.handleApplyFilter}
                >
                  Apply Filter
                </button>
        </div>
      </div>
    );
  }
}

export default RateFilter;
