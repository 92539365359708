import React from "react";
import "./walletFilter.css";
import firebase from "../../../firebase";
import MultiSelect from "../../custom/MultiSelect/MultiSelect";
import API from "../../../api";

class WalletFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // request_type: ['solo', 'multiple', 'bulk'],
      request_type: [
        { id: "select", name: "Select users" },
        { id: "0", name: "Single User" },
        { id: "1", name: "Multiple User" },
        { id: "2", name: "Bulk User" },
      ],
      users: [
        { id: "select", name: "Select users" },
        { id: "all", name: "All users" },
        { id: "15-days", name: "Last 15 Days" },
        { id: "30-days", name: "Last 30 Days" },
        { id: "60-days", name: "Last 60 Days" },
        { id: "90-days", name: "Last 90 Days" },
        { id: "addcart-3", name: "Add to cart last 3 days" },
        { id: "addcart-7", name: "Add to cart last 7 days" },
        { id: "purchase", name: "Purchase user" },
        { id: "cancel", name: "Cancelled users" },
        { id: "luxury", name: "Luxury users" },
        { id: "nps-positive", name: "NPS postive users" },
        { id: "nps-negative", name: "NPS negative users" },
      ],
      city: [],
      mobile_number: "",
      walletFilter: {
        users: [],
        cities: [],
        request_type: null,
        mobile_number : null,
      },
    };

    this.handleApplyFilter = this.handleApplyFilter.bind(this);
    this.afterFilterChange = this.afterFilterChange.bind(this);
    this.cityWiseWorkShop = this.cityWiseWorkShop.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.downloadSampleCSV = this.downloadSampleCSV.bind(this);
  }
  componentWillMount() {
    if (localStorage.getItem("wallet_city_data") != undefined) {
      this.state.city = JSON.parse(localStorage.getItem("wallet_city_data"));
    }
    if (localStorage.getItem("wallet_user_type") != undefined) {
      this.state.users = JSON.parse(localStorage.getItem("wallet_user_type"));
    }
  }
  componentDidMount() {
    /* Setting Filters Data from localstorage*/
    if (localStorage.getItem("wallet_filters") != undefined) {
      this.setState({
        walletFilter: JSON.parse(localStorage.getItem("wallet_filters")),
      });
    }
    if (localStorage.getItem("wallet_city_data") == undefined) {
      firebase
        .database()
        .ref("/cms/cities")
        .on(
          "value",
          (snapshot) => {
            var cityOptions = [];
            cityOptions = [
              { id: "select-all", name: "Select All" },
              ...snapshot.val(),
            ];
            this.setState({ city: cityOptions });
            localStorage.setItem(
              "wallet_city_data",
              JSON.stringify(this.state.city)
            );
          },
          (err) => {
            console.log("cities read failed from firebase", err.code);
          }
        );
    }
  }
  afterFilterChange(field) {
    field = field || null;
    console.log(this.state.walletFilter);
    this.handleApplyFilter()
    localStorage.setItem(
      "wallet_filters",
      JSON.stringify(this.state.walletFilter)
    );
  }

  handleApplyFilter() {
    this.props.onApplyFilter(this.state.walletFilter, "wallet_filters");
    this.props.getSingleUserInfo(this.state.walletFilter.mobile_number);
  }

  cityWiseWorkShop() {
    var location_id = this.state.walletFilter.cities.id;
    this.setState({
      walletFilter: {
        ...this.state.walletFilter,
        users: [],
      },
    });
    var that = this;
    API.getGaragesByLocation(location_id).then(function (response) {
      if (response.data.status) {
        let result = [
          { id: "select", name: "Select User" },
          ...response.data.data,
        ];
        that.setState({ users: result });

        localStorage.setItem("wallet_user_type", JSON.stringify(result));
      }
    });
  }
  handleInput(input) {
    this.setState({
      walletFilter: {
        ...this.state.walletFilter,
        mobile_number: input,
      },
    });
  }
  downloadSampleCSV() {
    var csv = "Mobile,Expiry Date (YYYY-MM-DD),Source,Channel,Remark,Amount\n";
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";
    hiddenElement.download = "gomoney.csv";
    hiddenElement.click();
  }
  render() {
    const { users, city, walletFilter, request_type } =
      this.state;

    return (
      <div className="mainFilterContainer">
        <div
          className="row-5 margin_10 margin_b_20"
          style={{ gridTemplateColumns: "repeat(6,15%)" }}
        >
          <>
            <div className="margin_r_16">
              <MultiSelect
                options={request_type}
                labelKey="name"
                checkbox_id="name"
                placeholder="Request Type"
                disabledItems={[]}
                selectedOptions={this.state.walletFilter.request_type}
                onChange={(value) =>
                  this.setState(
                    {
                      walletFilter: {
                        users: [],
                        cities: [],
                        request_type: value,
                        mobile_number: null,
                      },
                    },
                    () => this.afterFilterChange()
                  )
                }
              />
            </div>
            {walletFilter &&
              walletFilter.request_type &&
              walletFilter.request_type.id === "0" && (
                <>
                  <div className="margin_r_16">
                    <input
                      className="input-box"
                      type="text"
                      placeholder=" Enter Mobile Number"
                      value={this.state.walletFilter.mobile_number}
                      onChange={(e) => this.handleInput(e.target.value)}
                      style={{ height: "40px" }}
                    />
                  </div>
                  {/* {this.state.mobile_number.length === 10 && (
                    <div className="row-5">
                      <div className="blackspan65 weight700 floatright addContentBtnMargin">
                        <table className="stdtable">
                          <tbody>
                            <tr>
                              <td valign="center">
                                <button
                                  id="addleadbtn"
                                  className="btn btn_small btncolorprim btncard move"
                                  onClick={this.handelSearch}
                                >
                                  Search
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )} */}
                </>
              )}
            {walletFilter &&
              walletFilter.request_type &&
              walletFilter.request_type.id === "1" && (
                <>
                  <div className="margin_r_16">
                    <MultiSelect
                      multiple
                      options={city}
                      labelKey="name"
                      checkbox_id="name"
                      placeholder="Select Cities"
                      disabledItems={[]}
                      selectedOptions={this.state.walletFilter.cities}
                      onChange={(value) =>
                        this.setState(
                          {
                            walletFilter: {
                              ...this.state.walletFilter,
                              cities: value,
                            },
                            showFilterDiv: true,
                          },
                          () => this.afterFilterChange("city")
                        )
                      }
                    />
                  </div>
                  <div className="margin_r_16" style={{ width: "150%" }}>
                    <MultiSelect
                      options={users}
                      labelKey="name"
                      checkbox_id="name"
                      placeholder="User Type"
                      disabledItems={[]}
                      selectedOptions={this.state.walletFilter.users}
                      onChange={(value) =>
                        this.setState(
                          {
                            walletFilter: {
                              ...this.state.walletFilter,
                              users: value,
                            },
                          },
                          () => this.afterFilterChange()
                        )
                      }
                    />
                  </div>
                </>
              )}
            {((walletFilter.users &&
              walletFilter.cities.length > 0 &&
              walletFilter.users.id &&
              walletFilter.request_type &&
              walletFilter.request_type.id === "1") ||
              (walletFilter.request_type &&
                walletFilter.request_type.id === "0" &&
                walletFilter.mobile_number &&
                walletFilter.mobile_number.length === 10)) && (
              <>
                <div
                  className="blackspan65 weight700 floatright addContentBtnMargin"
                  style={{ marginLeft: "60%" }}
                >
                  <button
                    style={{
                      background: "rgb(105,118,148,1)",
                      color: "rgb(255, 255, 255)",
                    }}
                    id="addleadbtn"
                    className="btn btn_small btncolorprim btncard move"
                    onClick={this.handleApplyFilter}
                  >
                    Apply Filter
                  </button>
                </div>
              </>
            )}
            {walletFilter &&
              walletFilter.request_type &&
              walletFilter.request_type.id === "2" && (
                <>
                  <div className="row-5">
                    <div className="blackspan65 weight700 floatright addContentBtnMargin">
                      <table className="stdtable">
                        <tbody>
                          <tr>
                            <td valign="center">
                              <button
                                id="addleadbtn"
                                className="btn btn_small btncolorprim btncard move"
                                onClick={this.downloadSampleCSV}
                              >
                                Download Sample CSV
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
          </>
        </div>
      </div>
    );
  }
}

export default WalletFilter;
