import React from "react";

export default function Footer() {
  return (
    <>
      <div
        style={{
          position: "fixed",
          bottom: "0px",
          width: "100%",
          height: "2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderTop: "1px solid #d8dbe0",
          background: "#ebedef",
        }}
      >
        <footer style={{ color: "#4f5d73" }}>
          <h5>@Copyright 2020 Design for GoMechanic </h5>
        </footer>
      </div>
    </>
  );
}
