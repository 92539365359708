import React, { useState, useEffect, useRef } from "react";
import { NotificationManager } from "react-notifications";
import API from "../../api";
import "flatpickr/dist/themes/material_green.css";
import "./ServiceTag.css";

function WarrantyPage (props) {
  const [warrantyData, setWarrantyData] = useState(null);
  const [warrantyLoader, setWarrantyLoader]=useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const imageReset = useRef();

  useEffect(() => {
    setWarrantyData(props.onApplyFilter.content);
  }, [props.onApplyFilter.content])

  const reset = () => {
    imageReset.current.value = ""
  }

  function extSubCategoryName(event, key) {
    key = event.target.value;
    var obj = {...warrantyData};
    if(props.onApplyFilter.filters.warrantySubServices.id == "1") {
      obj.warranty_ext[firstWarrantyExtKey].sub_category_name = key;
    }
    if (props.onApplyFilter.filters.warrantySubServices.id == "2") {
      obj.warranty_ext[secondWarrantyExtKey].sub_category_name = key;
    }
    setWarrantyData(obj);
  }

  function extSubText(event, key) {
    key = event.target.value;
    var obj = { ...warrantyData };
    if (props.onApplyFilter.filters.warrantySubServices.id == "1") {
      obj.warranty_ext[firstWarrantyExtKey].sub_text = key;
    } 
    if (props.onApplyFilter.filters.warrantySubServices.id == "2"){
      obj.warranty_ext[secondWarrantyExtKey].sub_text = key;
    }
    setWarrantyData(obj);
  }

  function handleServiceData(event, key, index, descIndex=null) {
    let obj = {...warrantyData};
    let updatedString = event.target.value;
    if (props.onApplyFilter.filters.warrantySubServices.id == "1") {
      let careFreeWarrantiesData = obj.warranty_ext[firstWarrantyExtKey].service_data[index]
      switch (key) {
        case "Retail Name":
          careFreeWarrantiesData.retail_name = updatedString;
          break;
        case "Tab Title":
          careFreeWarrantiesData.tab_title = updatedString;
          break
        case "Service Sub Title": 
          careFreeWarrantiesData.service_sub_title = updatedString;
          break
        case "Description Snippets": 
          careFreeWarrantiesData.desc_snippets[descIndex] = updatedString;
          break
      }
    } 
    if (props.onApplyFilter.filters.warrantySubServices.id == "2") {
      let customWarrantiesData = obj.warranty_ext[secondWarrantyExtKey].service_data[index]
      console.log(customWarrantiesData);
      switch (key) {
        case "Retail Name":
          customWarrantiesData.retail_name = updatedString;
          break;
        case "Tab Title":
          customWarrantiesData.tab_title = updatedString;
          break
        case "Service Sub Title":
          customWarrantiesData.service_sub_title = updatedString;
          break
        case "Description Snippets":
          customWarrantiesData.desc_snippets[descIndex] = updatedString;
          break
      }
    }
    setWarrantyData(obj);
  }

  function handleSubCategoryUpdate(index) {
    let payloadObj = {};
    if(index === "0") {
      payloadObj["warranty_ext"] = warrantyData.warranty_ext[firstWarrantyExtKey];
      setWarrantyLoader(true);
      API.updateWarrantyPageData(payloadObj)
        .then(function (response) {
          if(response.status) {
            setWarrantyLoader(false);
            NotificationManager.success("Data Updated", "Successfully", 5000);
          }
        })
        .catch(function (error) {
          // handle error
          setWarrantyLoader(false);
          NotificationManager.error("Please try again", "Error", 5000);
        })
    }
    if (index === "1") {
      payloadObj["warranty_ext"] = warrantyData.warranty_ext[secondWarrantyExtKey];
      setWarrantyLoader(true);
      API.updateWarrantyPageData(payloadObj)
        .then(function (response) {
          if (response.status) {
            setWarrantyLoader(false);
            NotificationManager.success("Data Updated", "Successfully", 5000);
          }
        })
        .catch(function (error) {
          // handle error
          setWarrantyLoader(false);
          NotificationManager.error("Please try again", "Error", 5000);
        })
    }
  }

  function handleFaqData(event, key, index) {
    let obj = { ...warrantyData };
    let updatedString = event.target.value;
    obj.warranty_faqs.list[index][key] = updatedString;
    setWarrantyData(obj);
  }
  
  function handleFaqUpdate() {
    let payloadObj = {};
    payloadObj["warranty_faqs"] = warrantyData.warranty_faqs;
    setWarrantyLoader(true);
    API.updateWarrantyPageData(payloadObj)
      .then(function (response) {
        if (response.status) {
          NotificationManager.success("Data Updated", "Successfully", 5000);
          setWarrantyLoader(false);
        }
      })
      .catch(function (error) {
        // handle error
        setWarrantyLoader(false);
        NotificationManager.error("Please try again", "Error", 5000);
      })
  }

  function handleContentData(event, keyName, index, arrayIndex, identifierString="") {
    let obj = { ...warrantyData };
    let updatedString = event.target.value;
    let idFromFilter = props.onApplyFilter.filters.warrantySubServices.id;

    if (idFromFilter === "1") {
      obj.warranty_content.extended_warranty[keyName][arrayIndex] = updatedString;
    }
    if (idFromFilter === "2") {
      obj.warranty_content.warranty_savings[keyName] = updatedString;
    }
    if (idFromFilter === "3" && index === 0) {
      obj.warranty_content.warranty_claim[keyName] = updatedString;
    }
    if (idFromFilter === "3" && index === 1) {
      obj.warranty_content.warranty_claim[keyName][arrayIndex]["text"] = updatedString;
    }
    if (idFromFilter === "4" && index === 0) {
      obj.warranty_content.warranty_numbers[keyName] = updatedString;
    }
    if (idFromFilter === "4" && index === 1 && identifierString === "text") {
      obj.warranty_content.warranty_numbers[keyName][arrayIndex]["text"] = updatedString;
    }
    if (idFromFilter === "4" && index === 1 && identifierString === "sub_text") {
      obj.warranty_content.warranty_numbers[keyName][arrayIndex]["sub_text"] = updatedString;
    }
    setWarrantyData(obj);
  }

  function handleCarouselImageUpdate(e, index) {
    e.preventDefault();
    if (uploadFile) {
      var file = uploadFile[0];
      if (!file.type.match("image.*")) {
        NotificationManager.error("The file is not an image", "Error", 5000);
        return;
      }
    }
    let formData = new FormData();
    let obj = { extended_warranty: { update_image_index: index}};
    let stringObj = JSON.stringify(obj);
    let idData = {"id": 47}

    formData.append("file", file);
    formData.append("warranty_content", stringObj);

    setWarrantyLoader(true);
    API.updateWarrantyPageData(formData)
      .then(function (response) {
        if (response.status) {
          API.getWarrantyPageFilterData(idData)
            .then(function(response) {
              if(response.status) {
                setWarrantyData(response.data.data)
              }
            })
            .catch(function (error) {
              // handle error
              NotificationManager.error("Please try again", "Error", 5000);
              console.log(error, "Error in updating carousel image");
              setUploadFile(null);
              setWarrantyLoader(false);
              reset();
            });
          setWarrantyLoader(false);
          setUploadFile(null);
          reset();
        }
        NotificationManager.success(
          `Image uploaded`,
          "Successfully",
          5000
        );
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating carousel image");
        setUploadFile(null);
        setWarrantyLoader(false);
        reset();
      });
  };

  function handleContentUpdate() {
    let payloadObj = {};
    payloadObj["warranty_content"] = warrantyData.warranty_content;
    setWarrantyLoader(true);
    API.updateWarrantyPageData(payloadObj)
      .then(function (response) {
        if (response.status) {
          setWarrantyLoader(false);
          NotificationManager.success("Data Updated", "Successfully", 5000);
        }
      })
      .catch(function (error) {
        // handle error
        setWarrantyLoader(false);
        NotificationManager.error("Please try again", "Error", 5000);
      })
  }
  var singleSectionData = {...warrantyData};
  let comprehensiveCoverageData;
  let subCategoryString;
  let subTextString;
  let serviceData;
  let retailName;
  let tabTitle;
  let serviceSubTitle;
  let descSnippets;
  let descSnippetData;
  let contentObjKeys;
  var warrantyExtData = singleSectionData && singleSectionData.warranty_ext;
  var warrantyExtKeys = warrantyExtData && Object.keys(warrantyExtData);
  var firstWarrantyExtKey = warrantyExtKeys && warrantyExtKeys[0];
  var secondWarrantyExtKey = warrantyExtKeys && warrantyExtKeys[1];
  
  function getCategoryString(type, index) {
    warrantyExtKeys = warrantyExtData && Object.keys(warrantyExtData);
    let warrantyKey = index == 1 ? firstWarrantyExtKey : secondWarrantyExtKey
    let comprehensiveCoverageData = singleSectionData && singleSectionData.warranty_ext[warrantyKey];
    let subCategoryString = comprehensiveCoverageData && comprehensiveCoverageData.sub_category_name;
    let subTextString = comprehensiveCoverageData && comprehensiveCoverageData.sub_text;
    if(type == "subCategory") {
      return subCategoryString;
    }else if(type == "subText") {
      return subTextString;
    }else{
      return ""
    }
  }

  return (
    <>
    {(props.loader || warrantyLoader) ? (
      <>
        <div className="wp-modal-sb"></div>
        <div className="sub-modal-sb"></div>
      </>
    ) : (
      ""
    )}
    <div className="mainContainer" style={{width: "50%"}}>
      <div className="manageTimeSlot">
        Warranty Description
      </div>
      {/* Warranty extensiton start */}
      {warrantyData && warrantyData.warranty_ext && props.onApplyFilter.filters && props.onApplyFilter.filters.channel && props.onApplyFilter.filters.channel.id === 4 && props.onApplyFilter.filters.warrantyServices.name === "Extended" && props.onApplyFilter.filters.warrantySubServices && props.onApplyFilter.filters.warrantySubServices.id == "1" &&
      <>
        <div className="innerBox">
          <>
            <div style={{ margin: "1rem 0" }}>
            <span>Sub category name</span>
              <input
                type="text"
                defaultValue={getCategoryString("subCategory", 1)}
                name="title"
                  onBlur={(e) => extSubCategoryName(e, getCategoryString("subCategory", 1))}
                />
            </div>
            <div style={{ margin: "1rem 0" }}>
            <span>Sub text</span>
              <input
                type="text"
                  defaultValue={getCategoryString("subText", 1)}
                name="title"
                  onBlur={(e) => extSubText(e, getCategoryString("subText", 1))}
                />
            </div>
              
          </>

          <>
          {singleSectionData && singleSectionData.warranty_ext && singleSectionData.warranty_ext[firstWarrantyExtKey] && singleSectionData.warranty_ext[firstWarrantyExtKey]["service_data"].map((data, index) => {
              retailName = data.retail_name;
              tabTitle = data.tab_title;
              serviceSubTitle = data.service_sub_title;
              descSnippets = data.desc_snippets;
              return (<>
                <div className="" style={{ border: "none", textShadow: 'none', background: "#fff !important", fontSize: "1rem", fontWeight: "650", background: "#f6f6f6", padding: ".5rem"}}>
                  {tabTitle}
                </div>
              <div style={{ margin: "1rem 0" }}>
                <span>Retail Name</span>
                <input
                  type="text"
                  defaultValue={retailName}
                  name="title"
                    onBlur={(e) => handleServiceData(e, "Retail Name", index)}
                />
              </div>
              <div style={{ margin: "1rem 0" }}>
                <span>Tab Title</span>
                <input
                  type="text"
                  defaultValue={tabTitle}
                  name="title"
                    onBlur={(e) => handleServiceData(e, "Tab Title", index)}
                />
              </div>
              <div style={{ margin: "1rem 0" }}>
                <span>Service Sub Title</span>
                <input
                  type="text"
                  defaultValue={serviceSubTitle}
                  name="title"
                  onBlur={(e) => handleServiceData(e, "Service Sub Title", index)}
                />
              </div>
              <span>Description Snippets</span>
              {descSnippets.map((data, i) => {
                  descSnippetData = data;
                  return (<div style={{ margin: "1rem 0" }} >
                    <input
                      type="text"
                      defaultValue={descSnippetData}
                      name="title"
                      onBlur={(e) => handleServiceData(e, "Description Snippets", index, i)}
                    />
                  </div>
                  )
                })
              }
              </>)
            })
          }
            <div
              style={{
                textAlign: "right",
                marginBottom: "1rem",
                marginTop: "1rem",
              }}
            >
              <button className="button-update" onClick={() => handleSubCategoryUpdate("0")}>Update</button>
            </div>
          </>
        </div>
      </>}

      {warrantyData && warrantyData.warranty_ext && props.onApplyFilter.filters && props.onApplyFilter.filters.channel && props.onApplyFilter.filters.channel.id === 4 && props.onApplyFilter.filters.warrantyServices.name === "Extended" && props.onApplyFilter.filters.warrantySubServices && props.onApplyFilter.filters.warrantySubServices.id == "2" &&
        <>
          <div className="innerBox">
            <div style={{ margin: "1rem 0" }}>
              <span>Sub category name</span>
              <input
                type="text"
                defaultValue={getCategoryString("subCategory", 2)}
                name="title"
                onBlur={(e) => extSubCategoryName(e, getCategoryString("subCategory", 2))}
              />
            </div>
            <div style={{ margin: "1rem 0" }}>
              <span>Sub text</span>
              <input
                type="text"
                defaultValue={getCategoryString("subText", 2)}
                name="title"
                onBlur={(e) => extSubText(e, getCategoryString("subText", 2))}
              />
            </div>
            <>
              {singleSectionData && singleSectionData.warranty_ext && singleSectionData.warranty_ext[secondWarrantyExtKey] && singleSectionData.warranty_ext[secondWarrantyExtKey]["service_data"].map((data, index) => {
                retailName = data.retail_name;
                tabTitle = data.tab_title;
                serviceSubTitle = data.service_sub_title;
                descSnippets = data.desc_snippets;
                return (<>
                  <div className="" style={{ border: "none", textShadow: 'none', background: "#fff !important", fontSize: "1rem", fontWeight: "650", background: "#f6f6f6", padding: ".5rem"}}>
                    {tabTitle}
                  </div>
                  <div style={{ margin: "1rem 0" }}>
                    <span>Retail Name</span>
                    <input
                      type="text"
                      defaultValue={retailName}
                      name="title"
                      onBlur={(e) => handleServiceData(e, "Retail Name", index)}
                    />
                  </div>
                  <div style={{ margin: "1rem 0" }}>
                    <span>Tab Title</span>
                    <input
                      type="text"
                      defaultValue={tabTitle}
                      name="title"
                      onBlur={(e) => handleServiceData(e, "Tab Title", index)}
                    />
                  </div>
                  <div style={{ margin: "1rem 0" }}>
                    <span>Service Sub Title</span>
                    <input
                      type="text"
                      defaultValue={serviceSubTitle}
                      name="title"
                      onBlur={(e) => handleServiceData(e, "Service Sub Title", index)}
                    />
                  </div>
                  <span>Description Snippets</span>
                  {descSnippets.map((data, i) => {
                    descSnippetData = data;
                    return (<div style={{ margin: "1rem 0" }} >
                      <input
                        type="text"
                        defaultValue={descSnippetData}
                        name="title"
                        onBlur={(e) => handleServiceData(e, "Description Snippets", index, i)}
                      />
                    </div>
                    )
                  })
                  }
                </>)
              })
              }
              <div
                style={{
                  textAlign: "right",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
              >
                <button className="button-update" onClick={()=> handleSubCategoryUpdate("1")}>Update</button>
              </div>
            </>
          </div>
        </>
      }
      {/* Warranty extensiton end */}

      {/* Warranty Content start */}
      {warrantyData && warrantyData.warranty_content && props.onApplyFilter.filters && props.onApplyFilter.filters.channel && props.onApplyFilter.filters.channel.id === 4 && props.onApplyFilter.filters.warrantyServices.name === "Content" && props.onApplyFilter.filters.warrantySubServices && props.onApplyFilter.filters.warrantySubServices.name === "Extended Warranty" &&
        <>
        <div className="innerBox">
          {warrantyData.warranty_content.extended_warranty && Object.keys(warrantyData.warranty_content.extended_warranty).map((data, index) => {
            let keyName = data;
            let extendedWarrantyData = warrantyData.warranty_content.extended_warranty[keyName];

            if (data === "arrows" || data === "bullets") {
              return (
                <div style={{ margin: "1rem 0" }}>
                  <span style={{ textTransform: 'capitalize', fontWeight: "650"}}>{keyName}</span>
                  {extendedWarrantyData.map((arrayData, arrayIndex) => {
                    return (<>
                      <input
                        type="text"
                        defaultValue={arrayData}
                        name="title"
                        onBlur={(e) => handleContentData(e, keyName, index, arrayIndex)}
                        style={{ marginTop: "1rem" }}
                      />
                    </>)
                  })}
              </div>
            )}
          })
          }
          <div
            style={{
              textAlign: "right",
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
          >
            <button className="button-update" onClick={() => handleContentUpdate()}>Update</button>
          </div>
        </div>
        </>
      }

        {warrantyData && warrantyData.warranty_content && warrantyData.warranty_content.extended_warranty && props.onApplyFilter && props.onApplyFilter.filters && props.onApplyFilter.filters.warrantyServices && props.onApplyFilter.filters.warrantyServices.id && props.onApplyFilter.filters.warrantyServices.id == 4 &&
        <>
          {console.log(props.onApplyFilter.filters.warrantyServices.id, 'props')}
        <div className="innerBox" style={{padding: "1rem"}}>
            <span className="warranty-carousel-title">Carousel Images</span>
            <div className="warranty-carousel-image-container">
              {warrantyData.warranty_content.extended_warranty["carousel_images"].map((data, index) => {
                return (
                <>
                  <form>
                    <div className="category-card"> 
                      <img
                        src={
                            data
                              ? `${data}?version=${new Date().getTime()}`
                            : `https://via.placeholder.com/580x261?text=Image+Not+Uploaded`
                        }
                        alt=""
                        title="image-preview"
                      />
                      <div>
                        <input
                          type="file"
                          name="image_banner"
                          ref={imageReset}
                          onChange={
                            (e) => setUploadFile(e.target.files)
                          }
                        />
                      </div>
                      <div
                        style={{
                          textAlign: "right",
                          marginBottom: "1rem",
                          marginTop: "1rem",
                        }}
                      >
                          <button className="button-update" disabled={!uploadFile} style={(!uploadFile) ? { cursor: "not-allowed" } : { cursor: "pointer" }} 
                          onClick={(e) => handleCarouselImageUpdate(e, index)}>Update</button>
                      </div>
                  </div>
                </form>
              </>
                )
              }) 
              }
          </div>
        </div>
        </>
      }
      {warrantyData && warrantyData.warranty_content && props.onApplyFilter.filters && props.onApplyFilter.filters.channel && props.onApplyFilter.filters.channel.id === 4 && props.onApplyFilter.filters.warrantyServices.name === "Content" && props.onApplyFilter.filters.warrantySubServices && props.onApplyFilter.filters.warrantySubServices.name === "Warranty Savings" &&
        <>
        { warrantyData.warranty_content.warranty_savings && Object.keys(warrantyData.warranty_content.warranty_savings).map((data, index) => {
            let dataString = warrantyData.warranty_content.warranty_savings[data];
            return (
              <div className="innerBox">
                <div style={{ margin: "1rem 0" }}>
                  <span style={{ textTransform: 'capitalize' }}>{data}</span>
                  <input
                    type="text"
                    defaultValue={dataString}
                    name="title"
                    onBlur={(e) => handleContentData(e, data, index)}
                  />
                </div>
              </div>
            )
          })
        }
        <div
          style={{
            textAlign: "right",
            marginBottom: "1rem",
            marginTop: "1rem",
          }}
        >
          <button className="button-update" onClick={() => handleContentUpdate()}>Update</button>
        </div>
        </>
      }

      {warrantyData && warrantyData.warranty_content && props.onApplyFilter.filters && props.onApplyFilter.filters.channel && props.onApplyFilter.filters.channel.id === 4 && props.onApplyFilter.filters.warrantyServices.name === "Content" && props.onApplyFilter.filters.warrantySubServices && props.onApplyFilter.filters.warrantySubServices.name === "Warranty Claim" &&
      <>
      <div className="innerBox" style={{ padding: "0 1rem" }}>
      <div style={{ margin: "1rem 0" }}>
      { warrantyData.warranty_content.warranty_claim &&  Object.keys(warrantyData.warranty_content.warranty_claim).map((data, index) => {
          let stepsData = warrantyData.warranty_content.warranty_claim[data];
            if (data === "title") {
              return (
                <>
                <span style={{ textTransform: 'capitalize' }}>{data}</span>
                <input
                  type="text"
                  defaultValue={warrantyData.warranty_content.warranty_claim.title}
                  name="title"
                  onBlur={(e) => handleContentData(e, data, index)}
                  style={{ marginBottom: "2rem" }}
                />
                </>
            )}
            if (data === "steps") {
              let stepNumber;
              let stepsObj;
              return (
                stepsData.map((arrayData, arrayIndex) => {
                return (
                stepNumber = arrayData.step_no,
                stepsObj = {...arrayData},
                <>
                  <span style={{ textTransform: 'capitalize' }}>{`Step: ${arrayIndex + 1}`}</span>
                  <input
                    type="text"
                    defaultValue={stepsObj.text}
                    name="title"
                    onBlur={(e) => handleContentData(e, data, index, arrayIndex)}
                    style={{marginBottom: "2rem"}}
                  />
                </>)
              })
              )
            }
          })
      }
      </div>
      </div>
        <div
          style={{
            textAlign: "right",
            marginBottom: "1rem",
            marginTop: "1rem",
          }}
        >
          <button className="button-update" onClick={() => handleContentUpdate()}>Update</button>
        </div>
      </>
      }

      {warrantyData && warrantyData.warranty_content && props.onApplyFilter.filters && props.onApplyFilter.filters.channel && props.onApplyFilter.filters.channel.id === 4 && props.onApplyFilter.filters.warrantyServices.name === "Content" && props.onApplyFilter.filters.warrantySubServices && props.onApplyFilter.filters.warrantySubServices.name === "Warranty Numbers" &&
        <>
          <div className="innerBox" style={{ padding: "0 1rem" }}>
            <div style={{ margin: "1rem 0" }}>
            {   warrantyData.warranty_content.warranty_numbers && Object.keys(warrantyData.warranty_content.warranty_numbers).map((data, index) => {
                  let stepsData = warrantyData.warranty_content.warranty_numbers[data];
                  if (data === "title") {
                    return (
                      <>
                        <span style={{ textTransform: 'capitalize' }}>{data}</span>
                        <input
                          type="text"
                          defaultValue={warrantyData.warranty_content.warranty_numbers.title}
                          name="title"
                          onBlur={(e) => handleContentData(e, data, index)}
                          style={{ marginBottom: "2rem" }}
                        />
                      </>
                    )
                  }
                  if (data === "list") {
                    let listNumber;
                    let listObj;
                    return (
                      stepsData.map((arrayData, arrayIndex) => {   
                        return (
                          listObj = { ...arrayData },
                          <>
                            <span style={{ textTransform: 'capitalize' }}>{`Item: ${arrayIndex + 1}`}</span>
                            <input
                              type="text"
                              defaultValue={listObj.text}
                              name="title"
                              onBlur={(e) => handleContentData(e, data, index, arrayIndex, "text")}
                              style={{ marginBottom: "2rem" }}
                            />
                            <input
                              type="text"
                              defaultValue={listObj.sub_text}
                              name="title"
                              onBlur={(e) => handleContentData(e, data, index, arrayIndex, "sub_text")}
                              style={{ marginBottom: "2rem" }}
                            />
                          </>)
                      })
                    )
                  }
                })
              }
            </div>
          </div>
          <div
            style={{
              textAlign: "right",
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
          >
            <button className="button-update" onClick={() => handleContentUpdate()}>Update</button>
          </div>
        </>
      }
      {/* Warranty Content end */}

      {/* Warranty FAQ start */}
      {warrantyData && warrantyData.warranty_faqs && props.onApplyFilter.filters && props.onApplyFilter.filters.channel && props.onApplyFilter.filters.channel.id === 4 && props.onApplyFilter.filters.warrantyServices.name === "FAQs" &&
      <>
        <div className="innerBox">
          <div style={{ margin: "1rem 0" }}>
            {warrantyData.warranty_faqs.list.map((data, index) => {
              let questionString = data.question;
              let answerString = data.answer;
              return (
              <>
                <span>{`Q ${index + 1}`}</span>
                <input
                  type="text"
                  defaultValue={questionString}
                  name="title"
                  onBlur={(e) => handleFaqData(e, "question", index)}
                />
                <span>Answer</span>
                <textarea 
                  id="answer" 
                  name="answer" 
                  rows="5" 
                  cols="50" 
                  defaultValue={answerString}
                  style={{marginBottom: "2rem"}}
                  onBlur={(e) => handleFaqData(e, "answer", index)}
                  />
              </>
              )
            })}
            <div
              style={{
                textAlign: "right",
                marginBottom: "1rem",
                marginTop: "1rem",
              }}
            >
              <button className="button-update" onClick={() => handleFaqUpdate()}>Update</button>
            </div>
          </div>
        </div>
      </>}
      {/* Warranty FAQ end */}
    </div>
    </>
  )
}

export default WarrantyPage;