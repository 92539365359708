import React from "react";
import Constants from "../constants";

class Row extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
  }
  handleButtonClick(type, event) {
    event.stopPropagation();
    // this.props.onRowClick(this.props.data, this.props.tag);

    this.props.actionButton(
      type,
      'create_coupon',
      this.props.data.id,
      this.props.data,
      this.props.data
    );
  }
  handleClick(pageUrl) {
    //   this.props.onRowClick(this.props.data, this.props.tag);
    /*this.setState(state => ({
            isToggleOn: !state.isToggleOn
          }));*/
    //console.log()
    window.open(pageUrl);
  }
  handleInputBlur(rowData, e, type) {
    console.log(rowData, 'value', e.target.value);
    let obj = rowData;
    if (type in obj) {
      obj[type] = e.target.value ? parseInt(e.target.value) : null;
      this.props.handleInputChange(obj);
    }
  }


  render() {
    switch (this.props.tag) {
      case "coupon_control":
        
        let couponInfo = this.props.data && this.props.data ? this.props.data : {};
        let coupon_type = this.props.data && this.props.data.coupon_type ? this.props.data.coupon_type : "";
        let discount_type = this.props.data && this.props.data.discount_type ? this.props.data.discount_type : "";
        let discount_on = this.props.data && this.props.data.discount_on ? this.props.data.discount_on : "";
        let code = this.props.data && this.props.data.code ? this.props.data.code : "NA";
        let id = this.props.data && this.props.data.id ? this.props.data.id : "NA";
        let percentage = this.props.data && this.props.data.percentage ? this.props.data.percentage : "NA";
       
        return (
          <div>
            <div
              // style={{ background: "#eee" }}
              className={
                this.props.selectedRow === this.props.data.id
                  ? "boxes orders border-box clearfix leftalign blackspan9 selected"
                  : "boxes orders border-box clearfix leftalign blackspan9"
              }
            >
              <div className="textsize12 border-box weight500">
                <table className="stdtable middleUpperTable">
                  <tbody>
                    <tr className="middleUpperTR">
                      <td className="middleUpperTD width7TH">{id}</td>
                      <td className="middleUpperTD width7TH">{code}</td>
                      <td className="middleUpperTD width7TH">{coupon_type}</td>
                      <td className="middleUpperTD width7TH">
                        {discount_type}
                      </td>
                      <td className="middleUpperTD width7TH">{discount_on}</td>
                      <td className="middleUpperTD width7TH">{percentage}</td>
                      <td className="middleUpperTD width7TH">
                        <button
                          onClick={(e) => this.handleButtonClick("edit", e)}
                          className="btn btn_small btncolorprim btncard move"
                          style={{ width: "5rem" }}
                        >
                          Edit
                        </button>
                        <button
                          onClick={(e) =>
                            this.handleButtonClick("duplicate", e)
                          }
                          className="btn btn_small btncolorprim btncard move"
                          style={{
                            background: "rgb(105, 118, 148)",
                            color: "rgb(255, 255, 255)",
                          }}
                        >
                          Duplicate
                        </button>
                      </td>

                      {/* <td className="middleUpperTD width2TH">
                          <input
                            type="text"
                            placeholder="Maximum Rate"
                            defaultValue={couponInfo.id}
                            className="inputDealPrice"
                            onBlur={e => this.handleInputBlur(couponInfo, e, "max_price")}
                            style={{textAlign:'center'}}
                          />
                        </td> */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }
}

export default Row;
