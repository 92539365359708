import React from "react";
import firebase from "../../../firebase";
import "./imageCarouselFilter.css";
import MultiSelect from "../../custom/MultiSelect/MultiSelect";
import Constants from "../../../constants";

class ImageCarouselFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channel: [{
        "id" : 'select',
        "name" : "Select Channel"
      },{
        "id" : 1,
        "name" : "Website"
      }, {
        "id" : 2,
        "name" : "App"
      }, {
        "id" : 3,
        "name" : "Luxury App"
      },{
        "id" : 4,
        "name" : "Vertical Page"
      },{
        "id" : 5,
        "name" : "Square Banner"
      },{
        "id" : 6,
        "name" : "Middle Banner"
      },{
        "id" : 7,
        "name" : "Climate Banner"
      },{
        "id" : 8,
        "name" : "Performance Banner"
      },{
        "id" : 9,
        "name" : "Accessories Banner"
      },{
      "id": 10,
      "name": "Service Combos"
      },{
        "id": 11,
        "name": "Accessories Best Sellers"
      },{
        "id": 12,
        "name": "Home Bottom Strip"
      },{
        "id": 13,
        "name": "Category Section"
      }],
      version: [{
        "id" : 'select',
        "name" : "Select Version"
      },{
        "id":"old",
        "name":"Old"
      },{
        "id":"new",
        "name":"New"
      }],
      versionList: [{
        "id" : 'select',
        "name" : "Select Version"
      },{
        "id":"old",
        "name":"Old"
      },{
        "id":"new",
        "name":"New"
      },
      {
        "id": "v2",
        "name": "V2"
      }],
      size: [{
        "id" : 'select',
        "name" : "Select Size"
      },{
        "id" : 1,
        "name" : "ldpi"
      }, {
        "id" : 2,
        "name" : "mdpi"
      }, {
        "id" : 3,
        "name" : "hdpi"
      },{
        "id" : 4,
        "name" : "xhdpi"
      },{
        "id" : 5,
        "name" : "xxhdpi"
      },{
        "id" : 6,
        "name" : "xxxhdpi"
      }],
      serviceCombo:[{
        "id":0,
        "channel_sub_type": "accessories"
      }, {
        "id":1,
        "channel_sub_type": "retail"
      }],
      carType:[{
        "id" : 'select',
        "name" : "Select Car Type"
      },{
        "id": 1,
        "name": "Luxury"
      }, {
        "id": 2,
        "name": "General"
      }],
      city:[],
      imageFilter: {
        channelType: [],
        cities:[],
        version:[],
        size:[],
        serviceComboChannels: [],
        carType: [],
        segment: [],
      },
      showFilterDiv: false,
      segment_data: [
        {id:'select-all',name:'Select All'},
        {id: 1, name: "Sedan"},
        {id: 2, name: "Premium Hatchback"},
        {id: 3, name: "Hatchback"},
        {id: 4, name: "SUV"},
        {id: 5, name: "Premium SUV"},
        {id: 6, name: "Premium Sedan"}
      ],
    };

    this.handleApplyFilter = this.handleApplyFilter.bind(this);
    this.afterFilterChange = this.afterFilterChange.bind(this);
  }
  componentWillMount() {
    this.state.showFilterDiv = true;
    if(localStorage.getItem("city_data") != undefined){
      this.state.city = Constants.getCityData();
    }
  }
  componentDidMount() {
    /* Setting Filters Data from localstorage*/
    if (localStorage.getItem("image_filters") != undefined) {
      this.setState({
        imageFilter: JSON.parse(localStorage.getItem("image_filters")),
      });
    }
    // var cityOptions = [];
    // cityOptions = [{id:'select-all',name:'Select All'}, ...firebaseData.cities]
    // this.setState({city:cityOptions});
  }
  afterFilterChange(field) {
    field = field || null;

    localStorage.setItem(
      "image_filters",
      JSON.stringify(this.state.imageFilter)
    );
  }

  handleApplyFilter() {
    this.props.onApplyFilter(this.state.imageFilter, 'image_filters');
  }
  render() {
    const {
      channel,
      city,
      version,
      size,
      serviceCombo,
      carType,
      versionList,
      segment_data,
    } = this.state;

    return (
      <div className="mainFilterContainer">
        <div
          className="row-5 margin_10 margin_b_20"
          style={{ gridTemplateColumns: "repeat(6,20%)" }}
        >
          <div className="margin_r_16">
            <MultiSelect
              options={channel}
              labelKey="name"
              checkbox_id="name"
              placeholder="Channel Type"
              disabledItems={[]}
              selectedOptions={this.state.imageFilter.channelType}
              onChange={(value) =>
                this.setState(
                  {
                    imageFilter: {
                      ...this.state.imageFilter,
                      channelType: value,
                    },
                    showFilterDiv: true,
                  },
                  () => this.afterFilterChange()
                )
              }
            />
          </div>
          {this.state.imageFilter.channelType.id && (
            <React.Fragment>
              {this.state.imageFilter.channelType.id != 12 && this.state.imageFilter.channelType.id != 13 &&
                <React.Fragment>
                  <div className="margin_r_16">
                    <MultiSelect
                      multiple
                      options={city}
                      labelKey="name"
                      checkbox_id="name"
                      placeholder="Select City"
                      disabledItems={[]}
                      selectedOptions={this.state.imageFilter.cities}
                      onChange={(value) =>
                        this.setState(
                          {
                            imageFilter: {
                              ...this.state.imageFilter,
                              cities: value,
                            },
                          },
                          () => this.afterFilterChange()
                        )
                      }
                    />
                  </div>
                </React.Fragment>
              }
              {this.state.imageFilter.channelType.id && this.state.imageFilter.channelType.id == 10 && (
                <React.Fragment>
                  <div className="margin_r_16">
                    <MultiSelect
                      options={serviceCombo}
                      labelKey="channel_sub_type"
                      checkbox_id="channel_sub_type"
                      placeholder="Select Combo Channel"
                      disabledItems={[]}
                      selectedOptions={this.state.imageFilter.serviceComboChannels}
                      onChange={(value) =>
                        this.setState(
                          {
                            imageFilter: {
                              ...this.state.imageFilter,
                              serviceComboChannels: value,
                            },
                          },
                          () => this.afterFilterChange()
                        )
                      }
                    />
                  </div>
                </React.Fragment>
              )}

              {(this.state.imageFilter.channelType.id != 5 && this.state.imageFilter.channelType.id != 6 && this.state.imageFilter.channelType.id != 7 && this.state.imageFilter.channelType.id != 9 && this.state.imageFilter.channelType.id != 10 && this.state.imageFilter.channelType.id != 11 && this.state.imageFilter.channelType.id != 12 && this.state.imageFilter.channelType.id != 13) &&
                <React.Fragment>
                  <div className="margin_r_16">
                    <MultiSelect
                      options={version}
                      labelKey="name"
                      checkbox_id="name"
                      placeholder="Select Version"
                      disabledItems={[]}
                      selectedOptions={this.state.imageFilter.version}
                      onChange={(value) =>
                        this.setState(
                          {
                            imageFilter: {
                              ...this.state.imageFilter,
                              version: value,
                            },
                          },
                          () => this.afterFilterChange()
                        )
                      }
                    />
                  </div>
                </React.Fragment>
              }
              {this.state.imageFilter.channelType.id === 6 && 
                <React.Fragment>
                  <div className="margin_r_16">
                    <MultiSelect
                      options={versionList}
                      labelKey="name"
                      checkbox_id="name"
                      placeholder="Select Version"
                      disabledItems={[]}
                      selectedOptions={this.state.imageFilter.version}
                      onChange={(value) =>
                        this.setState(
                          {
                            imageFilter: {
                              ...this.state.imageFilter,
                              version: value,
                            },
                          },
                          () => this.afterFilterChange()
                        )
                      }
                    />
                  </div>
                </React.Fragment>
              }
              {(this.state.imageFilter.channelType.id == 4 || this.state.imageFilter.channelType.id == 13) &&
                <React.Fragment>
                  <div className="margin_r_16">
                    <MultiSelect
                      options={size}
                      labelKey="name"
                      checkbox_id="name"
                      placeholder="Select Size"
                      disabledItems={[]}
                      selectedOptions={this.state.imageFilter.size}
                      onChange={(value) =>
                        this.setState(
                          {
                            imageFilter: {
                              ...this.state.imageFilter,
                              size: value,
                            },
                          },
                          () => this.afterFilterChange()
                        )
                      }
                    />
                  </div>
                </React.Fragment>
              }
              {this.state.imageFilter.channelType.id == 6 &&
                <React.Fragment>
                  <div className="margin_r_16">
                    <MultiSelect
                      options={carType}
                      labelKey="name"
                      checkbox_id="name"
                      placeholder="Select Car Type"
                      disabledItems={[]}
                      selectedOptions={this.state.imageFilter.carType}
                      onChange={(value) =>
                        this.setState(
                          {
                            imageFilter: {
                              ...this.state.imageFilter,
                              carType: value,
                            },
                          },
                          () => this.afterFilterChange()
                        )
                      }
                    />
                  </div>
                </React.Fragment>
              }
              {(this.state.imageFilter.channelType.id == 2 && this.state.imageFilter.version.id == 'new' ) &&
                <React.Fragment>
                  <div className="margin_r_16">
                    <MultiSelect
                      multiple
                      options={segment_data}
                      labelKey="name"
                      checkbox_id="name"
                      placeholder="Select Segment"
                      disabledItems={[]}
                      selectedOptions={this.state.imageFilter.segment}
                      onChange={(value) =>
                        this.setState(
                          {
                            imageFilter: {
                              ...this.state.imageFilter,
                              segment: value,
                            },
                          },
                          () => this.afterFilterChange()
                        )
                      }
                    />
                  </div>
                </React.Fragment>
              }
              <React.Fragment>
                <div className="blackspan65 weight700 floatright addContentBtnMargin">
                  <button
                    style={{
                      background: "rgb(105,118,148,1)",
                      color: "rgb(255, 255, 255)",
                    }}
                    id="addleadbtn"
                    className="btn btn_small btncolorprim btncard move"
                    onClick={this.handleApplyFilter}
                  >
                    Apply Filter
                  </button>
                </div>
              </React.Fragment>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default ImageCarouselFilter;
