import React from 'react';
import './webFilter.css';
import firebase from '../../firebase';
import MultiSelect from '../custom/MultiSelect/MultiSelect';
import InputBox from '../custom/InputBox/InputBox';
import API from "../../api";
import Constants from "../../constants"

// const filters = {"city":true,"brand":true,"model":false,"fuel":true};
class WebFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            filters:[],
            page:[],
            city:[],
            brands:[],
            models:[],
            brandWiseModels:[],
            fuelType:[],
            services:[],
            regions:[],
            pagePath:[],
            subService:[],
            isCarDetailsMultiple: true,
            showServiceFilter: false,
            filterData : {
                pageType:[],
                cities:[],
                brands:[],
                fuelType:[],
                models:[],
                services:[],
                regions:[],
                pageUrl:[],
                subService:[],
            },
            showFilterDiv:false
        };

        this.handleApplyFilter  = this.handleApplyFilter.bind(this);
        this.afterSetPageType   = this.afterSetPageType.bind(this);
        this.afterFilterChange  = this.afterFilterChange.bind(this);
        this.cityWiseRegion     = this.cityWiseRegion.bind(this);
        this.brandWiseModel     = this.brandWiseModel.bind(this);
        this.getNearMePagePath     = this.getNearMePagePath.bind(this);
        this.setCarMultipleFlag     = this.setCarMultipleFlag.bind(this);
        this.handleCategoryChange     = this.handleCategoryChange.bind(this);
        this.handleInput     = this.handleInput.bind(this);
    }
    componentWillMount()
    {
        if(localStorage.getItem("page_type") != undefined){  
            this.state.page = JSON.parse(localStorage.getItem("page_type"));
            this.state.showFilterDiv = true;
        }
        if(localStorage.getItem("page_filters") != undefined){  
            this.state.filters = JSON.parse(localStorage.getItem("page_filters"));
        }
        if(localStorage.getItem("city_data") != undefined){  
            this.state.city = Constants.getCityData("single");
        }
        if(localStorage.getItem("brand_data") != undefined){  
            this.state.brands = JSON.parse(localStorage.getItem("brand_data"));
        }
        if(localStorage.getItem("fuel_data") != undefined){  
            this.state.fuelType = JSON.parse(localStorage.getItem("fuel_data"));
        }
        if(localStorage.getItem("service_data") != undefined){  
            this.state.services = JSON.parse(localStorage.getItem("service_data"));
        }
        if(localStorage.getItem("model_data") != undefined){  
            this.state.models = JSON.parse(localStorage.getItem("model_data"));
            this.state.brandWiseModels = (localStorage.getItem("brand_wise_model") == null)?[]:JSON.parse(localStorage.getItem("brand_wise_model"));
        }
        if(localStorage.getItem("city_wise_region") != undefined){  
            this.state.regions = JSON.parse(localStorage.getItem("city_wise_region"));
        }
        if(localStorage.getItem("sub_service") != undefined){  
            this.state.subService = JSON.parse(localStorage.getItem("sub_service"));
        }
    }
    componentDidMount()
    {
        /* Setting Filters Data from localstorage*/
        if(localStorage.getItem("web_filters") != undefined){
            this.state.filterData = JSON.parse(localStorage.getItem("web_filters"));
            // console.log('-----------',this.state.filterData);
            if(this.state.filterData.pageType.name == 'near-me')
                this.getNearMePagePath();
            if(this.state.filterData.pageType.name == 'service-detail'){ 
                this.setCarMultipleFlag();  
                this.getCategoryWiseServices(); 
            } else if(this.state.filterData.pageType.name == 'app'){ 
                this.getCategoryWiseServices(); 
            }
        }
        // if(this.state.filterData.pageType.name)
        //     this.afterSetPageType()

        if(localStorage.getItem("page_type") == undefined){    
            firebase.database().ref('/cms/page_type').on(
                'value',
                (snapshot) => {
                    this.setState({page:snapshot.val()});
                    // console.log('++++++++++++++++page_type');
                    localStorage.setItem("page_type",JSON.stringify(this.state.page));
                },
                (err) => {
                console.log('page type read failed from firebase', err.code);
                },
            );
        }
        if(localStorage.getItem("city_data") == undefined){
                firebase.database().ref('/cms/cities').on(
                    'value',
                    (snapshot) => {
                        var cityOptions = [];
                        cityOptions = [{id:'select',name:'Select city'}, ...snapshot.val()]
                        this.setState({city:cityOptions});
                        // console.log('++++++++++++++++cities');
                        localStorage.setItem("city_data",JSON.stringify(this.state.city));
                    },
                    (err) => {
                    console.log('cities read failed from firebase', err.code);
                    },
                );
        }
        if(localStorage.getItem("brand_data") == undefined){
                firebase.database().ref('/cms/brands').on(
                    'value',
                    (snapshot) => {
                        var brands = [];
                        brands = [{id:'select-all',name:'Select All'}, ...snapshot.val()]
                        this.setState({brands:brands});
                        // console.log('++++++++++++++++brands');
                        localStorage.setItem("brand_data",JSON.stringify(this.state.brands));
                    },
                    (err) => {
                    console.log('brands read failed from firebase', err.code);
                    },
                );
        }
        if(localStorage.getItem("fuel_data") == undefined){
          firebase.database().ref('/cms/fuel_type').on(
            'value',
            (snapshot) => {
                var fuelArr = [];
                fuelArr = [{id:'select-all',name:'Select All'}, ...snapshot.val()]
                this.setState({fuelType:fuelArr});
                // console.log('++++++++++++++++fuel_type');
                localStorage.setItem("fuel_data",JSON.stringify(this.state.fuelType));
            },
            (err) => {
              console.log('fuel type read failed from firebase', err.code);
            },
          );
        }
        if(localStorage.getItem("model_data") == undefined){
          firebase.database().ref('/cms/model/').on(
            'value',
            (snapshot) => {
                this.setState({models:snapshot.val()});
                // console.log('++++++++++++++++model');
                localStorage.setItem("model_data",JSON.stringify(this.state.models));
                this.brandWiseModel();
            },
            (err) => {
              console.log('model read failed from firebase', err.code);
            },
          );
        }
        if(localStorage.getItem("service_data") == undefined){
          firebase.database().ref('/cms/service_type').on(
            'value',
            (snapshot) => {
                var serviceOptions = [];
                serviceOptions = [{id:'select',name:'Select Service'}, ...snapshot.val()]
                this.setState({services:serviceOptions});
                // console.log('++++++++++++++++service_type');
                localStorage.setItem("service_data",JSON.stringify(this.state.services));
            },
            (err) => {
              console.log('services read failed from firebase', err.code);
            },
          );
        }
        // if(localStorage.getItem("brand_data") == undefined){
          
        // }
    }
    afterSetPageType(){
        console.log(this.state.filterData.pageType.name);
        firebase.database().ref('/cms/filter/'+this.state.filterData.pageType.name).on(
            'value',
            (snapshot) => {
                this.setState({filters:snapshot.val()});
                // console.log('++++++++++++++++filter');
                localStorage.setItem("page_filters",JSON.stringify(this.state.filters));
            },
            (err) => {
            console.log('filters read failed from firebase', err.code);
            },
        );
        this.getNearMePagePath();
        this.afterFilterChange();
    }
    getNearMePagePath(){
        if(this.state.filterData.pageType.name && this.state.filterData.pageType.name == 'near-me'){
            var that = this;
            API.getCMSPageType()
            .then(function(response) {
                
                if(response.status){
                    let pageUrl = [];
                        response.data.data.map((option,index) => {
                            index++;
                            pageUrl.push({id:index,name:option.page_path});
                        })
                    console.log(pageUrl,"######");
                    that.setState({pagePath:pageUrl});
                    localStorage.setItem("page_path",JSON.stringify(that.state.pagePath));
                }
            }).catch(function(error) {
                // handle error
                console.log(error);
              });
        }
    }
    afterFilterChange(field){
        field = field || null;
        localStorage.setItem("web_filters",JSON.stringify(this.state.filterData));
        if(field == 'brand'){
            this.brandWiseModel();
        }
        if(field == 'city'){
            this.cityWiseRegion()
        }
        if(field == 'category'){
            this.setCarMultipleFlag();
        }
        this.getCategoryWiseServices();
    }
    setCarMultipleFlag(){
        let pageType = this.state.filterData && this.state.filterData.pageType && this.state.filterData.pageType.name ? this.state.filterData.pageType.name : undefined;
        let category = this.state.filterData && this.state.filterData.services && this.state.filterData.services.id ? this.state.filterData.services.id : undefined;
        if(pageType == "service-detail" && (category == "21" || category == "-4"))
            this.setState({isCarDetailsMultiple:false});
        else if(pageType == "service-detail") {
            this.setState({isCarDetailsMultiple:true});
            localStorage.setItem("web_filters",JSON.stringify(this.state.filterData));
        }
    }
    getCategoryWiseServices(){
        let isAPICall = false;
        let pageType = this.state.filterData && this.state.filterData.pageType && this.state.filterData.pageType.name ? this.state.filterData.pageType.name : undefined;
        let category = this.state.filterData.services.id;
        if(pageType == "service-detail" && (category == "21" || category == "-4")){
            let brand = this.state.filterData && this.state.filterData.brands && this.state.filterData.brands.id ? this.state.filterData.brands.id : undefined;
            let model = this.state.filterData && this.state.filterData.models && this.state.filterData.models.id ? this.state.filterData.models.id : undefined;
            let fuel = this.state.filterData && this.state.filterData.fuelType && this.state.filterData.fuelType.id ? this.state.filterData.fuelType.id : undefined;
            if(brand && model && fuel){
                isAPICall = true;
                this.setState({showServiceFilter:true});
            }else{
                this.setState({showServiceFilter:false});
            }
        }
        else if((pageType == "service-detail" || pageType == 'app') && category!=undefined){
            isAPICall = true;
            this.setState({showServiceFilter:true});
        }
        else
            this.setState({showServiceFilter:false});

        if(pageType == 'service-detail' && isAPICall){
            var that = this;
            API.getCategoryWiseServices()
            .then(function(response) {
                // console.log('-----------',response);
                if(response.status){
                    let subService = [];
                    subService = [{id:'select',name:'Select Services'}];
                        response.data.data.map((option,index) => {
                            subService.push({id:option.service_slug,name:option.name});
                        })
                    console.log(subService,"######");
                    that.setState({subService:subService});
                    localStorage.setItem("sub_service",JSON.stringify(that.state.subService));
                }
            }).catch(function(error) {
                // handle error
                console.log(error);
              });
        }
        else if(pageType == 'app' && (category || category === 0) && isAPICall) {
            var that = this;
            API.getCategoryWiseServices()
            .then(function(response) {
                if(response.status){
                    let subService = [];
                    subService = [{category_id:'select',name:'Select Services',sub_category_id:"select"}];
                    response.data.data.map((option,index) => {
                        subService.push({id:option.id,name:option.name,sub_category_id:option.sub_category_id});
                    })
                    that.setState({subService:subService});
                    localStorage.setItem("sub_service",JSON.stringify(that.state.subService));
                }
            }).catch(function(error) {
                // handle error
                console.log(error);
            });
        }
    }
    cityWiseRegion(){
        var sitemap_name = this.state.filterData.cities.sitemap_name;
        sitemap_name = sitemap_name && sitemap_name.replace(' ','-');
        sitemap_name && 
          firebase.database().ref('/cms/city_wise_region/'+sitemap_name).on(
            'value',
            (snapshot) => {
                var regionArr = [];
                regionArr = [{id:'select-all',name:'Select All'}, ...snapshot.val()]
                this.setState({regions:regionArr,filterData : {...this.state.filterData,regions: []}});
                // console.log('++++++++++++++++city_wise_region');
                localStorage.setItem("city_wise_region",JSON.stringify(this.state.regions));
                localStorage.setItem("web_filters",JSON.stringify(this.state.filterData));
            },
            (err) => {
              console.log('regions read failed from firebase', err.code);
            },
          );
    }
    brandWiseModel(){
        let brands = this.state.filterData.brands;
        let models = this.state.models;
        let filterModel = this.state.filterData.models;
        let filterModelArr = [];
        // console.log("!!!!!!"+JSON.stringify(models));
        if (brands.length && brands.length > 0) {
            let temp = [];
            for (let i = 0; i < brands.length; i++){
                let slug_id = brands[i].slug_id;
                let brand_id = brands[i].id;
                let brandModel = models[slug_id];
                let modelObj = brandModel ? brandModel.model:''
                // console.log('filterModel'+JSON.stringify(filterModel));
                
                if(modelObj.length !== 0){
                    let models = []; let modelArr = null;
                    for (let k = 0; k < modelObj.length; k++){
                        // console.log('####'+JSON.stringify(modelObj[k]))
                        models.push(modelObj[k]);
                    }
                    modelArr = [{id:"-1",name:'---'+brands[i].name+'---'}, ...models];
                    temp.push(...modelArr);
                }
                if(filterModel.length !== 0){
                     for (let j = 0; j < filterModel.length; j++){
                        if(brand_id == filterModel[j].brand_id)
                            filterModelArr.push(filterModel[j]);
                    }
                }
            }
            var brandWiseModel = [];
            brandWiseModel = [{id:'select-all',name:'Select All'}, ...temp]
            this.setState({brandWiseModels:brandWiseModel,filterData : {...this.state.filterData,models: filterModelArr}});
            localStorage.setItem("brand_wise_model",JSON.stringify(brandWiseModel));
        }else{
            let brand_id = brands.id;
            let slug_id = brands.slug_id;
            let brandModel = models[slug_id];
            if (brand_id && brandModel) {
                if (filterModel.length !== 0) {
                  for (let j = 0; j < filterModel.length; j++) {
                    if (brand_id == filterModel[j].brand_id)
                      filterModelArr.push(filterModel[j]);
                  }
                }
          
                var brandWiseModel = [];
                brandWiseModel = [ { id: "select", name: "Select Model" }, ...brandModel.model,];
                this.setState({
                  brandWiseModels: brandWiseModel,
                  filterData: { ...this.state.filterData, models: filterModelArr },
                });
                localStorage.setItem("brand_wise_model", JSON.stringify(brandWiseModel));
            }

            this.setState({filterData : {...this.state.filterData,models: []}});
        }
        localStorage.setItem("web_filters",JSON.stringify(this.state.filterData));
    }

    handleApplyFilter(){
        this.props.onApplyFilter(this.state.filterData);
    }
    handleInput(input){
        let path = {id:null,name:input}
        this.setState({filterData:{...this.state.filterData,pageUrl:path}})
    }
    handleCategoryChange(value){
        // alert(value.id+'value');
        let category = this.state.filterData && this.state.filterData.services && this.state.filterData.services.id ? this.state.filterData.services.id : undefined;
        // alert(category+'category');
        if((category == "21" || category == "-4") && (value.id != "21" || value.id != "-4"))
        {
            // alert(4);
            this.setState({filterData : {...this.state.filterData,brands: [],models: [],fuelType:[],services: value,subService:{}}},() => this.afterFilterChange('category'));
        }
            // this.setState({filterData : {...this.state.filterData,brands: [],models: [],fuelType:[],subService:[]}});
        else{
            this.setState({filterData : {...this.state.filterData,services: value,subService:{}}},() => this.afterFilterChange('category'));
        }
    }
    render()
    {
        // console.log('+++++++++',this.state.filterData);
        // console.log("aaaaaa"+this.state.filters[this.state.filterData.pageType.city]);
        const { showFilterDiv, page, city , brands, fuelType, models, brandWiseModels, services, regions, pagePath, subService, isCarDetailsMultiple, showServiceFilter, filterData} = this.state; 
        const filters = this.state.filters;
        return(
            <div className="mainFilterContainer">
                <div className="row-5 margin_10 margin_b_20">
                    <div className="margin_r_16 margin_b_10">
                        <MultiSelect 
                            options={page}
                            labelKey="name"
                            checkbox_id="id"
                            placeholder="Page Type"
                            disabledItems={[]}
                            selectedOptions={this.state.filterData.pageType}
                            onChange={(value) => this.setState({filterData : {...this.state.filterData,pageType: value} ,showFilterDiv:true},() => this.afterSetPageType())} 
                        />
                    </div>
                    {showFilterDiv && //filters.city && 
                            <React.Fragment>
                                { filters?.city && filterData?.pageType?.id !== 5 &&
                                <div className="margin_r_16">
                                    <MultiSelect 
                                        // multiple
                                        options={city}
                                        labelKey="name"
                                        checkbox_id="id"
                                        placeholder="Select Cities"
                                        disabledItems={[]}
                                        selectedOptions={this.state.filterData.cities}
                                        onChange={(value) => this.setState({filterData : {...this.state.filterData,cities: value} },() => this.afterFilterChange('city'))} 
                                    />
                                </div>
                                }
                                { filters?.region && filterData?.cities.id && filterData?.pageType?.id !== 5 &&
                                    <div className="margin_r_16">
                                        <MultiSelect 
                                            multiple
                                            options={regions}
                                            labelKey="name"
                                            checkbox_id="sitename"
                                            placeholder="Select Regions"
                                            disabledItems={[]}
                                            selectedOptions={this.state.filterData.regions}
                                            onChange={(value) => this.setState({filterData : {...this.state.filterData,regions: value} },() => this.afterFilterChange())} 
                                        />
                                    </div>
                                }
                                {((filters?.service && filterData?.cities?.id) || (filterData?.pageType?.id === 5)) &&
                                 <div className="margin_r_16">
                                        <MultiSelect 
                                            options={filterData?.pageType?.id === 5 ? services && services.filter((item) => 
                                                item.slug!="car-tyre-replacement" && item.slug!="car-battery-replacement" 
                                            ): services}
                                            labelKey="name"
                                            checkbox_id="id"
                                            placeholder="Select Category"
                                            disabledItems={[]}
                                            selectedOptions={this.state.filterData.services}
                                            // onChange={(value) => this.setState({filterData : {...this.state.filterData,services: value}},() => this.afterFilterChange('category'))} 
                                            onChange={((value) => this.handleCategoryChange(value))}
                                        />
                                </div>
                                }
                                { filters?.url && //pagePath.length>0 &&
                                    // <InputBox
                                    // label="Page Path"
                                    // value={this.state.filterData.pageUrl}
                                    // onChange={(value)=> this.setState({filterData : {...this.state.filterData,pageUrl: value}},() => this.afterFilterChange())}
                                    // />
                                    <div className='margin_r_16'>
                                        <MultiSelect
                                            options={pagePath}
                                            labelKey="name"
                                            checkbox_id="id"
                                            placeholder="Select Page Path"
                                            disabledItems={[]}
                                            selectedOptions={this.state.filterData.pageUrl}
                                            onChange={(value) => this.setState({filterData : {...this.state.filterData,pageUrl: value}},() => this.afterFilterChange())} 
                                            onType={this.handleInput}
                                        />
                                    </div>
                                }

                                {filters?.brand && filterData?.cities?.id && filterData?.services?.name && filterData?.pageType?.id !== 5 &&
                                <div className="margin_r_16">
                                    <MultiSelect 
                                        multiple={isCarDetailsMultiple}
                                        options={brands}
                                        labelKey="name"
                                        checkbox_id="id"
                                        placeholder="Select Brand"
                                        disabledItems={[]}
                                        selectedOptions={this.state.filterData.brands}
                                        onChange={(value) => this.setState({filterData : {...this.state.filterData,brands: value} },() => this.afterFilterChange('brand'))} 
                                    />
                                </div>
                                }
                                {(filterData.brands.length > 0 || filterData.brands.name) && filterData?.pageType?.id !== 5 &&
                                <div className="margin_r_16">
                                    <MultiSelect 
                                        multiple={isCarDetailsMultiple}
                                        options={brandWiseModels}
                                        labelKey="name"
                                        checkbox_id="id"
                                        placeholder="Select Model"
                                        disabledItems={brandWiseModels.filter((assignToObj) =>
                                            assignToObj.id == "-1"
                                        )}
                                        selectedOptions={this.state.filterData.models}
                                        onChange={(value) => this.setState({filterData : {...this.state.filterData,models: value} },() => this.afterFilterChange())}   
                                    />
                                </div>
                                }
                                {(filterData.models.length > 0 || filterData.models.name) && filterData?.pageType?.id !== 5 &&
                                <div className="margin_r_16">
                                    <MultiSelect 
                                        multiple={isCarDetailsMultiple}
                                        options={fuelType}
                                        labelKey="name"
                                        checkbox_id="id"
                                        placeholder="Select Fuel Type"
                                        disabledItems={[]}
                                        selectedOptions={this.state.filterData.fuelType}
                                        onChange={(value) => this.setState({filterData : {...this.state.filterData,fuelType: value}},() => this.afterFilterChange('fuel'))} 
                                    />
                                </div>
                                }
                                {((showServiceFilter === true) && filterData?.pageType?.id !== 5) &&
                                <div className="margin_r_16">
                                    <MultiSelect 
                                        options={subService}
                                        labelKey="name"
                                        checkbox_id="id"
                                        placeholder="Select Service"
                                        disabledItems={[]}
                                        selectedOptions={this.state.filterData.subService}
                                        onChange={(value) => this.setState({filterData : {...this.state.filterData,subService: value}},() => this.afterFilterChange())} 
                                    />
                                </div>
                                }
                                {filterData?.pageType?.id === 5 &&
                                <div className="margin_r_16">
                                    <MultiSelect 
                                        options={subService}
                                        labelKey="name"
                                        checkbox_id="id"
                                        placeholder="Select Service"
                                        disabledItems={[]}
                                        selectedOptions={this.state.filterData.subService}
                                        onChange={(value) => this.setState({filterData : {...this.state.filterData,subService: value}})} 
                                    />
                                </div>
                                }
                                <div className="blackspan65 weight700 floatright addContentBtnMargin" >
                                    <table className="stdtable">
                                    <tbody>
                                        <tr>
                                            <td valign="center">
                                                <button id="addleadbtn" className="btn btn_small btncolorprim btncard move" onClick={this.handleApplyFilter}>Apply Filter</button>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </React.Fragment>
                    }
                </div>
            </div>
        );
    }
  
}

export default WebFilter;