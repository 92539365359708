import React, { useState, useEffect, useRef } from "react";
// import "./WhatsappTemplate.css";
import { NotificationManager } from "react-notifications";
import API from "../../api";
import Loader from "../../loader";
import WalletFilter from "./WalletFilter/walletFilter";
import "flatpickr/dist/themes/material_green.css";
import "./UserWallet.css";
import firebase from "../../firebase";

import Flatpickr from "react-flatpickr";

const UserWallet = (props) => {
  const [amount, setAmount] = useState();
  const [date, setDate] = useState("");
  const [remark, setRemark] = useState("");
  const [source, setSource] = useState("");
  const [channel, setChannel] = useState("");
  const [loader, setLoader] = useState(false);
  const [initialLoader, setInitialLoader] = useState(true);
  const [csvFile, setCsvFile] = useState([]);
  const [currentAmount, setCurrentAmount] = useState(0);
  const inputFileRef = useRef(null);

  useEffect(() => {
    if (props.filters) {
      checkWalletActivity();
    } else {
      checkWalletActivity(true);
      setInitialLoader(false);
    }
  }, []);
  useEffect(() => {
    setAmount("");
    setDate("");
    setRemark("");
    setSource("");
    setChannel("");
    setCurrentAmount(0);
  }, [props.filters]);

  function checkWalletActivity(firstLoad) {
    var walletRef = firebase.database().ref("/cms/walletActivity");
    let listener21 = walletRef.on("value", function (snapshot3) {
      console.log("insidelistner", snapshot3.val());
      if (snapshot3.val() == "active") {
        setLoader(true);
        setInitialLoader(false);
        NotificationManager.error(
          "Background in process",
          "Please try again after some time",
          20000
        );
      } else {
        setLoader(false);
        setInitialLoader(false);
        if (!firstLoad)
          NotificationManager.success("Background Process", "Successful", 5000);
      }
    });
  }

  function handleSaveButtonClick(key) {
    let obj = {};
    obj["value"] = amount;
    obj["expiry_date"] = date;
    obj["cities"] =
      props && props.filters && props.filters.cities
        ? props.filters.cities.map((item) => item.id)
        : [];
    obj["segment_type"] =
      props && props.filters && props.filters.users
        ? props.filters.users.id
        : "";
    obj["remark"] = remark;
    obj["source"] = source;
    console.log(obj);
    // return;
    setInitialLoader(true);
    API.updateWalletDetails(obj)
      .then(function (response) {
        if (response.status) {
          setInitialLoader(false);
        }
        NotificationManager.success(`Data saved`, "Successfully", 5000);
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating the wallet details");
      });
  }
  const onDragEnter = (e) => {
    e.preventDefault();
    console.log("triggered");
  };

  const onDragOver = (e) => {
    e.preventDefault();
    console.log("triggered");
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    console.log("triggered");
  };

  const onDropEvent = (e) => {
    e.preventDefault();
    // e.dataTransfer
    console.log(e.dataTransfer.files[0].type);
    if (e.dataTransfer.files[0].type === "text/csv") {
      setCsvFile(e.dataTransfer.files[0]);
    } else {
      NotificationManager.error(
        `Only CSV file accepted`,
        "Trying To Upload Wrong File "
      );
      setCsvFile([]);
    }
  };
  const onFileChange = (e) => {
    /*Selected files data can be collected here.*/
    console.log(e.target.files[0]);
    setCsvFile(e.target.files[0]);
  };
  const triggerFileUpload = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  };
  const uploadCSV = () => {
    // bulkUploadWalletCSV;
    setLoader(true);
    let data = new FormData();
    data.append("type", "csv");
    data.append("file", csvFile);
    // for (var value of data.values()) {
    //   console.log(value);
    // }
    if (data) {
      API.bulkUploadWalletCSV(data)
        .then(function (response) {
          console.log(response.data.data.update_missed_data.length);
          if (response.status) {
            setCsvFile([]);
            setLoader(false);
            if (response.data.data.update_missed_data.length) {
              let csv =
                "Mobile,Expiry Date (YYYY-MM-DD),Source,Channel,Remark,Amount\n";
              response.data.data.update_missed_data.map(data => {
                let row = [];
                row.push(data['Mobile'])
                row.push(data['Expiry Date (YYYY-MM-DD)'])
                row.push(data['Source'])
                row.push(data['Channel'])
                row.push(data['Remark'])
                row.push(data['Amount'])
                // for (const key in data) {
                //   if (Object.hasOwnProperty.call(data, key)) {
                //     const element = data[key];
                //     row.push(element);
                //   }
                // }
                csv += row.join(",");
                csv += "\n";
              })
              let hiddenElement = document.createElement("a");
              hiddenElement.href =
                "data:text/csv;charset=utf-8," + encodeURI(csv);
              hiddenElement.target = "_blank";
              hiddenElement.download = "goappmoney(missing data).csv";
              hiddenElement.click();
              NotificationManager.warning(
                response.data.message,
                "Warning",
                5000
              );
            } else {
              NotificationManager.success(
                response.data.message,
                "Successfully",
                5000
              );
            }
          }
        })
        .catch(function (error) {
          // handle error
          setCsvFile([]);
          setLoader(false);
          NotificationManager.error("Please try again", "Error", 5000);
          console.log(error, "Error in updating the wallet details");
        });
    }
  };
  const updateSingleUserWallet = () => {
    let data = new FormData();
    data.append("mobile", props.filters.mobile_number);
    data.append("expiry_date", date);
    data.append("remark", remark);
    data.append("source", source);
    data.append("amount", amount);
    data.append("channel", channel);
    for (var value of data) {
      console.log(value);
      if (!value[1].trim() || value[1] === "0") {
        NotificationManager.error("required", value[0]);
        return 0
      }
    }
    setLoader(true);
      API.updateSingleUserWallet(data)
        .then(function (response) {
          if (response.status) {
            setCurrentAmount(response.data.data.total_amount);
            setLoader(false);
          }
          NotificationManager.success(`Data saved`, "Successfully", 5000);
        })
        .catch(function (error) {
          // handle error
          setLoader(false);
          NotificationManager.error("Please try again", "Error", 5000);
          console.log(error, "Error in updating the wallet details");
        });
    
  };
  const getSingleUserWallet = (val) => {
    if(val){setLoader(true);
    API.getSingleUserWalletAmount(val)
      .then(function (response) {
        if (response.status) {
          console.log(response.data)
          if (!response.data.status) {
            NotificationManager.error(response.data.message, "Error",);
            setCurrentAmount(0);
          }
          else {
            NotificationManager.success(response.data.message, "Success");
            setCurrentAmount(response.data.data.go_app_money);
          }
          setLoader(false);
        }
      })
      .catch(function (error) {
        // handle error
        setLoader(false);
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating the wallet details");
      });}
  };
  function validateForm(id) {
    if (amount === 0 || amount === "" || isNaN(amount)) {
      NotificationManager.error("amount cannot be empty / Zero! ", "Required");
      return;
    }
    if (date.trim().length === 0) {
      NotificationManager.error("Date cannot be empty! ", "Required");
      return;
    }
    if (remark.trim().length === 0) {
      NotificationManager.error("Remark cannot be empty! ", "Required");
      return;
    }
    if (source.trim().length === 0) {
      NotificationManager.error("Source cannot be empty! ", "Required");
      return;
    }

    if (id === "0") {
      if (channel.trim().length === 0) {
        NotificationManager.error("Channel cannot be empty! ", "Required");
        return;
      }
      updateSingleUserWallet();
    } else {
      handleSaveButtonClick();
    }
  }
  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <WalletFilter
          onApplyFilter={props.onApplyFilter}
          getSingleUserInfo={(val) => {
            getSingleUserWallet(val);
          }}
        />
      </div>
      {initialLoader ? (
        <div id="websiteMainmiddleloader" className="bgwhite centeralign">
          <Loader showLoader={props.loader} />
        </div>
      ) : props.filters ? (
        <>
          {loader ? (
            <>
              <div className="modal-sb"></div>
              <div className="sub-modal-sb"></div>
            </>
          ) : (
            ""
          )}
          {props.filters.request_type &&
          props.filters.request_type.id !== "2" ? (
            // &&
            // ((props.filters.cities &&
            //   props.filters.users) ||
            //     props.filters.mobile_number.length === 10)
            <div className="mainContainer">
              <div className="manageTimeSlot">Manage User Wallet</div>
              <div className="innerBox">
                <div className="walletContainer">
                  <div className="walletDetailsContainer">Wallet Details :</div>
                  <div className="walletDetailsContainer maskingWalletContainer">
                    <div>
                      <input
                        type="text"
                        maxLength="30"
                        placeholder="Wallet Amount"
                        value={amount}
                        className={`field border-box small`}
                        style={{ width: "unset" }}
                        onChange={(e) => {
                          setAmount(
                            e.target.value ? parseInt(e.target.value) : ""
                          );
                        }}
                      />
                      <span className="required">*</span>
                    </div>
                    <div>
                      <Flatpickr
                        className={`field border-box small`}
                        style={{ width: "unset" }}
                        placeholder="Expiry Date"
                        value={date}
                        onChange={(selectedDates, dateStr, instance) => {
                          setDate(dateStr);
                        }}
                        // dateFormat="Y-m-d"
                      />
                      <span className="required">*</span>
                    </div>
                  </div>
                  <div className="walletDetailsContainer maskingWalletContainer">
                    <div>
                      <input
                        type="text"
                        maxLength="30"
                        placeholder="Remark"
                        value={remark}
                        className={`field border-box small`}
                        style={{ width: "unset" }}
                        onChange={(e) => {
                          setRemark(e.target.value);
                        }}
                      />
                      <span className="required">*</span>
                    </div>
                    <div>
                      <input
                        type="text"
                        maxLength="20"
                        placeholder="Source"
                        value={source}
                        className={`field border-box small`}
                        style={{ width: "unset" }}
                        onChange={(e) => {
                          setSource(e.target.value);
                        }}
                      />
                      <span className="required">*</span>
                    </div>
                  </div>
                  {props.filters.request_type.id === "0" && (
                    <div
                      className="walletDetailsContainer maskingWalletContainer"
                      style={{ alignItems: "flex-start" }}
                    >
                      <div>
                        <input
                          type="text"
                          maxLength="10"
                          placeholder="Channel"
                          value={channel}
                          className={`field border-box small`}
                          style={{ width: "unset" }}
                          onChange={(e) => {
                            setChannel(e.target.value);
                          }}
                        />
                        <span className="required">*</span>
                      </div>
                      {/* filler box used for maintaining ui grid */}
                      <div>
                        <input
                          disabled
                          type="text"
                          maxLength="20"
                          placeholder="Current Amount"
                          value={"Current Amount ₹ " + currentAmount}
                          className="field border-box small green-pill-box"
                          onChange={(e) => {
                            setCurrentAmount(e.target.value);
                          }}
                        />
                      </div>
                      {/* filler box end */}
                    </div>
                  )}
                  <div
                    className="walletDetailsContainer"
                    role="button"
                    style={{ justifyContent: "center" }}
                  >
                    <div
                      className="btn btn_small btncolorprim btncard move"
                          onClick={() => {
                        validateForm(props.filters.request_type.id )
                      }}
                    >
                      Save Details
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mainContainer">
              <div className="manageTimeSlot">Manage User Wallet</div>
              <div className="innerBox">
                <div className="walletContainer">
                  <div
                    className="walletDetailsContainer"
                    role="button"
                    style={{ justifyContent: "center", flexFlow: "column" }}
                  >
                    {csvFile && csvFile.length === 0 ? (
                      <>
                        <div
                          className="drag-area"
                          onDragEnter={(e) => onDragEnter(e)}
                          onDragOver={(e) => onDragOver(e)}
                          onDragLeave={(e) => onDragLeave(e)}
                          onDrop={(e) => onDropEvent(e)}
                        >
                          <header>Drag & Drop to Upload File</header>
                          <span>OR</span>
                          <button onClick={triggerFileUpload}>
                            Browse File
                          </button>
                          <input
                            type="file"
                            hidden
                            ref={inputFileRef}
                            onChange={onFileChange}
                            accept=".csv"
                          />
                        </div>
                        <br />
                      </>
                    ) : (
                      <>
                        <img
                          src="https://cdn0.iconfinder.com/data/icons/document-2-1/200/0_2-512.png"
                          style={{ height: "100px" }}
                          alt="file uploaded"
                        />
                        <br />
                        <h4>Ready For Upload </h4>
                        <div
                          className="btn btn_small btncolorprim btncard move"
                          onClick={() => {
                            uploadCSV();
                          }}
                        >
                          Upload Details
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="emptyMiddleMain centeralign weight400 blackspan56">
          Please apply filters to view details
        </div>
      )}
    </>
  );
};

export default UserWallet;
