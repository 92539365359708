import React, { useState, useEffect } from "react";
import Constants from '../../constants';
import "./slotBooking.css";
import BookingFilter from "./BookingFilter/bookingFilter";
import firebase from '../../firebase';
import { NotificationManager } from "react-notifications";
import API from "../../api";
import Loader from "../../loader";
import MultiSelect from "../custom/MultiSelect/MultiSelect";

export default function SlotBooking(props) {
  const [selectTab, setSelectTab] = useState(false);
  const [tab, setTab] = useState(false);
  const [slots, setTimeSlots] = useState([]);
  const [showLoader, setLoader] = useState(false);
  const [amountOptions, setAmountOptions] = useState([]);
  
  // const obj = {};

  const [bookingObj, setBookingObj] = useState({});
  const [milesTextEnabled, setMilesTextEnabled] = useState(false);
  
  let date = new Date();
  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  var days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  let currentMonth = months[date.getMonth()];
  let currentDate = date.getDate();
  let currentDay = days[date.getDay()];

  const Timimg = [
    { time: "10AM", value: 10 },
    { time: "11AM", value: 11 },
    { time: "12PM", value: 12 },
    { time: "1PM", value: 13 },
    { time: "2PM", value: 14 },
    { time: "3PM", value: 15 },
    { time: "4PM", value: 16 },
    { time: "5PM", value: 17 },
  ];
  const today = new Date();
  // const nextDay = new Date(today.setDate(today.getDate() + 1));
  // const dateString = `${days[nextDay.getDay()]} ${nextDay.getDate()} ${months[nextDay.getMonth()]}, ${nextDay.getFullYear()}`;

  
  // const timeSlots  = {
  //   timeSlots: slots.timeSlots,
  //   date: `${nextDay.getFullYear()}-${(`0${nextDay.getMonth() + 1}`).slice(-2)}-${(`0${nextDay.getDate()}`).slice(-2)}`,
  //   time: '11:00AM',
  //   mode: 0,
  //   dateString,
  // };

  // const availableTimeSlot = Constants.availableTimeSlotsForDate(timeSlots,
  //   `${today.getFullYear()}-${(`0${today.getMonth() + 1}`).slice(-2)}-${(`0${today.getDate()}`).slice(-2)}`);
  // console.log(slots.timeSlots,'availableTimeSlot',timeSlots);
  // return;
  // const Dates = [availableTimeSlot.length
  // > 0 ? [{
  //   nextDay: '',
  //   date: today.getDate(),
  //   day: days[today.getDay()],
  //   // dateString: `${next.getFullYear()}-${next.getMonth() + 1}-${next.getDate()}`,
  //   dateString: `${today.getFullYear()}-${(`0${today.getMonth() + 1}`).slice(-2)}-${(`0${today.getDate()}`).slice(-2)}`,
  // }] : []];

  const Dates = [{nextDay: '',
    date: today.getDate(),
    day: days[today.getDay()],
    // dateString: `${next.getFullYear()}-${next.getMonth() + 1}-${next.getDate()}`,
    dateString: `${today.getFullYear()}-${(`0${today.getMonth() + 1}`).slice(-2)}-${(`0${today.getDate()}`).slice(-2)}`,
  }];

  for (let i = 1; i <= 7; i++) {
    const next = new Date(today.setDate(today.getDate() + 1));
    const obj = {
      nextDay: '',
      date: next.getDate(),
      day: days[next.getDay()],
      // dateString: `${next.getFullYear()}-${next.getMonth() + 1}-${next.getDate()}`,
      dateString: `${next.getFullYear()}-${(`0${next.getMonth() + 1}`).slice(-2)}-${(`0${next.getDate()}`).slice(-2)}`,
    };
    Dates.push(obj);
  }

  console.log('Dates',Dates);

  
  function toggleDate(index, selectedDate) {
    let temp = { ...bookingObj };
    if (temp[index] == undefined || temp[index]["isDateEnabled"]) {
      temp[index]["isDateEnabled"] = false;
      
      let timeSlot = temp[index]["timeSlot"];
      let keys = Object.keys(timeSlot);
      for (var i = 0; i < keys.length; i++) {
        temp[index]['timeSlot'][keys[i]]['is_enable'] = false; 
        temp[index]['timeSlot'][keys[i]]['amount'] = null; 
      };
    } else if (!temp[index]["isDateEnabled"]) {
      temp[index]["isDateEnabled"] = true;
      
      let timeSlot = temp[index]["timeSlot"];
      let keys = Object.keys(timeSlot);
      for (var i = 0; i < keys.length; i++) {
        temp[index]['timeSlot'][keys[i]]['is_enable'] = true; 
      };
    }
    setBookingObj(temp);
  }
  function toggleTime(index, time, isActive) {
    if (isActive || isActive == undefined) {
      let obj = { ...bookingObj };
      
      let timeSlot = obj[index]["timeSlot"];
      obj[index]["timeSlot"][time]['is_enable'] = false;
      obj[index]["timeSlot"][time]['amount'] = null;
      let keys = Object.keys(timeSlot);
      let isDateActive = false;
      for (var i = 0; i < keys.length; i++) {
        let isTimeActive = timeSlot[keys[i]]['is_enable'];
        if (!isDateActive && !isTimeActive) isDateActive = false;
        if (isTimeActive) {
          isDateActive = true;
          break;
        }
      }
      obj[index]["isDateEnabled"] = isDateActive;
      setBookingObj(obj);
    } else {
      let obj = { ...bookingObj };
      obj[index]["isDateEnabled"] = true;
      obj[index]["timeSlot"][time]['is_enable'] = true;
      setBookingObj(obj);
    }
  }

  function handleText(index, e, time, ref){
    let value = (ref == "amount") ? e.id ? parseInt(e.id) : null : e.target.value;
    let temp = { ...bookingObj };
    if(time)
      temp[index]["timeSlot"][time][ref] = value;
    else
      temp[index]["text"] = value;
    setBookingObj(temp);
  }

  useEffect(() => {
    // firebase.database().ref('/website/india/timeSlots').on(
    //   'value',
    //   (snapshot) => {
    //       setTimeSlots(snapshot.val());
    //   },
    //   (err) => {
    //   console.log('cities read failed from firebase', err.code);
    //   },
    // );

    if(localStorage.getItem("slot_amount") != undefined){  
      let amount = JSON.parse(localStorage.getItem("slot_amount"));
      setAmountOptions(amount);
    }

    let slotBookingObj = [];
    let milesEnabledValue = props?.content[0]?.milesTextEnabled ? props?.content[0]?.milesTextEnabled : false;
    Dates.map((item, index) => {
      let masterConfig = {};
      masterConfig["date"]          = item.dateString;
      masterConfig["text"]          = "";
      masterConfig["isDateEnabled"] = true;
      masterConfig["timeSlot"]     = {};

      let timeSlot = {};
      Timimg.map((val, count) => {
        let temp = {};
        temp['is_enable'] = true;
        temp['text']      = "";
        timeSlot[val.value] = temp;
      });
      masterConfig["timeSlot"] = timeSlot;
      // slotBookingObj[index] = masterConfig;
      slotBookingObj.push(masterConfig);
    });
    // console.log('slotBookingObj',slotBookingObj);
    setBookingObj(slotBookingObj);
    setMilesTextEnabled(milesEnabledValue);
  }, []);

  useEffect(() => {
    if(props.content.length > 0)
      setBookingObj(props.content);
  }, [props.content]);

  function handleSaveClick(){
    let bookingSlots = {...bookingObj} ;
    console.log(bookingSlots);
    setLoader(true);

    API.updateSlotBookingDetails(bookingSlots, milesTextEnabled).then(function (response) {
      NotificationManager.success("Slots saved", "Successfully", 5000);
      setLoader(false);
    })
    .catch(function (error) {
      // handle error
      NotificationManager.error("Please try again", "Error", 5000);
      console.log(error, "Error in updating slot details");
      setLoader(false);
    });;
  }


  function handleRadioToggle(index, event) {
    let date_lock = event.target.checked;
    let temp = { ...bookingObj };

    temp[index]["token_lock"] = date_lock;
    if(!date_lock)
      temp[index]["token_amount"] = 0;
    setBookingObj(temp);
  }
  function handleMilesTextToggle(event) {
    let value = event.target.checked;
    setMilesTextEnabled(value);
  }
  function handleTokenAmount(index, e){
    let value = e && e.id ? parseInt(e.id) : 0;
    let temp = { ...bookingObj };
    
    temp[index]["token_amount"] = value;
    setBookingObj(temp);
  }

  return (
    <>
      {/* <div style={{ marginTop: "20px" }}>
          <BookingFilter  />
      </div> */}
      {showLoader ? 
      <div id="websiteMainmiddleloader" className="bgwhite centeralign">
      <Loader showLoader={showLoader} />
    </div> :
      <div className="mainContainer">
        <div className="manageTimeSlot">Manage Time Slots</div>
        <div className="innerBox">
          <div className="slotBookContainer">
            <div className="selectDate">
              Select Date and Time of Service :
              <span className="monthName">{currentMonth}</span>
              <button className="btn btn_small btncard move saveBtn" onClick={() => handleSaveClick()}>
                Save
              </button>
            </div>
            <div className="toggle-input-sb">
              Miles Text Enabled:
              <div>
                <div>
                  <input
                    type="checkbox"
                    className="toggle-switch-checkbox"
                    name="toggleSwitch"
                    id={`toggleSwitch_miles_users_enabled`}
                    checked={milesTextEnabled}
                    // defaultChecked={false}
                    onClick={(e) => {
                      handleMilesTextToggle(e)
                    }}
                  />
                  <label className="toggle-switch-label" htmlFor={`toggleSwitch_miles_users_enabled`}>
                    <span className="toggle-switch-inner" />
                    <span className="toggle-switch-switch" />
                  </label>
                </div>
              </div>
            </div>
            {Dates.map((item, index) => {
              let isDateActive = bookingObj[index] && bookingObj[index]["isDateEnabled"];
              let dateText     = bookingObj[index] && bookingObj[index]["text"];
              let dateLock     = bookingObj[index] && bookingObj[index]["token_lock"];
              let tokenAmount  = bookingObj[index] && bookingObj[index]["token_amount"];
              let tokenAmountDisabled  = bookingObj[index] && bookingObj[index]["token_lock"] === true ? true : false;
              let selectedAmountOption = tokenAmount ? {"id":tokenAmount, "name" : ""+tokenAmount} : {};
              return (
                <>
                <div className="dateSlot">
                  <div
                    onClick={() => {
                      toggleDate(index, item.dateString);
                    }}
                    className={
                      isDateActive === false
                        ? "dateBox dateBoxColor"
                        : "dateBox btncard move"
                    }
                  >
                    <span>{item.day}</span>
                    <span>{item.date}</span>
                  </div>
                  <div className="dateTextBox">
                    <input
                      key={index}
                      type="text"
                      maxLength="30"
                      placeholder="Text"
                      defaultValue={dateText}
                      className="dateInputBox field border-box small"
                      onChange={(e) => {
                        handleText(index, e);
                      }}
                    />
                    
                  </div>

                      <div className="toggle-input-sb">
                        Date Lock :
                        <div className="dateLockContainer">
                              <div style={{ marginRight: '1rem' }}>
                                <input
                                  type="checkbox"
                                  className="toggle-switch-checkbox"
                                  name="toggleSwitch"
                                  id={`toggleSwitch` + index}
                                  checked={dateLock}
                                  defaultChecked={false}
                                  onClick={(e) => {
                                    handleRadioToggle(index, e)
                                  }}
                                />
                                <label className="toggle-switch-label" htmlFor={`toggleSwitch` + index}>
                                  <span className="toggle-switch-inner" />
                                  <span className="toggle-switch-switch" />
                                </label>
                              </div>
                              <div style={{width: "210px", marginLeft:"38px"}}>
                                  <MultiSelect
                                      options={amountOptions.token}
                                      labelKey="name"
                                      checkbox_id="name"
                                      placeholder="Amount"
                                      disabledItems={[]}
                                      disabled={tokenAmountDisabled == false ? true: false}
                                      selectedOptions={selectedAmountOption}
                                      onChange={(value) =>
                                        handleTokenAmount(index, value)
                                        // handleText(index, value, val.value, "amount")
                                      }
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="timingSlot">
                    {Timimg.map((val, count) => {
                      let isSlotActive =
                        bookingObj[index] &&
                        bookingObj[index]["timeSlot"] &&
                        bookingObj[index]["timeSlot"][val.value] && 
                        bookingObj[index]["timeSlot"][val.value]['is_enable'] ;
                      let timeText     = bookingObj[index] && 
                        bookingObj[index]["timeSlot"] &&
                        bookingObj[index]["timeSlot"][val.value] && 
                        bookingObj[index]["timeSlot"][val.value]['text'] ;
                      let amount     = bookingObj[index] && 
                        bookingObj[index]["timeSlot"] &&
                        bookingObj[index]["timeSlot"][val.value] && 
                        bookingObj[index]["timeSlot"][val.value]['amount'] ?
                        bookingObj[index]["timeSlot"][val.value]['amount'] : "";
                      
                      let selectedOption = amount ? {"id":amount, "name" : ""+amount} : {};

                      let channel = props.filters && props.filters.channelType && props.filters.channelType.id ?  props.filters.channelType.id : "";
                      let type = props.filters && props.filters.type && props.filters.type.id ?  props.filters.type.id : "";
                      let slotAmount = type == "luxury" ? amountOptions.luxury : amountOptions.general;
                      console.log('val', val);
                      return (
                        <>
                          <div
                            onClick={() => {
                              toggleTime(index, val.value, isSlotActive);
                            }}
                            className={
                              isSlotActive == false
                                ? "timeBox timeBoxColor btncard move"
                                : "timeBox btncard move"
                            }
                          >
                            {val.time}
                          </div>
                          <div className="dateTextBox" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '1rem' }}>
                            <input
                              key={index}
                              type="text"
                              maxLength="30"
                              placeholder="Text"
                              defaultValue={timeText}
                              className="dateInputBox field border-box small"
                              onChange={(e) => {
                                handleText(index, e, val.value, "text");
                              }}
                            />
                            <MultiSelect
                              options={slotAmount}
                              labelKey="name"
                              checkbox_id="name"
                              placeholder="Amount"
                              disabledItems={[]}
                              disabled={isSlotActive == false ? true: false}
                              selectedOptions={selectedOption}
                              onChange={(value) =>
                                handleText(index, value, val.value, "amount")
                              }
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      }
    </>
  );
}
