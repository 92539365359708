import React from 'react';
import "./login/account1.css"

import API from './api';
import Constants from './constants';
import {Redirect} from 'react-router-dom';
import firebase from './firebase';

const axios = require('axios');



const tabList = [{tag:"orders" , title : "Orders"}, {tag:"fleets" , title : "Fleets"}, {tag:"users" , title : "Users"}, {tag:"services" , title : "Services"}, {tag:"packages" , title : "Packages"}, {tag:"deals" , title : "Deals"}, {tag:"cars" , title : "Cars"}, {tag:"invoices" , title : "Invoices"}, {tag:"garages" , title : "Garages"}, {tag:"crs" , title : "CRs"}, {tag:"bulkorders" , title : "Bulk Orders"}, {tag:"addresses" , title : "Addresses"}];











class PanelPreLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {phone : "", otp : ""};
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(type, e) {
      var obj = {};
      obj[type] = e.target.value;
    this.setState(obj);
    console.log(e.target.value);
    console.log(type);
    console.log(this.state);
  }

  handleClick() {
    console.log(this.state.phone);
    console.log(this.state.otp);
    console.log(this.props.status);
    switch(this.props.status){
        case "phone":
            this.props.onLoginClick(this.props.status, this.state.phone);
            break;
        case "otp":
            this.props.onLoginClick(this.props.status, this.state.otp);
            break;
    }
    
    /*this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));*/
    }

  render() {
    return (
        <div className="account_presignup clearfix">
            <h1 className="blackspan4 centeralign">Internal Login</h1>
            <p className="blackspan56 centeralign">Log in using your GoMechanic internal email.</p>

            <p className="colortert500span centeralign error">
                
            </p>

            {/* <form method="post" action="" className="internalloginform" autocomplete="off" id="internalloginform" data-type='internallogin'> */}
            {this.props.status == "phone" ? (
                <div className="border-box centeralign fieldbox double">
                    <input type="text" name="phone" placeholder="Phone number" className="field border-box small" value={this.state.phone} onChange={(e) => this.handleChange("phone", e)} style={{fontSize: '1.25rem', padding: '.75rem'}}/>
                </div>
            ) : (
                <div className="border-box centeralign fieldbox double">
                    <input type="text" name="otp" placeholder="One time activation code" className="field border-box small" value={this.state.otp} onChange={(e) => this.handleChange("otp", e)} style={{fontSize: '1.25rem', padding: '.75rem'}}/>
                </div>
            )}
            <div className="border-box centeralign fieldbox">
                {this.props.status == "phone" ? (
                    <button type="submit" name="internallogin_btn" className="btn btn_large btncolorprim" onClick = {this.handleClick}>Send OTP</button>
                ) : (
                    <button type="submit" name="internallogin_btn" className="btn btn_large btncolorprim" onClick = {this.handleClick}>Log in</button>
                )}
            </div>
            {/* </form> */}
        </div>
    	
    );
  }
}

class PanelSendOTP extends React.Component {
    constructor(props) {
      super(props);
  //    this.state = {};
    }
  
    render() {
      return (
        <div className="account_postsignup clearfix">
            <h1 className="colorprim500span centeralign">Good going!</h1>
            <p className="colorprimlight500span centeralign">Sending OTP ...</p>
        </div>
      );
    }
  }

class PanelPostLogin extends React.Component {
    constructor(props) {
      super(props);
  //    this.state = {};
    }
  
    render() {
      return (
        <div className="account_postsignup clearfix">
            <h1 className="colorprim500span centeralign">Perfectly done!</h1>
            <p className="colorprimlight500span centeralign">Logging you in ...</p>
        </div>
      );
    }
  }

  class PanelError extends React.Component {
    constructor(props) {
      super(props);
  //    this.state = {};
    }
  
    render() {
      return (
        <div className="account_postsignup clearfix">
            <h1 className="colorprim900span centeralign">Sorry!</h1>
            <p className="colorsec900span centeralign">You need to be a GoMechanic Support Executive to log in.</p>
        </div>
      );
    }
  }













class LoginMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    	status: "phone"
    };

    this.handleLoginClick = this.handleLoginClick.bind(this);
  }


  handleLoginClick(status, value) {
  	var that = this;

    switch(status){
        case "phone":
            that.setState({status: "sending otp"});
            API.sendOTP(value).then(function (response) {
                console.log("response");
                console.log(response);
                if(response.data.status){
                    sessionStorage.setItem("session_key", response.data.data.session_key);
                    sessionStorage.setItem("phone", value);
                    sessionStorage.setItem("user_roles", response.data.data.user_roles);
                    that.setState({status : "otp"});      
                }else{
                    that.setState({status : 'error'});
                }
                })
                .catch(function (error) {
                // handle error
                console.log(error);
                that.setState({status: "error"});
                })
                .finally(function () {
                // always executed
                });;
            break;
        case "otp":
            that.setState({status : "logging in"});
            API.verifyLogin(sessionStorage.getItem("phone"), value).then(function (response) {
                if(response.data.status){
                    // firebase.auth().signInWithCustomToken(response.data.data.custom_token).catch(function(error) {
                    //     // Handle Errors here.
                    //     var errorCode = error.code;
                    //     var errorMessage = error.message;
                    //     // ...
                    //   });
                    localStorage.setItem("user_id", response.data.data.id);
                    localStorage.setItem("phone", sessionStorage.getItem("phone"));
                    localStorage.setItem("custom_token", response.data.data.custom_token);
                    localStorage.setItem("user_type",response.data.data.user_type)
                    localStorage.setItem("access_token", response.data.data.access_token.token);
                    localStorage.setItem("token_expiration_date", response.data.data.access_token.expiration_date);
                    localStorage.setItem("user_roles", sessionStorage.getItem("user_roles"));
                    localStorage.setItem("loginTimeStamp",new Date().getTime())  
                    sessionStorage.removeItem("session_key");
                    sessionStorage.removeItem("user_roles");
                    sessionStorage.removeItem("phone");
                    that.setState({status : 'redirect'});   
                }else{
                    console.log(response.data.status);console.log(response.data.reason);
                    that.setState({status : 'error'});
                }
                })
                .catch(function (error) {
                // handle error
                console.log(error);
                })
                .finally(function () {
                // always executed
                });;
            break;
        default:
            break;
    }

//	API.tabClickCall(tag, that);
  }

  componentWillMount() {
	
    }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  render() {
    switch(this.state.status){
        case "logging in":
                return (
                    <React.Fragment>
                        <div id="accounttopbuffer"></div>
                        <div id="accountmaindiv" className="border-box">
                            <div className="account_header border-box centeralign">
                                <div className="border-box shadow-box bgwhite">
                                    <PanelPostLogin status={this.state.status} />
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    // <div id="containermainouter1">
                    // 	<PanelRight content={this.state.rightContent} showContent={this.state.showRightContent} tag={this.state.selectedTab}/>
                    // 	<PanelLeft onTabClick={this.handleTabClick} selectedTab={this.state.selectedTab}/>
                    // 	<PanelMiddle tag={this.state.selectedTab} selectedRow={this.state.selectedRow} content={this.state.middleContent} showContent={this.state.showMiddleContent} onRowClick={this.handleRowClick}/>
                    // </div>
                );
        case "error":
            return (
                <React.Fragment>
                    <div id="accounttopbuffer"></div>
                    <div id="accountmaindiv" className="border-box">
                        <div className="account_header border-box centeralign">
                            <div className="border-box shadow-box bgwhite">
                                <PanelError status={this.state.status} />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                // <div id="containermainouter1">
                // 	<PanelRight content={this.state.rightContent} showContent={this.state.showRightContent} tag={this.state.selectedTab}/>
                // 	<PanelLeft onTabClick={this.handleTabClick} selectedTab={this.state.selectedTab}/>
                // 	<PanelMiddle tag={this.state.selectedTab} selectedRow={this.state.selectedRow} content={this.state.middleContent} showContent={this.state.showMiddleContent} onRowClick={this.handleRowClick}/>
                // </div>
            );
        case "sending otp":
                return (
                    <React.Fragment>
                        <div id="accounttopbuffer"></div>
                        <div id="accountmaindiv" className="border-box">
                            <div className="account_header border-box centeralign">
                                <div className="border-box shadow-box bgwhite">
                                    <PanelSendOTP status={this.state.status} />
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    // <div id="containermainouter1">
                    // 	<PanelRight content={this.state.rightContent} showContent={this.state.showRightContent} tag={this.state.selectedTab}/>
                    // 	<PanelLeft onTabClick={this.handleTabClick} selectedTab={this.state.selectedTab}/>
                    // 	<PanelMiddle tag={this.state.selectedTab} selectedRow={this.state.selectedRow} content={this.state.middleContent} showContent={this.state.showMiddleContent} onRowClick={this.handleRowClick}/>
                    // </div>
                );
        case "redirect":
            // if((localStorage.getItem("user_roles") && localStorage.getItem("user_roles").indexOf('Support Executive') > -1)){
            //     return <Redirect to="/chat"/>
            // }
            if((localStorage.getItem("user_roles") && localStorage.getItem("user_roles").indexOf('Content Executive') > -1)){
                return <Redirect to="/content/website" />
            }else{
                return <Redirect to="/dashboard"/>
            }
        default:
                return (
                    <React.Fragment>
                        <div id="accounttopbuffer"></div>
                        <div id="accountmaindiv" className="border-box">
                            <div className="account_header border-box centeralign">
                                <div className="border-box shadow-box bgwhite">
                                <PanelPreLogin onLoginClick={this.handleLoginClick} status={this.state.status} />
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    // <div id="containermainouter1">
                    // 	<PanelRight content={this.state.rightContent} showContent={this.state.showRightContent} tag={this.state.selectedTab}/>
                    // 	<PanelLeft onTabClick={this.handleTabClick} selectedTab={this.state.selectedTab}/>
                    // 	<PanelMiddle tag={this.state.selectedTab} selectedRow={this.state.selectedRow} content={this.state.middleContent} showContent={this.state.showMiddleContent} onRowClick={this.handleRowClick}/>
                    // </div>
                );
    }
    
  }
}


export default LoginMain;
