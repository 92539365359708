import React, { useState, useEffect } from "react";
import "./workshopMasking.css";
import { NotificationManager } from "react-notifications";
import API from "../../api";
import Loader from "../../loader";

export default function WorkshopMasking(props) {
  const [showLoader, setLoader] = useState(false);
  const [isLeadAssignment, setLeadAssignment] = useState(false);
  const [isMasked, setMasked] = useState(false);
  const [isOTPEnabled, setOTPEnabled] = useState(true);
  const [isOrderClosed, setIsOrderClosed] = useState(false);
  const [workshopObj, setWorkshopObj] = useState({});
  const [npsValue, setNpsValue] = useState(null);
  const [warningDesc, setWarningDesc] = useState('');
  const [retailer, setRetailer] = useState(localStorage.getItem("workshop_filters") !== undefined ? JSON.parse(localStorage.getItem("workshop_filters")) : {})

  const [addPartsObj, setAddPartsObj] = useState({category_name:"", sub_category_name:"", is_private_label: false});
  const [circleLoader, setCircleLoader] = useState(false);

  useEffect(() => {
    if (props.content.id > 0) {
      setWorkshopObj(props.content);
      setLeadAssignment(props.content.workshop_closed)
      setMasked(props.content.mask_required);
      setOTPEnabled(props.content.otp_enabled);
      setIsOrderClosed(props.content.ordering_disabled)
      setNpsValue(props.content.nps_value ? props.content.nps_value : null)
      setWarningDesc(props.content.cms_warning_description ? props.content.cms_warning_description : '')
    } 
  }, [props.content, retailer]);
  
  function leadAssignmentRadioToggle(event) {
    setLoader(true);
    let garage_id = [workshopObj.id];
    let lead_assignment = event.target.checked;

    API.leadStatusAssignment(garage_id, lead_assignment).then(function (response) {
      NotificationManager.success("Lead Assignment Status Changed", "Successfully", 5000);
      setLoader(false);
      setLeadAssignment(lead_assignment);
    })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating Lead Assignment Status");
        setLoader(false);
      });
  }

  function handleRadioToggle(event) {
    setLoader(true);
    let garage_id = workshopObj.id;
    let mask_required = event.target.checked;

    API.updateGarageMasking(garage_id, mask_required).then(function (response) {
      NotificationManager.success("Workshop masked ", "Successfully", 5000);
      setLoader(false);
      setMasked(mask_required);
    })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating gargae masking ");
        setLoader(false);
      });
  }

  function handleOTPRadioToggle(event) {
    setLoader(true);
    let garage_id = workshopObj.id;
    let otp_enable = event.target.checked;

    let text = event.target.checked ? 'Enabled' : 'Disabled';

    API.updateDriverOTP(garage_id, otp_enable).then(function (response) {
      NotificationManager.success(`OTP ${text}`, "Successfully", 5000);
      setLoader(false);
      setOTPEnabled(otp_enable);
    })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating driver otp");
        setLoader(false);
      });
  }

  function handleOrderRadioToggle(event) {
    setLoader(true);
    let garage_id = workshopObj.id;
    let order_close = event.target.checked;

    let text = event.target.checked ? 'Enabled' : 'Disabled';

    API.updateClosedOrders(garage_id, order_close).then(function (response) {
      NotificationManager.success(`Order ${text}`, "Successfully", 5000);
      setLoader(false);
      setIsOrderClosed(order_close);
    })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating the order");
        setLoader(false);
      });
  }

  function handlePenalityRadioToggle(event, type) {
    let checked = event.target.checked;

    let obj = { ...workshopObj };
    obj['ga_wallet'][`${type}_enabled`] = checked;
    if (!checked)
      obj['ga_wallet'][`${type}_amount`] = 0;

    if ((type == "drop_otp_reward" || type == "drop_otp_penalty") && !checked) {
      obj['ga_wallet'][`${type}`] = 0;
    }

    setWorkshopObj(obj);
  }

  function handleRootToggle(event, type) {
    let checked = event.target.checked;
    let obj = { ...workshopObj };
    obj[type] = checked  
    if(!checked)
      obj['miles_nps_block_limit'] = 0
    setWorkshopObj(obj);
  }

  function UpdateSingaleValue(key, value) {
    let obj = {};
    obj[`${key}`] = value
    let temp = [];
    if (retailer && retailer.isRetailer && retailer.isRetailer.id == '1') {
      if (props.filters) {
        props.filters.workshops.filter((w) => {
          if (w.id != 'select-all') {
            temp.push(w.id);
          }
        })
      }
    }
    else if (retailer && retailer.isRetailer && retailer.isRetailer.id == '2') {

      if (props.filters) {
        props.filters.retailers.filter((w) => {
          if (w.id != 'select-all') {
            temp.push(w.id);
          }
        })
      }
    }
    obj['garage_id'] = temp;
    obj['channel'] = retailer && retailer.isRetailer.id;

    if (key === 'nps_value') {
      if ((obj[`${key}`] < 101 && obj[`${key}`] > -101)) {
        setCircleLoader(true)
        API.updateGarageWalletDetails(obj).then(function (response) {
          NotificationManager.success(`Data saved`, "Successfully", 5000);
          setCircleLoader(false)
        })
          .catch(function (error) {
            NotificationManager.error("Please try again", "Error", 5000);
            console.log(error, "Error in updating the garage wallet details");
            setCircleLoader(false)
          });

      } else {
        NotificationManager.error("NPS amount should  be in between -100 to 100", 5000);
      }
    } else {
      setCircleLoader(true)
      API.updateGarageWalletDetails(obj).then(function (response) {
        NotificationManager.success(`Data saved`, "Successfully", 5000);
        setCircleLoader(false)
      })
        .catch(function (error) {
          NotificationManager.error("Please try again", "Error", 5000);
          console.log(error, "Error in updating the garage wallet details");
          setCircleLoader(false)
        });
    }

  }
  function handleSaveButtonClick(key) {
    let obj = {};
    let amountKey = (key == "drop_otp_reward" || key == "drop_otp_penalty") ? key : `${key}_amount`;
    obj[amountKey] = workshopObj['ga_wallet'][amountKey];

    if (key === 'recon_limit' && obj[`${key}_amount`] < 0) {
      NotificationManager.error("Recon amount should  be positive", 5000);
      return;
    }

    if (key == "general_penalty" || key == "general_reward" || key == "adjustment_penalty") {
      obj[`${key}_remark`] = workshopObj['ga_wallet'][`${key}_remark`];
    } else {
      obj[`${key}_enabled`] = workshopObj['ga_wallet'][`${key}_enabled`];
    }

    if(key === "take_rate") {
      let stateObj = {...workshopObj};
      let objValue = stateObj[`take_rate`];
      objValue = Number(objValue);
      obj[key] = objValue;
    }

    let temp = [];
    if (retailer && retailer.isRetailer && retailer.isRetailer.id == '1') {
      if (props.filters) {
        props.filters.workshops.filter((w) => {
          if (w.id != 'select-all') {
            temp.push(w.id);
          }
        })
      }
    }
    else if (retailer && retailer.isRetailer && retailer.isRetailer.id == '2') {
      if (props.filters) {
        props.filters.retailers.filter((w) => {
          if (w.id != 'select-all') {
            temp.push(w.id);
          }
        })
      }
    }
    obj['garage_id'] = temp;
    obj['channel'] = retailer && retailer.isRetailer.id
    
    setCircleLoader(true);
    API.updateGarageWalletDetails(obj).then(function (response) {
      NotificationManager.success(`Data saved`, "Successfully", 5000);
      setCircleLoader(false);
    })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating the garage wallet details");
        setCircleLoader(false)
      });
  }

  function handleSaveMilesNps(){
    let obj = {} 
    let temp = [];
    if (retailer && retailer.isRetailer && retailer.isRetailer.id == '1') {
      if (props.filters) {
        props.filters.workshops.filter((w) => {
          if (w.id != 'select-all') {
            temp.push(w.id);
          }
        })
      }
    }
    else if (retailer && retailer.isRetailer && retailer.isRetailer.id == '2') {
      if (props.filters) {
        props.filters.retailers.filter((w) => {
          if (w.id != 'select-all') {
            temp.push(w.id);
          }
        })
      }
    }
    obj['miles_nps_block_limit'] = workshopObj.miles_nps_block_limit
    obj['miles_nps_block_enable'] = workshopObj.miles_nps_block_enable
    obj['garage_id'] = temp;
    obj['channel'] = retailer && retailer.isRetailer.id
    
    setCircleLoader(true);
    API.updateGarageWalletDetails(obj).then(function (response) {
      NotificationManager.success(`Data saved`, "Successfully", 5000);
      setCircleLoader(false);
    })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating the garage wallet details");
        setCircleLoader(false)
    });
  } 

  function handleText(type, e, ref = "amount") {
    let obj = { ...workshopObj };
    if (ref !== "retailer_text" && ref !== "retailer_reward_remarks" && ref!='miles_nps') {
      let value = ref == "amount" ? e.target.value ? parseInt(e.target.value) : 0 : e.target.value;
      obj['ga_wallet'][type] = value;
    }
    if (ref === "retailer_text") {
      let value = e.target.value ? e.target.value : 0;
      value = Number(value);
      value = value.toFixed(2);
      obj[type] = value;
    }
    if (ref === "miles_nps") {
      let value = e.target.value ? e.target.value : 0;
      value = Number(value);
      obj[type] = value;
    }
    if (ref === "retailer_reward_remarks" && type === "re_wallet_general_reward") {
      let value = e.target.value ? e.target.value : "";
      obj[type] = value;
    }
    else if (ref === "retailer_reward_remarks" && type === "re_wallet_general_reward_remark") {
      let value = e.target.value ? e.target.value : "";
      obj[type] = value;
    }
    setWorkshopObj(obj);
  }

  function handleAddPartText(key, e) {
    let obj = {...addPartsObj};
    console.log('obj', obj);
    obj['channel'] = "3";
    if (key !== "is_private_label") {
      obj[key] = e.target.value;
    } else if (key === "is_private_label") {
      obj[key] = e;
    }
    console.log('obj', obj);
    setAddPartsObj(obj)
  }

  function handleSaveAddPart() {
    let obj = { ...addPartsObj };
    console.log('obj',obj)
    setCircleLoader(true);
    API.updateGarageWalletDetails(obj).then(function (response) {
      NotificationManager.success(`Data saved`, "Successfully", 5000);
      setAddPartsObj({ category_name: "", sub_category_name: "", is_private_label: false });
      setCircleLoader(false)
    })
      .catch(function (error) {
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating the garage wallet details");
        setAddPartsObj({ category_name: "", sub_category_name: "", is_private_label: false });
        setCircleLoader(false)
      });
  }

  function handleClosePopup () {
    props.handlePageChange(false)
  }

  function handleSaveRewardButtonClick(){
    let obj = { ...workshopObj };
    let payload = {}
    let intValue = obj["re_wallet_general_reward"] ? obj["re_wallet_general_reward"] : 0;
    intValue = Number(intValue);
    payload["re_wallet_general_reward"] = intValue;
    payload["re_wallet_general_reward_remark"] = obj["re_wallet_general_reward_remark"] ? obj["re_wallet_general_reward_remark"] : "";
    payload["channel"] = "2";
    let temp = [];
    if (retailer && retailer.isRetailer && retailer.isRetailer.id == '2') {
      if (props.filters) {
        props.filters.retailers.filter((w) => {
          if (w.id != 'select-all') {
            temp.push(w.id);
          }
        })
      }
    }
    payload['garage_id'] = temp;

    setCircleLoader(true);
    API.updateGarageWalletDetails(payload).then(function (response) {
      NotificationManager.success(`Data saved`, "Successfully", 5000);
      setAddPartsObj({ category_name: "", sub_category_name: "", is_private_label: false });
      setCircleLoader(false)
    })
      .catch(function (error) {
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating the garage wallet details");
        setAddPartsObj({ category_name: "", sub_category_name: "", is_private_label: false });
        setCircleLoader(false)
      });
  }
  function handleSaveTakeRate() {
    let obj = {};
    let temp = [];
    let stateObj = { ...workshopObj };
    let objValue = stateObj[`take_rate`];

    objValue = Number(objValue);
    obj["take_rate"] = objValue;

    if (props.filters) {
      props.filters.retailers.filter((w) => {
        if (w.id != 'select-all') {
          temp.push(w.id);
        }
      })
    }
    
    obj['garage_id'] = temp;
    obj['channel'] = retailer && retailer.isRetailer.id

    if (objValue <= 100) {
      setCircleLoader(true);
      API.updateGarageWalletDetails(obj).then(function (response) {
        NotificationManager.success(`Data saved`, "Successfully", 5000);
        setCircleLoader(false);
      })
        .catch(function (error) {
          // handle error
          NotificationManager.error("Please try again", "Error", 5000);
          console.log(error, "Error in updating the garage wallet details");
          setCircleLoader(false)
        });
    } else if (objValue > 100) {
      NotificationManager.error("Take rate can't be more than 100", "Error", 5000);
    }
  }

  return (
    <>
      {circleLoader ? (
        <>
          <div className="modal-sb"></div>
          <div className="sub-modal-sb"></div>
        </>
      ) : (
        ""
      )}

      <>
        {showLoader ?
          <div id="websiteMainmiddleloader" className="bgwhite centeralign">
            <Loader showLoader={showLoader} />
          </div> :
          <>
            <div className={(props.filters && props.filters.cities && ((props.filters.retailers && props.filters.retailers.length && props.filters.retailers.length > 0) || (props.filters.workshops.length > 0)) || props.showAddPart) ?"mainContainer" : "block"}>
              {props.showAddPart
              ? (
                  <div className={props.showAddPart ? "innerBox" : "block"}>
                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                      <span style={{
                        color: "#282828",
                        fontSize: "1rem",
                        fontWeight: "600",
                        lineHeight: "1.42",
                        letterSpacing: "0.0088rem",
                        textTransform: "uppercase",
                      }}>
                        Add Spare Parts
                      </span>
                      <button style={{ cursor: "pointer" }} className="btn btn_small btncard move" onClick={handleClosePopup}>X</button>
                    </div>
                    <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                      <div className="selectDate maskingDataContainer">
                        Category Name:
                        <div>
                          <input
                            value={addPartsObj && addPartsObj.category_name ? addPartsObj.category_name : ""}
                            type="text"
                            maxLength="30"
                            placeholder="Text"
                            className={`dateInputBox field border-box small`}
                            onChange={(e) => {
                              handleAddPartText("category_name", e);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                      <div className="selectDate maskingDataContainer">
                        Sub-category Name:
                        <div>
                          <input
                            value={addPartsObj && addPartsObj.sub_category_name ? addPartsObj.sub_category_name : ""}
                            type="text"
                            maxLength="30"
                            placeholder="Text"
                            className={`dateInputBox field border-box small`}
                            onChange={(e) => {
                              handleAddPartText("sub_category_name", e);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                      <div className="selectDate maskingDataContainer">
                        <div>Is Private Label:</div>
                        <div>
                          <input
                            checked={addPartsObj && addPartsObj.is_private_label ? addPartsObj.is_private_label:false}
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            name="toggleIsPrivateLabel"
                            id="toggleIsPrivateLabel"
                            onChange={(e) => handleAddPartText("is_private_label", e.target.checked)}
                          />
                          <label className="toggle-switch-label" htmlFor="toggleIsPrivateLabel">
                            <span className="toggle-switch-inner" />
                            <span className="toggle-switch-switch" />
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                      <div className="selectDate maskingDataContainer">
                        SKU ID:
                        <div>
                          <input
                            type="text"
                            maxLength="30"
                            placeholder="1234"
                            // defaultValue={workshopObj.ga_wallet && workshopObj.ga_wallet.inventory_reward_amount}
                            className={`dateInputBox field border-box small`}
                            disabled={true}
                            style={{ cursor: "not-allowed" }}
                          />
                        </div>
                      </div>
                    </div> */}
                    <div role="button" style={{ marginTop: '20px' }}>
                      <div className="btn btn_small btncolorprim btncard move"
                        onClick={() => { handleSaveAddPart()}}
                      >Save</div>
                    </div>
                  </div>
              ) : (
                <>
                  <div className="manageTimeSlot">Manage {retailer && retailer.isRetailer && retailer.isRetailer.id == "2" ? "Retailers" : "Workshop"} Details</div>
                    <div className={retailer && retailer.isRetailer && retailer.isRetailer.id == '1' ? "innerBox" : "block"}>
                      <div className="selectDate" style={{ marginBottom: '20px' }}>{retailer && retailer.isRetailer && retailer.isRetailer.id == "2" ? "Retailer :" : "Workshop :"} <span className="monthName">{workshopObj.name}</span></div>
                      <div className="slotBookContainer">
                        <div className="selectDate maskingDataContainer">
                          <div>Stop Lead Assignment :</div>
                          {/* <span className="monthName">{workshopObj.name}</span> */}
                          <div>
                            <input
                              type="checkbox"
                              className="toggle-switch-checkbox"
                              name="toggleLASwitch"
                              id="toggleLASwitch"
                              onChange={(e) => UpdateSingaleValue("workshop_closed", e.target.checked)}
                              defaultChecked={props && props.content && props.content.workshop_closed === true ? true : false}
                            // checked={isLeadAssignment}
                            />
                            <label className="toggle-switch-label" htmlFor="toggleLASwitch">
                              <span className="toggle-switch-inner" />
                              <span className="toggle-switch-switch" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          <div>Enable Masking :</div>
                          {/* <span className="monthName">{workshopObj.name}</span> */}
                          <div>
                            <input
                              type="checkbox"
                              className="toggle-switch-checkbox"
                              name="toggleSwitch"
                              id="toggleSwitch"
                              onChange={(e) => UpdateSingaleValue("mask_required", e.target.checked)}
                              defaultChecked={props && props.content && props.content.mask_required ? props.content.mask_required : false}
                            // checked={isMasked}
                            />
                            <label className="toggle-switch-label" htmlFor="toggleSwitch">
                              <span className="toggle-switch-inner" />
                              <span className="toggle-switch-switch" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          Enable Driver OTP :
                          {/* <span className="monthName">{workshopObj.name}</span> */}
                          <div>
                            <input
                              type="checkbox"
                              className="toggle-switch-checkbox"
                              name="toggleOTPSwitch"
                              id="toggleOTPSwitch"
                              onChange={(e) => UpdateSingaleValue("otp_enabled", e.target.checked)}
                              defaultChecked={props && props.content && props.content.otp_enabled ? props.content.otp_enabled : false}
                            // checked={isOTPEnabled}
                            />
                            <label className="toggle-switch-label" htmlFor="toggleOTPSwitch">
                              <span className="toggle-switch-inner" />
                              <span className="toggle-switch-switch" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          <div>Close Order :</div>
                          {/* <span className="monthName">{workshopObj.name}</span> */}
                          <div>
                            <input
                              type="checkbox"
                              className="toggle-switch-checkbox"
                              name="toggleOrderSwitch"
                              id="toggleOrderSwitch"
                              onChange={(e) => UpdateSingaleValue("ordering_disabled", e.target.checked)}
                              defaultChecked={props && props.content && props.content.ordering_disabled ? props.content.ordering_disabled : false}
                            // checked={isOrderClosed}
                            />
                            <label className="toggle-switch-label" htmlFor="toggleOrderSwitch">
                              <span className="toggle-switch-inner" />
                              <span className="toggle-switch-switch" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          <div>Enable Tshirt Orders :</div>
                          {/* <span className="monthName">{workshopObj.name}</span> */}
                          <div>
                            <input
                              type="checkbox"
                              className="toggle-switch-checkbox"
                              name="toggleTshirtSwitch"
                              id="toggleTshirtSwitch"
                              onChange={(e) => UpdateSingaleValue("tshirt_block", e.target.checked)}
                              defaultChecked={props && props.content && props.content.tshirt_block ? props.content.tshirt_block : false}
                            // checked={isOrderClosed}
                            />
                            <label className="toggle-switch-label" htmlFor="toggleTshirtSwitch">
                              <span className="toggle-switch-inner" />
                              <span className="toggle-switch-switch" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          <div>Enable Mechanic :</div>
                          {/* <span className="monthName">{workshopObj.name}</span> */}
                          <div>
                            <input
                              type="checkbox"
                              className="toggle-switch-checkbox"
                              name="toggleMechanicSwitch"
                              id="toggleMechanicSwitch"
                              onChange={(e) => UpdateSingaleValue("mechanic_enabled", e.target.checked)}
                              defaultChecked={props && props.content && props.content.mechanic_enabled ? props.content.mechanic_enabled : false}
                            // checked={workshopObj && workshopObj.mechanic_enabled ? workshopObj.mechanic_enabled : false}
                            />
                            <label className="toggle-switch-label" htmlFor="toggleMechanicSwitch">
                              <span className="toggle-switch-inner" />
                              <span className="toggle-switch-switch" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          <div>Enable Oil Block :</div>
                          {/* <span className="monthName">{workshopObj.name}</span> */}
                          <div>
                            <input
                              type="checkbox"
                              className="toggle-switch-checkbox"
                              name="toggleOilSwitch"
                              id="toggleOilSwitch"
                              onChange={(e) => UpdateSingaleValue("oil_block_enabled", e.target.checked)}
                              defaultChecked={props && props.content && props.content.oil_block_enabled ? props.content.oil_block_enabled : false}
                            // checked={workshopObj && workshopObj.mechanic_enabled ? workshopObj.mechanic_enabled : false}
                            />
                            <label className="toggle-switch-label" htmlFor="toggleOilSwitch">
                              <span className="toggle-switch-inner" />
                              <span className="toggle-switch-switch" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          <div>Enable Lead Block Due To Oil :</div>
                          {/* <span className="monthName">{workshopObj.name}</span> */}
                          <div>
                            <input
                              type="checkbox"
                              className="toggle-switch-checkbox"
                              name="toggleOilLeadSwitch"
                              id="toggleOilLeadSwitch"
                              onChange={(e) => UpdateSingaleValue("lead_block_due_to_oil_barrel", e.target.checked)}
                              defaultChecked={props && props.content && props.content.lead_block_due_to_oil_barrel ? props.content.lead_block_due_to_oil_barrel : false}
                            // checked={workshopObj && workshopObj.mechanic_enabled ? workshopObj.mechanic_enabled : false}
                            />
                            <label className="toggle-switch-label" htmlFor="toggleOilLeadSwitch">
                              <span className="toggle-switch-inner" />
                              <span className="toggle-switch-switch" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          <div>Enable Job Card Location :</div>
                          {/* <span className="monthName">{workshopObj.name}</span> */}
                          <div>
                            <input
                              type="checkbox"
                              className="toggle-switch-checkbox"
                              name="toggleJobcardLocation"
                              id="toggleJobcardLocation"
                              onChange={(e) => UpdateSingaleValue("job_card_location_check_enabled", e.target.checked)}
                              defaultChecked={props && props.content.job_card_location_check_enabled ? props.content.job_card_location_check_enabled : false}
                             // checked={workshopObj && workshopObj.job_card_location_check_enabled}
                            />
                            <label className="toggle-switch-label" htmlFor="toggleJobcardLocation">
                              <span className="toggle-switch-inner" />
                              <span className="toggle-switch-switch" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          Enable Online Collection :
                          <div>
                            <input
                              type="checkbox"
                              className="toggle-switch-checkbox"
                              name="toggleCollectionSwitch"
                              id="toggleCollectionSwitch"
                              onChange={(e) => handlePenalityRadioToggle(e, "collection_limit")}
                              defaultChecked={false}
                              checked={workshopObj.ga_wallet && workshopObj.ga_wallet.collection_limit_enabled ? workshopObj.ga_wallet.collection_limit_enabled : false}
                            />
                            <label className="toggle-switch-label" htmlFor="toggleCollectionSwitch">
                              <span className="toggle-switch-inner" />
                              <span className="toggle-switch-switch" />
                            </label>
                          </div>
                          <div>
                            <input
                              type="text"
                              maxLength="30"
                              placeholder="Collection in %"
                              defaultValue={workshopObj.ga_wallet && workshopObj.ga_wallet.collection_limit_amount ? workshopObj.ga_wallet && workshopObj.ga_wallet.collection_limit_amount ? workshopObj.ga_wallet.collection_limit_amount : "" : ""}
                              disabled={workshopObj.ga_wallet && workshopObj.ga_wallet.collection_limit_enabled ? "" : "disabled"}
                              className={`dateInputBox field border-box small ${workshopObj.ga_wallet && workshopObj.ga_wallet.collection_limit_enabled ? "" : "inputDisabled"}`}
                              onChange={(e) => {
                                handleText("collection_limit_amount", e);
                              }}
                            />
                          </div>
                          <div role="button">
                            <div className="btn btn_small btncolorprim btncard move" onClick={() => { handleSaveButtonClick("collection_limit") }}>Save</div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          Enable Miles NPS :
                          <div>
                            <input
                              type="checkbox"
                              className="toggle-switch-checkbox"
                              name="toggleMilesNps"
                              id="toggleMilesNps"
                              onChange={(e) => handleRootToggle(e, "miles_nps_block_enable")}
                              defaultChecked={false}
                              checked={workshopObj && workshopObj.miles_nps_block_enable? workshopObj.miles_nps_block_enable : false}
                            />
                            <label className="toggle-switch-label" htmlFor="toggleMilesNps">
                              <span className="toggle-switch-inner" />
                              <span className="toggle-switch-switch" />
                            </label>
                          </div>
                          <div>
                            <input
                              type="text"
                              placeholder="Miles NPS Block Limit"
                              defaultValue={workshopObj && workshopObj.miles_nps_block_limit ? workshopObj.miles_nps_block_limit : ''}
                              className={`dateInputBox field border-box small ${workshopObj && workshopObj.miles_nps_block_enable ? "" : "inputDisabled"}`}
                              onChange={(e) => {
                                handleText("miles_nps_block_limit", e,"miles_nps");
                              }}
                            />
                          </div>
                          <div role="button">
                            <div className="btn btn_small btncolorprim btncard move" onClick={() => { handleSaveMilesNps() }}>Save</div>
                          </div>
                        </div>
                      </div> */}
                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          Enable Delivery OTP Reward:
                          {/* <span className="monthName">{workshopObj.name}</span> */}
                          <div>
                            <input
                              type="checkbox"
                              className="toggle-switch-checkbox"
                              name="toggleOtpRewardSwitch"
                              id="toggleOtpRewardSwitch"
                              onChange={(e) => handlePenalityRadioToggle(e, "drop_otp_reward")}
                              defaultChecked={false}
                              checked={workshopObj.ga_wallet && workshopObj.ga_wallet.drop_otp_reward_enabled ? workshopObj.ga_wallet.drop_otp_reward_enabled : false}
                            />
                            <label className="toggle-switch-label" htmlFor="toggleOtpRewardSwitch">
                              <span className="toggle-switch-inner" />
                              <span className="toggle-switch-switch" />
                            </label>
                          </div>
                          <div>
                            <input
                              type="text"
                              maxLength="30"
                              placeholder="Enter Reward"
                              defaultValue={workshopObj.ga_wallet && workshopObj.ga_wallet.drop_otp_reward ? workshopObj.ga_wallet && workshopObj.ga_wallet.drop_otp_reward ? workshopObj.ga_wallet.drop_otp_reward : "" : ""}
                              disabled={workshopObj.ga_wallet && workshopObj.ga_wallet.drop_otp_reward_enabled ? "" : "disabled"}
                              className={`dateInputBox field border-box small ${workshopObj.ga_wallet && workshopObj.ga_wallet.drop_otp_reward_enabled ? "" : "inputDisabled"}`}
                              onChange={(e) => {
                                handleText("drop_otp_reward", e);
                              }}
                            />
                          </div>
                          <div role="button">
                            <div className="btn btn_small btncolorprim btncard move" onClick={() => { handleSaveButtonClick("drop_otp_reward") }}>Save</div>
                          </div>
                        </div>
                      </div>
                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          Enable Delivery OTP Penalty:
                          {/* <span className="monthName">{workshopObj.name}</span> */}
                          <div>
                            <input
                              type="checkbox"
                              className="toggle-switch-checkbox"
                              name="toggleOtpPenaltySwitch"
                              id="toggleOtpPenaltySwitch"
                              onChange={(e) => handlePenalityRadioToggle(e, "drop_otp_penalty")}
                              defaultChecked={false}
                              checked={workshopObj.ga_wallet && workshopObj.ga_wallet.drop_otp_penalty_enabled ? workshopObj.ga_wallet.drop_otp_penalty_enabled : false}
                            />
                            <label className="toggle-switch-label" htmlFor="toggleOtpPenaltySwitch">
                              <span className="toggle-switch-inner" />
                              <span className="toggle-switch-switch" />
                            </label>
                          </div>
                          <div>
                            <input
                              type="text"
                              maxLength="30"
                              placeholder="Enter Penalty"
                              defaultValue={workshopObj.ga_wallet && workshopObj.ga_wallet.drop_otp_penalty ? workshopObj.ga_wallet && workshopObj.ga_wallet.drop_otp_penalty ? workshopObj.ga_wallet.drop_otp_penalty : "" : ""}
                              disabled={workshopObj.ga_wallet && workshopObj.ga_wallet.drop_otp_penalty_enabled ? "" : "disabled"}
                              className={`dateInputBox field border-box small ${workshopObj.ga_wallet && workshopObj.ga_wallet.drop_otp_penalty_enabled ? "" : "inputDisabled"}`}
                              onChange={(e) => {
                                handleText("drop_otp_penalty", e);
                              }}
                            />
                          </div>
                          <div role="button">
                            <div className="btn btn_small btncolorprim btncard move" onClick={() => { handleSaveButtonClick("drop_otp_penalty") }}>Save</div>
                          </div>
                        </div>
                      </div>
                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          Enable General Penalty:
                          <div>
                            <input
                              type="text"
                              maxLength="10"
                              placeholder="Enter Penalty"
                              defaultValue={workshopObj.ga_wallet && workshopObj.ga_wallet.general_penalty_amount ? workshopObj.ga_wallet.general_penalty_amount : ""}
                              disabled=""
                              className="dateInputBox field border-box small"
                              onChange={(e) => {
                                handleText("general_penalty_amount", e);
                              }}
                            />
                          </div>
                          <div>
                            <input
                              type="text"
                              maxLength="30"
                              placeholder="Enter Remark"
                              defaultValue={workshopObj.ga_wallet && workshopObj.ga_wallet.general_penalty_remark ? workshopObj.ga_wallet.general_penalty_remark : ""}
                              disabled={workshopObj.ga_wallet && workshopObj.ga_wallet.general_penalty_amount ? "" : "disabled"}
                              className={`dateInputBox field border-box small ${workshopObj.ga_wallet && workshopObj.ga_wallet.general_penalty_amount ? "" : "inputDisabled"}`}
                              onChange={(e) => {
                                handleText("general_penalty_remark", e, "remark");
                              }}
                            />
                          </div>
                          <div role="button">
                            <div className="btn btn_small btncolorprim btncard move" onClick={() => { handleSaveButtonClick("general_penalty") }}>Save</div>
                          </div>
                        </div>
                      </div>
                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          Enable General Reward:
                          <div>
                            <input
                              type="text"
                              maxLength="10"
                              placeholder="Enter Reward"
                              defaultValue={workshopObj.ga_wallet && workshopObj.ga_wallet.general_reward_amount ? workshopObj.ga_wallet.general_reward_amount : ""}
                              // disabled={workshopObj.ga_wallet && workshopObj.ga_wallet.drop_otp_penalty_enabled ? "" : "disabled"}
                              className="dateInputBox field border-box small"
                              onChange={(e) => {
                                handleText("general_reward_amount", e);
                              }}
                            />
                          </div>
                          <div>
                            <input
                              type="text"
                              maxLength="30"
                              placeholder="Enter Remark"
                              defaultValue={workshopObj.ga_wallet && workshopObj.ga_wallet.general_reward_remark ? workshopObj.ga_wallet.general_reward_remark : ""}
                              disabled={workshopObj.ga_wallet && workshopObj.ga_wallet.general_reward_amount ? "" : "disabled"}
                              className={`dateInputBox field border-box small ${workshopObj.ga_wallet && workshopObj.ga_wallet.general_reward_amount ? "" : "inputDisabled"}`}
                              onChange={(e) => {
                                handleText("general_reward_remark", e, "remark");
                              }}
                            />
                          </div>
                          <div role="button">
                            <div className="btn btn_small btncolorprim btncard move" onClick={() => { handleSaveButtonClick("general_reward") }}>Save</div>
                          </div>
                        </div>
                      </div>

                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          Enable Adjustment Penalty:
                          <div>
                            <input
                              type="text"
                              maxLength="10"
                              placeholder="Enter Penalty"
                              defaultValue={workshopObj.ga_wallet && workshopObj.ga_wallet.adjustment_penalty_amount ? workshopObj.ga_wallet.adjustment_penalty_amount : ""}
                              className="dateInputBox field border-box small small-gap-4"
                              onChange={(e) => {
                                handleText("adjustment_penalty_amount", e);
                              }}
                            />
                          </div>
                          <div>
                            <input
                              type="text"
                              maxLength="30"
                              placeholder="Enter Remark"
                              defaultValue={workshopObj.ga_wallet && workshopObj.ga_wallet.adjustment_penalty_remark ? workshopObj.ga_wallet.adjustment_penalty_remark : ""}
                              disabled={workshopObj.ga_wallet && workshopObj.ga_wallet.adjustment_penalty_amount ? "" : "disabled"}
                              className={`dateInputBox field border-box small small-gap-2 ${workshopObj.ga_wallet && workshopObj.ga_wallet.adjustment_penalty_amount ? "" : "inputDisabled"}`}
                              onChange={(e) => {
                                handleText("adjustment_penalty_remark", e, "remark");
                              }}
                            />
                          </div>
                          <div role="button">
                            <div className="btn btn_small btncolorprim btncard move" onClick={() => { handleSaveButtonClick("adjustment_penalty") }}>Save</div>
                          </div>
                        </div>
                      </div>

                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          Warning Description:
                          <div>
                            <input
                              type="text"
                              maxLength="30"
                              placeholder="Warning Description"
                              defaultValue={warningDesc}
                              className={`dateInputBox field border-box small`}
                              onChange={(e) => {
                                setWarningDesc(e.target.value)
                              }}
                            />
                          </div>
                          <div role="button">
                            <div className="btn btn_small btncolorprim btncard move" onClick={() => { UpdateSingaleValue("cms_warning_description", warningDesc) }}>Save</div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          <div>
                            <p>NPS Limit value </p>
                            <p className="font-10p">(Stop Lead Assignment if NPS is below)</p>
                          </div>
                          <div className="width-12r">
                            <input
                              type="number"
                              min="-100"
                              max="100"
                              maxLength="30"
                              placeholder="NPA limit"
                              defaultValue={npsValue}
                              className={`dateInputBox field border-box small`}
                              onChange={(e) => {
                                setNpsValue(e.target.value)
                              }}
                            />
                          </div>
                          <div role="button">
                            <div className="btn btn_small btncolorprim btncard move" onClick={() => { UpdateSingaleValue("nps_value", parseInt(npsValue)) }}>Save</div>
                          </div>
                        </div>
                      </div> */}
                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          Workshop Credit Limit:
                          {/* <span className="monthName">{workshopObj.name}</span> */}
                          {/* <div>
                  <input
                      type="checkbox"
                      className="toggle-switch-checkbox"
                      name="toggleCreditSwitch"
                      id="toggleCreditSwitch"
                      onChange={(e) => handlePenalityRadioToggle(e, "credit_limit")}
                      defaultChecked={false}
                      checked={workshopObj.ga_wallet && workshopObj.ga_wallet.credit_limit_enabled ? workshopObj.ga_wallet.credit_limit_enabled : false}
                    />
                    <label className="toggle-switch-label" htmlFor="toggleCreditSwitch">
                      <span className="toggle-switch-inner" />
                      <span className="toggle-switch-switch" />
                    </label>
                  </div> */}
                          <div>
                            <input
                              type="text"
                              maxLength="30"
                              placeholder="Text"
                              defaultValue={workshopObj.ga_wallet && workshopObj.ga_wallet.credit_limit_enabled ? workshopObj.ga_wallet && (workshopObj.ga_wallet.credit_limit_amount > -1) ? workshopObj.ga_wallet.credit_limit_amount : "" : ""}
                              disabled={workshopObj.ga_wallet && workshopObj.ga_wallet.credit_limit_enabled ? "" : "disabled"}
                              className={`dateInputBox field border-box small ${workshopObj.ga_wallet && workshopObj.ga_wallet.credit_limit_enabled ? "" : "inputDisabled"}`}
                              onChange={(e) => {
                                handleText("credit_limit_amount", e);
                              }}
                            />
                          </div>
                          <div role="button">
                            <div className="btn btn_small btncolorprim btncard move" onClick={() => { handleSaveButtonClick("credit_limit") }}>Save</div>
                          </div>
                        </div>
                      </div>
                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          Recon  Amount Limit:
                          {/* <span className="monthName">{workshopObj.name}</span> */}
                          <div>
                            <input
                              type="checkbox"
                              className="toggle-switch-checkbox"
                              name="toggleReconSwitch"
                              id="toggleReconSwitch"
                              onChange={(e) => handlePenalityRadioToggle(e, "recon_limit")}
                              defaultChecked={false}
                              checked={workshopObj.ga_wallet && workshopObj.ga_wallet.recon_limit_enabled ? workshopObj.ga_wallet.recon_limit_enabled : false}
                            />
                            <label className="toggle-switch-label" htmlFor="toggleReconSwitch">
                              <span className="toggle-switch-inner" />
                              <span className="toggle-switch-switch" />
                            </label>
                          </div>
                          <div>
                            <input
                              type="text"
                              maxLength="30"
                              placeholder="Text"
                              defaultValue={workshopObj.ga_wallet && workshopObj.ga_wallet.recon_limit_enabled ? workshopObj.ga_wallet && (workshopObj.ga_wallet.recon_limit_amount > -1) ? workshopObj.ga_wallet.recon_limit_amount : "" : ""}
                              disabled={workshopObj.ga_wallet && workshopObj.ga_wallet.recon_limit_enabled ? "" : "disabled"}
                              className={`dateInputBox field border-box small ${workshopObj.ga_wallet && workshopObj.ga_wallet.recon_limit_enabled ? "" : "inputDisabled"}`}
                              onChange={(e) => {
                                handleText("recon_limit_amount", e);
                              }}
                            />
                          </div>
                          <div role="button">
                            <div className="btn btn_small btncolorprim btncard move" onClick={() => { handleSaveButtonClick("recon_limit") }}>Save</div>
                          </div>
                        </div>
                      </div>
                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          Pickup penalty :
                          {/* <span className="monthName">{workshopObj.name}</span> */}
                          <div>
                            <input
                              type="checkbox"
                              className="toggle-switch-checkbox"
                              name="togglePickupSwitch"
                              id="togglePickupSwitch"
                              onChange={(e) => handlePenalityRadioToggle(e, "pickup_penalty")}
                              defaultChecked={false}
                              checked={workshopObj.ga_wallet && workshopObj.ga_wallet.pickup_penalty_enabled ? workshopObj.ga_wallet.pickup_penalty_enabled : false}
                            />
                            <label className="toggle-switch-label" htmlFor="togglePickupSwitch">
                              <span className="toggle-switch-inner" />
                              <span className="toggle-switch-switch" />
                            </label>
                          </div>
                          <div>
                            <input
                              type="text"
                              maxLength="30"
                              placeholder="Text"
                              defaultValue={workshopObj.ga_wallet && workshopObj.ga_wallet.pickup_penalty_enabled ? workshopObj.ga_wallet && workshopObj.ga_wallet.pickup_penalty_amount ? workshopObj.ga_wallet.pickup_penalty_amount : "" : ""}
                              disabled={workshopObj.ga_wallet && workshopObj.ga_wallet.pickup_penalty_enabled ? "" : "disabled"}
                              className={`dateInputBox field border-box small ${workshopObj.ga_wallet && workshopObj.ga_wallet.pickup_penalty_enabled ? "" : "inputDisabled"}`}
                              onChange={(e) => {
                                handleText("pickup_penalty_amount", e);
                              }}
                            />
                          </div>
                          <div role="button">
                            <div className="btn btn_small btncolorprim btncard move" onClick={() => { handleSaveButtonClick("pickup_penalty") }}>Save</div>
                          </div>
                        </div>
                      </div>
                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          Drop penalty :
                          {/* <span className="monthName">{workshopObj.name}</span> */}
                          <div style={{ marginLeft: "1%" }}>
                            <input
                              type="checkbox"
                              className="toggle-switch-checkbox"
                              name="toggleDropSwitch"
                              id="toggleDropSwitch"
                              onChange={(e) => handlePenalityRadioToggle(e, "drop_penalty")}
                              defaultChecked={false}
                              checked={workshopObj.ga_wallet && workshopObj.ga_wallet.drop_penalty_enabled ? workshopObj.ga_wallet.drop_penalty_enabled : false}
                            />
                            <label className="toggle-switch-label" htmlFor="toggleDropSwitch">
                              <span className="toggle-switch-inner" />
                              <span className="toggle-switch-switch" />
                            </label>
                          </div>
                          <div>
                            <input
                              type="text"
                              maxLength="30"
                              placeholder="Text"
                              defaultValue={workshopObj.ga_wallet && workshopObj.ga_wallet.drop_penalty_enabled ? workshopObj.ga_wallet && workshopObj.ga_wallet.drop_penalty_amount ? workshopObj.ga_wallet.drop_penalty_amount : "" : ""}
                              disabled={workshopObj.ga_wallet && workshopObj.ga_wallet.drop_penalty_enabled ? "" : "disabled"}
                              className={`dateInputBox field border-box small ${workshopObj.ga_wallet && workshopObj.ga_wallet.drop_penalty_enabled ? "" : "inputDisabled"}`}
                              onChange={(e) => {
                                handleText("drop_penalty_amount", e);
                              }}
                            />
                          </div>
                          <div role="button">
                            <div className="btn btn_small btncolorprim btncard move" onClick={() => { handleSaveButtonClick("drop_penalty") }}>Save</div>
                          </div>
                        </div>
                      </div>
                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          Awesome Rating :
                          {/* <span className="monthName">{workshopObj.name}</span> */}
                          <div style={{ marginLeft: "-2%" }}>
                            <input
                              type="checkbox"
                              className="toggle-switch-checkbox"
                              name="toggleRatingSwitch"
                              id="toggleRatingSwitch"
                              onChange={(e) => handlePenalityRadioToggle(e, "awesome_reward")}
                              // defaultChecked={false}
                              checked={workshopObj.ga_wallet && workshopObj.ga_wallet.awesome_reward_enabled ? workshopObj.ga_wallet.awesome_reward_enabled : false}
                            />
                            <label className="toggle-switch-label" htmlFor="toggleRatingSwitch">
                              <span className="toggle-switch-inner" />
                              <span className="toggle-switch-switch" />
                            </label>
                          </div>
                          <div>
                            <input
                              type="text"
                              maxLength="30"
                              placeholder="Text"
                              defaultValue={workshopObj.ga_wallet && workshopObj.ga_wallet.awesome_reward_enabled ? workshopObj.ga_wallet && workshopObj.ga_wallet.awesome_reward_amount ? workshopObj.ga_wallet.awesome_reward_amount : "" : ""}
                              disabled={workshopObj.ga_wallet && workshopObj.ga_wallet.awesome_reward_enabled ? "" : "disabled"}
                              className={`dateInputBox field border-box small ${workshopObj.ga_wallet && workshopObj.ga_wallet.awesome_reward_enabled ? "" : "inputDisabled"}`}
                              onChange={(e) => {
                                handleText("awesome_reward_amount", e);
                              }}
                            />
                          </div>
                          <div role="button">
                            <div className="btn btn_small btncolorprim btncard move" onClick={() => { handleSaveButtonClick("awesome_reward") }}>Save</div>
                          </div>
                        </div>
                      </div>
                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          Low Rating :
                          {/* <span className="monthName">{workshopObj.name}</span> */}
                          <div style={{ marginLeft: "3%" }}>
                            <input
                              type="checkbox"
                              className="toggle-switch-checkbox"
                              name="toggleLowRatingSwitch"
                              id="toggleLowRatingSwitch"
                              onChange={(e) => handlePenalityRadioToggle(e, "low_rating")}
                              // defaultChecked={false}
                              checked={workshopObj.ga_wallet && workshopObj.ga_wallet.low_rating_enabled ? workshopObj.ga_wallet.low_rating_enabled : false}
                            />
                            <label className="toggle-switch-label" htmlFor="toggleLowRatingSwitch">
                              <span className="toggle-switch-inner" />
                              <span className="toggle-switch-switch" />
                            </label>
                          </div>
                          <div>
                            <input
                              type="text"
                              maxLength="30"
                              placeholder="Text"
                              defaultValue={workshopObj.ga_wallet && workshopObj.ga_wallet.low_rating_enabled ? workshopObj.ga_wallet && workshopObj.ga_wallet.low_rating_amount ? workshopObj.ga_wallet.low_rating_amount : "" : ""}
                              disabled={workshopObj.ga_wallet && workshopObj.ga_wallet.low_rating_enabled ? "" : "disabled"}
                              className={`dateInputBox field border-box small ${workshopObj.ga_wallet && workshopObj.ga_wallet.low_rating_enabled ? "" : "inputDisabled"}`}
                              onChange={(e) => {
                                handleText("low_rating_amount", e);
                              }}
                            />
                          </div>
                          <div role="button">
                            <div className="btn btn_small btncolorprim btncard move" onClick={() => { handleSaveButtonClick("low_rating") }}>Save</div>
                          </div>
                        </div>
                      </div>
                      <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                        <div className="selectDate maskingDataContainer">
                          Tshirt penalty :
                          {/* <span className="monthName">{workshopObj.name}</span> */}
                          <div style={{ marginLeft: "3%" }}>
                            <input
                              type="checkbox"
                              className="toggle-switch-checkbox"
                              name="toggleLogoPenaltySwitch"
                              id="toggleLogoPenaltySwitch"
                              onChange={(e) => handlePenalityRadioToggle(e, "logo_penalty")}
                              // defaultChecked={false}
                              checked={workshopObj.ga_wallet && workshopObj.ga_wallet.logo_penalty_enabled ? workshopObj.ga_wallet.logo_penalty_enabled : false}
                            />
                            <label className="toggle-switch-label" htmlFor="toggleLogoPenaltySwitch">
                              <span className="toggle-switch-inner" />
                              <span className="toggle-switch-switch" />
                            </label>
                          </div>
                          <div>
                            <input
                              type="text"
                              maxLength="30"
                              placeholder="Text"
                              defaultValue={workshopObj.ga_wallet && workshopObj.ga_wallet.logo_penalty_enabled ? workshopObj.ga_wallet && workshopObj.ga_wallet.logo_penalty_amount ? workshopObj.ga_wallet.logo_penalty_amount : "" : ""}
                              disabled={workshopObj.ga_wallet && workshopObj.ga_wallet.logo_penalty_enabled ? "" : "disabled"}
                              className={`dateInputBox field border-box small ${workshopObj.ga_wallet && workshopObj.ga_wallet.logo_penalty_enabled ? "" : "inputDisabled"}`}
                              onChange={(e) => {
                                handleText("logo_penalty_amount", e);
                              }}
                            />
                          </div>
                          <div role="button">
                            <div className="btn btn_small btncolorprim btncard move" onClick={() => { handleSaveButtonClick("logo_penalty") }}>Save</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={retailer && retailer.isRetailer && retailer.isRetailer.id == '2' ? "innerBox" : "block"}>
                      <div>
                        <div className="selectDate" style={{ marginBottom: '20px' }}>{retailer && retailer.isRetailer && retailer.isRetailer.id == "2" ? "Retailer :" : "Workshop :"} <span className="monthName">{workshopObj.name}</span></div>
                        <div className="slotBookContainer">
                          <div className="selectDate maskingDataContainer">
                            <div>Stop Entry From Dashboard :</div>
                            {/* <span className="monthName">{workshopObj.name}</span> */}
                            <div>
                              <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                name="toggleLASwitch"
                                id="toggleLASwitch1"
                                onChange={(e) => UpdateSingaleValue("stop_entry_enabled", e.target.checked)}
                                defaultChecked={props && props.content && props.content.stop_entry_enabled === true ? true : false}
                              />
                              <label className="toggle-switch-label" htmlFor="toggleLASwitch1">
                                <span className="toggle-switch-inner" />
                                <span className="toggle-switch-switch" />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                          <div className="selectDate maskingDataContainer">
                            Item Add Rewards:
                            <div>
                              <input
                                type="text"
                                maxLength="30"
                                placeholder="Text"
                                defaultValue={workshopObj.ga_wallet && workshopObj.ga_wallet.inventory_reward_amount}
                                className={`dateInputBox field border-box small`}
                                onChange={(e) => {
                                  handleText("inventory_reward_amount", e);
                                }}
                              />
                            </div>
                            <div role="button">
                              <div className="btn btn_small btncolorprim btncard move" onClick={() => { handleSaveButtonClick("inventory_reward") }}>Save</div>
                            </div>
                          </div>
                        </div>
                        <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                          <div className="selectDate maskingDataContainer">
                            Order Creation Rewards:
                            <div>
                              <input
                                type="text"
                                maxLength="30"
                                placeholder="Text"
                                defaultValue={workshopObj.ga_wallet && workshopObj.ga_wallet.place_order_reward_amount}
                                className={`dateInputBox field border-box small`}
                                onChange={(e) => {
                                  handleText("place_order_reward_amount", e);
                                }}
                              />
                            </div>
                            <div role="button">
                              <div className="btn btn_small btncolorprim btncard move" onClick={() => { handleSaveButtonClick("place_order_reward") }}>Save</div>
                            </div>
                          </div>
                        </div>

                        <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                          <div className="selectDate maskingDataContainer">
                            <div>Status:</div>
                            <div>
                              <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                name="toggleActiveInactiveSwitch"
                                id="toggleActiveInactiveSwitch1"
                                onChange={(e) => UpdateSingaleValue("status", e.target.checked ? 1 : 0)}
                                defaultChecked={props && props.content && props.content.status === 1 ? true : false}
                              />
                              <label className="toggle-switch-label" htmlFor="toggleActiveInactiveSwitch1">
                                <span className="toggle-switch-inner active-inactive-text" />
                                <span className="toggle-switch-switch" />
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                          <div className="selectDate maskingDataContainer">
                            Take Rate:
                            <div>
                              <input
                                type="number"
                                step="0.01"
                                inputMode="decimal"
                                placeholder="Enter rate"
                                defaultValue={workshopObj.take_rate}
                                className={`dateInputBox field border-box small`}
                                onChange={(e) => {
                                  handleText("take_rate", e, "retailer_text");
                                }}
                              />
                            </div>
                            <div role="button">
                              <div className="btn btn_small btncolorprim btncard move" onClick={() => { handleSaveTakeRate("take_rate") }}>Save</div>
                            </div>
                          </div>
                        </div>

                        <div className="slotBookContainer" style={{ marginTop: '20px' }}>
                          <div className="selectDate maskingDataContainer">
                            Enable General Reward:
                            <div>
                              <input
                                type="number"
                                step="0.01"
                                maxLength="10"
                                placeholder="Enter Reward"
                                defaultValue={workshopObj && workshopObj.re_wallet_general_reward ? workshopObj.re_wallet_general_reward : ""}
                                className="dateInputBox field border-box small"
                                onChange={(e) => {
                                  handleText("re_wallet_general_reward", e, "retailer_reward_remarks");
                                }}
                                style={{width: "90%"}}
                              />
                            </div>
                            <div>
                              <input
                                type="text"
                                maxLength="30"
                                placeholder="Enter Remark"
                                defaultValue={workshopObj && workshopObj.re_wallet_general_reward_remark ? workshopObj.re_wallet_general_reward_remark : ""}
                                disabled={workshopObj && workshopObj.re_wallet_general_reward ? "" : "disabled"}
                                className={`dateInputBox field border-box small ${workshopObj && workshopObj.re_wallet_general_reward ? "" : "inputDisabled"}`}
                                onChange={(e) => {
                                  handleText("re_wallet_general_reward_remark", e, "retailer_reward_remarks");
                                }}
                              />
                            </div>
                            <div role="button" style={{marginLeft: "10px"}}>
                              <div className="btn btn_small btncolorprim btncard move" onClick={() => { handleSaveRewardButtonClick("general_reward") }}>Save</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </>
              )
            }
          </div>
          <div className={(props.filters && props.filters.cities && ((props.filters.retailers.length > 0) || (props.filters.workshops.length > 0)) || props.showAddPart) ? "block" : "emptyMiddleMain centeralign weight400 blackspan56"}>
            Please apply filters to view records
          </div>
          </>
        }
      </>
    </>
  );
}
