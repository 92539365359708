import React from 'react';
import "./base/sitebase1.css"
import Lottie from 'react-lottie'
import animation from './lottie.json'
// import "./dashboard/dashboard.css"


class Loader extends React.Component {
    constructor(props) {
        super(props);
    //    this.state = {};

        this.state = this.props;
        
        
    }

    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true, 
            animationData: animation,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid meet'
            }
          };
          if(this.props.fullHeight){
            return (
                <div id="dashboardchatmainright" className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box">
                    <table className="stdtable"><tr><td valign="center">
                            <Lottie options={defaultOptions}
                                height={300}
                                width={300}
                            />
                    </td></tr></table>
                </div>
              );
          }else{
            return (
                <div className="border-box bgwhite blackspan56 weight500" 
                style={{
                    // width: "100px" ,
                    // position: "absolute",
                    top:"0",
                    bottom: "0",
                    left: "0",
                    right: "0",
                    margin: "auto"}}>
                    <table className="stdtable">
                        <tbody>
                        <tr>
                            <td valign="center">
                            <Lottie options={defaultOptions}
                                height={300}
                                width={300}
                            />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
              );
          }
      
    }
  }


export default Loader;