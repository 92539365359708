import React from 'react';
// import "../dashboard/dashboard.css"
import ChatUtils from './chat_utils';
import Constants from "../constants";
import Loader from "../loader";

const tabList = [{tag:"support_chat" , title : "Support Chat"}, {tag:"orders" , title : "Orders"}, {tag:"fleets" , title : "Fleets"}, {tag:"users" , title : "Users"}, {tag:"services" , title : "Services"}, {tag:"packages" , title : "Packages"}, {tag:"deals" , title : "Deals"}, {tag:"cars" , title : "Cars"}, {tag:"invoices" , title : "Invoices"}, {tag:"garages" , title : "Garages"}, {tag:"crs" , title : "CRs"}, {tag:"bulkorders" , title : "Bulk Orders"}, {tag:"addresses" , title : "Addresses"}];


class PanelLeft extends React.Component {
    constructor(props) {
      super(props);
     this.handleSwitchClick = this.handleSwitchClick.bind(this);
  //    this.state = {showContent: false};
      this.state = {text : "", mine : true};
    }
  
    handleScroll = (e) => {
      //console.log("scrolling333");
      const bottom = Math.floor(e.target.scrollHeight - e.target.scrollTop) === parseInt(e.target.clientHeight) || Math.ceil(e.target.scrollHeight - e.target.scrollTop) === parseInt(e.target.clientHeight);
      //console.log(e.target.scrollHeight - e.target.scrollTop, e.target.clientHeight)
      if(!this.props.live){
        if (bottom && !this.props.showLoader) { this.props.onLeftScroll(this.props.live, this.state.mine);}
      }
    }

    handleTextChange(e){
      // console.log(e.target.value);
      this.setState({text : e.target.value}, () => this.threadSearch());
      // this.props.onThreadSearch(this.state.text);
      // this.props.onThreadSearch(e.target.value);
      // this.threadSearch();
    }

    handleSwitchClick(type, mSwitch){
      //console.log("hahahahahaha");
      //console.log(this.props.live);
      //console.log(this.state);
      //console.log(type, mSwitch);
      if(type == "threads"){
        if(mSwitch == "mine"){
          if(!this.state.mine){
            this.setState({mine : true});
          }
        }else{
          if(this.state.mine){
            this.setState({mine : false});
          }
        }
      }else{
        if(mSwitch == "live"){
          if(!this.props.live){
            this.props.onHistorySwitchClick("live");
          }
        }else{
          if(this.props.live){
            this.props.onHistorySwitchClick("archived");
          }
        }
      }
    }

    threadSearch(){
      this.props.onThreadSearch(this.state.text);
    }
  
    render() {
      var that = this;
      var tabs = [];

      //console.log("props", this.props);
      
      if(this.props.live){
        if(this.state.mine){
          tabs = this.props.threadsList.filter(o => o.assigned_executive == localStorage.getItem("user_id")).map((tab) =>
            <Tab tag={tab.id} name={tab.name} last_message = {tab.last_message ? (tab.last_message.length > 30 ? tab.last_message.substring(0,30) + " ...": tab.last_message) : ""} 
              last_messagetime = {tab.last_messagetime ? Constants.get_DateString1(tab.last_messagetime) : ""} last_time={tab.last_time} mobile={tab.mobile} unread={tab.unread} new={tab.new} 
              first_executive_message_time={tab.first_executive_message_time} first_customer_message_time={tab.first_customer_message_time}
              models={tab.id in this.props.allModels ? this.props.allModels[tab.id] : {}} onTabClick={this.props.onTabClick} selectedTab={this.props.selectedTab} is_typing_customer={tab.is_typing_customer}/>
          );
        }else{
          tabs = this.props.threadsList.map((tab) =>
            <Tab tag={tab.id} name={tab.name} last_message = {tab.last_message ? (tab.last_message.length > 30 ? tab.last_message.substring(0,30) + " ...": tab.last_message) : ""} 
              last_messagetime = {tab.last_messagetime ? Constants.get_DateString1(tab.last_messagetime) : ""} last_time={tab.last_time} mobile={tab.mobile} unread={tab.unread} new={tab.new}
              first_executive_message_time={tab.first_executive_message_time} first_customer_message_time={tab.first_customer_message_time}
              models={tab.id in this.props.allModels ? this.props.allModels[tab.id] : {}} onTabClick={this.props.onTabClick} selectedTab={this.props.selectedTab} is_typing_customer={tab.is_typing_customer}/>
          );
        }
      }else{
        //console.log("this.props.archivedThreadsList", this.props.archivedThreadsList, localStorage.getItem("user_id"));
        if(this.state.mine){
          tabs = this.props.archivedThreadsList.filter(o => o.assigned_executive == localStorage.getItem("user_id")).map((tab) =>
            <Tab tag={tab.id} name={tab.name} last_message = {tab.last_message ? (tab.last_message.length > 30 ? tab.last_message.substring(0,30) + " ...": tab.last_message) : ""} 
              last_messagetime = {tab.last_messagetime ? Constants.get_DateString1(tab.last_messagetime) : ""} last_time={tab.last_time} mobile={tab.mobile} unread={tab.unread} new={tab.new}
              // first_executive_message_support={tab.first_executive_message_support} first_customer_message_support={tab.first_customer_message_support}
              models={tab.id in this.props.archivedAllModels ? this.props.archivedAllModels[tab.id] : {}} onTabClick={this.props.onTabClick} selectedTab={this.props.selectedTab} is_typing_customer={tab.is_typing_customer}/>
          );
        }else{
          tabs = this.props.archivedThreadsList.map((tab) =>
            <Tab tag={tab.id} name={tab.name} last_message = {tab.last_message ? (tab.last_message.length > 30 ? tab.last_message.substring(0,30) + " ...": tab.last_message) : ""} 
              last_messagetime = {tab.last_messagetime ? Constants.get_DateString1(tab.last_messagetime) : ""} last_time={tab.last_time} mobile={tab.mobile} unread={tab.unread} new={tab.new}
              // first_executive_message_support={tab.first_executive_message_support} first_customer_message_support={tab.first_customer_message_support}
              models={tab.id in this.props.archivedAllModels ? this.props.archivedAllModels[tab.id] : {}} onTabClick={this.props.onTabClick} selectedTab={this.props.selectedTab} is_typing_customer={tab.is_typing_customer}/>
          );
        }
      }

      //console.log("tabsLenth", tabs);
  
      if(tabs.length == 0 && ((!this.props.allModels && this.props.live) || (!this.props.archivedAllModels && !this.props.live))){
        return(
          <div className="leftmain border-box leftalign relative shadow-box">
            
            <div className="third border-box leftalign blackspan7 weight400 absolute">
              <table className="stdtable"><tr><td valign="center"><Loader fullHeight={false} /></td></tr></table>
            </div>
          </div>
        );
      }else{
      return (
        <div className="leftmain border-box leftalign relative shadow-box">
          <div className="first border-box leftalign clearfix absolute">
              <input id="searchthreads" type="text" name="search" placeholder="Search anything (no, really)" className="field small border-box" value={this.state.text} onChange={(e) => this.handleTextChange(e)} />
            </div>
            <div className="second border-box leftalign clearfix absolute">
              <div className="choicethreads border-box centeralign floatleft uppercased weight700">
                <div className={this.state.mine ? "choicethreadsinner border-box centeralign floatleft selected" : "choicethreadsinner border-box centeralign floatleft"} 
                  onClick = {(e) => this.handleSwitchClick("threads", "mine")}>Mine</div>
                <div className={this.state.mine ? "choicethreadsinner border-box centeralign floatleft" : "choicethreadsinner border-box centeralign floatleft selected"} 
                  onClick = {(e) => this.handleSwitchClick("threads", "all")}>All</div>  
              </div>

              <div className="choicethreads border-box centeralign floatright uppercased weight700">
                <div className={this.props.live ? "choicethreadsinner border-box centeralign floatleft selected" : "choicethreadsinner border-box centeralign floatleft"} 
                  onClick = {(e) => this.handleSwitchClick("history", "live")}>Live</div>
                <div className={this.props.live ? "choicethreadsinner border-box centeralign floatleft" : "choicethreadsinner border-box centeralign floatleft selected"} 
                  onClick = {(e) => this.handleSwitchClick("history", "archived")}>Archived</div>  
              </div>
            </div>
          <div className="third border-box leftalign blackspan7 weight400 absolute" onScroll={this.handleScroll}>
            {tabs}
            {this.props.showLeftLoader ? (<Loader fullHeight={false}/>) : (<React.Fragment />)}
          </div>
        </div>
        );
      }
    }
  }


  class Tab extends React.Component {
    constructor(props) {
      super(props);
      this.state = {showContent: false};
  
      this.handleClick = this.handleClick.bind(this);
    }
  
      handleClick() {
          this.props.onTabClick(this.props.tag);
          /*this.setState(state => ({
            isToggleOn: !state.isToggleOn
          }));*/
      }
  
    render() {
      // console.log("props", this.props);
      //console.log("props1", this.props);
      var response_time = null;
      if(this.props.first_executive_message_time && this.props.first_customer_message_time)
        response_time = Constants.getTimeDifferenceFromMill(this.props.first_executive_message_time - this.props.first_customer_message_time);

      if (this.props.selectedTab == this.props.tag){
        return (
          // var innerHTML1 = '<div class="code">';
  //                 innerHTML1 += '<table class="stdtable"><tr><td valign="center" style="width:80%;">';
  //                 innerHTML1 += derbii_title;
  //                 innerHTML1 += '</td><td valign="center" style="width:20%;" class="rightalign"><span class="unread weight700 colorprim500span"></span></td></tr></table>';
  //                 innerHTML1 += '</div>';
  //                 innerHTML1 += '<div class="people">';
  //                 innerHTML1 += '<table class="stdtable"><tr><td valign="center">';
  //                 innerHTML1 += text;
  //                 innerHTML1 += '</td></tr></table>';
  //                 innerHTML1 += '</div>';
  
  //                 var new_d = document.createElement('div');
  
  
          <div id={this.props.id} className={this.props.unread != 0 ? "thread selected border-box clearfix colorprim500span" : "thread selected border-box clearfix"} 
            data-id={this.props.id} onClick={this.handleClick}>
            <div className="code">
              <table className="stdtable"><tr><td valign="center" style={{width:'60%'}}>
                {this.props.name}
              </td>
              <td valign="center" className="rightalign" style={{width:'30%'}}>{this.props.new ? (<span className="new weight400 whitespan bgcolorprim500 card">NEW</span>) : ""}</td>
              <td valign="center" className="rightalign" style={{width:'10%'}}><span className="unread weight700 colorprim500span">{this.props.unread != 0 ? this.props.unread : ""}</span></td></tr></table>
              </div>
              <div className="people">
              <table className="stdtable"><tr><td valign="center">
                  {this.props.mobile}
                </td>
                <td className="rightalign blackspan7" valign="center" style={{width:'auto'}}>
                  {response_time ? "R.T. : "+response_time : ""}
                </td>
                </tr></table>
              </div>
              <div className="msg">
              <table className="stdtable"><tr><td valign="center">
              <span className="msg weight300i">{this.props.last_message}</span>
                </td>
                <td valign="center" className="rightalign" style={{width:'40%'}}>
                  <span className="msg weight300i">{this.props.last_messagetime}</span>
                </td>
                </tr></table>
              </div>
          </div>
  
            // <div className={this.props.selectedTab===this.props.tag ? "tabs bgwhite border-box shadow-text selected" : "tabs bgwhite border-box shadow-text"} 
            //     data-type={this.props.tag} onClick={this.handleClick}>
            //     <table className="stdtable"><tr><td valign="center">
            //         {this.props.title}
            //     </td></tr></table>
            // </div>
        );  
      }else{
        //console.log(this.props, "hello");
        return (
          // var innerHTML1 = '<div class="code">';
  //                 innerHTML1 += '<table class="stdtable"><tr><td valign="center" style="width:80%;">';
  //                 innerHTML1 += derbii_title;
  //                 innerHTML1 += '</td><td valign="center" style="width:20%;" class="rightalign"><span class="unread weight700 colorprim500span"></span></td></tr></table>';
  //                 innerHTML1 += '</div>';
  //                 innerHTML1 += '<div class="people">';
  //                 innerHTML1 += '<table class="stdtable"><tr><td valign="center">';
  //                 innerHTML1 += text;
  //                 innerHTML1 += '</td></tr></table>';
  //                 innerHTML1 += '</div>';
  
  //                 var new_d = document.createElement('div');
  
  
          <div id={this.props.id} className={this.props.unread != 0 ? "thread border-box clearfix colorprim500span" : "thread border-box clearfix"} 
            data-id={this.props.id} onClick={this.handleClick}>
            <div className="code">
              <table className="stdtable"><tr><td valign="center" style={{width:'60%'}}>
                {this.props.name}
                 {/* - {this.props.tag} */}
              </td>
              <td valign="center" className="rightalign" style={{width:'30%'}}>{this.props.new ? (<span className="new weight400 whitespan bgcolorprim500 card">NEW</span>) : ""}</td>
              <td valign="center" className="rightalign" style={{width:'10%'}}><span className="unread weight700 colorprim500span">{this.props.unread != 0 ? this.props.unread : ""}</span></td></tr></table>
              </div>
              <div className="people">
              <table className="stdtable"><tr><td valign="center">
                  {this.props.mobile}
                </td>
                <td className="rightalign blackspan7" valign="center" style={{width:'auto'}}>
                  {response_time ? "R.T. : "+response_time : ""}
                </td>
                </tr></table>
              </div>
              <div className="msg">
              
                {this.props.is_typing_customer ? (<table className="stdtable"><tr><td valign="center"><span className="msg weight700i colorprim500span">Typing ...</span></td></tr></table>) : 
                  (<table className="stdtable"><tr><td valign="center"><span className="msg weight300i">{this.props.last_message}</span></td><td valign="center" className="rightalign" style={{width:'40%'}}><span className="msg weight300i">{this.props.last_messagetime}</span></td></tr></table>)}
              
              </div>
          </div>
  
            // <div className={this.props.selectedTab===this.props.tag ? "tabs bgwhite border-box shadow-text selected" : "tabs bgwhite border-box shadow-text"} 
            //     data-type={this.props.tag} onClick={this.handleClick}>
            //     <table className="stdtable"><tr><td valign="center">
            //         {this.props.title}
            //     </td></tr></table>
            // </div>
        );
      }
      
    }
  }

  export default PanelLeft;