import React from 'react';
import firebase from '../firebase';
import Constants from '../constants';

var divM= {};
var divH= {};
var divK= {};
var div_lastkeys= {};
var div_lasttimes= {};
var div_lastmessages= {};
var div_lastmessagetimes = {};

var thread_codes = [];

var threads_list = [];

var all_models = {};

var fbListeners1 = [];
var fbListeners2 = [];
var fbListeners3 = [];
var fbListeners4 = [];
// var childAddedListeners = [];

var database = firebase.database();

var aiRef = firebase.database().ref('support/docs/supportchat/quick_messages_internal');
var seRef = firebase.database().ref('support/executives');
var chatRef = firebase.database().ref('support/supportchat');
var queueRef = firebase.database().ref('queue/tasks');

class ChatUtils{
    clearListeners(){
        
        // aiRef.off();
        // seRef.off();
        // chatRef.off();
        // for(var code2 in thread_codes){
        //     firebase.database().ref('support/supportchat_admin/'+code2+'/last_read/'+localStorage.getItem("user_id")).off();
        //     chatRef.child(code2).child("chat").off();
        // }

        for(var l1 in fbListeners1){
            if(l1)
                l1['ref'].off(l1['type'], l1['listener']);
        }
        for(var l2 in fbListeners4){
            if(l2)
                l2['ref'].off(l2['type'], l2['listener']);
        }
        for(var l3 in fbListeners2){
            if(l3)
                firebase.database().ref(l3['path']).off(l3['type'], l3['listener']);
        }
        for(var l4 in fbListeners3){
            if(l4)
                l4['ref'].child(l4['path']).off(l4['type'], l4['listener']);
        }
        
    }

    loadInitial(supportchat, returnNow){
        //console.log("loadingInitial");

        divM= {};
        divH= {};
        divK= {};
        div_lastkeys= {};
        div_lasttimes= {};
        div_lastmessages= {};
        div_lastmessagetimes = {};
        thread_codes = [];
        threads_list = [];
        all_models = {};

        let listener1 = seRef.once('value').then(function(data) {
            // console.log("quick");
            sessionStorage.setItem("se_users", JSON.stringify(data.val()));
            // console.log(JSON.parse(sessionStorage.getItem("se_users")));
        });
        fbListeners1.push({'path' : null, 'ref' : seRef, 'type' : 'value', 'listener' : listener1});

        let listener2 = aiRef.once('value').then(function(data) {
            // console.log("quick");
            sessionStorage.setItem("quick_messages_internal", JSON.stringify(data.val()));
            // console.log(JSON.parse(sessionStorage.getItem("quick_messages_internal")));
        });
        fbListeners1.push({'path' : null, 'ref' : aiRef, 'type' : 'value', 'listener' : listener2});


        // chatRef.orderByChild('last_updated').limitToLast(50).on('child_added', function(data) {
        
        let listener7 = chatRef.on('child_removed', function(data) {
            //console.log("data_removed", data.key);
            // console.log(data.key in all_models);
            if(data.key in all_models){
                //console.log(typeof(data.key))
                for(var l in fbListeners3){
                    if (l['ref'] == chatRef.child(data.key)){
                        l['ref'].child(l['path']).off(l['type'], l['listener']);
                    }
                }
                delete divM[data.key];
                delete divH[data.key];
                delete divK[data.key];
                delete div_lastkeys[data.key];
                delete div_lasttimes[data.key];
                delete div_lastmessages[data.key];
                delete div_lastmessagetimes[data.key];
                delete all_models[data.key];
                
                let objTL = threads_list.find(o => o.id === data.key);
                var indexTL = threads_list.indexOf(objTL);
                threads_list.splice(indexTL, 1);
                
                let indexTC = thread_codes.indexOf(data.key);
                thread_codes.splice(indexTC, 1);
                
                supportchat.setState({threads_list : threads_list, all_models : all_models, divK : divK, divM : divM, divH : divH});
                
            }
        });

        fbListeners4.push({'path' : null, 'ref' : chatRef, 'type' : 'child_removed', 'listener' : listener7});

        let listener3 = chatRef.orderByChild('last_updated').on('child_added', function(data) {
            //console.log("consoling", data.val().name, data.val().mobile);
            console.log(data.val());
            if (thread_codes.indexOf(data.key) == -1){
                var childref= chatRef.child(data.key);

            // //console.log(data.child("details").val());

            var models= [];
            var texts = [];
            var textHs = [];
            var mKeys = [];


            var textH = '';
            var textM = "Hey there! Welcome to GoMechanic. It's great to have you on board. You can chat with our support team here. Click on the + (plus) button in the chat-box to send a quick message. We hope you enjoy your time Cheers!";
                
            
            var message= {
                        senderName:"bot",
                        senderUserID:"bot",
                        time:(new Date()).getTime(),
                        notification:false,
                        textH : textH,
                        textM : textM
                        }
                models.push(message);

            


            textHs.push(textH);
            texts.push(textM);
            mKeys.push(null);
            div_lastkeys[data.key] = null;
            div_lasttimes[data.key] = message['time'];
            div_lastmessages[data.key] = textM;
            div_lastmessagetimes[data.key] = message['time'];

            
            var currentModel = null;

            var messageCount = 0;

            var first_customer_message_time = null;
            var first_executive_message_time = null;

            data.child("chat").forEach(function(child){
                //console.log("consoling1", data.val().name, data.val().mobile);

                if(!currentModel || (currentModel && !Constants.get_TimeComparison(currentModel['time'], child.val().time))){
                    // if(!Constants.get_TimeComparison(currentModel['time'], child.val().time)){
                        var textH = "";
                        var textM = "";
                            
                        
                        var message= {
                                    senderName:null,
                                    senderUserID:null,
                                    time:child.val().time,
                                    notification:false,
                                    textH : textH,
                                    textM : textM
                                    };

                            models.push(message);

                        


                        textHs.push(textH);
                        texts.push(textM);
                        mKeys.push(null);
                        div_lastkeys[data.key] = null;
                        div_lasttimes[data.key] = message['time'];
                        div_lastmessages[data.key] = textM;
                        div_lastmessagetimes[data.key] = message['time'];
                    // }
                }

                //console.log(child);
                var textH = '';
                var textM = '';
                  
                if (child.val().notification){
                    textH = child.val().type.toUpperCase();
                    textM = child.val().text.toUpperCase();
                    
                } else{
                    textH = '';
                    textM = child.val().text;
                }

                var message= {orderID:child.val().orderID,
                            senderName:child.val().senderName,
                            senderUserID:child.val().senderUserID,
                            time:child.val().time,
                            notification:child.val().notification,
                            type:child.val().type,
                            senderInternalID : child.val().senderInternalID,
                            textH : textH,
                            textM : textM
                            }
                  models.push(message);

                currentModel = message;


                textHs.push(textH);
                texts.push(textM);
                mKeys.push(child.key);
                div_lastkeys[data.key] = child.key;
                div_lasttimes[data.key] = child.val().time;
                div_lastmessages[data.key] = textM;
                div_lastmessagetimes[data.key] = child.val().time;
                

                messageCount += 1;

                if (child.val().senderName){
                    if (!first_customer_message_time && child.val().senderUserID != "bot"){
                        first_customer_message_time = child.val().time;
                    }else if (!first_executive_message_time && first_customer_message_time && child.val().senderUserID == "bot"){
                        first_executive_message_time = child.val().time;
                    }
                }
            });
            divM[data.key] = texts;
            divH[data.key] = textHs;
            divK[data.key] = mKeys;

            var code1 = data.key;

            //console.log("consoling2", data.val().name, data.val().mobile);
            thread_codes.push(code1);

            // let obj = derbiis_list.find(o => o.code === code1);


            let obj = {'name' : data.child("name").val(), 'mobile' : data.child("mobile").val(), 'assigned_executive' : data.child('assigned_executive').val(), 'new' : (messageCount == 1) ? true : false, 'first_customer_message_time' : first_customer_message_time, 'first_executive_message_time' : first_executive_message_time};
            //console.log("consoling3", data.val().name, data.val().mobile);
            threads_list.push(obj);
            //console.log(threads_list.length, "length");

            var thread_title = data.key;

            if(obj != null && obj.name != null){
                thread_title = obj.name;
            }

            if(obj != null){
                var indexObj = threads_list.indexOf(obj);
                obj['last_time'] = div_lasttimes[code1];
                obj['last_message'] = div_lastmessages[code1];
                obj['last_messagetime'] = div_lastmessagetimes[code1];
                obj['id'] = data.key;
                obj['unread'] = 0;
                threads_list[indexObj] = obj;
                
            }


            threads_list.sort(function(a, b) {
                    return b.last_time - a.last_time;
            });
            threads_list.sort(function(a, b) {
                    return b.last_time - a.last_time;
            });
            // if($("#searchthreads").val().trim() == '' || $("#searchthreads").val() == null){
            //     $(".leftmain > .second").html('');
            //     derbiis_list.forEach(function(derbii){
            //         if (derbii.code in thread_boxes){
            //             $(".leftmain > .second").append(thread_boxes[derbii.code]);
            //         }
            //     });
            // }

//					$(".thread#"+code1).html(innerHTML1);

            
            let listener4 = firebase.database().ref('support/supportchat_admin/'+code1+'/last_read/'+localStorage.getItem("user_id")).once('value').then(function(data){
                var unread = 0;
                if (data.val() != null){
                    var time = data.val().time;
                    for (var i = models.length-1; i >= 0; i--){
                        var message = models[i];
                        if (message.time >= time && message.senderName != 'bot'){
                            unread += 1;
                        }else{
                            break;
                        }
                    }



                    if (unread > 0){
                        //console.log(unread);//console.log(code1);
                        // $(".thread#"+code1).find('span.unread').text(unread);
                        // if(!$(".thread#"+code1).hasClass('colorprim500span')){
                        //     $(".thread#"+code1).addClass('colorprim500span');
                        // }
                    }
                }else{
                    var new_date = new Date();
					var new_time = new_date.getTime();
                    firebase.database().ref('support/supportchat_admin/'+code1+'/last_read/'+localStorage.getItem("user_id")).set({time:new_time});
                    unread = 0;
                }

                if(obj != null){
                    var indexObj = threads_list.indexOf(obj);
                    obj['unread'] = unread;
                    threads_list[indexObj] = obj;
                }
                supportchat.setState({threads_list : threads_list});
//					$(".thread#"+code1).html(innerHTML1);
            });
            fbListeners2.push({'path' : 'support/supportchat_admin/'+code1+'/last_read/'+localStorage.getItem("user_id"), 'ref' : null, 'type' : 'value', 'listener4' : listener4});

            all_models[code1] = models;

            //console.log("consoling4", data.val().name, data.val().mobile);
            supportchat.setState({threads_list : threads_list, all_models : all_models, divK : divK, divM : divM, divH : divH});
            
//             var innerHTML = '<div id="'+data.key+'" class="thread border-box clearfix shadow-text" data-derbiicode="'+data.key+'" data-gh=\''+JSON.stringify(models)+'\'>';
//             innerHTML += '<table class="stdtable"><tr><td valign="center">';
//             innerHTML += derbii_title;
//             innerHTML += '</td></tr></table>';
//             innerHTML += '</div>';

//             thread_boxes_initial[code1] = innerHTML;
            
// //				$(".leftmain > .second").append(innerHTML);

            

//             firebase.database().ref('derbii_people/'+code1).once('value').then(function(data){
//                 var text = "";
//                 var people = [];
//                 data.forEach(function(child){
//                     people.push(child.val().name);
//                 });

//                 for (i = 0; i < people.length; i++) { 
//                     text += people[i];
//                     if(i != (people.length-1)){
//                         text += ", ";
//                     }
//                 }

//                 var innerHTML1 = '<div class="code">';
//                 innerHTML1 += '<table class="stdtable"><tr><td valign="center" style="width:80%;">';
//                 innerHTML1 += derbii_title;
//                 innerHTML1 += '</td><td valign="center" style="width:20%;" class="rightalign"><span class="unread weight700 colorprim500span"></span></td></tr></table>';
//                 innerHTML1 += '</div>';
//                 innerHTML1 += '<div class="people">';
//                 innerHTML1 += '<table class="stdtable"><tr><td valign="center">';
//                 innerHTML1 += text;
//                 innerHTML1 += '</td></tr></table>';
//                 innerHTML1 += '</div>';

//                 var new_d = document.createElement('div');

//                 $(new_d).attr('id', code1);
//                 $(new_d).addClass('thread').addClass('border-box').addClass('clearfix').addClass('shadow-text');
//                 $(new_d).attr('data-derbiicode', code1);
//                 $(new_d).attr('data-gh', JSON.stringify(models));
//                 $(new_d).html(innerHTML1);

//                 thread_boxes[code1] = $(new_d);
// //					thread_boxes[code1] = '<div id="'+code1+'" class="thread border-box clearfix shadow-text" data-derbiicode="'+code1+'" data-gh=\''+JSON.stringify(models)+'\'>' + innerHTML1 + '</div>';
//                 thread_people.push({'code': code1, 'people': text});

                
//                 derbiis_list.sort(function(a, b) {
//                       return b.last_time - a.last_time;
//                 });
//                 derbiis_list.sort(function(a, b) {
//                       return b.last_time - a.last_time;
//                 });
//                 if($("#searchthreads").val().trim() == '' || $("#searchthreads").val() == null){
//                     $(".leftmain > .second").html('');
//                     derbiis_list.forEach(function(derbii){
//                         if (derbii.code in thread_boxes){
//                             $(".leftmain > .second").append(thread_boxes[derbii.code]);
//                         }
//                     });
//                 }

// //					$(".thread#"+code1).html(innerHTML1);

//                 firebase.database().ref('derbiichat_admin/'+code1+'/last_read/'+$("div.leftmain").attr('data-user-id')).once('value').then(function(data){
//                     if (data.val() != null){
//                         var time = data.val().time;
//                         var unread = 0;
//                         for (var i = models.length-1; i >= 0; i--){
//                             var message = models[i];
//                             if (message.time >= time && message.senderName != 'bot'){
//                                 unread += 1;
//                             }else{
//                                 break;
//                             }
//                         }

//                         if (unread > 0){
//                             //console.log(unread);//console.log(code1);
//                             $(".thread#"+code1).find('span.unread').text(unread);
//                             if(!$(".thread#"+code1).hasClass('colorprim500span')){
//                                 $(".thread#"+code1).addClass('colorprim500span');
//                             }
//                         }
//                     }

// //					$(".thread#"+code1).html(innerHTML1);
//                 });
//             });



            

            

            
            
                var chat_start_key = "";
                if(data.key in div_lastkeys && div_lastkeys[data.key] != null){
                    chat_start_key = div_lastkeys[data.key];
                }
                
                childref.child("is_customer_typing").on('value', function(snapshot3) {
                    let obj1 = threads_list.find(o => o.id === code1);
                    var indexObj1 = threads_list.indexOf(obj1);
                    //console.log(snapshot3.val())
                    if(obj1 != null){
                        if(snapshot3.val()){
                            //console.log(snapshot3.val(), 1)
                            
                            obj1['is_typing_customer'] = true;
                        }else{
                            //console.log(snapshot3.val(), 2)
                            if('is_typing_customer' in obj1){
                                delete obj1['is_typing_customer'];
                            }
                        }
                        
                        threads_list[indexObj1] = obj1;
                        //console.log("obj1", obj1);
                        //console.log("threads1", threads_list);
                        supportchat.setState({threads_list : threads_list});
                    }
                    
                });
                  

                let listener5 = childref.child("chat").orderByKey().startAt(chat_start_key).on('child_added', function(child){
                    //					if(child.key != div_lastkeys[data.key] && child.val().senderName != 'bot'){
                                    //console.log(divK[data.key]);
                                    //console.log(child.key);
                                if (data.key in divK){
                                    var exists = false;
                    
                                    for (var i = 0; i < divK[data.key].length; i++){
                                        //console.log("existing");//console.log(child.key);//console.log(divK[data.key][i]);
                                        if (child.key == divK[data.key][i]){
                                            exists = true;
                                            break;
                                        }
                                    }
                    
                                    //console.log("exists");
                                    //console.log(exists);
                    
                                    if (!exists){
                    
                    //					if(child.key != div_lastkeys[data.key] && divK[data.key].indexOf(child.key) != -1){
                    
                                        var texts = divM[data.key];
                                        var textHs = divH[data.key];
                                        var mKeys = divK[data.key];
                                        
                                        var textH = '';
                                        var textM = '';
                                          
                                        if (child.val().notification){
                                            textH = child.val().type.toUpperCase();
                                            textM = child.val().text.toUpperCase();
                                        } else{
                                            textH = '';
                                            textM = child.val().text;
                                        }
                                        
                    
                                        var datanew= all_models[data.key];
                                        var newobject= {
                                            senderName : child.val().senderName,
                                            senderUserID : child.val().senderUserID,
                                            orderID : child.val().orderID,
                                            time : child.val().time,
                                            notification:child.val().notification,
                                            type:child.val().type,
                                            senderInternalID : child.val().senderInternalID,
                                            textH : textH,
                                            textM : textM
                                        };

                                        if(datanew.length == 0 || (datanew.length > 0 && !Constants.get_TimeComparison(datanew[datanew.length-1]['time'], child.val().time))){
                                            // if(!Constants.get_TimeComparison(datanew[datanew.length-1]['time'], child.val().time)){
                                                var textH = "";
                                                var textM = "";
                                                    
                                                
                                                var message= {
                                                            senderName:null,
                                                            senderUserID:null,
                                                            time:child.val().time,
                                                            notification:false,
                                                            textH : textH,
                                                            textM : textM
                                                            };
                        
                                                    datanew.push(message);
                        
                                                
                        
                        
                                                textHs.push(textH);
                                                texts.push(textM);
                                                mKeys.push(null);
                                                divM[data.key] = texts;
                                                divH[data.key] = textHs;
                                                divK[data.key] = mKeys;
                                                div_lastkeys[data.key] = null;
                                                div_lasttimes[data.key] = message['time'];
                                                div_lastmessages[data.key] = textM;
                                                div_lastmessagetimes[data.key] = message['time'];
                                            // }
                                        }

                                        datanew.push(newobject);
                    
                    
                                        
                    
                    
                                        textHs.push(textH);
                                        texts.push(textM);
                                        mKeys.push(child.key);
                                        divM[data.key] = texts;
                                        divH[data.key] = textHs;
                                        divK[data.key] = mKeys;
                    
                    //						//console.log(datanew);
                    
                                        if (child.val().senderName){
                                            if (!first_customer_message_time && child.val().senderUserID != "bot"){
                                                first_customer_message_time = child.val().time;
                                            }else if (!first_executive_message_time && first_customer_message_time && child.val().senderUserID == "bot"){
                                                first_executive_message_time = child.val().time;
                                            }
                                        }

                                        

                                        div_lasttimes[data.key] = child.val().time;
                                        div_lastmessages[data.key] = textM;
                                        div_lastmessagetimes[data.key] = child.val().time;
                    
                                        let obj = threads_list.find(o => o.id === data.key);
                                        if(obj != null){
                                            var indexObj = threads_list.indexOf(obj);
                                            obj['last_time'] = div_lasttimes[data.key];
                                            obj['last_message'] = div_lastmessages[data.key];
                                            obj['last_messagetime'] = div_lastmessagetimes[data.key];
                                            if(!obj['first_exective_message_time'])
                                                obj['first_executive_message_time'] = first_executive_message_time;
                                            if(!obj['first_customer_message_time'])
                                                obj['first_customer_message_time'] = first_customer_message_time;
                                            threads_list[indexObj] = obj;
                                        }
                                        
                                        // $("#"+data.key).attr('data-gh', JSON.stringify(datanew));
                                        
                                        all_models[data.key] = datanew
                                        
                    
                                        if (supportchat.state.selectedTab == data.key){
                                            // var date = new Date(child.val().time);
                            
                                            // var dateString = get_DateString(date);
                                            
                                            // if(child.val().senderName != 'bot'){
                                            //     var innerHTML = '<div class="message clearfix border-box">'
                                            //     innerHTML += '<div class="others leftalign border-box">';
                                            //     innerHTML += '<div class="sender border-box clearfix">';
                                            //     innerHTML += '<div class="senderimage border-box">';
                                            //     if (child.val().senderGoogleID != null && child.val().senderGoogleID != ""){
                                            //         innerHTML += '<img height="30px" width="30px" src="';
                                            //         innerHTML += child.val().senderGoogleID;
                                            //         innerHTML += '" />';	
                                            //     }else{
                                            //         innerHTML += '<img height="30px" width="30px" src="https://graph.facebook.com/';
                                            //         innerHTML += child.val().senderFacebookID
                                            //         innerHTML += '/picture?width=9999" />';	
                                            //     }
                                            //     innerHTML += '</div>';
                                            //     innerHTML += '<div class="sendertext border-box">';
                                            //     innerHTML += '<div class="sendername border-box blackspan leftalign">';
                                            //     innerHTML += child.val().senderName
                                            //     innerHTML += '</div>';
                                            //     innerHTML += '<div class="sendertime border-box ccspan leftalign">';
                                            //     innerHTML += dateString
                                            //     innerHTML += '</div>';
                                            //     innerHTML += '</div>';
                                            //     innerHTML += '</div>';
                                            //     innerHTML += '<div class="text border-box clearfix leftalign"><span>';
                                            //     innerHTML += child.val().text;
                                            //     innerHTML += '</span></div>';
                                            //     innerHTML += '</div></div>';	
                                            // }else{
                                            //     var innerHTML = '<div class="message clearfix border-box">'
                                            //     innerHTML += '<div class="self rightalign border-box">';
                                            //     innerHTML += '<div class="sender border-box clearfix">';
                                            //     innerHTML += '<div class="sendertext border-box clearfix">';
                                            //     innerHTML += '<div class="sendername border-box blackspan rightalign">';
                                            //     if (child.val().senderInternalID != null){
                                            //         innerHTML += "Derbii Team (" + internalIDs[child.val().senderInternalID] + ")";
                                            //     }else{
                                            //         innerHTML += "Derbii Team";
                                            //     }
                                            //     innerHTML += '</div>';
                                            //     innerHTML += '<div class="sendertime border-box ccspan rightalign">';
                                            //     innerHTML += dateString
                                            //     innerHTML += '</div>';
                                            //     innerHTML += '</div>';
                                            //     innerHTML += '<div class="senderimage border-box">';
                                            //     innerHTML += '<img height="30px" width="30px" src="https://derbiicdn.storage.googleapis.com/images/derbii_logo_color_new2_100.png" />';
                                            //     innerHTML += '</div>';
                                            //     innerHTML += '</div>';
                                            //     if(child.val().notification){
                    
                                            //         innerHTML += '<div class="textNotif border-box clearfix rightalign">';
                                            //         innerHTML += '<div class="textNotifHeader border-box clearfix">';
                                            //         innerHTML += textH;
                                            //         innerHTML += '</div>';
                                            //         innerHTML += '<div class="textNotifMessage border-box clearfix">';
                                            //         innerHTML += textM;
                                            //         innerHTML += '</div>';
                                            //         innerHTML += '</div>';
                                            //     }else{
                                            //         innerHTML += '<div class="text border-box clearfix rightalign">';
                                            //         innerHTML += child.val().text;
                                            //         innerHTML += '</div>';
                                            //     }
                                            //     innerHTML += '</div></div>';
                                            // }
                                            
                                            // $(".chat").append(innerHTML);
                    
                                            // $('.chat').scrollTop($('.chat').prop("scrollHeight"));
                    
                    
                                            if(child.val().senderName != 'bot'){
                                                var new_date = new Date();
                                                var new_time = new_date.getTime();
                                                firebase.database().ref('support/supportchat_admin/'+code1+'/last_read/'+localStorage.getItem("user_id")).set({time:new_time});
                                            }
                                        }else{
                                            if(child.val().senderName != 'bot'){
                                                var unread = 0;
                                                if(obj != null && 'unread' in obj && obj['unread'] != null){
                                                    unread = obj['unread'];
                                                }
                                                // supportchat.setState({'threads_list' : threads_list});
                                                
                                                unread += 1;

                                                //console.log("unread", unread);
                    
                                                if (unread > 0){
                                                    if(obj != null){
                                                        var indexObj1 = threads_list.indexOf(obj);
                                                        obj['unread'] = unread;
                                                        threads_list[indexObj1] = obj;
                                                        //console.log("unread1", unread);
                                                        //console.log("unread2", obj['unread']);
                                                    }
                                                    // supportchat.setState({threads_list : threads_list}); // Was uncommented earlier
                                                }
                                            }
                                        }
                    
                                        threads_list.sort(function(a, b) {
                                              return b.last_time - a.last_time;
                                        });
                                        threads_list.sort(function(a, b) {
                                              return b.last_time - a.last_time;
                                        });
                                        // if($("#searchthreads").val().trim() == '' || $("#searchthreads").val() == null){
                                        // $(".leftmain > .second").html('');
                                        //     derbiis_list.forEach(function(derbii){
                                        //         if (derbii.code in thread_boxes){
                                        //             $(".leftmain > .second").append(thread_boxes[derbii.code]);
                                        //         }
                                        //     });
                                        // }
                                        supportchat.setState({threads_list : threads_list, all_models : all_models, divK : divK, divM : divM, divH : divH});
                                    }
                                    
                                }
                                });
                                fbListeners3.push({'path' : 'chat', 'ref' : childref, 'type' : 'child_added', 'listener' : listener5});
            }
        });
        fbListeners1.push({'path' : null, 'ref' : chatRef, 'type' : 'child_added', 'listener' : listener3});
    }


    clickTab(tag, supportchat, threads_array){
        let obj = threads_array.find(o => o.id === tag);
        var date = new Date();
        var time = date.getTime();

        div_lasttimes[tag] = time;
		if(obj != null){
			var indexObj = threads_list.indexOf(obj);
            obj['unread'] = 0;
            obj['last_time'] = div_lasttimes[tag];
			threads_list[indexObj] = obj;
        }
        if(obj != null){
			var indexObj = threads_array.indexOf(obj);
            obj['unread'] = 0;
            obj['last_time'] = div_lasttimes[tag];
			threads_array[indexObj] = obj;
        }

        firebase.database().ref('support/supportchat_admin/'+tag+'/last_read/'+localStorage.getItem("user_id")).set({time:time});
        
        supportchat.setState({threads_list : threads_array, selectedTab : tag, rightOrder : NaN});
    }


    sendAIMessage(mText, mType, selectedTab, supportchat){
        //console.log(mText, mType);
        if(mType === "notification") {
            var textH = mText.notif_type.toUpperCase();
            var textM = mText.message;
            this.sendAIMessageMain(true, textM, textH, selectedTab, supportchat);
        }else{
            var textH = '';
            var textM = mText;
            this.sendAIMessageMain(false, textM, textH, selectedTab, supportchat);
        }
    }


    sendAIMessageMain(notification, mText, hText, selectedTab, supportchat){
        var datanew= all_models[selectedTab];

            var code = selectedTab;

            var date = new Date();
            
            var dateString = Constants.get_DateString(date);

            var time = date.getTime();

            var texts = divM[selectedTab];
            var textHs = divH[selectedTab];
            var mKeys = divK[selectedTab];

            if(datanew.length == 0 || (datanew.length > 0 && !Constants.get_TimeComparison(datanew[datanew.length-1]['time'], time))){
                
                    var textH = "";
                    var textM = "";
                        
                    
                    var message= {
                                senderName:null,
                                senderUserID:null,
                                time:time,
                                notification:false,
                                textH : textH,
                                textM : textM
                                };

                        datanew.push(message);

                    


                    textHs.push(textH);
                    texts.push(textM);
                    mKeys.push(null);
                    divM[code] = texts;
                    divH[code] = textHs;
                    divK[code] = mKeys;
                    div_lastkeys[code] = null;
                    div_lasttimes[code] = message['time'];
                    div_lastmessages[code] = textM;
                    div_lastmessagetimes[code] = message['time'];
                
            }

            
            var textH = hText;
            var textM = mText;


            div_lasttimes[code] = time;
            div_lastmessages[code] = textM;
            div_lastmessagetimes[code] = time;

            let obj = threads_list.find(o => o.id === code);
            if(obj != null){
                var indexObj = threads_list.indexOf(obj);
                obj['last_time'] = div_lasttimes[code];
                obj['last_message'] = div_lastmessages[code];
                obj['last_messagetime'] = div_lastmessagetimes[code];
                if(!obj['first_exective_message_time'] && obj['first_customer_message_time'])
                    obj['first_executive_message_time'] = time;
                
                threads_list[indexObj] = obj;
            }

            var chatRefPush = chatRef.child(code).child("chat").push();



            

            texts.push(mText);
            textHs.push(hText);
            mKeys.push(chatRefPush.key);
            divM[selectedTab] = texts;
            divH[selectedTab] = textHs;
            divK[selectedTab] = mKeys;


            
            var newobject= {
                senderName : 'bot',
                senderUserID : 'bot',
                orderID : null,
                time : time,
                senderInternalID : localStorage.getItem("user_id"),
                textM : textM,
                textH : textH
            };
            if(notification){
                newobject['notification'] = true;
                newobject['type'] = hText;
            }
            datanew.push(newobject);

            all_models[selectedTab] = datanew;

            if(notification){
                chatRefPush.set({
                    text: mText,
                    senderName : 'bot',
                    senderUserID : 'bot',
                    orderID : null,
                    notification: true,
                    type : hText,
                    time : time,
                    senderInternalID : localStorage.getItem("user_id")
                });
                chatRef.child(code).child("last_updated").set(time);
    
                queueRef.push().set({
                    message: mText,
                    senderName : 'bot',
                    senderUserID : 'bot',
                    orderID : null,
                    receiverID : code,
                    messageType: "notification",
                    type: hText,
                    _state : "support_message_start"
                });
            }else{
                chatRefPush.set({
                    text: mText,
                    senderName : 'bot',
                    senderUserID : 'bot',
                    orderID : null,
                    notification: false,
                    time : time,
                    senderInternalID : localStorage.getItem("user_id")
                });
                chatRef.child(code).child("last_updated").set(time);
    
                queueRef.push().set({
                    message: mText,
                    senderName : 'bot',
                    senderUserID : 'bot',
                    orderID : null,
                    receiverID : code,
                    messageType: "text",
                    _state : "support_message_start"
                });
            }

            
            
            threads_list.sort(function(a, b) {
                    return b.last_time - a.last_time;
            });
            threads_list.sort(function(a, b) {
                    return b.last_time - a.last_time;
            });

            if(hText.toLowerCase() == "conversation closed"){
                for(var l in fbListeners3){
                    if (l['ref'] == chatRef.child(selectedTab)){
                        l['ref'].child(l['path']).off(l['type'], l['listener']);
                    }
                }
                delete divM[selectedTab];
                delete divH[selectedTab];
                delete divK[selectedTab];
                delete div_lastkeys[selectedTab];
                delete div_lasttimes[selectedTab];
                delete div_lastmessages[selectedTab];
                delete div_lastmessagetimes[selectedTab];
                delete all_models[selectedTab];
                
                let objTL = threads_list.find(o => o.id === selectedTab);
                var indexTL = threads_list.indexOf(objTL);
                threads_list.splice(indexTL, 1);
                
                let indexTC = thread_codes.indexOf(selectedTab);
                thread_codes.splice(indexTC, 1);
                
                // thread_codes = [];
                // threads_list = [];
            }

            chatRef.child(code).child("is_executive_typing").remove();

            supportchat.setState({threads_list : threads_list, all_models : all_models, divK : divK, divM : divM, divH : divH});
    }


    sendMessage(mText, selectedTab, supportchat){
            // var that;
            // var that1=$('button[name=chatsend_btn]');
            //     that=$(this);
            //         var message = $("#sendie");
                    
            var code = selectedTab;
    
            var date = new Date();
            
            var dateString = Constants.get_DateString(date);

            var time = date.getTime();

            var texts = divM[selectedTab];
            var textHs = divH[selectedTab];
            var mKeys = divK[selectedTab];

            var datanew= all_models[selectedTab];

            if(datanew.length == 0 || (datanew.length > 0 && !Constants.get_TimeComparison(datanew[datanew.length-1]['time'], time))){
                
                    var textH = "";
                    var textM = "";
                        
                    
                    var message= {
                                senderName:null,
                                senderUserID:null,
                                time:time,
                                notification:false,
                                textH : textH,
                                textM : textM
                                };

                        datanew.push(message);

                    


                    textHs.push(textH);
                    texts.push(textM);
                    mKeys.push(null);
                    divM[code] = texts;
                    divH[code] = textHs;
                    divK[code] = mKeys;
                    div_lastkeys[code] = null;
                    div_lasttimes[code] = message['time'];
                    div_lastmessages[code] = textM;
                    div_lastmessagetimes[code] = message['time'];
                
            }

            var textH = '';
            var textM = mText;

            

            div_lasttimes[code] = time;
            div_lastmessages[code] = textM;
            div_lastmessagetimes[code] = time;

            let obj = threads_list.find(o => o.id === code);
            if(obj != null){
                var indexObj = threads_list.indexOf(obj);
                obj['last_time'] = div_lasttimes[code];
                obj['last_message'] = div_lastmessages[code];
                obj['last_messagetime'] = div_lastmessagetimes[code];
                if(!obj['first_exective_message_time'] && obj['first_customer_message_time'])
                    obj['first_executive_message_time'] = time;
                threads_list[indexObj] = obj;
            }

            var chatRefPush = chatRef.child(code).child("chat").push();


            // var innerHTML = '<div class="message clearfix border-box">'
            // innerHTML += '<div class="self rightalign border-box">';
            // innerHTML += '<div class="sender border-box clearfix">';
            // innerHTML += '<div class="sendertext border-box clearfix">';
            // innerHTML += '<div class="sendername border-box blackspan rightalign">';
            // innerHTML += "Derbii Team (" + internalIDs[$("div.leftmain").attr('data-user-id')] + ")";
            // innerHTML += '</div>';
            // innerHTML += '<div class="sendertime border-box ccspan rightalign">';
            // innerHTML += dateString
            // innerHTML += '</div>';
            // innerHTML += '</div>';
            // innerHTML += '<div class="senderimage border-box">';
            // innerHTML += '<img height="30px" width="30px" src="https://derbiicdn.storage.googleapis.com/images/derbii_logo_color_new2_100.png" />';
            // innerHTML += '</div>';
            // innerHTML += '</div>';
            // innerHTML += '<div class="text border-box clearfix rightalign"><span>';
            // innerHTML += message.val();
            // innerHTML += '</span></div>';
            // innerHTML += '</div></div>';
            // $(".chat").append(innerHTML);


            

            texts.push(mText);
            textHs.push('');
            mKeys.push(chatRefPush.key);
            divM[selectedTab] = texts;
            divH[selectedTab] = textHs;
            divK[selectedTab] = mKeys;

            
            var newobject= {
                senderName : 'bot',
                senderUserID : 'bot',
                orderID : null,
                time : time,
                senderInternalID : localStorage.getItem("user_id"),
                textM : textM,
                textH : textH
            };
            datanew.push(newobject);

//					//console.log(datanew);
            
            all_models[selectedTab] = datanew


            chatRefPush.set({
                text: mText,
                senderName : 'bot',
                senderUserID : 'bot',
                orderID : null,
                notification: false,
                time : time,
                senderInternalID : localStorage.getItem("user_id")
            });

            chatRef.child(code).child("last_updated").set(time);

            queueRef.push().set({
                message: mText,
                senderName : 'bot',
                senderUserID : 'bot',
                orderID : null,
                receiverID : code,
                messageType: "text",
                _state : "support_message_start"
            });

            


            //  $('#sendie').val('');

            // $('.chat').animate({
            //     scrollTop: $('.chat').prop("scrollHeight")
            // }, 500);

            // $('.leftmain > .second').animate({
            //     scrollTop: 0
            // }, 500);

            
            threads_list.sort(function(a, b) {
                  return b.last_time - a.last_time;
            });
            threads_list.sort(function(a, b) {
                  return b.last_time - a.last_time;
            });
            // if($("#searchthreads").val().trim() == '' || $("#searchthreads").val() == null){
            //     $(".leftmain > .second").html('');
            //     derbiis_list.forEach(function(derbii){
            //         if (derbii.code in thread_boxes){
            //             $(".leftmain > .second").append(thread_boxes[derbii.code]);
            //         }
            //     });
            // }

            chatRef.child(code).child("is_executive_typing").remove();

            supportchat.setState({threads_list : threads_list, all_models : all_models, divK : divK, divM : divM, divH : divH});
    }





    searchThreads(val, supportchat){
        //console.log("val", val);
        if (val != null && val.trim() != "") {
    
    
            //console.log("thread_listing", threads_list.length);
    
    
            let obj1 = threads_list.filter(o => (o.name && o.name.toLowerCase().indexOf(val.toLowerCase()) != -1));
            let obj2 = threads_list.filter(o => (o.mobile ? (o.mobile.toLowerCase().indexOf(val.toLowerCase()) != -1) : false ));
            // let obj3 = thread_people.filter(o => (o.people == "" ? false : (o.people.toLowerCase().indexOf(val.toLowerCase()) != -1) ));


            var codes = [];

            obj1.forEach(function(obj) { 
                codes.push(obj.id);
            });
            obj2.forEach(function(obj) {
                codes.push(obj.id);
            });
            // obj3.forEach(function(obj) {
            //     codes.push(obj.code);
            // });

            for (var key in divM) {
                var texts = divM[key];
                if (texts.find(function(str) {return (str && str.toLowerCase().indexOf(val.toLowerCase()) != -1);})){
                    codes.push(key);
                }
            }

            for (var key in divH) {
                var textHs = divH[key];
                if (textHs.find(function(str) {return (str && str.toLowerCase().indexOf(val.toLowerCase()) != -1);})){
                    codes.push(key);
                }
            }

            var new_codes = codes.filter(function(item, pos, self) {
                  return self.indexOf(item) == pos;
            });

            var newArray = threads_list.filter(function(item) {
                return new_codes.includes(item.id);    
            });

            // $(".leftmain > .second").html('');

            // new_codes.forEach(function(code) {
            //     $(".leftmain > .second").append(thread_boxes[code]);
            // });
            supportchat.setState({threads_list : newArray, searchText : val});
        } else {
            //console.log("thread_listing1", threads_list.length);

            threads_list.sort(function(a, b) {
                  return b.last_time - a.last_time;
            });
            threads_list.sort(function(a, b) {
                  return b.last_time - a.last_time;
            });

            supportchat.setState({threads_list : threads_list, searchText : NaN});

            // threads_list.forEach(function(derbii){
            //     if (derbii.code in thread_boxes){
            //         $(".leftmain > .second").append(thread_boxes[derbii.code]);
            //     }
            // });
/*			thread_codes.forEach(function(code) {
                $(".leftmain > .second").append(thread_boxes[code]);
            });*/
        }
    }


    isTypingAlert(user_id, isTyping){
        if(isTyping)
            chatRef.child(user_id).child("is_executive_typing").set(true);
        else
            chatRef.child(user_id).child("is_executive_typing").remove();
    }


}

export default new ChatUtils();

