
import React from "react";
import "./trainingFilter.css";
import firebase from "../../../firebase";
import MultiSelect from "../../custom/MultiSelect/MultiSelect";
import API from "../../../api";
import Constants from "../../../constants";

class TrainingFilter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			workshops: [],
			cities: [],
			users: [],
			multipleUsers: [],
			trainingFilter: {
				workshop: {},
				city: {},
				user: {},
				multipleUsers: [],
			},
			showFilterDiv: false,
		};

		this.handleApplyFilter = this.handleApplyFilter.bind(this);
		this.afterFilterChange = this.afterFilterChange.bind(this);
		this.cityWiseWorkShop = this.cityWiseWorkShop.bind(this);
		this.workshopWiseUser = this.workshopWiseUser.bind(this);
	}
	componentWillMount() {
		// this.state.showFilterDiv = true;

		if (localStorage.getItem("city_data") != undefined) {
			this.state.city = Constants.getCityData("single");
		}
		if (localStorage.getItem("city_wise_workshops") != undefined) {
			this.state.workshops = JSON.parse(
				localStorage.getItem("city_wise_workshops")
			);
			this.state.showFilterDiv = true;
		}
		if (localStorage.getItem("workshop_wise_user") != undefined) {
			this.state.users = JSON.parse(localStorage.getItem("workshop_wise_user"));
			this.state.multipleUsers = JSON.parse(localStorage.getItem("workshop_wise_multiple_user"));
		}
	}
	componentDidMount() {
		/* Setting Filters Data from localstorage*/
		if (localStorage.getItem("training_filters") != undefined) {
				this.setState({trainingFilter:JSON.parse(localStorage.getItem("training_filters"))})
			}
		if (localStorage.getItem("city_data") == undefined) {
			firebase
				.database()
				.ref("/cms/cities")
				.on(
					"value",
					(snapshot) => {
						var cityOptions = [];
						cityOptions = [
							{ id: "select", name: "Select City" },
							...snapshot.val(),
						];
						this.setState({ cities: cityOptions });
						localStorage.setItem("city_data", JSON.stringify(this.state.cities));
					},
					(err) => {
						console.log("cities read failed from firebase", err.code);
					}
				);
		}
	}
	afterFilterChange(field) {
		field = field || null;
		if (field === "city") {
			this.cityWiseWorkShop();
		} 
		else if (field === "user") {
			this.workshopWiseUser(this.state.trainingFilter.workshop.id);
		}

		localStorage.setItem(
			"training_filters",
			JSON.stringify(this.state.trainingFilter)
		);
	}

	handleApplyFilter() {
		this.props.onApplyFilter(this.state.trainingFilter, "training_filters");
	}

	cityWiseWorkShop() {
		var location_id = this.state.trainingFilter.city.id;
		this.setState({
			trainingFilter: {
				...this.state.trainingFilter,
				workshop: {},
			},
			workshops:[]
		});
		var that = this;
		API.getGaragesByLocation(location_id).then(function (response) {
			if (response.data.status) {
				let result = [
					{ id: "select", name: "Select Workshop" },
					...response.data.data,
				];
				that.setState({ workshops: result });

				localStorage.setItem("city_wise_workshops", JSON.stringify(result));
			}
		});
	}

	// Filter user
	// workshopWiseUser(workshop_id) {
	workshopWiseUser(workshopId) {
		let workshop_id = workshopId //workshopId
		this.setState({
			trainingFilter: {
				...this.state.trainingFilter,
				user: {},
			},
			users: [],
			multipleUsers: [],
		});
		var that = this;
		API.getUsersByGarage(workshop_id).then(function (response) {
		if (response.data.status) {
				let result = [
					{ id: "select", name: "Select User" },
					...response.data.data,
				];
				let multipleUserResult = [
					{ id: "select-all", name: "Select All" },
					...response.data.data,
				]
				that.setState({ users: result });
				that.setState({ multipleUsers: multipleUserResult });
				localStorage.setItem("workshop_wise_user", JSON.stringify(result));
				localStorage.setItem("workshop_wise_multiple_user", JSON.stringify(multipleUserResult));
			} 
		});
	}
	render() {
		const { showFilterDiv, workshops, city, users, cities, trainingFilter, user, multipleUsers } =
			this.state;
		return (
			<div className="mainFilterContainer">
				<div
					className="row-5 margin_10 margin_b_20"
					style={{ gridTemplateColumns: "repeat(6,18%)" }}
				>
					<React.Fragment>
						<div className="margin_r_16">
							<MultiSelect
								options={city}
								labelKey="name"
								checkbox_id="name"
								placeholder="Select Cities"
								disabledItems={[]}
								selectedOptions={trainingFilter.city}
								onChange={(value) =>
									this.setState(
										{
											trainingFilter: {
												...trainingFilter,
												city: value,
											},
											showFilterDiv: true,
										},
										() => this.afterFilterChange("city")
									)
								}
							/>
						</div>
					</React.Fragment>
					{showFilterDiv && (
						<React.Fragment>
							{this.state.trainingFilter.city.id  && 
							<div className="margin_r_16" 
							style={{ width: "95%" }}
							>
								<MultiSelect
									options={workshops}
									labelKey="name"
									checkbox_id="id"
									placeholder="Select Workshop"
									disabledItems={[]}
									selectedOptions={this.state.trainingFilter.workshop}
									onChange={(value) =>
										this.setState(
											{
												trainingFilter: {
													...this.state.trainingFilter,
													workshop: value,
												},
											},
											() => this.afterFilterChange("user")
										)
									}
								/>
							</div>}
							{this.state.trainingFilter.city.id && this.state.trainingFilter.workshop.id && 
							<div className="margin_r_16">
								<MultiSelect
									options={users}
									labelKey="name"
									checkbox_id="id"
									placeholder="User's Name"
									disabledItems={[]}
									extraDetails={true}
									selectedOptions={this.state.trainingFilter.user}
									onChange={(value) =>
										this.setState(
											{
												trainingFilter: {
													...this.state.trainingFilter,
													user: value,
												},
											},
											() => this.afterFilterChange("users")
										)
									}
								/>
							</div>}
							{this.state.trainingFilter.city.id && this.state.trainingFilter.workshop.id &&  this.state.trainingFilter.user.id &&
							<div className="margin_r_16">
								<MultiSelect
									multiple
									options={multipleUsers}
									labelKey="name"
									checkbox_id="id"
									placeholder="Multiple Users"
									disabledItems={[]}
									extraDetails={true}
									selectedOptions={this.state.trainingFilter.multipleUsers}
									onChange={(value) =>
										this.setState(
											{
												trainingFilter: {
													...this.state.trainingFilter,
													multipleUsers: value,
												},
											},
										)
									}
								/>
							</div>}
							{this.state.trainingFilter.city.id && this.state.trainingFilter.workshop.id &&  this.state.trainingFilter.user.id &&
							<div
								className="blackspan65 weight700 floatright addContentBtnMargin"
							>
								<button
									style={{
										background: "rgb(105,118,148,1)",
										color: "rgb(255, 255, 255)",
									}}
									id="addleadbtn"
									className="btn btn_small btncolorprim btncard move"
									onClick={this.handleApplyFilter}
								>
									Apply Filter
								</button>
							</div>}
						</React.Fragment>
					)}
				</div>
			</div>
		);
	}
}

export default TrainingFilter;
