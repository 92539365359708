import React from "react";
import "./pricingFilter.css";
import firebase from "../../firebase";
import MultiSelect from "../custom/MultiSelect/MultiSelect";
import API from "../../api";
import { NotificationManager } from "react-notifications";

// const filters = {"city":true,"brand":true,"model":false,"fuel":true};
class PricingFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dealType: [],
      brands: [],
      models: [],
      segments: [],
      accessories_product: [],
      accessories_sub_product: [],
      brandWiseModels: [],
      fuelType: [],
      categories: [],
      bulkType: [],
      bulkValue: [],
      warranty: [],
      pricingFilter: {
        deals: [],
        brands: [],
        fuelType: [],
        models: [],
        categories: [],
        brandType: [],
        brandValue: [],
        segments: [],
        warranty: [],
        accessories_product: [],
        accessories_sub_product: [],
      },
      showFilterDiv: false,
    };

    this.handleApplyFilter = this.handleApplyFilter.bind(this);
    this.afterFilterChange = this.afterFilterChange.bind(this);
    this.brandWiseModel = this.brandWiseModel.bind(this);
    this.getTyreBatteryData = this.getTyreBatteryData.bind(this);
    this.getBrandvalue = this.getBrandvalue.bind(this);
    this.segmentWiseModel = this.segmentWiseModel.bind(this);
  }
  componentWillMount() {
    if (localStorage.getItem("deal_type") != undefined) {
      this.setState({dealType: [
                    { id: "select", name: "Select Deals" },
                    ...JSON.parse(localStorage.getItem("deal_type"))
                ]});
      this.setState({showFilterDiv: true});
    }
    if (localStorage.getItem("brand_data") != undefined) {
      this.setState({brands: [
                  { id: "select", name: "Select Brands" },
                  ...JSON.parse(localStorage.getItem("brand_data"))
                ]});
    }
    if (localStorage.getItem("fuel_data") != undefined) {
      this.setState({fuelType: [
                  { id: "select-all", name: "Select All" },
                  ...JSON.parse(localStorage.getItem("fuel_data"))
                ]});
    }
    if (localStorage.getItem("model_data") != undefined) {
      this.setState({models: JSON.parse(localStorage.getItem("model_data"))});
      this.setState({brandWiseModels:localStorage.getItem("brand_wise_model") == null ? []
      : JSON.parse(localStorage.getItem("brand_wise_model"))})
    }
    if(localStorage.getItem("category_data") != undefined){ 
      let category_data = JSON.parse(localStorage.getItem("category_data"));
      let cat_filter = localStorage.getItem("country") !== 'malaysia' ? category_data : category_data.filter((obj)=>{ return obj.id != 1000});
      this.setState({categories:[
                  { id: "select", name: "Select Category" },
                  ...cat_filter,
                ]}); 
    }
    if(localStorage.getItem("brand_type") != undefined){  
      this.setState({brandType: JSON.parse(localStorage.getItem("brand_type"))});
    }
    if (localStorage.getItem("brand_value") != undefined) {
      this.setState({
        brandValue: JSON.parse(localStorage.getItem("brand_value")),
      });
    }
    if(localStorage.getItem("battery_warranty") != undefined){  
      this.setState({warranty: JSON.parse(localStorage.getItem("battery_warranty"))});
    }
    if (localStorage.getItem("segment_wise_models") != undefined) {
      let segment_wise_models= {...JSON.parse(localStorage.getItem("segment_wise_models"))};
      this.setState({segmentWiseModels: segment_wise_models});
    }
    if (localStorage.getItem("segment_data") != undefined) {
      this.setState({segments:[
                  { id: "select", name: "Select Segments" },
                  ... JSON.parse(localStorage.getItem("segment_data")),
                ]});
    }
    if (localStorage.getItem("accessories_product") != undefined) {
      this.setState({accessories_product : JSON.parse( localStorage.getItem("accessories_product"))});
    }
  }
  componentDidMount() {
    /* Setting Filters Data from localstorage*/
    if (localStorage.getItem("pricing_filters") != undefined) {
      this.setState({
        pricingFilter: JSON.parse(localStorage.getItem("pricing_filters")),
      });
    }
    if (localStorage.getItem("accessories_product") == undefined) {
      this.setState({accessories_product :  JSON.parse( localStorage.getItem("accessories_product"))});
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.batteryTyreData !== prevProps.batteryTyreData && this.props.batteryTyreData === true) {
      this.getTyreBatteryData();
    }
  }
  afterFilterChange(field) {
    field = field || null;
    if (field === "brand") {
      this.brandWiseModel();
    }
    if (
      field === "category" &&
      (this.state.pricingFilter.categories.id == -4.1 ||
        this.state.pricingFilter.categories.id == 21.1)
    ) {
      this.getTyreBatteryData();
    }
    if (field === "brand_type") {
      this.getBrandvalue();
    }
    if (field === "segment") {
      this.segmentWiseModel();
    }
    if (
      field === "category" &&
      this.state.pricingFilter.categories.id == 1000
    ) {
      this.getAccessoriesProductCat();
    }
    if (field === "accessories_product") {
      this.setState({
        accessories_sub_product: [
          ...this.state.pricingFilter.accessories_product.sub_categories,
        ],
      });
    }
    localStorage.setItem(
      "pricing_filters",
      JSON.stringify(this.state.pricingFilter)
    );
  }
  getAccessoriesProductCat() {
    let data = {};
    data.deal_id = this.state.pricingFilter.deals.id;
    data.service_id = this.state.pricingFilter.categories.id;
    console.log(data);
    let that = this;
    
      API.getAccessoriesProductCat(data)
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.status) {
            console.log("getTyreBatteryData", response.data);
            let data =
              response.data && response.data.data ? [{ id: "select-all", name: "Select All" }, ...response.data.data] : [];
            localStorage.setItem("accessories_product", JSON.stringify(data));
            that.setState({ accessories_product: data });
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          NotificationManager.error("Please try again", "Error", 5000);
          that.setState({ accessories_product: [] });
        });
  }

  getTyreBatteryData() {
    let id =
      this.state.pricingFilter.categories &&
      this.state.pricingFilter.categories.id
        ? this.state.pricingFilter.categories.id
        : "";
    var that = this;
    this.setState({
      bulkType: [],
      bulkValue: [],
      pricingFilter: {
        ...this.state.pricingFilter,
        brandType: [],
        brandValue: [],
      },
    });
    API.getTyreBatteryData(id)
      .then(function (response) {
        if (response.data.status) {
          console.log("getTyreBatteryData", response.data);
          let data =
            response.data && response.data.data ? response.data.data : [];
          that.setState({
            bulkType: [{ id: "-1", name: "Select Brands" }, ...data],
          });
          localStorage.setItem(
            "brand_type",
            JSON.stringify([{ id: "-1", name: "Select Brands" }, ...data])
          );
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        NotificationManager.error("Please try again", "Error", 5000);
        that.setState({
          bulkType: [],
          bulkValue: [],
          pricingFilter: {
            ...that.state.pricingFilter,
            brandType: [],
            brandValue: [],
          },
        });
      });
  }
  getBrandvalue() {
    console.log("getBrandvalue", this.state.pricingFilter.brandType);
    if (this.state.pricingFilter && this.state.pricingFilter.brandType) {
      let value = this.state.pricingFilter.brandType.value;
      let warranty = this.state.pricingFilter.brandType.value1;
      var brand_value = [];
      var battery_warranty = [];
      value.map((item, index) => {
        let obj = {};
        obj["id"] = index;
        obj["name"] = item;
        brand_value.push(obj);
      });

      if (warranty.length > 0) {
        warranty.map((item, index) => {
          let obj = {};
          obj["id"] = index;
          obj["name"] = item;
          battery_warranty.push(obj);
        });
      }
      this.setState({
        bulkValue: [{ id: "select-all", name: "Select All" }, ...brand_value],
        warranty: [
          { id: "select-all", name: "Select All" },
          ...battery_warranty,
        ],
        pricingFilter: {
          ...this.state.pricingFilter,
          brandValue: [],
          warranty: [],
        },
      });
      localStorage.setItem(
        "brand_value",
        JSON.stringify([
          { id: "select-all", name: "Select All" },
          ...brand_value,
        ])
      );
      localStorage.setItem(
        "battery_warranty",
        JSON.stringify([
          { id: "select-all", name: "Select All" },
          ...battery_warranty,
        ])
      );
      // return [{id: "select-all", name: 'Select All'}, ...brand_value];
    }
  }

  brandWiseModel() {
    let brands = this.state.pricingFilter.brands;
    let models = this.props.fbData.brandWiseModel;
    let filterModel = this.state.pricingFilter.models;
    let filterModelArr = [];

    let brand_id = brands.id;
    let slug_id = brands.compare_name;
    let brandModel = models[slug_id];

    if (brand_id && brandModel) {
      if (filterModel.length !== 0) {
        for (let j = 0; j < filterModel.length; j++) {
          if (brand_id == filterModel[j].brand_id)
            filterModelArr.push(filterModel[j]);
        }
      }

      var brandWiseModel = [];
      brandWiseModel = [
        { id: "select-all", name: "Select All" },
        ...brandModel.model,
      ];
      this.setState({
        brandWiseModels: brandWiseModel,
        pricingFilter: { ...this.state.pricingFilter, models: filterModelArr },
      });
      localStorage.setItem("brand_wise_model", JSON.stringify(brandWiseModel));
    } else {
      this.setState({
        pricingFilter: { ...this.state.pricingFilter, models: [] },
      });
    }
    localStorage.setItem(
      "pricing_filters",
      JSON.stringify(this.state.pricingFilter)
    );
  }
  segmentWiseModel() {
    let segments = this.state.pricingFilter.segments;
    let models = this.props.fbData.segmentWiseModel;
    let filterModel = this.state.pricingFilter.models;
    let filterModelArr = [];

    let slug_id = segments.name
    //   segments && segments.name
    //     ? segments.name.replace(" ", "-").toLowerCase()
    //     : "";
    let segmentModel = models[slug_id];

    if (slug_id && segmentModel) {
      if (filterModel.length !== 0) {
        for (let j = 0; j < filterModel.length; j++) {
          if (slug_id == filterModel[j].segment_name)
            filterModelArr.push(filterModel[j]);
        }
      }

      var segmentWiseModel = [];
      segmentWiseModel = [
        { id: "select-all", name: "Select All" },
        ...segmentModel.model,
      ];
      this.setState({
        brandWiseModels: segmentWiseModel,
        pricingFilter: { ...this.state.pricingFilter, models: filterModelArr },
      });
      localStorage.setItem(
        "brand_wise_model",
        JSON.stringify(segmentWiseModel)
      );
    } else {
      this.setState({
        pricingFilter: { ...this.state.pricingFilter, models: [] },
      });
    }
    // localStorage.setItem(
    //   "rate_filters",
    //   JSON.stringify(this.state.pricingFilter)
    // );
  }

  handleApplyFilter() {
    this.props.onApplyFilter(this.state.pricingFilter, "pricing_filters");
  }
  render() {
    const {
      showFilterDiv,
      brandWiseModels,
      pricingFilter,
      bulkType,
      bulkValue,
      warranty,
      accessories_product,
      accessories_sub_product,
    } = this.state;
    const {fbData} = this.props

    return (
      <div className="mainFilterContainer">
        <div
          className="row-5 margin_10 margin_b_20"
          style={ { gridTemplateColumns: "repeat(5,20%)",rowGap:"1rem" }}
        >
          <div className="margin_r_16">
            <MultiSelect
              options={[
                { id: "select", name: "Select Deals" },
                ...fbData.deal_zone
            ]}
              labelKey="name"
              checkbox_id="name"
              placeholder="Deal Type"
              disabledItems={[]}
              selectedOptions={this.state.pricingFilter.deals}
              onChange={(value) =>
                this.setState(
                  {
                    pricingFilter: {
                      ...this.state.pricingFilter,
                      deals: value,
                      brands: [],
                      fuelType: [],
                      models: [],
                      brandType: [],
                      brandValue: [],
                      segments: [],
                      warranty: [],
                      accessories_product: [],
                      accessories_sub_product: [],
                      categories:[]
                    },
                    showFilterDiv: true,
                  },
                  () => this.afterFilterChange()
                )
              }
            />
          </div>
          {showFilterDiv && (
            <>
              {pricingFilter.deals && pricingFilter.deals.name && (
                <>
                  <div className="margin_r_16">
                    <MultiSelect
                      options={localStorage.getItem('country') === 'malaysia' ? [
                  { id: "select", name: "Select Category" },
                  ...fbData.pricing_categories.filter(((obj)=>{ return obj.id != 1000})),
                ]:[{ id: "select", name: "Select Category" },
                ...fbData.pricing_categories]}
                      labelKey="name"
                      checkbox_id="name"
                      placeholder="Select Category"
                      disabledItems={[]}
                      selectedOptions={this.state.pricingFilter.categories}
                      onChange={(value) =>
                        this.setState(
                          {
                            pricingFilter: {
                              ...this.state.pricingFilter,
                              categories: value,
                              brands: [],
                              fuelType: [],
                              models: [],
                              brandType: [],
                              brandValue: [],
                              segments: [],
                              warranty: [],
                              accessories_product: [],
                              accessories_sub_product: [],
                            },
                          },
                          () => this.afterFilterChange("category")
                        )
                      }
                    />
                  </div>
                  {pricingFilter.categories &&
                    pricingFilter.categories.id == 1000 &&
                    accessories_product && (
                      <>
                        <div className="margin_r_16">
                          <MultiSelect
                            options={accessories_product}
                            labelKey="name"
                            checkbox_id="id"
                            placeholder="Select Accessories Product"
                            disabledItems={[]}
                            selectedOptions={
                              this.state.pricingFilter.accessories_product
                            }
                            onChange={(value) =>
                              this.setState(
                                {
                                  pricingFilter: {
                                    ...this.state.pricingFilter,
                                    accessories_product: value,
                                    accessories_sub_product:[],
                                    brands: [],
                                    fuelType: [],
                                    models: [],
                                    brandType: [],
                                    brandValue: [],
                                    segments: [],
                                    warranty: [],
                                  },
                                },
                                () =>
                                  this.afterFilterChange("accessories_product")
                              )
                            }
                          />
                        </div>
                      </>
                    )}
                  {pricingFilter.categories &&
                    pricingFilter.categories.id == 1000 &&
                    accessories_product &&
                    this.state.pricingFilter.accessories_product && this.state.pricingFilter.accessories_product.id  && (
                      <>
                        <div className="margin_r_16">
                          <MultiSelect
                            multiple
                            options={accessories_sub_product}
                            labelKey="name"
                            checkbox_id="id"
                            placeholder="Select Accessories Sub Product"
                            disabledItems={[]}
                            selectedOptions={
                              this.state.pricingFilter.accessories_sub_product
                            }
                            onChange={(value) =>
                              this.setState(
                                {
                                  pricingFilter: {
                                    ...this.state.pricingFilter,
                                    accessories_sub_product: value,
                                    brands: [],
                                    fuelType: [],
                                    models: [],
                                    brandType: [],
                                    brandValue: [],
                                    segments: [],
                                    warranty: [],
                                  },
                                },
                                () =>
                                  this.afterFilterChange("accessories_product")
                              )
                            }
                          />
                        </div>
                      </>
                    )}
                  {pricingFilter.categories &&
                    pricingFilter.categories.id != -4.1 &&
                    pricingFilter.categories.id != 21.1 && (
                      <>
                        <div className="margin_r_16">
                          <MultiSelect
                            options={[
                              { id: "select", name: "Select Segments" },
                              ...fbData.segment,
                            ]}
                            labelKey="name"
                            checkbox_id="name"
                            placeholder="Select Segments"
                            disabledItems={[]}
                            selectedOptions={this.state.pricingFilter.segments}
                            disabled={
                              pricingFilter &&
                              pricingFilter.brands.id &&
                              pricingFilter.categories.id !== 173
                                ? true
                                : false
                            }
                            onChange={(value) =>
                              this.setState(
                                {
                                  pricingFilter: {
                                    ...this.state.pricingFilter,
                                    segments: value,
                                    fuelType: [],
                                  },
                                },
                                () => this.afterFilterChange("segment")
                              )
                            }
                          />
                        </div>
                        {pricingFilter.categories.id !== 173 && (
                          <div className="margin_r_16">
                            <MultiSelect
                              options={[
                                { id: "select", name: "Select Brands" },
                                ...fbData.car_brands
                              ]}
                              labelKey="name"
                              checkbox_id="name"
                              placeholder="Select Brand"
                              disabledItems={[]}
                              selectedOptions={this.state.pricingFilter.brands}
                              disabled={
                                pricingFilter &&
                                pricingFilter.segments &&
                                pricingFilter.segments.id
                                  ? true
                                  : false
                              }
                              onChange={(value) =>
                                this.setState(
                                  {
                                    pricingFilter: {
                                      ...this.state.pricingFilter,
                                      brands: value,
                                      fuelType: [],
                                    },
                                  },
                                  () => this.afterFilterChange("brand")
                                )
                              }
                            />
                          </div>
                        )}
                      </>
                    )}
                </>
              )}

              {pricingFilter.categories &&
                (pricingFilter.categories.id == -4.1 ||
                  pricingFilter.categories.id == 21.1) &&
                pricingFilter.categories.id !== 173 && (
                  <>
                    <div className="margin_r_16">
                      <MultiSelect
                        options={bulkType}
                        labelKey="name"
                        checkbox_id="name"
                        placeholder="Select Brand Type"
                        disabledItems={[]}
                        selectedOptions={this.state.pricingFilter.brandType}
                        onChange={(value) =>
                          this.setState(
                            {
                              pricingFilter: {
                                ...this.state.pricingFilter,
                                brandType: value,
                              },
                            },
                            () => this.afterFilterChange("brand_type")
                          )
                        }
                      />
                    </div>
                  </>
                )}
              {pricingFilter.brandType &&
                pricingFilter.brandType.id &&
                (pricingFilter.categories.id == -4.1 ||
                  pricingFilter.categories.id == 21.1) &&
                pricingFilter.categories.id !== 173 && (
                  <>
                    <div className="margin_r_16">
                      <MultiSelect
                        multiple
                        options={bulkValue}
                        labelKey="name"
                        checkbox_id="name"
                        placeholder="Select Value"
                        disabledItems={[]}
                        selectedOptions={this.state.pricingFilter.brandValue}
                        onChange={(value) =>
                          this.setState(
                            {
                              pricingFilter: {
                                ...this.state.pricingFilter,
                                brandValue: value,
                              },
                            },
                            () => this.afterFilterChange()
                          )
                        }
                      />
                    </div>
                  </>
                )}
              {pricingFilter.brandValue && pricingFilter.categories && 
                pricingFilter.brandValue.length > 0 &&
                pricingFilter.categories.id == -4.1 &&
                pricingFilter.categories.id !== 173 && (
                  <>
                    <div className="margin_r_16">
                      <MultiSelect
                        multiple
                        options={warranty}
                        labelKey="name"
                        checkbox_id="name"
                        placeholder="Select Warranty"
                        disabledItems={[]}
                        selectedOptions={this.state.pricingFilter.warranty}
                        onChange={(value) =>
                          this.setState(
                            {
                              pricingFilter: {
                                ...this.state.pricingFilter,
                                warranty: value,
                              },
                            },
                            () => this.afterFilterChange()
                          )
                        }
                      />
                    </div>
                  </>
                )}
              {(pricingFilter.brands.id || pricingFilter.segments.id) &&
                pricingFilter.categories.id != -4.1 &&
                pricingFilter.categories.id != 21.1 &&
                pricingFilter.categories.id !== 173 && (
                  <div className="margin_r_16">
                    <MultiSelect
                      multiple
                      options={brandWiseModels}
                      labelKey="name"
                      checkbox_id="id"
                      placeholder="Select Model"
                      disabledItems={[]}
                      selectedOptions={this.state.pricingFilter.models}
                      onChange={(value) =>
                        this.setState(
                          {
                            pricingFilter: {
                              ...this.state.pricingFilter,
                              models: value,
                            },
                          },
                          () => this.afterFilterChange()
                        )
                      }
                    />
                  </div>
                )}
              {((pricingFilter.brands && pricingFilter.brands.id) ||
                (pricingFilter.segments && pricingFilter.segments.id)) &&
                pricingFilter.models.length > 0 &&
                pricingFilter.categories.id != -4.1 &&
                pricingFilter.categories.id != 21.1 &&
                pricingFilter.categories.id !== 173 && (
                  <div className="margin_r_16">
                    <MultiSelect
                      multiple
                      options={[
                        { id: "select-all", name: "Select All" },
                        ...fbData.fuelTypes
                      ]}
                      labelKey="name"
                      checkbox_id="id"
                      placeholder="Select Fuel Type"
                      disabledItems={[]}
                      selectedOptions={this.state.pricingFilter.fuelType}
                      onChange={(value) =>
                        this.setState(
                          {
                            pricingFilter: {
                              ...this.state.pricingFilter,
                              fuelType: value,
                            },
                          },
                          () => this.afterFilterChange()
                        )
                      }
                    />
                  </div>
                )}
              {(this.state.pricingFilter && this.state.pricingFilter.deals && this.state.pricingFilter.deals.id && (this.state.pricingFilter.categories.id || this.state.pricingFilter.categories.id === 0)) && 
                <div className="blackspan65 weight700 floatright addContentBtnMargin">
                  <button
                    style={{
                      background: "rgb(105,118,148,1)",
                      color: "rgb(255, 255, 255)",
                    }}
                    id="addleadbtn"
                    className="btn btn_small btncolorprim btncard move"
                    onClick={this.handleApplyFilter}
                  >
                    Apply Filter
                  </button>
                </div>
              }
            </>
          )}
        </div>
      </div>
    );
  }
}

export default PricingFilter;
