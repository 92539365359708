import React from "react";
import "../dashboard/dashboard.css";
import $ from "jquery";

import PanelLeft from "./leftpanel";
// import PanelRight from "./rightpanel";
import Row from "./row";
// import FilterBox from "./filterbox";
import MiddleUpper from "./middleupper";
import MiddleMain from "./middlemain";
import MiddleExtra from "./middleextra";
import Loader from "../loader";
import WebFilter from "./WebFilter/webFilter";
import API from "../api";
import Constants from "../constants";
import { Redirect } from "react-router-dom";
import "./middleupper.css";

const axios = require("axios");

// const tabList = [{tag:"content_website" , title : "Website content"}, {tag:"support_chat" , title : "Support Chat"}, {tag:"orders" , title : "Orders"}, {tag:"fleets" , title : "Fleets"}, {tag:"users" , title : "Users"}, {tag:"services" , title : "Services"}, {tag:"packages" , title : "Packages"}, {tag:"deals" , title : "Deals"}, {tag:"cars" , title : "Cars"}, {tag:"invoices" , title : "Invoices"}, {tag:"garages" , title : "Garages"}, {tag:"crs" , title : "CRs"}, {tag:"bulkorders" , title : "Bulk Orders"}, {tag:"addresses" , title : "Addresses"}];

class PanelMiddle extends React.Component {
  constructor(props) {
    super(props);
    //    this.state = {};
  }

  render() {
    if (this.props.showLoader) {
      // if(true){
      return (
        <div id="websiteMainmiddleloader" className="bgwhite centeralign">
          <Loader showLoader={this.props.showLoader} />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          {/* <div id="dashboardmainmiddle" className="bgwhite leftalign websiteContentDash">
             <div id="dashboardprime" className="leftalign"> */}
          {/* {this.props.extraContent ? (
                  <MiddleExtra
                    content={this.props.extraContent}
                    onMiddleExtraButtonClick={this.props.onMiddleExtraButtonClick}
                    selectedRow={this.props.selectedRow}
                  />
                  ):( <React.Fragment/> )
            } */}
          {/* <div style={{marginTop:"20px"}}>
                <WebFilter 
                    onApplyFilter={this.props.onFilterChange}
                />
            </div> */}
          {this.props.webFilters ? (
            [
              <MiddleUpper
                tag={this.props.tag}
                label={this.props.label}
                onFilterClick={this.props.onFilterClick}
                onAddClick={this.props.onAddClick}
                onFilterChange={this.props.onFilterChange}
                web_filters={this.props.webFilters}
              />,
              this.props.showContent ? (
                <MiddleMain
                  tag={this.props.tag}
                  selectedRow={this.props.selectedRow}
                  content={this.props.content}
                  showContent={this.props.showContent}
                  onRowClick={this.props.onRowClick}
                  rightDetails={this.props.rightContent}
                  onAction={this.props.rightOnRightButtonClick}
                  web_filters={this.props.webFilters}
                />
              ) : (
                <React.Fragment />
              ),
            ]
          ) : (
            <div className="emptyMiddleMain centeralign weight400 blackspan56">
              Please apply filters to view records
            </div>
          )}
          {/* </div> */}
          {/* </div> */}
        </React.Fragment>
      );
    }
  }
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "logged in",
      rightContent: NaN,
      middleContent: NaN,
      showMiddleContent: false,
      showRightContent: false,
      middleExtraContent: NaN,
      selectedTab: "faq_near_me",
      selectedRow: "",
      filter_showing: false,
      filter_args: undefined,
      web_filters: undefined,
      tableName: "cms_faq",
      label: "FAQ",
    };

    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.handleMiddleExtraButtonClick = this.handleMiddleExtraButtonClick.bind(
      this
    );
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  handleFilterClick() {
    this.setState({ filter_showing: !this.state.filter_showing });
  }

  handleAddClick(tag) {
    let content = this.state.middleContent;
    if(this.state.web_filters.pageType.name === "app") {
      this.setState({
        middleExtraContent: {
          type: "add",
          tag: tag,
          table: this.state.tableName,
          label: this.state.label,
          row: null,
          content: content,
        },
      });
    } else {
      this.setState({
        middleExtraContent: {
          type: "add",
          tag: tag,
          table: this.state.tableName,
          label: this.state.label,
          row: null,
          content: null,
        },
      });
    }
  }

  handleSearchClick(args) {
    this.setState({
      filter_args: args,
      filter_showing: !this.state.filter_showing,
    });
    API.tabSearchCall(this.state.selectedTab, this, args);
    //this.setState({filter_showing : !this.state.filter_showing});
  }

  handleRowClick(data, tag) {
    /*### Not in use  handleEditButtonClick is being used*/
    this.setState({
      selectedRow: data.uuid,
      showRightContent: true,
      rightContent: data,
    });
  }

  handleEditButtonClick(type, tag, row, content, data) {
    this.setState({
      selectedRow: data.uuid,
      showRightContent: true,
      rightContent: data,
    });
    this.setState({
      middleExtraContent: {
        type: type,
        tag: tag,
        table: this.state.tableName,
        label: this.state.label,
        row: row,
        content: content,
      },
    });
  }

  handleMiddleExtraButtonClick(type, content, row) {
    if (type == "save") {
      // var middleContent = this.state.middleContent;
      // let obj = middleContent.find(o => o.id === row);
      // if(obj != null){
      // 	var indexObj = middleContent.indexOf(obj);

      // 	middleContent[indexObj] = content;
      // }

      // this.setState({middleExtraContent: NaN, middleContent : middleContent, rightContent: content});

      this.setState({
        showMiddleContent: false,
        showRightContent: false,
        rightContent: NaN,
        middleExtraContent: NaN,
        showLoader: true,
      });

      API.tabClickCallContentWebsite(
        this.state.selectedTab,
        this,
        this.state.tableName
      );
    } else {
      this.setState({ middleExtraContent: NaN });
    }
  }

  handleTabClick(tab) {
    var that = this;

    that.setState({
      selectedTab: tab.tag,
      label: tab.title,
      tableName: tab.table,
      showMiddleContent: false,
      showRightContent: false,
      rightContent: NaN,
      showLoader: true,
      middleExtraContent: NaN,
    });

    API.tabClickCallContentWebsite(tab.tag, that, tab.table);
  }
  handleFilterChange(filters) {
    var that = this;
    this.setState({
      web_filters: filters,
      showLoader: true,
    });
    localStorage.setItem("web_filters", JSON.stringify(filters));
    API.tabClickCallContentWebsite(
      that.state.selectedTab,
      that,
      that.state.tableName
    );
  }

  componentWillMount() {
    if (localStorage.getItem("access_token") === null) {
      this.setState({ status: "not logged in" });
    } else {
      var that = this;
      that.setState({ showLoader: true });
      if (
        localStorage.getItem("statuses") === null ||
        localStorage.getItem("fleets_summary") === null ||
        localStorage.getItem("garages_summary") === null
      ) {
        API.getStatuses({
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
          .then(function (response) {
            Constants.setStatuses(response.data.data);
            API.get_fleets_summary1({
              headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token"),
              },
            })
              .then(function (response) {
                Constants.setFleetsSummary(response.data.data);
                API.get_garages_summary1({
                  headers: {
                    Authorization:
                      "Bearer " + localStorage.getItem("access_token"),
                  },
                })
                  .then(function (response) {
                    Constants.setGaragesSummary(response.data.data);
                    API.tabClickCallContentWebsite(
                      that.state.selectedTab,
                      that
                    );
                    that.setState({ showLoader: false });
                  })
                  .catch(function (error) {
                    // handle error
                    console.log(error);
                  })
                  .finally(function () {
                    // always executed
                  });
              })
              .catch(function (error) {
                // handle error
                console.log(error);
              })
              .finally(function () {
                // always executed
              });
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .finally(function () {
            // always executed
          });
      } else {
        if (localStorage.getItem("web_filters") != null)
          API.tabClickCallContentWebsite(
            that.state.selectedTab,
            that,
            that.state.tableName
          );

        that.setState({
          showLoader: false,
          web_filters: JSON.parse(localStorage.getItem("web_filters")),
        });
      }
    }
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    if (
      this.state.status === "not logged in" ||
      new Date(localStorage.getItem("token_expiration_date")) <= new Date()
    ) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("token_expiration_date");
      // localStorage.setItem("user_id");
      localStorage.removeItem("user_id");
      localStorage.removeItem("phone");
      localStorage.removeItem("custom_token");
      localStorage.removeItem("user_roles");
      return <Redirect to="/login" />;
    } else if (this.state.selectedTab === "support_chat") {
      return <Redirect to="/chat" />;
    } else {
      // return <Redirect to="/dashboard"/>
      return (
        <div id="containermainouter1" className="MainGridComponent">
          {/* <PanelRight content={this.state.rightContent} showContent={this.state.showRightContent} tag={this.state.selectedTab} selectedRow={this.state.selectedRow} onRightButtonClick={this.handleEditButtonClick}/>
           */}
          <PanelLeft
            onTabClick={this.handleTabClick}
            selectedTab={this.state.selectedTab}
          />
          <div
            id="dashboardmainmiddle"
            className="bgwhite leftalign websiteContentDash marginDashboard1"
          >
            <div id="dashboardprime" className="leftalign">
              <div style={{ marginTop: "20px" }}>
                <WebFilter onApplyFilter={this.handleFilterChange} />
              </div>
              {this.state.middleExtraContent ? (
                <MiddleExtra
                  content={this.state.middleExtraContent}
                  onMiddleExtraButtonClick={this.handleMiddleExtraButtonClick}
                  selectedRow={this.state.selectedRow}
                  web_filters={this.state.web_filters}
                  onAddClick={this.handleAddClick}
                />
              ) : (
                <React.Fragment />
              )}
              <PanelMiddle
                tag={this.state.selectedTab}
                label={this.state.label}
                webFilters={this.state.web_filters}
                onFilterChange={this.handleFilterChange}
                filterShowing={this.state.filter_showing}
                selectedRow={this.state.selectedRow}
                filterArgs={this.state.filter_args}
                showLoader={this.state.showLoader}
                extraContent={this.state.middleExtraContent}
                onMiddleExtraButtonClick={this.handleMiddleExtraButtonClick}
                onAddClick={this.handleAddClick}
                content={this.state.middleContent}
                showContent={this.state.showMiddleContent}
                onRowClick={this.handleRowClick}
                onFilterClick={this.handleFilterClick}
                onSearchClick={this.handleSearchClick}
                rightContent={this.state.rightContent}
                rightOnRightButtonClick={this.handleEditButtonClick}
              />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Dashboard;
