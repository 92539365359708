import React from "react";
import MultiSelect from "../../custom/MultiSelect/MultiSelect";

class ManageHomePageFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channel: [
                  { "id": "select", "name": "Select Options" }, 
                  // { "id": "1", "name": "Website" }, 
                  { "id": "2", "name": "Retail App" }
                ],
      subChannel: [
                    { "id": "select", "name": "Select Options" }, 
                    { "id": "1", "name": "Change Section" }, 
                    { "id": "2", "name": "Change Position"}
                  ],
      section: [
                    { "id": "select", "name": "Select Options" }, 
                    { "id": "1", "name": "Trending Services" }, 
                    { "id": "2", "name": "Workshop Near You" }, 
                    { "id": "3", "name": "Service Combos"},
                    { "id": "4", "name": "Blog Posts And Articles"}
                  ],
      hompageData: {
        channel: [],
        subChannel: [],
        section: []
      },
    };

    this.handleApplyFilter = this.handleApplyFilter.bind(this);
  }
  // componentWillMount() {
  //   if (localStorage.getItem("service_filters") != undefined) {
  //     let filters = JSON.parse(localStorage.getItem("service_filters"));
  //     let channel = filters && filters.channel ? filters.channel.id : "";
  //     let key = channel == 3 ? localStorage.getItem("accessories_categories") : localStorage.getItem("service_data");
  //     if (key != undefined) {
  //       this.state.services = JSON.parse(key);
  //     }
  //   }
  // }
  componentDidMount() {
    if (localStorage.getItem("homepage_filter") != undefined) {
      this.setState({
        hompageData: JSON.parse(localStorage.getItem("homepage_filter")),
      });
    }
  }
  
  handleApplyFilter() {
    this.props.onApplyFilter(this.state.hompageData, "homepage_filter");
    // set data in local storage
    localStorage.setItem("homepage_filter", JSON.stringify(this.state.hompageData));
  }

  render() {
    const { channel, subChannel, section } = this.state;
    return (
      <div className="mainFilterContainer">
        <div className="row-5 margin_10 margin_b_20">
          {
            <React.Fragment>
              <div className="margin_r_16">
                <MultiSelect
                  labelKey="name"
                  checkbox_id="name"
                  options={channel}
                  placeholder="Select Page Type"
                  selectedOptions={this.state.hompageData.channel}
                  onChange={(value) => {
                    this.setState({
                      hompageData: {
                        ...this.state.hompageData,
                        channel: value,
                      }
                    }
                    )
                  }}
                ></MultiSelect>
              </div>

              {/* Sub Channels  */}
              {this.state.hompageData.channel && this.state.hompageData.channel.id &&
                <div className="margin_r_16">
                <MultiSelect
                  labelKey="name"
                  checkbox_id="name"
                  options={subChannel}
                  placeholder="Select Page Type"
                  selectedOptions={this.state.hompageData.subChannel}
                  onChange={(value) => {
                    this.setState({
                      hompageData: {
                        ...this.state.hompageData,
                        subChannel: value,
                      }
                    }
                    )
                  }}
                ></MultiSelect>
              </div>}

              {/* Sections: Only for Change Section  */}
              {this.state.hompageData.subChannel.id && (this.state.hompageData.subChannel.id === "1") &&
                <div className="margin_r_16">
                <MultiSelect
                  labelKey="name"
                  checkbox_id="name"
                  options={section}
                  placeholder="Select Page Type"
                  selectedOptions={this.state.hompageData.section}
                  onChange={(value) => {
                    this.setState({
                      hompageData: {
                        ...this.state.hompageData,
                        section: value,
                      }
                    }
                    )
                  }}
                ></MultiSelect>
              </div>
              }

              {this.state.hompageData.subChannel.id 
              && ((this.state.hompageData.section && this.state.hompageData.section.id) || this.state.hompageData.subChannel.id === "2") 
              &&
                <div className="row-5">
                <div className="blackspan65 weight700 floatright addContentBtnMargin" >
                  <table className="stdtable">
                    <tbody>
                      <tr>
                        <td valign="center">
                          <button id="manageHomepageBtn" className="btn btn_small btncolorprim btncard move" onClick={this.handleApplyFilter}>Apply Filter</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              }
            </React.Fragment>
          }
        </div>
      </div>
    );
  }
}

export default ManageHomePageFilter;
