import React from 'react';
import  "../dashboard/dashboard2chat.css"
import $ from 'jquery';

import PanelLeft from './leftpanel';
import PanelRight from './rightpanel';
import PanelMiddle from './middlepanel';
import Loader from '../loader';

import API from '../api';
import Constants from '../constants';
import {Redirect} from 'react-router-dom';

import firebase from '../firebase';
import ChatUtils from './chat_utils';
import ChatUtilsArchive from './chat_utils_archive';

// var firebase = require('firebase/app');
// require('firebase/auth');
// require('firebase/database');
// require('firebase/messaging');

const axios = require('axios');



const tabList = [{tag:"support_chat" , title : "Support Chat"}, {tag:"orders" , title : "Orders"}, {tag:"fleets" , title : "Fleets"}, {tag:"users" , title : "Users"}, {tag:"services" , title : "Services"}, {tag:"packages" , title : "Packages"}, {tag:"deals" , title : "Deals"}, {tag:"cars" , title : "Cars"}, {tag:"invoices" , title : "Invoices"}, {tag:"garages" , title : "Garages"}, {tag:"crs" , title : "CRs"}, {tag:"bulkorders" , title : "Bulk Orders"}, {tag:"addresses" , title : "Addresses"}];





// firebase.auth().signInWithEmailAndPassword("gomechanic123@gmail.com", "Hitler_12345").catch(function(error) {
//   // Handle Errors here.
//   var errorCode = error.code;
//   var errorMessage = error.message;
//   console.log(error);
//   // ...
// });



firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
	// User is signed in.
	var isAnonymous = user.isAnonymous;
	var uid = user.uid;
	// ...
  } else {
	// User is signed out.
	// ...
  }
  // ...
});


const messaging = firebase.messaging();

//console.log("firebase");


function deleteToken() {
    // Delete Instance ID token.
    // [START delete_token]
    messaging.getToken()
    .then(function(currentToken) {
      messaging.deleteToken(currentToken)
      .then(function() {
        console.log('Token deleted.');
//        setTokenSentToServer(false);
        // [START_EXCLUDE]
        // Once token is deleted update UI.

//        resetUI();
        // [END_EXCLUDE]
      })
      .catch(function(err) {
        console.log('Unable to delete token. ', err);
      });
      // [END delete_token]
    })
    .catch(function(err) {
      console.log('Error retrieving Instance ID token. ', err);
//      showToken('Error retrieving Instance ID token. ', err);
    });
  }



function getToken(){
	messaging.getToken()
  .then(function(currentToken) {
    if (currentToken) {
      //console.log("currentToken");
    	//console.log(currentToken);
    	firebase.database().ref('fcmwebtokens/'+localStorage.getItem("user_id")).set({token: currentToken});
//      sendTokenToServer(currentToken);
//      updateUIForPushEnabled(currentToken);
    } else {
      // Show permission request.
      console.log('No Instance ID token available. Request permission to generate one.');
      // Show permission UI.
//      updateUIForPushPermissionRequired();
//      setTokenSentToServer(false);
    }
  })
  .catch(function(err) {
    console.log('An error occurred while retrieving token. ', err);
//    showToken('Error retrieving Instance ID token. ', err);
//    setTokenSentToServer(false);
  });
}


messaging.requestPermission()
    .then(function() {
      //console.log('Notification permission granted.');

      getToken();
      // TODO(developer): Retrieve an Instance ID token for use with FCM.
      // [START_EXCLUDE]
      // In many cases once an app has been granted notification permission, it
      // should update its UI reflecting this.
//      resetUI();
      // [END_EXCLUDE]
    })
    .catch(function(err) {
      console.log('Unable to get permission to notify.', err);
    });



//   // Get a reference to the database service
    

/*    chatRef.once('value').then(function(snapshot) {
        
      // ...
    });*/




messaging.onTokenRefresh(function() {
    getToken();
  });



messaging.onMessage(function(payload) {
  console.log("Message received. ", payload);
  // ...
});








class SupportChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
		status : "logged in",
      rightContent1: NaN,
      rightContent2: NaN,
    	middleContent: NaN,
    	showMiddleContent: false,
      showRightContent1: false,
      showRightContent2: false,
      rightOrder: NaN,
      showLoader : false,
      showLeftLoader : false,
      searchText : NaN,
		selectedTab: 0,
		selectedRow: "",
		filter_showing : false,
		filter_args : undefined,
		threads_list : [],
		divK : [],
		divM : [],
		divH : [],
    all_models : {},
    archived_threads_list : [],
		archived_divK : [],
		archived_divM : [],
		archived_divH : [],
    archived_all_models : {},
    live: true
    };

    this.handleTabClick = this.handleTabClick.bind(this);
	this.handleRowClick = this.handleRowClick.bind(this);
	this.handleFilterClick = this.handleFilterClick.bind(this);
	this.handleSearchClick = this.handleSearchClick.bind(this);
	this.handleThreadSearch = this.handleThreadSearch.bind(this);

	this.handleGlobalAIMessageClick = this.handleGlobalAIMessageClick.bind(this);
  this.handleGlobalSend = this.handleGlobalSend.bind(this);
  this.handleRightScroll = this.handleRightScroll.bind(this);
  this.handleLeftScroll = this.handleLeftScroll.bind(this);
  this.handleRightOrderClick = this.handleRightOrderClick.bind(this);
  this.handleBackOrderClick = this.handleBackOrderClick.bind(this);
  this.handleHistorySwitchClick = this.handleHistorySwitchClick.bind(this);
  }

  handleGlobalAIMessageClick(mText, mType, selectedTab){
	ChatUtils.sendAIMessage(mText, mType, selectedTab, this)
  }

  handleGlobalSend(mText, selectedTab){
	ChatUtils.sendMessage(mText, selectedTab, this);
  }

  handleThreadSearch(val){
    if(this.state.live){
      ChatUtils.searchThreads(val, this);
    }else{
      ChatUtilsArchive.searchThreads(val, this);
    }
  }

  handleFilterClick() {
	this.setState({filter_showing : !this.state.filter_showing});
  }

  handleSearchClick(args) {
	  console.log("searching");
	  console.log(args);
	  this.setState({filter_args : args, filter_showing : !this.state.filter_showing});
	  API.tabSearchCall(this.state.selectedTab, this, args);
	//this.setState({filter_showing : !this.state.filter_showing});
  }

  handleRowClick(data, tag) {
	console.log(data);
	console.log(tag);
	// this.setState({selectedRow : data.order_id, showRightContent : true, rightContent : data});
  }

  handleTabClick(tag) {
    var that = this;
    if(this.state.live){
      ChatUtils.clickTab(tag, this, this.state.threads_list);
    }else{
      ChatUtilsArchive.clickTab(tag, this, this.state.archived_threads_list);
    }
    API.getChatUserDetails(tag, this);
    API.getOrderDetailsPaginate(tag, [], this);
	// API.tabClickCall(tag, that);
  }

  handleRightScroll(tag, orders) {
    var that = this;
    console.log("scrollHandling");
    API.getOrderDetailsPaginate(tag, orders, this);
	// API.tabClickCall(tag, that);
  }

  handleLeftScroll(live, mine) {
    var that = this;
    console.log("this", this);
    console.log("scrollHandling");
    if(!live){
      API.getSupportChatArchives(this, this.state.archived_threads_list.length, mine, this);
    }
	// API.tabClickCall(tag, that);
  }

  handleRightOrderClick(order) {
    var that = this;
    console.log("RightRightOrder");
    console.log(order);
    this.setState({rightOrder : order});
	// API.tabClickCall(tag, that);
  }

  handleBackOrderClick() {
    var that = this;
    console.log("RightRightOrder");
    this.setState({rightOrder : NaN});
	// API.tabClickCall(tag, that);
  }

  handleHistorySwitchClick(type, mine) {
    var that = this;
    console.log("History");
    console.log(type);
    if(type == "live"){
      this.setState({live : true});
    } else{
      this.setState({live : false});
      if(this.state.archived_threads_list.length == 0){
        API.getSupportChatArchives(this, this.state.archived_threads_list.length, mine, this);
      }
    }
  }

  componentWillReceiveProps(){
    
  }

  componentWillMount() {
    if(localStorage.getItem("access_token") === null){
      this.setState({status : 'not logged in'});
    }else{
      var that = this;
      // that.setState({"showLoader" : true});
      if(localStorage.getItem("statuses") === null || localStorage.getItem("fleets_summary") === null || localStorage.getItem("garages_summary") === null){
        API.getStatuses({headers: {'Authorization': "Bearer " + localStorage.getItem("access_token")}}).then(function (response) {
          console.log(response.data);
          Constants.setStatuses(response.data.data);
          API.get_fleets_summary1({headers: {'Authorization': "Bearer " + localStorage.getItem("access_token")}}).then(function (response) {
            console.log(response.data);
            Constants.setFleetsSummary(response.data.data);
            API.get_garages_summary1({headers: {'Authorization': "Bearer " + localStorage.getItem("access_token")}}).then(function (response) {
              console.log(response.data);
              Constants.setGaragesSummary(response.data.data);
                console.log("oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo");
                if(sessionStorage.getItem("reloaded")){
                  ChatUtils.loadInitial(that, false);
                }else{
                  sessionStorage.setItem("reloaded", true);
                  window.location.reload();
                }
              })
              .catch(function (error) {
              // handle error
              console.log(error);
              })
              .finally(function () {
              // always executed
              });
            })
            .catch(function (error) {
            // handle error
            console.log(error);
            })
            .finally(function () {
            // always executed
            });
          })
          .catch(function (error) {
          // handle error
          console.log(error);
          })
          .finally(function () {
          // always executed
          });
      }else{
        console.log("vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv");
        if(sessionStorage.getItem("reloaded")){
          ChatUtils.loadInitial(that, false);
        }else{
          sessionStorage.setItem("reloaded", true);
          window.location.reload();
        }
      }
    }
  }

  componentDidMount() {

  }

  componentWillUnmount() {
    console.log("UnMOunting");
    // ChatUtils.clearListeners();
    // this.setState({
    //   status : "logged in",
    //     rightContent1: NaN,
    //     rightContent2: NaN,
    //     middleContent: NaN,
    //     showMiddleContent: false,
    //     showRightContent1: false,
    //     showRightContent2: false,
    //     rightOrder: NaN,
    //     showLoader : false,
    //     searchText : NaN,
    //   selectedTab: 0,
    //   selectedRow: "",
    //   filter_showing : false,
    //   filter_args : undefined,
    //   threads_list : [],
    //   divK : [],
    //   divM : [],
    //   divH : [],
    //   all_models : {}
    //   });
  }

  render() {
	  if(this.state.status === "not logged in" || new Date(localStorage.getItem("token_expiration_date")) <= new Date()){
		  localStorage.removeItem("access_token");
		  localStorage.removeItem("token_expiration_date");
		  return <Redirect to="/login"/>
	  }else{
      if((localStorage.getItem("user_roles") && localStorage.getItem("user_roles").indexOf('Support Executive') > -1) || localStorage.getItem("phone") == "9990903321"){
        return(
          <div id="containermainouter1">
            {/* <PanelRight content={this.state.rightContent} showContent={this.state.showRightContent} tag={this.state.selectedTab} selectedTab={this.state.selectedTab} allModels={this.state.all_models} 
              divK={this.state.divK} divM={this.state.divM} divH={this.state.divH}/> */}
            <PanelLeft onTabClick={this.handleTabClick} selectedTab={this.state.selectedTab} archivedThreadsList = {this.state.archived_threads_list} 
              threadsList = {this.state.threads_list} archivedAllModels = {this.state.archived_all_models} allModels = {this.state.all_models} onThreadSearch={this.handleThreadSearch}
              searchText={this.state.searchText} onHistorySwitchClick={this.handleHistorySwitchClick} live={this.state.live} onLeftScroll={this.handleLeftScroll} showLeftLoader={this.state.showLeftLoader}/>
            <PanelMiddle tag={this.state.selectedTab}  selectedTab={this.state.selectedTab} archivedThreadsList = {this.state.archived_threads_list} live={this.state.live}
              threadsList = {this.state.threads_list} archivedAllModels = {this.state.archived_all_models} allModels={this.state.all_models} searchText={this.state.searchText}
              archivedDivK={this.state.archived_divK} archivedDivM={this.state.archived_divM} archivedDivH={this.state.archived_divH} divK={this.state.divK} divM={this.state.divM} divH={this.state.divH} onRowClick={this.handleRowClick} onGlobalAIMessageClick={this.handleGlobalAIMessageClick} onGlobalSend={this.handleGlobalSend}/>
            <PanelRight tag={this.state.selectedTab}  selectedTab={this.state.selectedTab} archivedAllModels = {this.state.archived_all_models} allModels={this.state.all_models} 
              showLoader={this.state.showLoader} rightOrder={this.state.rightOrder} onRightOrderClick={this.handleRightOrderClick}
              content1={this.state.rightContent1} content2={this.state.rightContent2} showContent1={this.state.showRightContent1} showContent2={this.state.showRightContent2} onRightScroll={this.handleRightScroll}
              onBackOrderClick={this.handleBackOrderClick} />
          </div>
        );
      }else{
        return (
          <Redirect to="/"/>
        );
      }
	  }
  }
}


export default SupportChat;
