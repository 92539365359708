import React, { useState, useEffect } from "react";
import "./couponFilter.css";
import MultiSelect from "../custom/MultiSelect/MultiSelect";
import "flatpickr/dist/themes/material_green.css";
import Constants from "../../constants";
function CouponFilter(props) { 
  const [couponFilter, setCouponFilter] = useState({
    name: "",
    limit: 20,
    offset: 0,
    search: "1",
    start_date: "",
    end_date: "",
    discount_type: [],
    discountOn: [],
  });
  const [discountTypeList, setDiscountTypeList] = useState(
    Constants.getDiscountType
  );
  const [discountOn, setDiscountOn] = useState(Constants.getDiscountOn);
  const [showFilterDiv, setFilterDiv] = useState(false);
  const handleApplyFilter = (e) => {
    props.onApplyFilter(couponFilter, "coupon_filters");
  };
  const handelInputChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setCouponFilter({
      ...couponFilter,
      [name]: value,
    });
    localStorage.setItem("coupon_filters", JSON.stringify(couponFilter));
  };
  const handelSelectChange = (value, key) => {
    console.log(value, key);
    setCouponFilter({
      ...couponFilter,
      [key]: value,
    });
  };
  return (
    <div className="mainFilterContainer">
      <div
        className="row-5 margin_10 margin_b_20"
        style={{ gridTemplateColumns: "repeat(7,15%)" }}
      >
        <div className="margin_r_16">
          <input
            type="text"
            className={`field border-box small`}
            style={{ width: "unset" }}
            name="name"
            defaultValue={couponFilter.coupon}
            placeholder="Coupon Name"
            onChange={(e) => {
              handelInputChange(e);
            }}
          />
        </div>
        <div className="margin_r_16">
          <MultiSelect
            options={discountTypeList}
            // multiple
            labelKey="name"
            checkbox_id="id"
            placeholder="Coupon Type"
            disabledItems={[]}
            name="discount_type"
            selectedOptions={couponFilter.discount_type}
            onChange={(e) => {
              handelSelectChange(e, "discount_type");
            }}
          />
        </div>
        <div className="margin_r_16">
          <MultiSelect
            options={discountOn}
            multiple
            labelKey="name"
            checkbox_id="id"
            placeholder="Discount On"
            disabledItems={[]}
            name="discountOn"
            selectedOptions={couponFilter.discountOn}
            onChange={(e) => {
              handelSelectChange(e, "discountOn");
            }}
          />
        </div>
        <div className="margin_r_16">
          <div> 
          <input type="date" name="start_date" id="start_date" placeholder="End Date"  value={couponFilter.start_date} className={`field border-box small`} onChange={(e) => {
              handelInputChange(e);
            }} />
          </div>
        </div>
        <div className="margin_r_16">
          <div>
            <input type="date" name="end_date" id="end_date" placeholder="End Date"  value={couponFilter.end_date} className={`field border-box small`} onChange={(e) => {
              handelInputChange(e);
            }} />
          </div>
        </div>
        <div className="margin_r_16">
          <input
            type="number"
            min={0}
            step={1}
            className={`field border-box small`}
            style={{ width: "unset" }}
            name="limit"
            defaultValue={couponFilter.limit}
            placeholder="limit"
            onChange={(e) => {
              handelInputChange(e); 
            }}
          />
        </div>
        <div>
          <button
            style={{
              background: "rgb(105,118,148,1)",
              color: "rgb(255, 255, 255)",
              marginTop: "5px",
            }}
            className="btn btn_small btncolorprim btncard move"
            onClick={handleApplyFilter}
          >
            Apply Filter
          </button>
        </div>
      </div>
    </div>
  );
}

export default CouponFilter;
