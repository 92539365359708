import React, { useState, useEffect } from 'react'
import deleteIcon from "../../../images/delete_icon.png";
import API from '../../../api';
import { NotificationManager } from "react-notifications";
import { useParams } from 'react-router-dom'

const MCQEdit = ({ filters }) => {
	const [questionData, setQuestionData] = useState([])
	const [count, setCOunt] = useState(0)
	const [loader, setLoader] = useState(false)
	const { level_id, type_id } = useParams()
	let arrayId = []
	let temp = filters?.multipleUsers.filter((user) => {
		if (user.id != 'select-all') {
			arrayId.push(user.id);
		}
	})

    useEffect(() => {
        setLoader(true)
        API.getMCQ(filters.user.id, level_id, 2).then(function (response) {
            if (response.data.status){
                NotificationManager.success(
                    response.data.message,
                    "Successfully",
                    5000
                )
                setQuestionData(response.data.data)
            }
			else{
				NotificationManager.error("Please try again", "Error", 5000);
			}
			setLoader(false)
        })
        .catch(function (error) {
			setLoader(false)
			NotificationManager.error("Please try again", "Error", 5000);
		});
    }, [])

    const handleDelete = (id, userId) => {
        setLoader(true)
        const questionDetails = {
            data: [
                {
                    type: 2,
                    id,
                    user_id: userId,
					multiple_user_id: arrayId,
                }
            ]
        }
        if (typeof id === 'string' && id.includes('new')) {
            const updatedQuestions = questionData.filter((question) => !(question.id === id))
            setQuestionData(updatedQuestions)
            setLoader(false)
        }
        else
            API.deleteQuestionVideos(questionDetails).then((response) => {
                if (response.status) {
                    const updatedQuestions = questionData.filter((question) => !(question.id === id))
                    NotificationManager.success(
                        response.data.message,
                        "Successfully",
                        5000
                    )
                    setQuestionData(updatedQuestions)
                }
				else{
					NotificationManager.error("Please try again", "Error", 5000);
				}
                setLoader(false)
            })
            .catch(function (error) {
				setLoader(false)
                NotificationManager.error("Please try again", "Error", 5000);
            });
    }

	const handleChange = (id, key, value, optionKey) => {
		const updatedValue = questionData.map((each) => {
			if (each.id === id) {
				if (optionKey) {
					const newOptions = each.options.map((option) => {
						if (option.option_id === optionKey)
							return { ...option, [key]: value }
						else
							return option
					})
					return { ...each, ["options"]: newOptions }
				}
				else
					return { ...each, [key]: value }
			}
			else return each
		})
		setQuestionData(updatedValue)
	}

	const saveData = () => {
		const updatedQuestion = questionData.map(({ id, ...each }) => {
			if (typeof id === 'string' && id.includes('new'))
				return each
			else return { id, ...each }
		})
        const isQuestionsValid = updatedQuestion.some((each) => {
            return each.question_text === ""
        })
        const isAnswerValid = updatedQuestion.some((each) => {
            return each.correct_option_id === ""    
        })
        const isOptionsValid = updatedQuestion.some((each) => {
              return each.options.some((option)=>option.option_text === "")  
        })
        if (isOptionsValid || isAnswerValid || isQuestionsValid) {
            if (isOptionsValid)
                NotificationManager.error("The Option Text is required", "Error", 5000);
            if (isAnswerValid)
                NotificationManager.error("The Correct option must be marked", "Error", 5000);
            if (isQuestionsValid)
                NotificationManager.error("The Question Text is required", "Error", 5000);
            return
        }
        setLoader(true)
        const updatedData = {
            type: parseInt(type_id),
            user_id: filters.user.id,
            user_role: filters.user.user_type,
            workshop_id: filters.workshop.id,
            data: updatedQuestion,
			multiple_user_id: arrayId,
        }
        API.postMCQ_VideoUpdate(updatedData).then((res) =>{
            if(res.status){
                NotificationManager.success(
                    res.data.message,
                    "Successfully",
                    5000
                )
             API.getMCQ(filters.user.id, level_id, type_id).then(function (response) {
                if (response.status)
                {
                    setQuestionData(response.data.data)
                 }   
				 else{
					NotificationManager.error("Please try again", "Error", 5000);
				 }
				 setLoader(false)
            })
        }
        }).catch(function (error) {
			setLoader(false)
                NotificationManager.error("Please try again", "Error", 5000);
     })
    }

    const addElement = () => {
        setCOunt(count + 1)
        setQuestionData([
            {
                id: `${count}_new`,
                type: 2,
                level_id: parseInt(level_id),
                test_id: 1,
                is_active: true,
                is_mandatory: true,
                question_text: "",
                correct_option_id: "",
                options: [
                    {
                        option_text: "",
                        option_id: "1",
                    },
                    {
                        option_text: "",
                        option_id: "2",
                    },
                    {
                        option_text: "",
                        option_id: "3",
                    },
                    {
                        option_text: "",
                        option_id: "4",
                    },
                ],
                user_id: filters.user.id,
                user_role: filters.user.user_type,
                workshop_id: filters.workshop.id 
            },
            ...questionData
        ])
    }

	return (
		<>
			{loader ? (
				<>
					<div className="modal-sb"></div>
					<div className="sub-modal-sb"></div>
				</>
			) : (
				""
			)}
			<div className='mcq-questions-final-child'>
				<div className="flex">
					<p className="training-level-heading ">
						Level {level_id} Training
					</p>
				</div>
				<div>
					<div className="training-levels-mb-0">
						<p className="sub-heading">MCQ's questions
						<span className='validation-warning'>MCQ's question, options and correct option are mandatory.</span>
						</p>
						<div className="flex">
							<button className="ml-24 edit-button bg-blue width-small" onClick={() => addElement()}>
								Add question
							</button>
							<button className="ml-24 edit-button bg-blue width-small" onClick={() => saveData()}>
								Save
							</button>
						</div>
					</div>
				</div>
				{questionData.map((question,index) => {
					return (

						<div className="form-wrapper ">
							<div className="question-text-wrapper flex">
								<input
									className="question-text"
									type="text"
									name="firstQuestionText"
									placeholder={`Question ${index+1}`}
									id="firstQuestionText"
									value={question.question_text}
									onChange={(e) => handleChange(question.id, 'question_text', e.target.value)}
								/>
								<div className='mt-32'>
									<input
										type="checkbox"
										className="ta-toggle-switch-checkbox"
										name={question.id}
										id={question.id}
										value={question.is_mandatory}
										onChange={(e) => handleChange(question.id, "is_mandatory", !(question.is_mandatory))}
									/>
									<label
										className="ta-toggle-switch-label"
										htmlFor={question.id}
									>
										<span className={
											question.is_mandatory === true
												? "ta-toggle-switch-inner"
												: "set-false-toggle-value"
										} />
										<span className="ta-toggle-switch-switch" />
									</label>
									{/* delete button  */}
									<button type="submit" className="delete-icon" onClick={() => handleDelete(question.id, question.user_id)}>
										<img src={deleteIcon} alt="Delete Icon" />
									</button>
								</div>
							</div >
							<div className="option-wrapper">
								{question.options.map((option, index) => {
									return (
										<div key={option.option_id} className="single-option  flex">
											<input
												type="radio"
												// id="q1-op1"
												name={option.id}
												className="check-box"
												checked={question.correct_option_id == option.option_id}
												onChange={
													(e) => handleChange(question.id, "correct_option_id", option.option_id)
												}
												id={`${option.option_text}${index}`}
											/>
											<label for={`${option.option_text}${index}`} className="check-box-label"></label>
											<p>
												<input className="option-text" placeholder={`Option ${index+1}`} type="text" value={option.option_text} onChange={
													(e) => handleChange(question.id, "option_text", e.target.value, option.option_id)
												} />
											</p>
										</div>
									)
								})}
							</div>
						</div >
					)
				})}
				< div className="horizontal-line" ></div >
			</div >
		</>
	)
}

export default MCQEdit;