import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import API from "../../api";
import Loader from "../../loader";
import MilesFilter from "./MilesFilter/milesFilter";
import "flatpickr/dist/themes/material_green.css";
import "./Miles.css";
import Constants from "../../constants";

const Miles = (props) => {
  const [mileOrder, setMileOrder] = useState("");
  const [loader, setLoader] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [milesInfo, setMilesInfo] = useState([]);
  const [refundMiles, setRefundMIles] = useState(false);
  const [selectCar, setSelectCar] = useState(-1);
  const [filter, setFilter] = useState("");

  function callSearchAPI() {
    const filter = JSON.parse(localStorage.getItem("miles_filters"));
    API.getMilesUserDetail(filter.mobile_number)
      .then(function (response) {
        if (response.status) {
          setUserInfo(response.data.data);
          localStorage.setItem("miles_data", JSON.stringify(response.data));
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  function updateMilesRate() {
    let obj = {};
    obj.data = milesInfo;
    setLoader(true);
    API.updateMilesSegment(obj)
      .then(function (response) {
        if (response.status) {
          NotificationManager.success(
            response.data.message,
            "Successfully",
            5000
          );
        }
        setLoader(false);
      })
      .catch(function (error) {
        // handle error
        setLoader(false);
        console.log(error);
        NotificationManager.error("Please try again", "Error", 5000);
      });
  }

  function orderRefund() {
    if (mileOrder == "") {
      NotificationManager.error(`Enter Order ID`, "Error", 5000);
      return false;
    }
    const data = {
      order_id: mileOrder,
    };
setLoader(true);
    API.cancleMiles(data)
      .then(function (response) {
        if (response.data.status) {
          setLoader(false);
          NotificationManager.success(
            `Miles Order Cancelled`,
            "Successfully",
            5000
          );
        } else {
          NotificationManager.error(
            response.data.message,
            "Successfully",
            5000
          );
        }
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating the serviceTag details");
      });
  }
  function userInfoPass(value) {
    setUserInfo(value.data);
  }
  function onApplyFilter(data) {
    setLoader(true);
    if (data && data.segments) {
      setUserInfo([]);
      setRefundMIles(false);
      let arrayCopy = data.segments
      if (arrayCopy[0]?.id === "select-all") {
        arrayCopy.shift();
      }
      API.getMilesSegmentInfo(arrayCopy[0]?.name)
        .then(function (response) {
          setLoader(false);
          if (response.status) {
            let finalData = []
            arrayCopy.forEach((element) => {
              finalData.push(element.name)
            });
            response.data.data.name = finalData
            setMilesInfo(response.data.data);
            localStorage.setItem("miles_data", JSON.stringify(response.data));
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          setLoader(false);
        });
    } else if (data && data.id === 3) {
      setLoader(false);
      setRefundMIles(true);
      setUserInfo([]);
      setMilesInfo([]);
    } else {
      setLoader(false);
      setRefundMIles(false);
      setMilesInfo([]);
    }
  }
  function handelChange(e) {
    const { name, value } = e.target;
    setMilesInfo((prevState) => ({
      ...prevState,
      [name]: value ? value : 0,
    }));
    // console.log(milesInfo);
  }
  function carSelection(event) {
    setSelectCar(event.target.value);
  }

  function mileStatusUpdate() {
    if (selectCar < 0) {
      NotificationManager.error(`Please Select Car`, "Error", 5000);
      return false;
    }
    const data = { ...userInfo[selectCar] };
    setLoader(true);
    API.changeMileStatus(data)
      .then(function (response) {
        if (response.status) {
          setLoader(false);
          // let userData = userInfo
          // userData[selectCar] = response.data
          // console.log(userData)
          // setUserInfo([...userData])
          callSearchAPI();
        }
        NotificationManager.success(`Mile Status Update`, "Successfully", 5000);
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating the serviceTag details");
      });
  }

  function getDate(value) {
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    return year + "/" + month + "/" + day;
  }
  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <MilesFilter
          setMilesFilter={setFilter}
          onApplyFilter={onApplyFilter}
          userInfoPass={userInfoPass}
        />
      </div>
      {loader ? (
        <>
          <div className="modal-sb"></div>
          <div className="sub-modal-sb"></div>
        </>
      ) : (
        ""
      )}
      {filter ? (
        <>
          {filter.id === 1 && userInfo && userInfo.length > 0 && (
            <>
              <div className="mainContainer">
                <div className="manageTimeSlot">Miles Info</div>
                <div className="innerBox">
                  <div className="serviceTagDetailsContainer">Select Car :</div>
                  <select
                    value={selectCar}
                    style={{ marginBottom: "1.5rem" }}
                    placeholder="Select User's Car"
                    onChange={carSelection}
                  >
                    <option value="-1">Select Car </option>
                    {userInfo.length &&
                      userInfo.map((car, index) => {
                        return (
                          <option value={index}>{`${car.car_tbl.brand} - ${
                            car.car_tbl.model
                          } - ${car.registration_no} ${
                            car.amc_data ? "--Miles" : ""
                          }`}</option>
                        );
                      })}
                  </select>
                  <div className="serviceTagContainer">
                    <table className="milesTable ">
                      <caption> Miles User Info</caption>
                      <tr>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                      </tr>
                      {userInfo.length && (
                        <tr>
                          <td>
                            {selectCar > -1 ? userInfo[selectCar].name : "NA"}
                          </td>
                          <td>
                            {selectCar > -1 ? userInfo[selectCar].phone : "NA"}
                          </td>
                          <td>
                            {selectCar > -1 ? userInfo[selectCar].email : "NA"}
                          </td>
                        </tr>
                      )}
                    </table>
                    <table className="milesTable ">
                      <caption>User's Car Info</caption>
                      <tr>
                        <th> Registration ID</th>
                        <th>Car Type</th>
                        {/* <th>Miles Purchased</th> */}
                        <th>Expires At</th>
                        <th>Miles Status</th>
                      </tr>
                      {userInfo.length && (
                        <tr>
                          <td>
                            {selectCar > -1
                              ? userInfo[selectCar].registration_no
                              : "NA"}
                          </td>
                          <td>
                            {selectCar > -1
                              ? userInfo[selectCar].car_tbl.segment
                              : "NA"}
                          </td>
                          <td>
                            {selectCar > -1 && userInfo[selectCar].amc_data
                              ? Constants.get_DateString1Date(
                                  userInfo[selectCar].amc_data.expires_at.$date
                                )
                              : "NA"}
                          </td>
                          {/* <td>key:amc_data</td> */}
                          <td>
                            {selectCar > -1
                              ? userInfo[selectCar].amc_data
                                ? "Active"
                                : "Inactive"
                              : "NA"}
                          </td>
                        </tr>
                      )}
                    </table>
                    <div
                      className="serviceTagDetailsContainer"
                      role="button"
                      style={{ justifyContent: "center" }}
                    >
                      {userInfo.length && (
                        <div
                          className="btn btn_small btncolorprim btncard move"
                          onClick={() => {
                            mileStatusUpdate();
                          }}
                        >
                          {/* Change Status */}
                          {selectCar > -1
                            ? userInfo[selectCar].amc_data
                              ? "Deactivate Miles"
                              : "Activate Miles"
                            : "Change Status"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {filter.id === 2 &&
            !!milesInfo &&
            !!milesInfo.material_cost && (
              <div className="mainContainer">
                <div className="manageTimeSlot">Miles Segment Rate</div>
                <div className="innerBox">
                  <div className="order-refund">
                    <div className="serviceTagDetailsContainer maskingWalletContainer">
                      <input
                        type="text"
                        maxLength="30"
                        placeholder="Order ID"
                        className={`dateInputBox field border-box small`}
                        value={milesInfo.material_cost}
                        name="material_cost"
                        onChange={(e) => {
                          handelChange(e);
                        }}
                        style={{ width: "20rem" }}
                      />
                    </div>
                    <div className="serviceTagDetailsContainer" role="button">
                      <div
                        className="btn btn_small btncolorprim btncard move"
                        onClick={() => {
                          updateMilesRate();
                        }}
                      >
                        Update
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          {filter.id === 3 && (
            <div className="mainContainer">
              <div className="manageTimeSlot">Refund Miles</div>
              <div className="innerBox">
                <div className="order-refund">
                  <div className="serviceTagDetailsContainer maskingWalletContainer">
                    <input
                      type="text"
                      maxLength="30"
                      placeholder="Order ID"
                      className={`dateInputBox field border-box small`}
                      value={mileOrder}
                      onChange={(e) => {
                        setMileOrder(
                          e.target.value ? String(e.target.value) : ""
                        );
                      }}
                      style={{ width: "20rem" }}
                    />
                  </div>
                  <div className="serviceTagDetailsContainer" role="button">
                    <div
                      className="btn btn_small btncolorprim btncard move"
                      onClick={() => {
                        orderRefund();
                      }}
                    >
                      Refund
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          className="emptyMiddleMain centeralign weight400 blackspan56"
          Style={{ position: "static" }}
        >
          Please apply filters to view Miles details
        </div>
      )}
    </>
  );
};

export default Miles;
