import React from "react";
import { useEffect, useState } from "react";
import API from "../api";
import MultiSelect from "./custom/MultiSelect/MultiSelect";
import Constants from "../constants";
import { NotificationManager } from "react-notifications";
import Moment from "react-moment";
import moment from "moment";
import "./pricingCms.css";

function CouponCrud(props) {
  /*
        id: parseInt(this.$route.params.id),  
  */
  const [coupon, setCoupon] = useState({
    // common keys
    applicable_per_order: null,
    code: "",
    coupon_type: "",
    description: "",
    dis_on: [],
    discount_type: "",
    end_time: "",
    lmt_per_user: "",
    location_ids: [],
    max_discount_amount: "",
    minimum_order: "",
    over_all_lmt: "",
    overall_lmt_used: 0,
    package_id: [],
    percentage: "",
    service_id: [],
    start_time: "",
    // cms coupon specific keys
    package_ids: null,
    service_ids: null,
    discount_on: null,
    location_id: null,
    discount_type_obj: null,
  });
  
  const [discountOnList, setDiscountOnList] = useState(Constants.getDiscountOn);
  const [couponTypeList, setcouponTypeList] = useState(Constants.getCouponType);
  const [discountTypeList, setdiscountTypeList] = useState(Constants.getCouponTypeDuplicate);
  const [serviceList, setServiceList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [locationList, setLocationList] = useState(Constants.getCityData);
  const [initialLoader, setInitialLoader] = useState(false);
  const splitToObject = (data, key) => {
    let arr = data[key].split(",");
    let newObj = arr.map((item) => {
      let obj = { id: "", name: "" };
      obj.id = item;
      obj.name = item;
      return obj;
    });
    console.log(newObj);
    return { ...data, [key]: newObj };
  };
  useEffect(() => {
    if (props.rightContent.id) {
      setInitialLoader(true);
      API.getCouponDetails(props.rightContent.id)
        .then(function (response) {
          console.log(response.data);
          let responseData = { ...response.data.data };
          responseData = splitToObject(responseData, "discount_on"); 
          setCoupon(responseData);
          if(responseData && responseData.service_id_obj)
            setServiceList([...responseData.service_id_obj]);
          setInitialLoader(false);
          NotificationManager.success(
            "Successfully",
            `Coupon Info Called`,
            5000
          );
        })
        .catch(function (error) {
          NotificationManager.error("Please try again", "Error", 5000);
          console.log(error, "Error in updating the product status");
          setInitialLoader(false);
        });
      // return () => {};
    }
    // get packages
    API.getCouponPackages()
        .then(function (response) {
          if(response.data.data && response.data.data.length>0){
            let packages = [];
            response.data.data.map((val)=>{
              let temp = {}
              temp['id'] = val['id'];
              temp['name'] = val['name'];
              packages.push(temp);
            })
            setPackageList(packages);
          }
          // setPackageList(response.data.data);
        })
        .catch(function (error) {
          NotificationManager.error("Error in fetching package list", "Error", 5000);
          // console.log(error, "Error in updating the product status");
        });
    // fetch package

    searchService();
    // fetch services 

    // fetch cities
    API.getCouponCities()
        .then(function (response) {
          console.log(response.data);
          if(response.data.data && response.data.data.length>0){
            let cities = [];
            response.data.data.map((val)=>{
              let temp = {}
              temp['id'] = val['id'];
              temp['name'] = val['name'];
              cities.push(temp);
            })
            setLocationList(cities);
          }
          // setLocationList([...response.data.data, {id: response.data.data.id, name: response.data.data.name}]);
        })
        .catch(function (error) {
          NotificationManager.error("Error in fetching city list", "Error", 5000);
          // console.log(error, "Error in updating the product status");
        });
  }, []);

  const handelInputChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    console.log(name, value);
    setCoupon({
      ...coupon,
      [name]: value,
    });
  };
  const handelIntInputChange = (e) => {
    let value = e.target.value ? parseInt(e.target.value) : '';
    let name = e.target.name;
    console.log(name, value);
    setCoupon({
      ...coupon,
      [name]: value,
    });
  };
  const handelCED = (e) => {
    // console.log(props.middleExtraContent.type);
    // console.log("coupon data");
    // console.log(coupon);
    if (!validate()) {
    let newStartDate = moment(coupon.start_time).utcOffset(coupon.start_time);
    let newEndDate = moment(coupon.end_time).utcOffset(coupon.end_time);
    let discount_on_str = coupon.discount_on && coupon.discount_on.length > 0 ? coupon.discount_on[0].id === 'select-all' ?  coupon.discount_on.slice(1).map((obj) => obj.id).join(","): coupon.discount_on.map((obj) => obj.id).join(",") :""
    let discount_id_arr = coupon.discount_on ? coupon.discount_on.map((obj) => obj.id): []
    let service_id_arr = coupon.service_id_obj ? coupon.service_id_obj.map((obj) => obj.id) : [];
    let package_id_arr = coupon.package_id_obj ? coupon.package_id_obj.map((obj) => obj.id) : [];
    let location_id_arr = coupon.package_id_obj && coupon.location_id_obj? coupon.location_id_obj.map((obj) => obj.id) : [];
    let location_id_str = ""
    let package_id_str = ""
    let service_id_str = ""
    if(service_id_arr[0]==='select-all'){
      // service_id_arr = service_id_arr.slice(1)
      //coupon = {...coupon,service_id_obj: coupon.service_id_obj.slice(1)}
      service_id_str = service_id_arr ? service_id_arr.slice(1).join(",") : ""
    }
    else{
      service_id_str = service_id_arr ? service_id_arr.join(",") : ""
    }
    if(package_id_arr[0]==='select-all'){
      // package_id_arr = package_id_arr.slice(1)
      //coupon = {...coupon,package_id_obj: coupon.package_id_obj.slice(1)}
      package_id_str = coupon.package_id_obj ? coupon.package_id_obj.slice(1).map((obj) => obj.id).join(",") : ""
    }
    else{
      package_id_str = coupon.package_id_obj ? coupon.package_id_obj.map((obj) => obj.id).join(",") : ""
    }
    if(discount_id_arr && discount_id_arr[0]==='select-all'){
      // discount_id_arr = discount_id_arr.slice(1)
      //coupon = {...coupon,location_id_obj: coupon.location_id_obj.slice(1)}
      discount_id_arr = discount_id_arr ? discount_id_arr.slice(1).join(",") : ""
      
    }
    else{
      discount_id_arr = discount_id_arr ? discount_id_arr.map((obj) => obj.id).join(",") : ""
    }
    if(location_id_arr && location_id_arr[0]==='select-all'){
      // location_id_arr = location_id_arr.slice(1)
      //coupon = {...coupon,location_id_obj: coupon.location_id_obj.slice(1)}
      location_id_str = location_id_arr ? location_id_arr.slice(1).join(",") : ""
    }
    else{
      location_id_str = location_id_arr ? location_id_arr.join(",") : ""
    }
    let data = {
      ...coupon,
      applicable_per_order: null,
      percentage: parseInt(coupon.percentage),
      discount_type: coupon.discount_type_obj.name,
      discount_on: discount_on_str,
      dis_on: discount_id_arr,
      coupon_type: coupon && coupon.coupon_type_obj ? coupon.coupon_type_obj.name : "",
      start_time: newStartDate.format("YYYY-MM-DD HH:mm:ss"),
      end_time: newEndDate.format("YYYY-MM-DD HH:mm:ss"),
      location_ids: location_id_arr,
      package_id: package_id_arr,
      service_id: service_id_arr,
      location_id: location_id_str,
      service_ids: service_id_str,
      package_ids: package_id_str,
    };
    // return;
    // delete data.discount_type_obj;
    // delete data.coupon_type_obj;
    // delete data.service_id_obj;
    // delete data.package_id_obj;
    // delete data.location_id_obj;
    setInitialLoader(true);
    switch (props.middleExtraContent.type) {
      case "edit":
        API.updateCoupon(data)
          .then(function (response) {
            console.log(response.data, "response");
            setInitialLoader(false);
            NotificationManager.success(`Data saved`, "Successfully", 5000);
          })
          .catch(function (error) {
            setInitialLoader(false);
            NotificationManager.error("Please try again", "Error", 5000);
            console.log(error, "Error in updating the product status");
          });
        break;
      default:
        delete data.id
        API.createCoupon(data)
          .then(function (response) {
            setInitialLoader(false);
            console.log(response.data, "response");
            NotificationManager.success(`Data saved`, "Successfully", 5000);
          })
          .catch(function (error) {
            setInitialLoader(false);
            NotificationManager.error("Please try again", "Error", 5000);
            console.log(error, "Error in updating the product status");
          });
        break;
      }
    }
};
  const handelSelectChange = (value, key) => {
    setCoupon({
      ...coupon,
      [key]: value,
    });
  };

  const searchService = (str) => {
    // if (str.length !== 0 && str.length % 3 === 0) {
      API.getCouponServices(str)
        .then(function (response) {
          setServiceList(response.data.data);
          // NotificationManager.success(
          //   "Successfully",
          //   `Coupon Info Called`,
          //   5000
          // );
        })
        .catch(function (error) {
          NotificationManager.error("Error in fetching services", "Error", 5000);
          console.log(error, "Error in updating the product status");
        });
    // }
  };

  const validate = () => {
    let error = {};
    if (!coupon.code || coupon.code.length < 3 || coupon.code.length > 20) {
      error.name = "Name is required and should be between 3 to 20 characters";
    }
    if (!coupon.percentage || coupon.percentage > 100) {
      error.percentage = "Percentage is required and should be less than 100";
    }
    if (!coupon.minimum_order){
      error.minimum_order = "Minimum order is required";
    }
    if (!coupon.max_discount_amount){
      error.max_discount_amount = "Max discount amount is required";
    }
    // if (!coupon.start_time) {
    //   error.start_time = "Start time is required";
    // }
    if (!coupon.end_time) {
      error.end_time = "End time is required";
    }
    if (!coupon.discount_on) {
      error.discount_on = "Discount on is required";
    }
    if (!coupon.discount_type_obj) {
      error.discount_type = "Discount type is required";
    } else {
      if (coupon.discount_type_obj.name === "SERVICE") {
        if (!coupon.service_id_obj) {
          error.services = "Service is required";
        }
      } else if (coupon.discount_type_obj.name === "PACKAGE") {
        if (!coupon.package_id_obj) {
          error.package = "Package is required";
        }
      } else if (coupon.discount_type_obj.name === "COMBO") {
        if (!coupon.service_id_obj) {
          error.services = "Service is required";
        }
        if (!coupon.package_id_obj) {
          error.package = "Package is required";
        }
      }
    }
    if (moment(coupon.start_time).isAfter(moment(coupon.end_time))) {
      error.end_time = "End time should be greater than start time";
    }
    if (!coupon.coupon_type_obj) {
      error.coupon_type = "Coupon type is required";
    }
    // if (!coupon.location_id_obj) {
    //   error.location_ids = "Location is required";
    // }
    if(!coupon.over_all_lmt || coupon.over_all_lmt < 0){
      error.over_all_lmt = "Over all limit should be greater than 0";
    }    
    for (const message in error) {
      if (Object.hasOwnProperty.call(error, message)) { 
        NotificationManager.error(`${error[message]}`, "Incomplete Data", 5000);
        
      }
    }
    return Object.keys(error).length ? true : false;
  }

  return (
    <>
    {initialLoader ? (
            <>
              <div className="modal-cc-sb"></div>
              <div className="sub-modal-sb"></div>
            </>
          ) : (
            ""
          )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className="main-container"
      >
        <fieldset className="grid-container grid-row">
          <legend>Coupon Details</legend>
          <span>
            <label htmlFor="code" className="label-required">
              Coupon Code
            </label>
            <input
            className={`field border-box small`}
              type="text"
              id="code"
              name="code"
              value={coupon.code}
              maxLength="20"
              onChange={(e) => handelInputChange(e)}
            />
          </span>
          <span>
            <label htmlFor="coupon-type" className="label-required">
              Coupon Type
            </label>
            <MultiSelect
              // multiple
              options={couponTypeList}
              labelKey="name"
              checkbox_id="name"
              placeholder="Coupon Type"
              disabledItems={[]}
              name="coupon_type"
              id="coupon-type"
              selectedOptions={coupon.coupon_type_obj ? coupon.coupon_type_obj : []}
              onChange={(e) => {
                handelSelectChange(e, "coupon_type_obj");
              }}
            />
          </span>
          <span>
            <label htmlFor="percentage" className="label-required">
              Flat Discount/Percentage
            </label>
            <input
              type="number"
              id="percentage"
              name="percentage"
              className={`field border-box small`}
              defaultValue={coupon.percentage}
              max={100}
              min={0}
              onChange={(e) => handelIntInputChange(e)}
            />
          </span>
          <span>
            <label htmlFor="min-order" className="label-required">
              Min Order
            </label>
            <input
              type="number"
              id="min-order"
              name="minimum_order"
              className={`field border-box small`}
              value={coupon && coupon.minimum_order ? coupon.minimum_order : ""}
              min={0}
              onChange={(e) => handelIntInputChange(e)}
            />
          </span>
          <span>
            <label htmlFor="max-discount" className="label-required">
              Max Discount
            </label>
            <input
              type="number"
              id="max-discount"
              name="max_discount_amount"
              className={`field border-box small`}
              min={0}
              value={coupon.max_discount_amount}
              onChange={(e) => handelIntInputChange(e)}
            />
          </span>
        </fieldset>
        <fieldset className="grid-container">
          <legend>Dates</legend>
          <span>
            <label htmlFor="start-date">Start Date</label>
            <br />
            <input
              type="datetime-local"
              className={`field border-box small`}
              style={{ width: "100%" }}
              id="start-date"
              name="start_time"
              value={coupon.start_time}
              onChange={(e) => handelInputChange(e)}
            />
          </span>
          <span>
            <label htmlFor="expire-date" className="label-required">
              Expire Date
            </label>
            <br />
            <input
              type="datetime-local"
              className={`field border-box small`}
              style={{ width: "100%" }}
              id="expire-date"
              name="end_time"
              value={coupon.end_time}
              onChange={(e) => handelInputChange(e)}
            />
          </span>
        </fieldset>
        <fieldset className="grid-container">
          <legend>Applicable On</legend>
          <span>
            <label htmlFor="discount-on">Discount On</label>
            <br />
            <MultiSelect
              multiple
              options={discountOnList}
              labelKey="name"
              checkbox_id="id"
              placeholder="Discount On"
              disabledItems={[]}
              name="discount_on"
              id="discount-on"
              selectedOptions={coupon.discount_on ? coupon.discount_on : []}
              onChange={(e) => {
                handelSelectChange(e, "discount_on");
              }}
            />
          </span>
        </fieldset>
        <fieldset className="grid-container">
          <legend>Type</legend>
          <span>
            <label htmlFor="discount-type" className="label-required">
              Discount Type
            </label>
            <MultiSelect
              // multiple
              options={discountTypeList}
              labelKey="name"
              checkbox_id="name"
              placeholder="Discount Type"
              disabledItems={[]}
              name="discount_type"
              id="coupon-type"
              selectedOptions={coupon && coupon.discount_type_obj ? coupon.discount_type_obj: []}
              onChange={(e) => {
                handelSelectChange(e, "discount_type_obj");
              }}
            />
          </span>
        </fieldset>
        <fieldset className="grid-container">
          <legend>Limit</legend>
          <span>
            <label htmlFor="overall-limit">Overall Limit</label>
            <input
              type="number"
              id="overall-limit"
              name="over_all_lmt"
              value={coupon.over_all_lmt}
              className={`field border-box small`}
              min={0}
              onChange={(e) => handelIntInputChange(e)}
            />
          </span>
          <span>
            <label htmlFor="limit-user">Limit User</label>
            <input
              type="number"
              id="limit-user"
              name="lmt_per_user"
              value={coupon.lmt_per_user}
              className={`field border-box small`}
              min={0}
              onChange={(e) => handelIntInputChange(e)}
            />
          </span>
        </fieldset>
        <fieldset className="grid-container">
          <legend>Other Info</legend>
          <span>
            <label htmlFor="services">Services</label>
            <MultiSelect
              multiple
              disabled={coupon && coupon.discount_type_obj && coupon.discount_type_obj.id == 2 ? true : false}
              options={serviceList && Array.isArray(serviceList) ? [{ name: "Select All", id: "select-all" },...serviceList]:[]}
              labelKey="name"
              checkbox_id="id"
              placeholder="Coupon Services"
              disabledItems={[]}
              name="service_ids"
              id="services"
              selectedOptions={coupon.service_id_obj ? coupon.service_id_obj : []}
              onChange={(e) => {
                handelSelectChange(e, "service_id_obj");
              }}
              onType={searchService}
            />
          </span>
          <span>
            <label htmlFor="packages">Packages</label>
            <MultiSelect
              multiple
              disabled={coupon && coupon.discount_type_obj && coupon.discount_type_obj.id == 1 ? true : false}
              options={packageList ? [{ name: "Select All", id: "select-all" },...packageList] : coupon.package_id_obj}
              labelKey="name"
              checkbox_id="name"
              placeholder="Packages"
              disabledItems={[]}
              name="package_ids"
              id="package"
              selectedOptions={coupon && coupon.package_id_obj ? coupon.package_id_obj : []}
              onChange={(e) => {
                handelSelectChange(e, "package_id_obj");
              }}
            />
          </span>
          <span>
            <label htmlFor="location">Location</label>
            <MultiSelect
              multiple
              options={[{ name: "Select All", id: "select-all" },...locationList]}
              labelKey="name"
              checkbox_id="id"
              placeholder="Location"
              disabledItems={[]}
              name="location_id"
              id="location"
              selectedOptions={coupon.location_id_obj ? coupon.location_id_obj : []}
              onChange={(e) => {
                handelSelectChange(e, "location_id_obj");
              }}
            />
          </span>
        </fieldset>
        <fieldset className="grid-container">
          <legend>Description</legend>
          <span>
            <label htmlFor="coupon-description">Coupon Description</label>
            <textarea
              name="description"
              id="description"
              cols="30"
              rows="10"
              value={coupon.description}
              onChange={(e) => handelInputChange(e)}
            />
          </span>
        </fieldset>
        <span className="button-group">
          {props.middleExtraContent.type &&
          props.middleExtraContent.type === "duplicate" ? (
            <button
              className="duplicate"
              onClick={(e) => {
                handelCED(e);
              }}
            >
              Duplicate
            </button>
          ) : props.middleExtraContent.type === "edit" ? (
            <button
              className="save"
              onClick={(e) => {
                handelCED(e);
              }}
            >
              Update
            </button>
          ) : (
            <button
              className="save"
              onClick={(e) => {
                handelCED(e);
              }}
            >
              Create
            </button>
          )}
        </span>
      </form>
    </>
  );
}

export default CouponCrud;
