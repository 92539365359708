import React, { useState, useEffect, useRef } from "react";
import { NotificationManager } from "react-notifications";
import API from "../../api";
import "flatpickr/dist/themes/material_green.css";
import "./ServiceTag.css";

function WarrantyCoverage(props) {
  if (props && props.onApplyFilter && props.onApplyFilter.filters && props.onApplyFilter.filters.warrantyCoverageData)
  {console.log(props.onApplyFilter.filters.warrantyCoverageData, 'WarrantyCoverage props');}

  const [warrantyData, setWarrantyData] = useState(null);
  const [warrantyLoader, setWarrantyLoader] = useState(false);

  useEffect(() => {
    setWarrantyData(props.onApplyFilter.content);
  }, [props.onApplyFilter.content])

  function handleWarrantyCoverageData(event, key, index, listIndex) {
    let obj = { ...warrantyData };
    let updatedString = event.target.value;
    obj["warranty_terms_and_conditions"][index]["list"][listIndex]  = updatedString;
    
    setWarrantyData(obj);
  }

  function handleWarrantyCoverageUpdate() {
    let payloadObj = {};
    payloadObj["data"] = warrantyData;

    setWarrantyLoader(true);
    
    API.updateWarrantyCoverageData(payloadObj)
      .then(function (response) {
        if (response.status) {
          NotificationManager.success("Data Updated", "Successfully", 5000);
          setWarrantyLoader(false);
        }
      })
      .catch(function (error) {
        // handle error
        setWarrantyLoader(false);
        NotificationManager.error("Please try again", "Error", 5000);
      })
  }

  return (
    <>
      {(props.loader || warrantyLoader) ? (
        <>
          <div className="wp-modal-sb"></div>
          <div className="sub-modal-sb"></div>
        </>
      ) : (
        ""
      )}
      <div className="mainContainer">
        {warrantyData && warrantyData.retail_name &&
          <div className="manageTimeSlot">
            {warrantyData.retail_name}
        </div>}
        <div className="innerBox">
          {warrantyData && warrantyData.retail_name &&
          <>
            {warrantyData.warranty_terms_and_conditions &&
              warrantyData.warranty_terms_and_conditions.map((data, index) => {
                return (
                  <div key={data["sub_heading"] + index} style={{paddingTop: "1rem"}} >
                    <span style={{fontSize: "1rem", fontWeight: "650", marginTop: "1rem"}}>{data["sub_heading"]}</span>
                    {data['list'].map((listData, listIndex) => {
                      return (
                        <textarea 
                          id="listItem" 
                          name="title" 
                          rows="4" 
                          cols="50" 
                          defaultValue={listData}
                          onBlur={(e) => handleWarrantyCoverageData(e, data["sub_heading"], index, listIndex)}
                          style={{ marginTop: "1.5rem", height: "60pt" }}
                          ></textarea>
                      )
                    })
                    }
                  </div>
                )
              })
            }
          </>
          }
          <div
            style={{
              textAlign: "right",
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
          >
            <button className="button-update btncard move" onClick={() => handleWarrantyCoverageUpdate()}>Update</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default WarrantyCoverage;