import firebase from 'firebase'
import Constants from './constants'

firebase.initializeApp(Constants.getFirebaseConfig());

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/serviceWorker.js')
    .then(function(registration) {
        firebase.messaging().useServiceWorker(registration);
    }).catch(function(err) {
      console.log('Service worker registration failed, error:', err);
    });
  }

export default firebase;

const data = {
  cities: [],
  brands: [],
  categories: [],
  models: {},
  fuelTypes: [],
  deals: [],
  city_wise_regions: null,
  pageTypes: [],
  services: [],
  slotAmount: [],
}

const db = firebase.database();

export const getMasterData = () => {
    const dbPrefix = localStorage.getItem("country") == "malaysia" ? '/cms/filterData/malaysia/' : '/cms/filterData/india/';
    if(localStorage.getItem("access_token")){
      const firebaseCities = db.ref(`${dbPrefix}cities`);
      const firebaseSlotAmount = db.ref(`${dbPrefix}slotAmount`);

      if(!localStorage.getItem("city_data")){
        firebaseCities.on(
            'value',
            (snapshot) => {
                console.log('cities fetched from firebase');
                // this.setState({city:cityOptions});
                data.cities = snapshot.val();
                localStorage.setItem("city_data",JSON.stringify([ ...snapshot.val()]));
            },
            (err) => {
            console.log('cities read failed from firebase', err.code);
            },
        );
      }else{
          data.cities = JSON.parse(localStorage.getItem("city_data"));
      }


      if(!localStorage.getItem("slot_amount")){
        firebaseSlotAmount.on(
            'value',
            (snapshot) => {
                console.log('slot amount fetched from firebase');
                // this.setState({city:cityOptions});
                data.slotAmount = snapshot.val();
                localStorage.setItem("slot_amount",JSON.stringify(snapshot.val()));
            },
            (err) => {
            console.log('slot amount read failed from firebase', err.code);
            },
        );
      }else{
          data.slotAmount = JSON.parse(localStorage.getItem("slot_amount"));
      }
    }
}

export const firebaseData = data;