import React from 'react';
// import "./dashboard/dashboard.css"
class MiddleUpper extends React.Component {
    constructor(props) {
        super(props);
        this.handleFilterClick = this.handleFilterClick.bind(this);
        this.handleSummaryClick = this.handleSummaryClick.bind(this);
    }
  
    handleFilterClick(){
        console.log("clicking");
        this.props.onFilterClick();
    }

    handleSummaryClick(){
        
    }

    render() {

        switch(this.props.tag){
            case 'orders':
                    return (
                        <React.Fragment>
                        <div className="top border-box clearfix leftalign">
                            <div className="border-box clearfix">
                                <div className="blackspan65 weight700 floatleft">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="filterbtn" className="btn btn_small btncolorprim btncard move" onClick={this.handleFilterClick}>Filter</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                {/*		        <div className="blackspan65 weight700 floatleft">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>*/}
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="addleadbtn" className="btn btn_small btncolorprim btncard move">Add order</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="excelleadsbtn" className="btn btn_small btnwhite btncard move" data-type="">Get as Excel file</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="summarybtn" className="btn btn_small btnwhite btncard move" data-type="" onClick={this.handleSummaryClick}>Summary</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="middle border-box clearfix leftalign">
                            <div className="border-box blackspan65 weight700 dpmboxes1">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes8">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Order ID</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes8">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Type</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes8">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Reg Num</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes8">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Value</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes8">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Channel</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes8">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Status</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes8">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Pickup date</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes8">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Delivery date</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes8">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Est. Delivery date</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes9">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        </React.Fragment>
                    );
            case 'fleets':
                    return (
                        <React.Fragment>
                        <div className="top border-box clearfix leftalign">
                            <div className="border-box clearfix">
                                <div className="blackspan65 weight700 floatleft">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="filterbtn" className="btn btn_small btncolorprim btncard move">Filter</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                {/*		        <div className="blackspan65 weight700 floatleft">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>*/}
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="addleadbtn" className="btn btn_small btncolorprim btncard move">Add fleet</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="excelleadsbtn" className="btn btn_small btnwhite btncard move" data-type="">Get as Excel file</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="middle border-box clearfix leftalign">
                            <div className="border-box blackspan65 weight700 dpmboxes1">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes26">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">ID</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Name</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes8">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Type</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Location</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Primary contact</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Billing name</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes9">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        </React.Fragment>
                    );

            case 'garages':
                            return (
                                <React.Fragment>
                                <div className="top border-box clearfix leftalign">
                                    <div className="border-box clearfix">
                                        <div className="blackspan65 weight700 floatleft">
                                            <table className="stdtable">
                                                <tr>
                                                    <td valign="center">
                                                        <button id="filterbtn" className="btn btn_small btncolorprim btncard move">Filter</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                        {/*		        <div className="blackspan65 weight700 floatleft">
                                            <table className="stdtable">
                                                <tr>
                                                    <td valign="center">
                                                        <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>*/}
                                        <div className="blackspan65 weight700 floatright">
                                            <table className="stdtable">
                                                <tr>
                                                    <td valign="center">
                                                        <button id="addleadbtn" className="btn btn_small btncolorprim btncard move">Add garage</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="blackspan65 weight700 floatright">
                                            <table className="stdtable">
                                                <tr>
                                                    <td valign="center">
                                                        <button id="excelleadsbtn" className="btn btn_small btnwhite btncard move" data-type="">Get as Excel file</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="middle border-box clearfix leftalign">
                                    <div className="border-box blackspan65 weight700 dpmboxes1">
                                        <table className="stdtable">
                                            <tr>
                                                <td valign="center"></td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="border-box blackspan65 weight700 dpmboxes26">
                                        <table className="stdtable">
                                            <tr>
                                                <td valign="center">ID</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="border-box blackspan65 weight700 dpmboxes10">
                                        <table className="stdtable">
                                            <tr>
                                                <td valign="center">Name</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="border-box blackspan65 weight700 dpmboxes8">
                                        <table className="stdtable">
                                            <tr>
                                                <td valign="center">Code</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="border-box blackspan65 weight700 dpmboxes10">
                                        <table className="stdtable">
                                            <tr>
                                                <td valign="center">Location</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="border-box blackspan65 weight700 dpmboxes10">
                                        <table className="stdtable">
                                            <tr>
                                                <td valign="center">Contact</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="border-box blackspan65 weight700 dpmboxes10">
                                        <table className="stdtable">
                                            <tr>
                                                <td valign="center">Display name</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="border-box blackspan65 weight700 dpmboxes9">
                                        <table className="stdtable">
                                            <tr>
                                                <td valign="center"></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                </React.Fragment>
                            );
            default:
                return null;
        }
    }
  }

export default MiddleUpper;