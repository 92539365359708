import React, { useState, useEffect } from "react";
import "./WhatsappTemplate.css";
import { NotificationManager } from "react-notifications";
import API from "../../api";
import Loader from "../../loader";
import WhatsappTemplateFilter from "./WhatsappTemplateFilter/WhatsappTemplateFilter";
import Row from "./row";

const WhatsappTemplate = (props) => {

  const rows = props && props.content && props.content.length > 0 ?
    props.content.map((obj, index) => (
      <Row key={index} data={obj} srNum = {index} />
    )) : null;
  
  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <WhatsappTemplateFilter  onApplyFilter={props.onApplyFilter}/>
      </div>
      {
        props.loader ?
        <div id="websiteMainmiddleloader" className="bgwhite centeralign">
          <Loader showLoader={props.loader} />
        </div> :
        props.content.length > 0 ? 
      
        <React.Fragment>
          <div className="mainImageContainer">
            <div className="manageCarouselImage">Manage Whatsapp Templates</div>
            <div className="border-box clearfix leftalign"
              // style={{ top: "125px" }}
            >
              <div className="border-box blackspan65 weight700">
                <table className="middleUpperTable-ic stdtable">
                  <thead>
                      <tr>
                        <th className="middleUpperTH-ic width1TH-ic">Sr. No</th>
                        <th className="middleUpperTH-ic width2TH-ic">Template Id</th>
                        <th className="middleUpperTH-ic width3TH-ic">Template Text</th>
                        <th className="middleUpperTH-ic width2TH-ic">Action</th>
                      </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
          <div
            className="imageCarouselMiddleMain border-box leftalign"
            style={{ height: "fit-content", overflowY: "hidden", marginTop: "51px", marginBottom: '4rem' }}
          >
            {rows}
          </div>
        </React.Fragment>
        :
        <div className="emptyMiddleMain centeralign weight400 blackspan56">
          Please apply filters to view records
        </div>
        }
      </>
  );
};

export default WhatsappTemplate;