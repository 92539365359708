import React from "react";
import "./workshopFilter.css";
import firebase from "../../../firebase";
import MultiSelect from "../../custom/MultiSelect/MultiSelect";
import API from "../../../api";
import Constants from "../../../constants";

class WorkshopFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workshops: [],
      retailers: [],
      city: [],
      isretailer: " ",
      country: "",
      workshopFilter: {
        workshops: [],
        retailers: [],
        cities: [],
        isRetailer: " ",
        isPopUp: false,
      }

    };

    this.handleApplyFilter = this.handleApplyFilter.bind(this);
    this.afterFilterChange = this.afterFilterChange.bind(this);
    this.cityWiseWorkShop = this.cityWiseWorkShop.bind(this);
    this.handleAddParts = this.handleAddParts.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleFilterMouseOver = this.handleFilterMouseOver.bind(this);
  }
  componentWillMount() {
    // this.state.showFilterDiv = true;
    if (localStorage.getItem("city_data") != undefined) {
      this.state.city = Constants.getCityData("single");
    }
    if (localStorage.getItem("isRetailer") != undefined) {
      this.state.workshopFilter.isRetailer = JSON.parse(localStorage.getItem("isRetailer"));
    }
    if (localStorage.getItem("city_wise_workshops") != undefined) {
      this.state.workshops = JSON.parse(localStorage.getItem("city_wise_workshops"));
      this.state.showFilterDiv = true;
    } 
    if(localStorage.getItem("country") !== undefined) {
      this.state.country = localStorage.getItem("country");
    }
  }
  componentDidMount() {
    /* Setting Filters Data from localstorage*/
    if (localStorage.getItem("workshop_filters") != undefined) {
      this.setState({
        workshopFilter: JSON.parse(localStorage.getItem("workshop_filters")),
      });
    }
    if (localStorage.getItem("city_data") == undefined) {
      firebase.database().ref('/cms/cities').on(
        'value',
        (snapshot) => {
          var cityOptions = [];
          cityOptions = [{ id: 'select', name: 'Select City' }, ...snapshot.val()]
          this.setState({ city: cityOptions });
          localStorage.setItem("city_data", JSON.stringify(this.state.city));
        },
        (err) => {
          console.log('cities read failed from firebase', err.code);
        },
      );
    }
  }
  afterFilterChange(field) {
    field = field || null;
    if (field == 'city') {
      if (this.state.workshopFilter.isRetailer.id == '1') {
        this.cityWiseWorkShop()
      }
      else if (this.state.workshopFilter.isRetailer.id == '2') {
        this.cityWiseRetailers()
      }
    }
    if (field == 'workshop') {
      if (this.state.workshopFilter.isRetailer.id == '1') {
        this.setState({ showFilterDiv: true });
        this.setState({ showRetailDiv: false });

      }
      else if (this.state.workshopFilter.isRetailer.id == '2') {
        this.setState({ showRetailDiv: true });
        this.setState({ showFilterDiv: false });
      }
      else {
        this.setState({ showFilterDiv: false });
        this.setState({ showRetailDiv: false });
      }
    }
  }

  handleApplyFilter() {
    localStorage.setItem(
      "workshop_filters",
      JSON.stringify(this.state.workshopFilter)
    );
    this.props.onApplyFilter(this.state.workshopFilter, 'workshop_filters');
  }

  handleAddParts() {
    let obj = { ...this.state.workshopFilter };
    obj["isPopUp"] = true;
    localStorage.setItem(
      "workshop_filters", 
      JSON.stringify(obj)
    )
    this.props.onApplyFilter(this.state.workshopFilter, 'workshop_filters');
  }
  
  handleMouseOver() {
    this.setState(
      {
        workshopFilter: {
          ...this.state.workshopFilter,
          isPopUp: true
        }
      }
    )
  }

  handleFilterMouseOver() {
    this.setState(
      {
        workshopFilter: {
          ...this.state.workshopFilter,
          isPopUp: false
        }
      }
    )
  }
  cityWiseWorkShop() {
    var location_id = this.state.workshopFilter.cities.id;
    this.setState(
      {
        workshopFilter: {
          ...this.state.workshopFilter,
          workshops: [],
        }
      }
    )
    var that = this;
    API.getGaragesByLocation(location_id).then(function (response) {
      if (response.data.status) {
        let result = [{ id: 'select-all', name: 'Select All' }, ...response.data.data];
        that.setState({ workshops: result });

        localStorage.setItem("city_wise_workshops", JSON.stringify(result));
      }
    })
  }

  cityWiseRetailers() {
    var location_id = this.state.workshopFilter.cities.id;
    this.setState(
      {
        workshopFilter: {
          ...this.state.workshopFilter,
          retailers: [],
        }
      }
    )
    var that = this;
    API.getRetailersByLocation(location_id).then(function (response) {
      if (response.data.status) {
        let result = [{ id: 'select-all', name: 'Select All' }, ...response.data.data];
        that.setState({ retailers: result });

        localStorage.setItem("city_wise_retailers", JSON.stringify(result));
      }
    })
  }
  render() {
    const {
      workshops,
      retailers,
      city,
      workshopFilter,
      country,
    } = this.state;

    return (
      <div className="mainFilterContainer">
        <div
          className="row-5 margin_10 margin_b_20"
          style={{ gridTemplateColumns: "repeat(6,15%)" }}
        >
          <div className="margin_r_16">
            <MultiSelect
              labelKey="name"
              checkbox_id="name"
              options={country !== "malaysia" ? [{ "id": "select", "name": "Select Options" }, { "id": "1", "name": "Workshops" }, { "id": "2", "name": "Retailer" }] : [{ "id": "select", "name": "Select Options" }, { "id": "1", "name": "Workshops" }]}
              placeholder="Select Retail or Workshop "
              selectedOptions={this.state.workshopFilter.isRetailer}
              onChange={(value) =>
                this.setState({
                  workshopFilter: {
                    ...this.state.workshopFilter,
                    isRetailer: value,
                    cities: [],
                    workshops: [],
                    retailers: [],
                  }

                },
                  () => this.afterFilterChange('workshop')
                )}
            ></MultiSelect>
          </div>
          {
            workshopFilter.isRetailer && workshopFilter.isRetailer.id ?
              <React.Fragment>
                <div className="margin_r_16">
                  <MultiSelect
                    options={city}
                    labelKey="name"
                    checkbox_id="name"
                    placeholder="Select Cities"
                    disabledItems={[]}
                    selectedOptions={this.state.workshopFilter.cities}
                    onChange={(value) =>
                      this.setState(
                        {
                          workshopFilter: {
                            ...this.state.workshopFilter,
                            cities: value,
                          },
                        },
                        () => this.afterFilterChange('city')
                      )
                    }
                  />
                </div>
              </React.Fragment>
              : ""
          }

          {
            workshopFilter.isRetailer && workshopFilter.isRetailer.id == '1' ? (
              <React.Fragment>
                <div className="margin_r_16" style={{ width: '150%' }}>
                  <MultiSelect
                    multiple
                    options={workshops}
                    labelKey="name"
                    checkbox_id="id"
                    placeholder="Workshop Name"
                    disabledItems={[]}
                    selectedOptions={this.state.workshopFilter.workshops}
                    onChange={(value) =>
                      this.setState(
                        {
                          workshopFilter: {
                            ...this.state.workshopFilter,
                            workshops: value,
                          }
                        },
                      )
                    }
                  />
                </div>

              </React.Fragment>
            ) : workshopFilter.isRetailer && workshopFilter.isRetailer.id == '2' ? (
              <React.Fragment>
                <div className="margin_r_16" style={{ width: '150%' }}>
                  <MultiSelect
                    multiple
                    options={retailers}
                    labelKey="name"
                    checkbox_id="id"
                    placeholder="Retailer Name"
                    disabledItems={[]}
                    selectedOptions={this.state.workshopFilter.retailers}
                    onChange={(value) =>
                      this.setState(
                        {
                          workshopFilter: {
                            ...this.state.workshopFilter,
                            retailers: value,
                          }
                        },
                      )
                    }
                  />
                </div>

              </React.Fragment>
            ) : ""
          }
          {(this.state && this.state.workshopFilter && this.state.workshopFilter.cities && this.state.workshopFilter.cities.id && this.state.workshopFilter.isRetailer && this.state.workshopFilter.isRetailer.id && ((this.state.workshopFilter.retailers && !!this.state.workshopFilter.retailers.length) || this.state.workshopFilter.workshops && !!this.state.workshopFilter.workshops.length)) && 
            <div className="blackspan65 weight700 floatright addContentBtnMargin" style={{ marginLeft: '60%' }}>
              <button
                style={{
                  background: "rgb(105,118,148,1)",
                  color: "rgb(255, 255, 255)",
                }}
                id="addleadbtn"
                className="btn btn_small btncolorprim btncard move"
                onClick={this.handleApplyFilter}
                onMouseEnter={this.handleFilterMouseOver}
              >
                Apply Filter
              </button>
            </div>
          }
          {this.state && this.state.workshopFilter && this.state.workshopFilter.isRetailer && this.state.workshopFilter.isRetailer.id && this.state.workshopFilter.isRetailer.id === "2" && 
            <div  className="blackspan65 weight700 floatright addContentBtnMargin" 
              style={{ marginLeft: (this.state.workshopFilter.cities && this.state.workshopFilter.cities.id && this.state.workshopFilter.retailers && this.state.workshopFilter.retailers.length) ? "30%" : "60%" }}
            >
              <button
                style={{
                  background: "rgb(92, 184, 92)",
                  color: "rgb(255, 255, 255)",
                  borderColor: "rgb(76, 174, 76)",
                }}
                id="addleadbtn"
                className="btn btn_small btncard move"
                onMouseEnter={this.handleMouseOver}
                onClick={()=>this.props.handlePageChange(true)}
              >
                Add Parts
              </button>
            </div>
          }
        </div >
      </div >
    );
  }
}

export default WorkshopFilter;
