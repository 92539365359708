import React, { useState, useEffect } from "react";
import "./activateProducts.css";
import { NotificationManager } from "react-notifications";
import API from "../../api";
import Loader from "../../loader";

export default function ActivateProducts(props) {
  useEffect(() => {
    console.log("props called", props);
    if (props.content) {
    }
  }, [props.content]);

  function UpdateSingleValue(value) {
    let obj = {};
    obj[props.filters.channelType.id] = value
    let temp = [];
    if(props.filters){
        props.filters.cities.filter((w) => {
            if (w.id !== 'select-all') {
                temp.push(w.id);
            }
          return 1
        })
    }
    obj['city_id'] = temp;
      // console.log(obj)
      API.updateActiveProducts(obj)
        .then(function (response) {
          NotificationManager.success(`Data saved`, "Successfully", 5000);
        })
        .catch(function (error) {
          NotificationManager.error("Please try again", "Error", 5000);
          console.log(error, "Error in updating the product status");
        });
  }

  return (
    <>
      <div className="mainContainer">
        <div className="manageTimeSlot">Manage Activate Products</div>
        <div className="innerBox">
          <div className="slotBookContainer">
            <div className="selectDate maskingDataContainer">
              <div>{props.filters ? props.filters.channelType.name : ""} :</div>
              <div>
                <input
                  type="checkbox"
                  className="toggle-switch-checkbox"
                  name="toggleLASwitch"
                  id="toggleLASwitch"
                  onChange={(e) => UpdateSingleValue(e.target.checked)}
                  defaultChecked={
                    props.filters.cities.length === 1
                      ? props.content[props.filters.channelType.id]
                        ? props.content[props.filters.channelType.id]
                        : false
                      : false
                  }
                />
                <label className="toggle-switch-label" htmlFor="toggleLASwitch">
                  <span className="toggle-switch-inner" />
                  <span className="toggle-switch-switch" />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
