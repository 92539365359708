import React, { useState, useEffect } from "react";
import "./ImageCarousel.css";
import { NotificationManager } from "react-notifications";
import API from "../../api";
import Loader from "../../loader";
import ImageCarouselFilter from "./ImageCarouselFilter/imageCarouselFilter";
import SelectDay from "./SelectDay";
import MainTitles from "./MainTitles"
import Row from "./row";

export default function ImageCarousel(props) {

  const [title, setTitle] = useState("");

  function handelTitleChange(e) {
    setTitle(e.target.value);
  }

  function handelUpdateTitles() {
    let filters = JSON.parse(localStorage.getItem("image_filters"));
    var formData = new FormData();
    let channel = (props.filters.channelType.name).toLowerCase();
    let carousel = props.filters.version.id;
    let id = props.content.carousel[0].serial_no;
    let city_id = [];

    if (filters && filters.cities.length > 0) {
      let temp = [];

      filters.cities.filter((city) => {
        if (city.id != 'select-all') {
          temp.push(city.id);
        }
      })
      city_id = temp;
    }

    formData.append("channel", channel);
    formData.append("carousel", carousel);
    formData.append("version", carousel);
    formData.append("id", id);
    formData.append('city_id', city_id);
    formData.append('header_text', title);

    if (id == 1) {
      console.log('call api');
      API.updateCarouselImages(formData)
        .then(function (response) {
          NotificationManager.success("Title Updated", "Successfully", 5000);
        })
        .catch(function (error) {
          // handle error
          NotificationManager.error("Please try again", "Error", 5000);
          console.log(error, "Error in uploading image");
        });
    }
  }

  function handleRows() {
    if(props?.filters?.channelType?.name === "Category Section") {
      return props?.content?.map((obj, index) => (
        <Row
          key={index}
          data={obj}
          filters={props.filters}
          onApplyFilter={props.onApplyFilter}
        />
      ));
    } else {
      return props.content.carousel && props.content.carousel.map((obj, index) => (
        <Row
          key={props && props.filters && props.filters.channelType && props.filters.version && ((props.filters.channelType.name == "Vertical Page" && props.filters.version.name == "New") || (props.filters.channelType.name == "Middle Banner" && props.filters.version.name == "V2")) ?  obj.id+'-'+obj.index : index}
          data={obj}
          filters={props.filters}
          onApplyFilter={props.onApplyFilter}
        />
      ));
    }
  }

  if (props.filters?.channelType?.id !== 6 && props.filters?.version?.id !== "v2") {
    var headerText = props.content && props.content.carousel && props.content?.carousel[0]?.header_text;
  }

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <ImageCarouselFilter  onApplyFilter={props.onApplyFilter}/>
      </div>
      {
        props.loader ?
          <div id="websiteMainmiddleloader" className="bgwhite centeralign">
            <Loader showLoader={props.loader} />
          </div> :
          (props.content.carousel && props.content.carousel.length > 0) || (props?.filters?.channelType?.name === "Category Section" && props.content && props.content.length > 0) ?

            <React.Fragment>
              {
                props.filters && props.filters.channelType && props.filters.channelType.name == "Square Banner" &&
                <React.Fragment>
                  <SelectDay />
                </React.Fragment>
              }
              {
                props.filters && props.filters.channelType && (props.filters.channelType.name == "Performance Banner" || props.filters.channelType.id == 9 || props.filters.channelType.id == 10 || props.filters.channelType.id == 11) && props.filters.cities.length != 0 &&
                < MainTitles channelType={props.filters.channelType.name} mainTitle={props.content.main_title} subTitle={props.content.main_sub_title} cityArray={props.filters.cities}  channelSubType={props.filters.serviceComboChannels.channel_sub_type}/>
              }
         
              {
                props.filters && props.filters.channelType && props.filters.channelType.name == "Vertical Page" &&
                props.filters.version.name === "New" &&
                <React.Fragment>
                    <div className="innerBox" style={{padding: "1rem", width: "50%", margin: "2% auto"}}>
                    <div>
                      <div>
                        <label className="headerText">Header Text</label>
                      </div>
                      <input
                        type="text"
                        defaultValue={headerText}
                        placeholder="Enter Header Text"
                        onChange={(e) => handelTitleChange(e)}
                        name="title"
                        style={{width: "85%"}}
                      />
                        <button className="button-update btncard move"
                          onClick={handelUpdateTitles}
                          style={{ cursor: "pointer" }}
                        >
                          Save
                        </button>
                    </div>
                  </div>
                </React.Fragment>
              }       
          {/* <div className="mainImageContainer-ic">
            <div className="manageCarouselImage">Manage Carousel Images</div>
            <div className="border-box clearfix leftalign"
              // style={{ top: "125px" }}
            >
              <div className="border-box blackspan65 weight700">
                <table className="middleUpperTable-ic stdtable">
                  <thead>
                      <tr>
                        <th className="middleUpperTH-ic width1TH-ic">ID</th>
                        <th className="middleUpperTH-ic width2TH-ic">Image</th>
                        {}
                        <th className="middleUpperTH-ic width2TH-ic">Link</th>
                        {props.filters && props.filters.channelType && (props.filters.channelType.name != "Vertical Page" && props.filters.channelType.name != "Square Banner"  && props.filters.channelType.name != "Middle Banner" && props.filters.channelType.name != "Climate Banner" && props.filters.channelType.name != "Performance Banner" && props.filters.channelType.name != "Accessories Banner") &&
                          <th className="middleUpperTH-ic width1TH-ic">Delete Image</th>
                          }
                          
                        {props.filters && props.filters.channelType && props.filters.channelType.name == "Performance Banner" &&
                          <th className="middleUpperTH-ic width1TH-ic">Title</th>
                        }                          
                        {((props.filters && props.filters.version && props.filters.version.id == "new" &&
                         props.filters && props.filters.channelType && (props.filters.channelType.name == "App" || props.filters.channelType.name == "Luxury App" )) || props.filters.channelType.name == "Accessories Banner") &&
                          <th className="middleUpperTH-ic width1TH-ic">Small Banner</th>
                        }
                        {
                          props.filters && props.filters.channelType && props.filters.channelType.name == "Vertical Page" && props.filters.version.id == "old" &&
                          <th className="middleUpperTH-ic width1TH-ic">Header Banner</th>
                        }
                        {
                            props.filters && props.filters.channelType && props.filters.channelType.name == "Vertical Page" && props.filters.version.id == "new" &&
                            <React.Fragment>
                              <th className="middleUpperTH-ic width1TH-ic">Bg Color</th>
                              <th className="middleUpperTH-ic width1TH-ic">Title Color</th>
                              <th className="middleUpperTH-ic width1TH-ic">Sub-title Color</th>
                            </React.Fragment>
                        }
                        {
                          props.filters && props.filters.channelType && props.filters.channelType.name == "Climate Banner" &&
                          <React.Fragment>
                            <th className="middleUpperTH-ic width6TH-ic">Description</th>
                            <th className="middleUpperTH-ic width1TH-ic">Toggle</th>
                          </React.Fragment>
                        }
                        <th className="middleUpperTH-ic width1TH-ic">Action</th>
                      </tr>
                  </thead>
                </table> */}

              {/* {
                props.filters && props.filters.channelType &&  props.filters.channelType.id == 10 && props.filters.channelType.id == 10 && props.filters.cities.length != 0 &&
                < MainTitles channelType={props.filters.channelType.name} mainTitle={props.content.main_title} subTitle={props.content.main_sub_title} cityArray={props.filters.cities} channelSubType={props.filters.serviceComboChannels.channel_sub_type} />
              } */}

              <div className="mainImageContainer-ic">
                <div className="manageCarouselImage">Manage Carousel Images</div>
                <div className="border-box clearfix leftalign"
                // style={{ top: "125px" }}
                >
                  <div className="border-box blackspan65 weight700">
                    <table className="middleUpperTable-ic stdtable">
                      <thead>
                        <tr>
                          {props.filters.channelType.id != 13 &&
                            <th className="middleUpperTH-ic width1TH-ic">ID</th>
                          }
                          {props.filters && props.filters.channelType && props.filters.channelType.name == "Middle Banner" &&
                            <th className="middleUpperTH-ic width1TH-ic">Type</th>
                          }
                          <th className="middleUpperTH-ic width2TH-ic">Image</th>
                          {props.filters.channelType.id != 13 &&
                            <th className="middleUpperTH-ic width2TH-ic">Link</th>
                          }
                          {props.filters && props.filters.channelType && (props.filters.channelType.name != "Vertical Page" && props.filters.channelType.name != "Square Banner" && props.filters.channelType.name != "Middle Banner" && props.filters.channelType.name != "Climate Banner" && props.filters.channelType.name != "Performance Banner" && props.filters.channelType.name != "Accessories Banner" && props.filters.channelType.id != 10 && props.filters.channelType.id != 11 && props.filters.channelType.id != 12 && props.filters.channelType.id != 13) &&
                            <th className="middleUpperTH-ic width1TH-ic">Delete Image</th>
                          }

                          {props.filters && props.filters.channelType && (props.filters.channelType.name == "Performance Banner" || props.filters.channelType.id == 11 || props.filters.channelType.id == 12 || props.filters.channelType.id == 13 ) &&
                            <th className="middleUpperTH-ic width1TH-ic">Title</th>
                          }

                          {props.filters && props.filters.channelType &&  props.filters.channelType.id == 12 &&
                            <th className="middleUpperTH-ic width1TH-ic">Button Text</th>
                          }
                          {((props.filters && props.filters.version && props.filters.version.id == "new" &&
                            props.filters && props.filters.channelType && (props.filters.channelType.name == "App" || props.filters.channelType.name == "Luxury App")) || props.filters.channelType.name == "Accessories Banner") &&
                            <th className="middleUpperTH-ic width1TH-ic">Small Banner</th>
                          }
                          {(props.filters && props.filters.version && props.filters.version.id === "new" &&
                            props.filters && props.filters.channelType && (props.filters.channelType.name === "App" || props.filters.channelType.name === "Luxury App")) &&
                            <th className="middleUpperTH-ic width1TH-ic">Miles Banner</th>
                          }
                          {
                            props.filters && props.filters.channelType && props.filters.channelType.name == "Vertical Page" &&  props.filters.version.id == "old" &&
                            <th className="middleUpperTH-ic width1TH-ic">Header Banner</th>
                          }
                          {
                            props.filters && props.filters.channelType && props.filters.channelType.name == "Climate Banner" &&
                            <React.Fragment>
                              <th className="middleUpperTH-ic width6TH-ic">Description</th>
                              <th className="middleUpperTH-ic width1TH-ic">Toggle</th>
                            </React.Fragment>
                          }
                          {
                            props.filters && props.filters.channelType && props.filters.channelType.name == "Vertical Page" && props.filters.version.id == "new" &&
                            <React.Fragment>
                              <th className="middleUpperTH-ic width1TH-ic">Bg Color</th>
                              <th className="middleUpperTH-ic width1TH-ic">Title Color</th>
                              <th className="middleUpperTH-ic width1TH-ic">Sub-title Color</th>
                            </React.Fragment>
                          }
                          {
                            props.filters && props.filters.channelType && (props.filters.channelType.id == 10 || props.filters.channelType.id == 11) &&
                            <React.Fragment>
                              <th className="middleUpperTH-ic width6TH-ic">Description</th>
                            </React.Fragment>
                          }
                          {
                            props.filters && props.filters.channelType &&  props.filters.channelType.id == 10 &&
                            <React.Fragment>
                              <th className="middleUpperTH-ic width1TH-ic">Combo Off</th>
                            </React.Fragment>
                          }
                          <th className="middleUpperTH-ic width1TH-ic">Action</th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
              <div
                className="imageCarouselMiddleMain-ic border-box leftalign"
                style={{ height: "fit-content", overflowY: "hidden", marginBottom: '4rem' }}
              >
                {handleRows()}
              </div>
            </React.Fragment>
            :
            <div className="emptyMiddleMain centeralign weight400 blackspan56">
              Please apply filters to view records
            </div>
      }
    </>
  );
}
