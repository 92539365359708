import React from "react";
import "./pageBannerFilter.css";
import firebase from "../../../firebase";
import MultiSelect from "../../custom/MultiSelect/MultiSelect";
import API from "../../../api";
import { NotificationManager } from "react-notifications";

class PageBannerFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
      services: [],
      page_banner_type: [{ id: "select", name: "Select Banner Type" }, { id: "1", name: "Retail" }, { id: "2", name: "Accessories" }],
      subServiceData: [],
      showServiceFilter: true,
      filterData: {
        page_banner_type:{},
        services: [],
      },
    };

    this.handleApplyFilter = this.handleApplyFilter.bind(this);
    this.afterFilterChange = this.afterFilterChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handelPageTypeChange = this.handelPageTypeChange.bind(this);
  }
  componentWillMount() {
    if (localStorage.getItem("service_data") != undefined) {
      this.state.services = JSON.parse(localStorage.getItem("service_data"));
    }
  }
  componentDidMount() {
    /* Setting Filters Data from localstorage*/
    let localFilterData = JSON.parse(localStorage.getItem("page_banner_filters"))
    if (localStorage.getItem("page_banner_filters") != undefined) {
      this.setState({
        filterData: localFilterData,
      }); 
      if(localFilterData.page_banner_type.id === '2'){
        this.setState({ services: JSON.parse(localStorage.getItem("accessories_product")) });
      }else if(localFilterData.page_banner_type.id === '1'){
        firebase.database().ref('/cms/service_type').on(
          'value',
          (snapshot) => {
            var serviceOptions = [];
            serviceOptions = [{ id: 'select', name: 'Select Service' }, ...snapshot.val()]
            this.setState({ services: serviceOptions });
            localStorage.setItem("service_data", JSON.stringify(this.state.services));
          },
          (err) => {
            console.log('services read failed from firebase', err.code);
          },
        );
      }
      else{
        this.setState({ services: [] });
      }
    }
  }
  afterFilterChange(field) {
    field = field || null;
    if (
      field.id === '2'
    ) {
      this.getAccessoriesProductCat();
    }
    else if (field.id === '1'){
      this.setState({ services: JSON.parse(localStorage.getItem("service_data")) });
    }
    localStorage.setItem("page_banner_filters", JSON.stringify(this.state.filterData));
  }

  handleApplyFilter() {
    this.props.applyFilter(this.state.filterData);
  }

  handleCategoryChange(value) {
    this.props.onDropDownChange(null)
    this.props.onDropDownChangeSetId(null)
    this.setState({ filterData: { 
      ...this.state.filterData,
      services: value } }, () => this.afterFilterChange(value));

  }
  handelPageTypeChange(value) {
    this.props.onDropDownChange(null)
    this.props.onDropDownChangeSetId(null)
    this.setState({ filterData: {
       ...this.state.filterData,
      page_banner_type: value,
      services:[] } }, () => this.afterFilterChange(value));
  }
  getAccessoriesProductCat() {
    let data = {};
    // data.deal_id = 13;
    data.service_id = 1000;
    let that = this;
    
      API.getAccessoriesProductCat(data)
        .then(function (response) {
          if (response.data.status) {
            let data =
              response.data && response.data.data ? [{ id: "select", name: "Select Product" }, ...response.data.data] : [];
            localStorage.setItem("accessories_product", JSON.stringify(data));
            that.setState({ services: data });
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          NotificationManager.error("Please try again", "Error", 5000);
          that.setState({ services: [] });
        });
  }
  render() {
    const { page_banner_type,services,filterData } = this.state;
    return (
      <div className="mainFilterContainer">
        <div className="row-5 margin_10 margin_b_20">
          {
            <>
            <div className="margin_r_16">
                <MultiSelect
                  options={page_banner_type}
                  labelKey="name"
                  checkbox_id="id"
                  placeholder="Select Banner Type"
                  disabledItems={[]}
                  selectedOptions={filterData.page_banner_type}
                  onChange={(value) => this.handelPageTypeChange(value)}
                />
              </div>
              {filterData && filterData.page_banner_type && filterData.page_banner_type.id && (<div className="margin_r_16">
                <MultiSelect
                  options={services}
                  labelKey="name"
                  checkbox_id="id"
                  placeholder="Select Category"
                  disabledItems={[]}
                  selectedOptions={filterData.services}
                  onChange={(value) => this.handleCategoryChange(value)}
                />
              </div>)}
              {filterData.services && filterData.services.name &&
                <div className="row-5">
                  <div className="blackspan65 weight700 floatright addContentBtnMargin" >
                    <table className="stdtable">
                      <tbody>
                        <tr>
                          <td valign="center">
                            <button id="addleadbtn" className="btn btn_small btncolorprim btncard move" onClick={this.handleApplyFilter}>Apply Filter</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              }
            </>
          }
        </div>
      </div>
    );
  }

}

export default PageBannerFilter;