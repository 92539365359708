import React from "react";
import "../dashboard/dashboard.css";
import PanelLeft from "./leftpanel";
import MiddleMain from "./middlemain";
import Loader from "../loader";
import RateFilter from "./RateFilter/rateFilter";
import API from "../api";
import Constants from "../constants";
import { Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";

const axios = require("axios");

class PanelMiddle extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.showLoader) {
      // if(true){
      return (
        <div id="websiteMainmiddleloader" className="bgwhite centeralign">
          <Loader showLoader={this.props.showLoader} />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          {this.props.webFilters ? (
            [
              // <MiddleUpper
              //   tag={this.props.tag}
              //   label={this.props.label}
              //   onFilterClick={this.props.onFilterClick}
              //   onAddClick={this.props.onAddClick}
              //   onFilterChange={this.props.onFilterChange}
              // />,
              this.props.showContent ? (
                <MiddleMain
                  tag={this.props.tag}
                  selectedRow={this.props.selectedRow}
                  content={this.props.content}
                  showContent={this.props.showContent}
                  onAction={this.props.rightOnRightButtonClick}
                  onSaveClick={this.props.onSavePricingDetails}
                />
              ) : (
                  <React.Fragment />
                ),
            ]
          ) : (
              <div className="emptyMiddleMain centeralign weight400 blackspan56">
                Please apply filters to view records
              </div>
            )}
        </React.Fragment>
      );
    }
  }
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "logged in",
      rightContent: NaN,
      middleContent: NaN,
      showMiddleContent: false,
      showRightContent: false,
      middleExtraContent: NaN,
      selectedTab: "rate_control",
      selectedRow: "",
      web_filters: undefined,
      tableName: "cms_faq",
      label: "Deals",
      firebaseData:{
        deal_zone:[],
        brandWiseModel:[],
        pricing_categories:[],
        segment:[],
        car_brands:[],
        segmentWiseModel:[],
        fuelTypes:[],
      },
    };

    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
  }

  handleSaveButtonClick(data) {
    var that = this;

    this.setState({
      showLoader: true,
    });
    // console.log("---------", data);
    API.updateRatePrices(that, data)
      .then(function (response) {
        NotificationManager.success("Details saved", "Successfully", 5000);
        that.setState({ showLoader: false });
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating deal pricing data");
      });
  }

  handleEditButtonClick(type, tag, row, content, data) {
    this.setState({
      selectedRow: data.uuid,
      showRightContent: true,
      rightContent: data,
    });
    this.setState({
      middleExtraContent: {
        type: type,
        tag: tag,
        table: this.state.tableName,
        label: this.state.label,
        row: row,
        content: content,
      },
    });
  }

  handleTabClick(tab) {
    var that = this;

    that.setState({
      selectedTab: tab.tag,
      label: tab.title,
      tableName: tab.table,
      showMiddleContent: false,
      showRightContent: false,
      rightContent: NaN,
      showLoader: true,
      middleExtraContent: NaN,
    });

    API.tabClickCallContentWebsite(tab.tag, that, tab.table);
  }
  handleFilterChange(filters, filterType) {
    console.log("filyer ", filters, filterType)
    var that = this;
    this.setState({
      web_filters: filters,
      showLoader: true,
    });
    localStorage.setItem(filterType, JSON.stringify(filters));
    API.tabClickCallContentWebsite(
      that.state.selectedTab,
      that,
      that.state.tableName
    );
  }

  hideMenuBTN() {
    var element1 = document.getElementById("dashboardmainleft");
    var rightImg = document.getElementById("rotate");
    // if (element1.style.display == "none") {
    //   element1.style.display = "block";
    //   rightImg.style.transform = "rotate(0deg)";
    // } else {
    //   element1.style.display = "none";
    //   rightImg.style.transform = "rotate(180deg)";
    // }
    if (element1.style.width == "0%") {
      element1.style.width = "100%";
      element1.style.animation = "slide 2s";
      rightImg.style.transform = "rotate(0deg)";
    } else {
      element1.style.animation = "slider 0.25s";
      element1.style.width = "0%";
      rightImg.style.transform = "rotate(180deg)";
    }
  }

  componentWillMount() {
    if (localStorage.getItem("access_token") === null) {
      this.setState({ status: "not logged in" });
    } else {
      var that = this;
      that.setState({ showLoader: true });
      {API.firebaseData(that)}
      if (localStorage.getItem("rate_filters")) {
        API.tabClickCallContentWebsite(
          that.state.selectedTab,
          that,
          that.state.tableName
        );
      } else {
        that.setState({ showLoader: false });
      }
    }
  }

  componentDidMount() { }

  componentWillUnmount() { }

  render() {
    if (
      this.state.status === "not logged in" ||
      new Date(localStorage.getItem("token_expiration_date")) <= new Date()
    ) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("token_expiration_date");
      // localStorage.setItem("user_id");
      localStorage.removeItem("user_id");
      localStorage.removeItem("phone");
      localStorage.removeItem("custom_token");
      localStorage.removeItem("user_roles");
      return <Redirect to="/login" />;
    } else if (this.state.selectedTab === "support_chat") {
      return <Redirect to="/chat" />;
    } else {
      // return <Redirect to="/dashboard"/>
      return (
        <div id="containermainouter1" className="MainGridComponent">
          {/* <PanelRight content={this.state.rightContent} showContent={this.state.showRightContent} tag={this.state.selectedTab} selectedRow={this.state.selectedRow} onRightButtonClick={this.handleEditButtonClick}/>
           */}
          <PanelLeft
            onTabClick={this.handleTabClick}
            selectedTab={this.state.selectedTab}
          />

          <div
            id="dashboardmainmiddle"
            style={{ overflowY: "scroll" }}
            className="bgwhite leftalign websiteContentDash marginDashboard1 scrollMiddle"
          >
            <div
              id="dashboardprime"
              className="leftalign"
              style={{
                overflowY: "scroll",
                minHeight: "100vh",
                height: "auto",
              }}
            >
              {
                this.state.selectedTab == "rate_control" &&
                <React.Fragment>
                  <div style={{ marginTop: "20px" }}>
                    <RateFilter onApplyFilter={this.handleFilterChange}  fbData={this.state.firebaseData}/>
                  </div>
                  <PanelMiddle
                    tag={this.state.selectedTab}
                    label={this.state.label}
                    webFilters={this.state.web_filters}
                    selectedRow={this.state.selectedRow}
                    showLoader={this.state.showLoader}
                    content={this.state.middleContent}
                    showContent={this.state.showMiddleContent}
                    rightOnRightButtonClick={this.handleEditButtonClick}
                    onSavePricingDetails={this.handleSaveButtonClick}
                  />
                </React.Fragment>
              }
            </div>
            <button
              className="bottomToggleButton btncard move"
              onClick={this.hideMenuBTN}
            >
              <img
                id="rotate"
                className="arrowToggle"
                src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/left.svg"
              />
            </button>

          </div>
        </div>
      );
    }
  }
}

export default Dashboard;
