import React from "react";
import "./topbar/topbar1.css";
import { Redirect, Link } from "react-router-dom";
import API from "./api";

const axios = require("axios");

class Topbar extends React.Component {
  constructor(props) {
    super(props);

    this.hideMenu = this.hideMenu.bind(this);
  }

  hideMenu() {
    var element = document.getElementById("dashboardmainleft");
    // if (element.style.display == "none") {
    //   element.style.display = "block";
    // } else {
    //   element.style.display = "none";
    // }
    if (element.style.width == "0%") {
      element.style.width = "100%";
      element.style.animation = "slide 2s";
    } else {
      element.style.width = "0%";
      element.style.animation = "slider 0.25s";
    }
  }

  render() {
    return (
      <div className="topbarouter" id="topbarouter">
        <div className="topbarinner" id="topbarinner">
          <nav
            className="topbar light persistent border-box dashboard"
            id="topbar"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span
                onClick={this.hideMenu}
                style={{
                  margin: "0 0.75rem",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                <img src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/group984.svg" />
              </span>
              <a style={{ margin: "0 auto 0 0" }} href="/" className="logo"></a>
              <div className="topbarchoiceboxes border-box centeralign floatright uppercased weight700">
                <div className="topbarchoiceboxesinner border-box centeralign floatleft">
                  {localStorage.getItem("country")}
                </div>
              </div>
              <ul>
                <li>
                  <div className="dropdown">
                    <img
                      className="avatarLogo"
                      src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/avatar.png"
                    />
                    <div className="dropdown-content">
                      <Link to="/logout">Logout</Link>
                    </div>
                  </div>
                </li>
                <li className="mobile_btn mobile_menu_btn">
                  <a href="#" className="btn_topbar">
                    Menu
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <nav className="mobile_menu border-box">
            <div className="mobile_menu_wrapper border-box">
              <div className="mobile_menu_header border-box">
                <a href="/" className="logo"></a>
                <a href="#" className="close btn_topbar">
                  Close
                </a>
              </div>
              <ul>
                <li>
                  <a href="/accounts/logout/?next=/accounts/login/">Logout</a>
                </li>
              </ul>
              <ul className="mobile_menu_footer">
                <li className="colorprimA400span">
                  Available in New Delhi, Gurgaon, Faridabad, NOIDA, Greater
                  NOIDA & Ghaziabad.
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

export default Topbar;
