// import React from 'react';

var statuses = [];

// const base_url = "https://gomechanic.app/";//"https://staging.gomechanic.app/"
const base_url = "https://gomechanic.app/";
const base_url_malyasia = "https://mly.gomechanic.app/";
const base_url_comms = "https://comms.gomechanic.app/";
const base_url_central = "https://central.gomechanic.app/";

const frontEndUrl = base_url.includes("dev.") ? "https://dev.web.gomechanic.app/" : "https://web.gomechanic.app/";

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

// const firebase_config = {
//     apiKey: "AIzaSyBrl7ms6peFc8DgUKwJpp231XoCeI23PpU",
//     authDomain: "gomechanic-retail.firebaseapp.com",
//     databaseURL: "https://gomechanic-retail.firebaseio.com",
//     projectId: "gomechanic-retail",
//     storageBucket: "gomechanic-retail.appspot.com",
//     messagingSenderId: "5389558266",
//     appId: "1:5389558266:web:e7a91a4051e54edb"
// };

const firebase_config = {
    apiKey: "AIzaSyAb93QJ0M_63eg-njHd0ygZ0oCEpbQZGtA",
    authDomain: "goretail-e508d.firebaseapp.com",
    databaseURL: "https://goretail-e508d-default-rtdb.firebaseio.com",
    projectId: "goretail-e508d",
    storageBucket: "goretail-e508d.appspot.com",
    messagingSenderId: "489238591274",
    appId: "1:489238591274:web:55dd0c6fde49187b105053",
    measurementId: "G-8WZCPGZ0L2"
  }

const workDone = {
    "10": "cutting and welding", "20": "Alignment", "23": "Pressing", "32": "Turning",
    "33": "Dry Cleaning", "45": "Rubbing and Polishing", "1": "Checked", "15": "Scanning",
    "25": "Pulling", "31": "Oiling", "48": "Denting", "7": "Labour", "13": "Service",
    "35": "Filling Charge", "19": "Balancing", "21": "Charging", "36": "Puncture Repair",
    "2": "Cleaned", "4": "Replaced", "12": "Overhaul", "28": "Touch Up", "53": "Programming",
    "27": "Polishing", "38": "Restoration", "55": "Flushing", "8": "Lubrication", "30": "Bleeding",
    "43": "Teflon Coating", "44": "Dressing", "49": "Dent Paint", "51": "Towing", "5": "Serviced",
    "42": "Detailed Cleaning", "46": "Paint", "9": "Uninstallation", "14": "Opening & Refitting",
    "3": "Repaired", "24": "Machining/Lathe", "29": "Car Wash", "47": "Partial Paint",
    "18": "Tuning", "22": "Jumpstart", "50": "Roadside Assistance", "52": "Booklet Sell",
    "11": "Inspection", "17": "Wiring", "54": "Adjusted", "34": "Duplicate Making",
    "41": "Cabin Cleaning", "16": "Noise Adjust", "40": "Suction", "6": "Top-up",
    "26": "Rubbing", "37": "Checkup", "39": "Discharge", "56": "Vaccuming and Polishing",
    "57": "Dry Dent", "58": "Calibration", "59": "Service Reset", "60": "Washing and Vacumming",
    "61": "Scanning and Service Reset", "62": "Leak Test", "63": "Suspension Overhaul Front",
    "64": "Suspension Overhaul Rear", "65": "Clutch Overhaul", "66": "Brakes Overhaul Front",
    "67": "Brakes Overhaul Rear", "68": "Bleeding", "69": "Service Labour", "70": "Vacuuming",
    "71": "Reprogramming", "72": "Regeneration"
}

const countries = ["india", "malaysia"];

const coupon_type = [
  { name: "Select", id: "select" },
  { name: "PCT", id: 1 },
  { name: "AMT", id: 2 },
  { name: "FLAT_PRICE_INC", id: 3 },
  { name: "FLAT_PRICE_EXCL", id: 4 },
];
const discount_type = [
  { name: "Select", id: "select" },
  { name: "SERVICE", id: "SERVICE" },
  { name: "PACKAGE", id: "PACKAGE" },
  { name: "ORDER", id: "ORDER" },
  { name: "LABOUR PRICE", id: "ORDERLP" },
  { name: "MATERIAL PRICE", id: "ORDERMP" },
  { name: "COMBO", id: "COMBO" },
];
const discount_duplicate = [
    { name: "Select", id: "select" },
    { name: "SERVICE", id: 1 },
    { name: "PACKAGE", id: 2 },
    { name: "ORDER", id: 3 },
    { name: "LABOUR PRICE", id: 4 },
    { name: "MATERIAL PRICE", id: 5 },
    { name: "COMBO", id: 6 },
  ];
const discount_on = [
  { name: "Select All", id: "select-all" },
  { name: "APP", id: "APP" },
  { name: "WEBSITE", id: "WEBSITE" },
  { name: "CRAPP", id: "CRAPP" },
  { name: "BOOKLET", id: "BOOKLET" },
  { name: "LEAD PANEL", id: "LEAD PANEL" },
];

class Constants {
    getFirebaseConfig() {
        return firebase_config;
    }
    getBaseURL(type) {
        let country = localStorage.getItem("country");
        let url = type && type == "otp" ? base_url_comms : base_url;
        if (country === "india")
            return url;
        else if (country === "malaysia")
            return base_url_malyasia;
        else
            return url;
    }
    getCentralBaseURL() {
        return base_url_central;
    }
    getFrontEndURL() {
        return frontEndUrl;
    }
    orderTypes() {
        return { 101: "General", 100: "Preventive", 103: "Insurance", 102: "Document" };
    }
    getWorkDoneText(key) {
        if (key)
            return workDone[key];

        return "";
    }
    setStatuses(data) {
        localStorage.setItem("statuses", JSON.stringify(data));
    }

    setFleetsSummary(data) {
        localStorage.setItem("fleets_summary", JSON.stringify(data));
    }

    setGaragesSummary(data) {
        localStorage.setItem("garages_summary", JSON.stringify(data));
    }

    statuses() {
        return statuses;
    }
    getCountries() {
        return countries;
    }
    getSelectedCountry() {
        return localStorage.getItem("country");
    }
    getTimeDifferenceFromMill(diff) {
        var secs = Math.floor(diff / 1000);
        if (secs < 60) {
            return secs + "s";
        } else {
            var mins = Math.floor(secs / 60);
            if (mins < 60) {
                secs = secs % 60;
                return mins + "m" + " " + secs + "s";
            } else {
                var hours = Math.floor(mins / 60);
                mins = mins % 60;
                return hours + "h" + " " + mins + "m";
            }
        }
    }

    get_TimeString = function (date) {
        var hour = date.getHours();
        var minute = date.getMinutes();
        var ampm = hour < 12 ? "AM" : "PM";
        if (hour >= 12) {
            hour = hour - 12;
        }
        if (hour == 0) {
            hour = 12;
        }
        if (date <= 9) {
            date = "0" + date;
        }
        if (hour <= 9) {
            hour = "0" + hour;
        }
        if (minute <= 9) {
            minute = "0" + minute;
        }

        // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
        var timeString = hour + ":" + minute + " " + ampm;
        // dateString += timeString;

        return timeString;
    };

    get_DateString = function (date) {
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var ampm = hour < 12 ? "AM" : "PM";
        if (hour >= 12) {
            hour = hour - 12;
        }
        if (hour == 0) {
            hour = 12;
        }
        if (date <= 9) {
            date = "0" + date;
        }
        if (hour <= 9) {
            hour = "0" + hour;
        }
        if (minute <= 9) {
            minute = "0" + minute;
        }
        if (month <= 9) {
            month = "0" + month;
        }
        if (day <= 9) {
            day = "0" + day;
        }

        var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
        var timeString = hour + ":" + minute + " " + ampm;
        dateString += timeString;

        return dateString;
    };

    get_DateStringX = function (date1) {
        var nowdate = new Date();
        var date = new Date(date1);

        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var ampm = hour < 12 ? "AM" : "PM";
        if (hour >= 12) {
            hour = hour - 12;
        }
        if (hour == 0) {
            hour = 12;
        }
        if (date <= 9) {
            date = "0" + date;
        }
        if (hour <= 9) {
            hour = "0" + hour;
        }
        if (minute <= 9) {
            minute = "0" + minute;
        }
        if (month <= 9) {
            month = "0" + month;
        }
        if (day <= 9) {
            day = "0" + day;
        }

        var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
        var timeString = hour + ":" + minute + " " + ampm;
        dateString += timeString;

        return dateString;
    };


    get_DateString1 = function (date1) {
        var nowdate = new Date();
        var date = new Date(date1);

        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var ampm = hour < 12 ? "AM" : "PM";
        if (hour >= 12) {
            hour = hour - 12;
        }
        if (hour == 0) {
            hour = 12;
        }
        if (date <= 9) {
            date = "0" + date;
        }
        if (hour <= 9) {
            hour = "0" + hour;
        }
        if (minute <= 9) {
            minute = "0" + minute;
        }
        if (month <= 9) {
            month = "0" + month;
        }
        if (day <= 9) {
            day = "0" + day;
        }

        if (date.getDay() == nowdate.getDay() && date.getMonth() == nowdate.getMonth() && date.getYear() == nowdate.getYear()) {
            return hour + ":" + minute + " " + ampm;
        } else {
            if (date.getYear() != nowdate.getYear()) {
                return monthNames[month - 1] + " " + date.getFullYear();
            } else {
                return day + " " + monthNames[month - 1];
            }
        }

        // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
        // var timeString = hour + ":" + minute + " " + ampm;
        // dateString += timeString;

        //         return dateString;
    };

    get_DateString1Date = function (date1) {
        var nowdate = new Date();
        var date = new Date(date1);

        var month = date.getMonth() + 1;
        var day = date.getDate();
        if (date <= 9) {
            date = "0" + date;
        }
        if (month <= 9) {
            month = "0" + month;
        }
        if (day <= 9) {
            day = "0" + day;
        }

        if (date.getDay() == nowdate.getDay() && date.getMonth() == nowdate.getMonth() && date.getYear() == nowdate.getYear()) {
            return "Today";
        } else {
            if (date.getYear() != nowdate.getYear()) {
                return day + " " + monthNames[month - 1] + " " + date.getFullYear();
            } else {
                return day + " " + monthNames[month - 1];
            }
        }

        // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
        // var timeString = hour + ":" + minute + " " + ampm;
        // dateString += timeString;

        //         return dateString;
    };

    get_DateString2 = function (date1) {
        var nowdate = new Date();
        var date = new Date(date1);

        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var ampm = hour < 12 ? "AM" : "PM";
        if (hour >= 12) {
            hour = hour - 12;
        }
        if (hour == 0) {
            hour = 12;
        }
        if (date <= 9) {
            date = "0" + date;
        }
        if (hour <= 9) {
            hour = "0" + hour;
        }
        if (minute <= 9) {
            minute = "0" + minute;
        }
        if (month <= 9) {
            month = "0" + month;
        }
        if (day <= 9) {
            day = "0" + day;
        }


        var dateString = day + " " + monthNames[month - 1] + " " + date.getFullYear() + ", ";
        var timeString = hour + ":" + minute + " " + ampm;
        dateString += timeString;

        return dateString;
    };


    get_TimeComparison = function (date1, date2) {
        var date11 = new Date(date1);
        var date22 = new Date(date2);

        if (date11.getDay() == date22.getDay() && date11.getMonth() == date22.getMonth() && date11.getYear() == date22.getYear()) {
            return true;
        } else {
            return false;
        }

        // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
        // var timeString = hour + ":" + minute + " " + ampm;
        // dateString += timeString;

        //         return dateString;
    };

    get_TimeComparison1 = function (date1, date2) {
        var date11 = new Date(date1);
        var date22 = new Date(date2);

        if (date11.getDay() == date22.getDay() && date11.getMonth() == date22.getMonth() && date11.getYear() == date22.getYear() && date11.getHours() == date22.getHours() && date11.getMinutes() == date22.getMinutes()) {
            return true;
        } else {
            return false;
        }

        // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
        // var timeString = hour + ":" + minute + " " + ampm;
        // dateString += timeString;

        //         return dateString;
    };


    initializeTinyMCE() {
        // tinymce.init({
        //     selector: '#mytextarea'
        // });
    }

    getPricingData(table) {
        let filters = JSON.parse(localStorage.getItem("pricing_filters"));
        // console.log("-------------here" + JSON.stringify(filters));
        var pricing_filter = {};
        let deal_id = filters && filters.deals ? filters.deals.id : null;

        if (deal_id) {
            pricing_filter['deal_id'] = deal_id;

            let category_id = filters.categories ? filters.categories.id : "";
            let segment_name = filters.segments ? filters.segments.name : "";

            if (filters.categories && filters.categories.is_accessories) {
                pricing_filter["is_accessories"] = filters.categories.is_accessories;
            }


            pricing_filter['service_id'] = filters.services ? filters.services.id : "";

            if (category_id != -4.1 && category_id != 21.1 && category_id != 173) {
                pricing_filter['brand_id'] = filters.brands ? filters.brands.id : "";

                if (filters.models.length > 0) {
                    let temp = [];

                    filters.models.filter((model) => {
                        if (model.id != 'select-all') {
                            temp.push(model.id);
                        }
                    })
                    pricing_filter['models'] = temp;
                } else {
                    pricing_filter['models'] = [];
                }

                if (filters.fuelType.length > 0) {
                    let temp = [];

                    filters.fuelType.filter((fuel) => {
                        if (fuel.id != 'select-all') {
                            temp.push(fuel.id);
                        }
                    })
                    pricing_filter['fuel_type'] = temp;
                } else {
                    pricing_filter['fuel_type'] = [];
                }
            }



            pricing_filter['service_id'] = category_id

            if ((category_id === -4.1 || category_id === 21.1) && category_id !== 173) {
                pricing_filter['brand_name'] = filters.brandType ? filters.brandType.name : "";

                if (filters.brandValue.length > 0) {
                    let temp = [];

                    filters.brandValue.filter((value) => {
                        if (value.id != 'select-all') {
                            temp.push(value.name);
                        }
                    })
                    pricing_filter['brand_value'] = temp;
                } else {
                    pricing_filter['brand_value'] = [];
                }
            }

            if (category_id === -4.1) {
                if (filters.warranty.length > 0) {
                    let temp = [];

                    filters.warranty.filter((value) => {
                        if (value.id != 'select-all') {
                            temp.push(value.name);
                        }
                    })
                    pricing_filter['brand_warranty'] = temp;
                } else {
                    pricing_filter['brand_warranty'] = [];
                }
            }
            if (category_id === 173 || segment_name) {
                pricing_filter['segment'] = segment_name
            }
            if (category_id === 1000 && filters.accessories_product && filters.accessories_product) {
                pricing_filter['accessories_product_category'] = filters.accessories_product.id
                if (filters.accessories_sub_product.length > 0) {
                    let temp = [];

                    filters.accessories_sub_product.filter((accessories_sub_product) => {
                        if (accessories_sub_product.id != 'select-all') {
                            temp.push(accessories_sub_product.id);
                        }
                    })
                    pricing_filter['accessories_product_sub_categories'] = temp;
                } else {
                    pricing_filter['accessories_product_sub_categories'] = [];
                }
                pricing_filter['segment'] = segment_name
            }

        }
        // console.log("-------------" + JSON.stringify(pricing_filter));
        return pricing_filter;
    }

    getRateControlData(table) {
        let filters = JSON.parse(localStorage.getItem("rate_filters"));
        var rate_filter = {};
        let deal_id = filters && filters.deals ? filters.deals.id : null;

        if (deal_id) {
            rate_filter['deal_id'] = deal_id;

            rate_filter['brand_id'] = filters.brands && filters.brands.id ? filters.brands.id : "";

            rate_filter['segment'] = filters.segments && filters.segments.name ? filters.segments.name : "";

            if (filters.models.length > 0) {
                let temp = [];

                filters.models.filter((model) => {
                    if (model.id != 'select-all') {
                        temp.push(model.id);
                    }
                })
                rate_filter['models'] = temp;
            } else {
                rate_filter['models'] = [];
            }

            if (filters.models.length > 0 && filters.fuelType.length > 0) {
                let temp = [];

                filters.fuelType.filter((fuel) => {
                    if (fuel.id != 'select-all') {
                        temp.push(fuel.id);
                    }
                })
                rate_filter['fuel_type'] = temp;
            } else {
                rate_filter['fuel_type'] = [];
            }

            let line_item = filters.lineItem;

            rate_filter['id'] = line_item && line_item.id ? line_item.id : "";

            rate_filter['name'] = line_item && line_item.name ? line_item.name : "";

            rate_filter['gm_num'] = line_item && line_item.gm_num ? line_item.gm_num : "";
        }
        return rate_filter;
    }

    getFilterData(table) {

        let filters = JSON.parse(localStorage.getItem("web_filters"));
        // console.log("-------------"+JSON.stringify(filters));
        var web_filter = {};

        let page_type = filters && filters.pageType && filters.pageType.name ? filters.pageType.name : "";

        web_filter['page_type'] = filters && filters.pageType ? filters.pageType : {};

        switch (page_type) {
            case "website":
            case "service-detail":
                // web_filter['page_type'] = filters.pageType;

                web_filter['service'] = filters.services;

                // web_filter['fuel_type'] = filters.fuelType;

                web_filter['city'] = [filters.cities.id];
                // if (filters.cities.length !== 0) {
                //     let temp = [];
                //     for (let i = 0; i < filters.cities.length; i++){
                //         temp.push(filters.cities[i].id);
                //     }
                //     web_filter['city'] = temp;
                // }
                if (filters.brands && filters.brands.length > 0) {
                    let temp = [];
                    for (let i = 0; i < filters.brands.length; i++) {
                        filters.brands[i].id != 'select-all' && temp.push(filters.brands[i].id);
                    }
                    web_filter['brands'] = temp;
                } else {
                    let brand_id = filters.brands && filters.brands.id ? [filters.brands.id] : []
                    web_filter['brands'] = brand_id;
                }
                if (filters.models && filters.models.length > 0 && filters.fuelType && filters.fuelType.length > 0) {
                    let temp = [];
                    for (let i = 0; i < filters.fuelType.length; i++) {

                        filters.fuelType[i].id != 'select-all' && temp.push(filters.fuelType[i].id);
                    }
                    web_filter['fuel_type'] = temp;
                } else {
                    let fuel_id = filters.fuelType && filters.fuelType.id ? [filters.fuelType.id] : []
                    web_filter['fuel_type'] = fuel_id;
                }
                if (filters.brands.length > 0) {
                    let temp = {};
                    // let brands = web_filter['brands'];
                    for (let i = 0; i < filters.brands.length; i++) {
                        // temp.push(filters.models[i].id);
                        // temp[filters.models[i].brand_id] = filters.models[i].name;
                        // alert(filters.brands[i].id);
                        let brand_id = filters.brands[i].id;

                        if (brand_id != 'select-all') {
                            if (filters.models.length !== 0) {
                                for (let k = 0; k < filters.models.length; k++) {
                                    if (brand_id === filters.models[k].brand_id) {
                                        if (temp[brand_id] === undefined && filters.models[k].id != 'select-all') {
                                            temp[brand_id] = [filters.models[k].id];
                                        }
                                        else if (filters.models[k].id != 'select-all') {
                                            temp[brand_id].push(filters.models[k].id);
                                        }
                                    }
                                    else if (temp[brand_id] === undefined) {
                                        temp[brand_id] = [];
                                    }
                                }
                            } else {
                                temp[brand_id] = [];
                            }
                        }
                    }
                    web_filter['bm'] = temp;
                } else {
                    let brand_id = filters.brands.id;
                    let model_id = filters.models.id;
                    if (brand_id && model_id) {
                        let temp = {};
                        temp[brand_id] = [model_id];

                        web_filter['bm'] = temp;
                    }
                }

                if (filters.models.length > 0) {
                    let temp = [];
                    for (let i = 0; i < filters.models.length; i++) {
                        filters.models[i].id != 'select-all' && temp.push(filters.models[i].id);
                    }
                    web_filter['models'] = temp;
                } else {
                    let model_id = filters.models && filters.models.id ? [filters.models.id] : []
                    web_filter['models'] = model_id;
                }

                if (filters.regions.length !== 0) {
                    let temp = [];
                    for (let i = 0; i < filters.regions.length; i++) {
                        filters.regions[i].id != 'select-all' && temp.push(filters.regions[i].sitename);
                    }
                    web_filter['region'] = temp;
                }
                if (filters.subService && filters.subService.id)
                    web_filter['sub_service'] = filters.subService;
                break;
            case "near-me":
                web_filter['page_path'] = (filters.pageUrl && filters.pageUrl.name) ? filters.pageUrl.name : "";
                break;
            case "app":
                web_filter['service'] = filters.services;
                web_filter['service_id'] = filters.subService.id ? filters.subService.id : 0;
                if (filters.subService){
                    web_filter['category_id'] = filters.services.id; 
                    web_filter['sub_category_id'] = filters?.subService?.sub_category_id ? filters?.subService?.sub_category_id : 0;
                } 
                break;
        }

        web_filter['table'] = table;

        // console.log("@@@@@@@@"+JSON.stringify(web_filter));
        return web_filter;
    }

    getWebsiteUrl(data) {


        let page_type = data.page_type.name;
        var page_url = '';

        let service = data.sub_service && data.sub_service.id ? data.sub_service.id : data.service && data.service.slug ? data.service.slug : "";

        if (page_type == 'near-me') {
            page_url = `/${data.page_path}`;
        }
        else {
            if (data.city.name)
                page_url += '/' + data.city.sitemap_name.replace(' ', '-').toLowerCase();

            if (data.region && !service)
                page_url += '/' + data.region.toLowerCase();    

            if (service)
                page_url += '/' + service.toLowerCase();

            if (service && data.region)
                page_url += '-in-' + data.region.toLowerCase();

            if (data.brand.slug_id)
                page_url += '/' + data.brand.slug_id.toLowerCase();

            if (data.brand.name && data.model.name)
                page_url += '-' + data.model.name.replace(' ', '-').toLowerCase();

            if (data.model.name && data.fuel_type.name)
                page_url += '/' + data.fuel_type.name.toLowerCase();
        }
        return page_url;
    }
    getSlotBookingData(table) {
        let filters = JSON.parse(localStorage.getItem("booking_filters"));
        // console.log("-------------"+JSON.stringify(filters));
        var booking_filter = {};
        let channelId = filters && filters.channelType ? filters.channelType.id : null;
        let type = filters && filters.type ? filters.type.id : null;

        if (channelId) {
            booking_filter['channel'] = filters.channelType;
            booking_filter['slot_for'] = type;

            if (filters.cities.length > 0) {
                let temp = [];
                for (let i = 0; i < filters.cities.length; i++) {
                    if (filters.cities[i].id !== 'select-all')
                        temp.push(filters.cities[i].id);
                }
                booking_filter['city'] = temp;
            }
        }
        return booking_filter;
    }
    availableTimeSlotsForDate = function (timeSlots, compareDateString) {
        timeSlots.filter((timeSlot) => {
            const currentDate = new Date();
            const currentDateString = `${currentDate.getFullYear()}-${`0${currentDate.getMonth()
                + 1}`.slice(-2)}-${`0${currentDate.getDate()}`.slice(-2)}`;
            if (currentDateString === compareDateString) {
                const compareTime = new Date(new Date().setHours(timeSlot.startHour));
                const difference = compareTime - new Date();
                const diffInMinutes = Math.round(difference / 1000 / 60);
                return diffInMinutes > 120;
            }
            return true;
        })
    }
    getCityData = function (type) {
        let selectType = type && type == "single" ? { id: 'select', name: 'Select City' } : { id: 'select-all', name: 'Select All' };
        var cityOptions = [];
        cityOptions = [selectType, ...JSON.parse(localStorage.getItem("city_data"))]
        return cityOptions;
    }

    getUserData = function () {

    }
    getDiscountOn=function(){
        return discount_on;
    }
    getDiscountType=function(){
        return discount_type
    }
    getCouponType=function(){
        return coupon_type
    }
    getCouponTypeDuplicate=function(){
        return discount_duplicate
    }

}

export default new Constants();