import React, { useState, useEffect } from "react";
import "./ImageCarousel.css";
import { NotificationManager } from "react-notifications";
import API from "../../api";

export default function SelectDay(props) {
  var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  
  const [weekdays, setWeekdays] = useState([]);
  useEffect(() => {
    if(weekdays.length == 0){
      API.getWeekDays().then(function (response) {
        console.log(response,'response');
        setWeekdays(response.data.data.week_new)
      })
      .catch(function (error) {
        console.log(error, "Error in getting weekdays detail");
      });
    }
  },[]);

  function handleRadioToggle(item, isDayActive) {
    let temp = [ ...weekdays ];
    if(isDayActive){
      const index = temp.indexOf(item);
      if (index > -1) {
        temp.splice(index, 1);
      }
    }else{
      temp.push(item);
    }
    setWeekdays(temp);
  }

  function saveWeekDays(){
    let data = {"weekday":weekdays};
    API.saveWeekDays(data).then(function (response) {
      NotificationManager.success("WeekDays saved", "Successfully", 5000);
    })
    .catch(function (error) {
      console.log(error, "Error in saving weekdays detail");
      NotificationManager.error("Something went wrong", "Error", 5000);
    });
  }

  return (
    <>
        <React.Fragment>
            <div className="mainWeekDayContainer" style={{marginTop:"20px"}}>
                <div style={{ display: "flex", flexDirection:"row" }}>
                    <div style={{margin: "15px"}}>Select Day :</div>
                    {
                      days.map((item, index) => {
                        let isDayActive = weekdays.includes(item);
                        return (
                        <div
                          onClick={(e) => {
                            handleRadioToggle(item, isDayActive);
                          }}
                          className={
                            isDayActive === true
                              ? "day-box dateBoxColor"
                              : "day-box btncard move"
                          }
                        >
                          <span>{item}</span>
                        </div>
                        )
                      })
                    }
                    <div style={{margin:"10px"}}>
                        <button className="btn btn_small btncard move saveBtn" 
                            onClick={(e) => {
                              saveWeekDays();
                            }}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
      </>
  );
}
