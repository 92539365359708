import React from "react";
import Row from "./row";

class MiddleMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      price_data: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }
  handleChange(rowData) {
    // this.setState({ price_data: { ...this.state.price_data, ...rowData } });
    this.state.price_data.push(rowData);
  }
  handleSaveClick() {
    this.props.onSaveClick(this.state.price_data);
  }

  render() {
    var that = this;
    let filterData = JSON.parse(localStorage.getItem("pricing_filters"));
    let category = filterData && filterData.categories && filterData.categories.name ? filterData.categories.name : "";
    const rows = this.props.content && this.props.content.coupons && this.props.content.coupons.map((obj, index) => (
      <Row
        key={index}
        data={obj}
        selectedRow={this.props.selectedRow}
        tag={that.props.tag}
        actionButton={this.props.onAction}
        handleInputChange={this.handleChange}
      />
    ));

    return (
      <div>
        {/* Middle Upper Start */}
        <React.Fragment>
          {/* Below button */}
          {/* <Package /> */}
          <div
            className="widthMainComp border-box clearfix leftalign"
            style={{ top: "168px",marginTop: "51px" }}
          >
            <div className="border-box blackspan65 weight700">
              <table className="middleUpperTable stdtable">
                <thead>

                    <tr>
                    <th className="middleUpperTH width7TH">Id</th>
                    <th className="middleUpperTH width7TH">Code</th>
                      <th className="middleUpperTH width7TH">Coupon Type</th>
                      <th className="middleUpperTH width7TH">Discount Type</th>
                      <th className="middleUpperTH width7TH">Discount On</th> 
                      <th className="middleUpperTH width7TH">Percentage</th>
                      <th className="middleUpperTH width7TH">Actions</th>
                    </tr>
                 
                </thead>
              </table>
            </div>
          </div>
        </React.Fragment>
        {/* Middle Upper End */}

        {/* Middle Main Start */}
        <div
          className="main border-box leftalign"
          style={{ height: "65vh", overflowY: "scroll"  }}
        >
          {!this.props.showContent ? (
            <React.Fragment />
          ) : this.props.content.length == 0 ? (
            <div className="empty centeralign weight400 blackspan56">
              No Data Found
            </div>
          ) : (
                rows
              )}
        </div>
        {/* Middle Main End */}
      </div>
    );
  }
}

export default MiddleMain;
