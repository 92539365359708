import React from "react";

let tabList =[]

class PanelLeft extends React.Component {
	constructor(props) {
		super(props);
		this.state = { showContent: false };
	}
	render() {
		if (this.props.userType === 'Training Admin') {
			tabList = [{
				tag: "training_module",
				title: "Training Module",
				table: "training_module",
			}]
		} else {
			tabList = [{ tag: "deals_pricing", title: "Manage Deals", table: "cms_faq" },
			{ tag: "slot_booking", title: "Manage Booking", table: "cms_booking" },
			{ tag: "workshop_masking", title: "Manage Workshop", table: "cms_masking" },
			{ tag: "activate_products", title: "Activate Products", table: "cms_masking" },
			{ tag: "cms_carousel", title: "Manage Images", table: "cms_carousel" },
			{
				tag: "whatsapp_template",
				title: "Manage Templates",
				table: "cms_whatsapp_template",
			},
			{ tag: "manage_wallet", title: "Manage Wallet", table: "cms_wallet" },
			{ tag: "manage_service_tag", title: "Manage Tags", table: "cms_wallet" },
			{ tag: "miles", title: "Miles", table: "miles" },
			{ tag: "page_banner", title: "Page Banner", table: "page_banner" },
			{ tag: "manage_homepage", title: "Manage Homepage", table: "manage_homepage" }
			]
		}
		const tabs = tabList.map((tab, index) => (
			<Tab
				key={index}
				tag={tab.tag}
				title={tab.title}
				table={tab.table}
				onTabClick={this.props.onTabClick}
				selectedTab={this.props.selectedTab}
			/>
		));

		return (
			<div
				id="dashboardmainleft"
				className="border-box bgwhite leftalign floatleft shadow-box widthDashboard100"
			>
				<div className="first border-box clearfix leftalign shadow-box">
					<div className="border-box clearfix flexInternal">
						<div className="dashboardmainleft1boxesinner clearfix whitespan weight900 shadow-text flexInternal">
							GoMechanic
							<br />
							Internal
						</div>
					</div>
				</div>

				<div className="second border-box leftalign">
					<div className="floatleft leftalign blackspan7 weight400" style={{ overflowY: 'scroll', scrollBehaviour: "smooth" }}>{tabs}</div>
				</div>
			</div>
		);
	}
}

class Tab extends React.Component {
	constructor(props) {
		super(props);
		this.state = { showContent: false };

		this.handleClick = this.handleClick.bind(this);
	}

	handleClick() {
		this.props.onTabClick(this.props);
	}

	render() {
		return (
			<div
				className={
					this.props.selectedTab === this.props.tag
						? "tabs bgwhite border-box shadow-text selected"
						: "tabs bgwhite border-box shadow-text"
				}
				data-type={this.props.tag}
				onClick={this.handleClick}
				style={{ display: "flex", alignItems: "center" }}
			>
				<div>{this.props.title}</div>
			</div>
		);
	}
}

export default PanelLeft;
