import React, { useState,useEffect } from "react";
import "./trainingSubDetails.css";
import { useHistory, useParams } from "react-router-dom"
import { NotificationManager } from "react-notifications";
import API from "../../../api.js";

function TrainingSubDetails({ content ,filters,that}) {
	const [selectedLevel, setSelectedLevel] = useState(content)
	const [loader, setLoader] = useState(false)
	const history = useHistory()
	const { level_id } = useParams()
	let arrayId = []
	let temp = filters?.multipleUsers.filter((user) => {
		if (user.id != 'select-all') {
			arrayId.push(user.id);
		}
	})

	useEffect(() => {
		setSelectedLevel(content)
	},[content])

	const handleEditAllQuestion = (type_id) =>
		history.push(`/dashboard/miscellaneous/type/${level_id}/${type_id}`)

	const handleContentChange = () => {
		API.getTrainingLevel(filters.workshop.id, filters.user.id).then((res) => {
			if (res.data.status)
				that.setState({ showLoader: false, middleContent: res.data.data })
			setLoader(false)
		}
		)
	}	

	const handleLevelDataChange = (value, key, type) => {
		if (key === 'passing_threshold')
			value = parseInt(value)
		const updatedLevelData = selectedLevel.level_data
			.map((each) => {
				if (each.type === type)
					return { ...each, [key]: value }
				else
					return each
			}
			)
		if(key==='is_mandatory')
		{
		setLoader(true)
		API.postAddNextLevelData({...selectedLevel,level_data: updatedLevelData, multiple_user_id: arrayId}).then((res) => {
			if(res.data.status) 
			{
					NotificationManager.success(
						res.data.message,
						"Successfully",
						5000
					)
			handleContentChange()	
			}else{
				NotificationManager.error("Please try again", "Error", 5000);
				setLoader(false);
			}
		})
		.catch(function (error) {
			NotificationManager.error("Please try again", "Error", 5000);
			setLoader(false);
		});
	}
		if(key!=='is_mandatory')
		setSelectedLevel((prev) =>  ({ ...prev, level_data: updatedLevelData }))	
	}

	const handleChange = (key, value) => { 
		setLoader(true)
		API.postAddNextLevelData({ ...selectedLevel, [key]: value, multiple_user_id: arrayId }).then((res) => {
			if(res.data.status){
				NotificationManager.success(
					res.data.message,
					"Successfully",
					5000
				)
			handleContentChange()
			}else{
				NotificationManager.error("Please try again", "Error", 5000);
				setLoader(false);
			}
		}
	  )			
	  .catch(function (error) {
		NotificationManager.error("Please try again", "Error", 5000);
		setLoader(false);
	});
	}

	const handleSubmit = () => {
		setLoader(true)
		API.postAddNextLevelData({...selectedLevel, selectedLevel, multiple_user_id: arrayId}).then((res) => {
			if(res.data.status){
				NotificationManager.success(
					res.data.message,
					"Successfully",
					5000
				)
				handleContentChange()
			}else{
				NotificationManager.error("Please try again", "Error", 5000);
				setLoader(false);
			}
		}
		).catch(function (error) {
			NotificationManager.error("Please try again", "Error", 5000);
			setLoader(false);
		});
	}

	const nonEditPopup = () =>{
		NotificationManager.error("Level already started/completed", "Error", 5000);
	}

	 const checkDisabled = (data) => { 
		return data.some((each)=>each.is_editable===false)
	 } 	

     return (
		<>
			{loader ? (
				<>
					<div className="modal-sb"></div>
					<div className="sub-modal-sb"></div>
				</>
			) : (
				""
			)}
			{selectedLevel && <div>
				{/* Select MCQs and Videos section to add or edit */}
				<div className="flex mt-54">
					<p className="training-level-heading">
						{selectedLevel.name} Training
					</p>
					<div className="flex">
						{/* Reset test */}
						<div className="flex level-toggle-section ">
							<button className="reset-button" onClick={() => window.confirm('Are you sure you want to reset test?') 
							    && handleChange('reset_level', true)}>
								Reset Test
							</button>
						</div>

						{/* Toggle mandatory */}
						<div className="flex level-toggle-section mr-32">
							<p className="training-level-mandatory mr-32">Mandatory</p>
							<input
								type="checkbox"
								className={checkDisabled(selectedLevel.level_data)?"toggle-training-checkbox disabled-cursor":"toggle-training-checkbox"}
								name="MainMandotory"
								id="MainMandotory"
								value={selectedLevel.is_mandatory}
								onChange={() => {
									if(checkDisabled(selectedLevel.level_data))
									NotificationManager.error("Level already started/completed", "Error", 5000);
									else
									handleChange('is_mandatory', !selectedLevel.is_mandatory)}	
								}
							/>
							<label
								className="ta-toggle-switch-label"
								htmlFor="MainMandotory"
							>
								<span
									className={
										checkDisabled(selectedLevel.level_data)?
										selectedLevel.is_mandatory === true
										? "ta-toggle-switch-inner disabled-cursor"
										: "set-false-toggle-value disabled-cursor"
										:
										selectedLevel.is_mandatory === true
											? "ta-toggle-switch-inner"
											: "set-false-toggle-value"
									}
								/>
								<span className="ta-toggle-switch-switch" />
							</label>
						</div>
					</div>
				</div>

				{/* Select section */}
				{
					selectedLevel.level_data.map((each) => {
						return (
							<div>
								<div className={each.is_editable?"training-levels":"training-levels disable-training-levels"}>
									<p className="sub-heading">{each.name}</p>
									<p className="sub-heading"></p>
									<div className="flex">
										<p className="label-text">
											<strong>Mandatory</strong>
										</p>
										<input
											type="checkbox"
											className="ta-toggle-switch-checkbox"
											name={each.id}
											id={each.id}
											value={each.is_mandatory}
											onChange={() => {
												if(each.is_editable)
													handleLevelDataChange(!each.is_mandatory, 'is_mandatory', each.type)
												else 
													nonEditPopup()
											}}
										/>
										<label
											className="ta-toggle-switch-label"
											htmlFor={each.id}
										>
											<span
												className={
													each.is_editable?each.is_mandatory == true
														? "ta-toggle-switch-inner"
														: "set-false-toggle-value":each.is_mandatory == true
														? "ta-toggle-switch-inner disabled-cursor"
														: "set-false-toggle-value disabled-cursor"
												}
											/>
											<span className="ta-toggle-switch-switch" />
										</label>
										<div className="threshold-input-wrapper">
										{!each.is_editable && <label
												htmlFor={`${each.id}_level_data`}
												className="threshold-label"
											>
											<div>Threshold </div> 
											</label>}
											<input
												type="number"
												name={`${each.id}_level_data`}
												id={`${each.id}_level_data`}
												className={!each.is_editable?"disabled-cursor threshold-input":"threshold-input"}
												disabled={!each.is_editable}
												value={each.passing_threshold}
												onChange={(e) => handleLevelDataChange(e.target.value, 'passing_threshold', each.type)}
												placeholder="Threshold/10"
											/>
											{each.is_editable && 
											<label
												htmlFor={`${each.id}_level_data`}
												className="threshold-input-label"
											>
												<button
												type="submit" 
												className="save-small" 
													onClick={() => {
														if (each.is_editable)
															return handleSubmit()
														else
															return nonEditPopup()
													}}
													>
													Save
												</button>
											</label>}
										</div>
										<button 
										onClick={() => {
											if(each.is_editable)
												return handleEditAllQuestion(each.type)
											else 
												return nonEditPopup()
										}}
											className={!each.is_editable?"ml-70 edit-button  color-blue bg-white disabled-cursor":"ml-70 edit-button  color-blue bg-white"}>
											{each.type === 1 ? 'Edit All Videos' : 'Edit All Questions'}
										</button>
									</div>
								</div>
							</div>
						);
					})}
			</div>}
		</>
	);
}

export default TrainingSubDetails;
