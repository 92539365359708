import React from "react";
import TrainingSubDetails from "./pricing_cms/TrainingModule/TrainingSubDetails/TrainingSubDetails";
import TrainingModule from "./pricing_cms/TrainingModule/TrainingModule";
import { useParams } from 'react-router-dom'
import MCQEdit from "./pricing_cms/TrainingModule/MCQEdit/MCQEdit"
import VideoEdit from "./pricing_cms/TrainingModule/VideosEdit/VideosEdit"
import TrainingFilter from "./pricing_cms/TrainingModule/TrainingFilter/TrainingFilter";

const  TrainingLeveleCms = ({content,filters,that}) => {

  const URL =  window.location.pathname
  const {level_id,type_id} = useParams()

  const renderScreen=()=> {
    if(URL.includes('type'))
    {
      if(type_id==="1")
      return <VideoEdit filters={filters}/>
      else if(type_id==="2")
      return <MCQEdit filters={filters}/>
    }
    else if(URL.includes('level'))
    {
      const selectedLevel = content.find((each) => String(each.id) === level_id)
      return <TrainingSubDetails content={selectedLevel} filters={filters} that={that}/>
    }
      else 
      return(
      <>
			<TrainingFilter onApplyFilter={that.handleFilterChange} />
      <TrainingModule content={content} filters={filters} that={that}/>  
      </> 
      )
  }
  return (
    <div style={{ marginTop: "20px" }}>{renderScreen()}</div>
    )
}

export default TrainingLeveleCms;
