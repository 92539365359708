import React from "react";
// import "./dashboard/dashboard.css"
import "./middleupper.css";

import Constants from "../constants";

class Row extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      out_of_stock : "",
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleRadio = this.handleRadio.bind(this);
  }
  handleRadio(rowData, e, type) {
      this.setState({out_of_stock : e.target.value})
      console.log(rowData, 'value', e.target.checked);
      let obj = rowData;
    if (type in obj) {
      obj[type] = Number(e.target.checked);

      let data = {};
      if ("package_id" in obj)
        data[obj['package_id']] = obj;
      else
        data[obj['service_id']] = obj;
        console.log(data)
      this.props.handleInputChange(data);
  }}
  handleButtonClick(type, event) {
    event.stopPropagation();
    // this.props.onRowClick(this.props.data, this.props.tag);

    this.props.actionButton(
      type,
      this.props.tag,
      this.props.data.uuid,
      this.props.data,
      this.props.data
    );
    /*this.setState(state => ({
          isToggleOn: !state.isToggleOn
        }));*/
  }
  handleClick(pageUrl) {
    //   this.props.onRowClick(this.props.data, this.props.tag);
    /*this.setState(state => ({
            isToggleOn: !state.isToggleOn
          }));*/
    //console.log()
    window.open(pageUrl);
  }
  handleInputBlur(rowData, e, type) {
    let obj = rowData;
    if (type in obj) {
      obj[type] = e.target.value;

      let data = {};
      if ("package_id" in obj)
        data[obj['package_id']] = obj;
      else
        data[obj['service_id']] = obj;
      this.props.handleInputChange(data);
    }
  }

  handleInputBlurForHomepage(rowData, e, type) {
    let obj = rowData;
    let data = {};
    if (type in obj) {
      obj[type] = e.target.value;
      if ("position" in obj) {
        data[obj['position']] = obj;
      }
      this.props.handleInputChange(obj);
    } else {
      obj["name"] = e.target.value;
      data[obj['name']] = obj;
      this.props.handleInputChange(obj);
    }
  }

  render() {
    switch (this.props.tag) {
      case "deals_pricing":
        let pricingDetails = this.props.data && this.props.data.price_details ? this.props.data.price_details : {};
        let service_id = pricingDetails ? pricingDetails.service_id : "";
        let workDone = pricingDetails && pricingDetails.work_done ? Constants.getWorkDoneText(pricingDetails.work_done) : "NA";
        let materialCost = pricingDetails ? pricingDetails.material_cost : "0";
        let labourCost = pricingDetails ? pricingDetails.labour_cost : "0";
        let category = this.props.category;
        let brand1 = pricingDetails ? pricingDetails.brand_1_name : "NA";
        let brand2 = pricingDetails ? pricingDetails.brand_2_name : "NA";
        let brand1_price = pricingDetails ? parseInt(pricingDetails.brand_1_price) : "0";
        let brand2_price = pricingDetails ? pricingDetails.brand_2_price : "0";
        let brandName = pricingDetails ? pricingDetails.brand : "NA";
        let tyreSize = pricingDetails ? pricingDetails.tyre_size : "NA";
        let productName = pricingDetails ? pricingDetails.product_name : "NA";
        let batteryBrandName = this.props.data.brand ? this.props.data.brand : "NA";
        let warranty = pricingDetails ? pricingDetails.warranty : "NA";
        let out_of_stock = pricingDetails ? pricingDetails.out_of_stock : 1
        let strike_through_price = pricingDetails ? pricingDetails.strike_through_price : "NA"
        let name = pricingDetails ? pricingDetails.name : "NA"
        let amp = pricingDetails && pricingDetails.ampere ? pricingDetails.ampere : "NA"

        return (
          < div >
            <div
              // style={{ background: "#eee" }}
              className={
                this.props.selectedRow === this.props.data.uuid
                  ? "boxes orders border-box clearfix leftalign blackspan9 selected"
                  : "boxes orders border-box clearfix leftalign blackspan9"
              }
            >
              <div className="textsize12 border-box weight500">
                <table className="stdtable middleUpperTable">
                  <tbody>
                    {category == 'Package' ?
                      (<tr className="middleUpperTR">
                        <td className="middleUpperTD width6TH">
                          {this.props.data.name ? this.props.data.name : "NA"}
                        </td>
                        <td className="middleUpperTD width3TH">
                          <input
                            type="text"
                            defaultValue={materialCost}
                            className="inputDealPrice"
                            onBlur={e => this.handleInputBlur(pricingDetails, e, "material_cost")}
                          />

                        </td>
                        <td className="middleUpperTD width2TH">
                          <input
                            type="text"
                            defaultValue={labourCost}
                            className="inputDealPrice"
                            onBlur={e => this.handleInputBlur(pricingDetails, e, "labour_cost")}
                          />
                        </td>
                        <td className="middleUpperTD width3TH">
                          {brand1}
                        </td>
                        <td className="middleUpperTD width2TH">
                          <input
                            type="text"
                            defaultValue={brand1_price}
                            className="inputDealPrice"
                            onBlur={e => this.handleInputBlur(pricingDetails, e, "brand_1_price")}
                          />
                        </td>
                        <td className="middleUpperTD width3TH">
                          {brand2}
                        </td>
                        <td className="middleUpperTD width2TH">
                          <input
                            type="text"
                            defaultValue={brand2_price}
                            className="inputDealPrice"
                            onBlur={e => this.handleInputBlur(pricingDetails, e, "brand_2_price")}
                          />
                        </td>

                        <td className="middleUpperTD width3TH">
                          {workDone}
                        </td>
                      </tr>) :

                      category == 'Tyre' ? (
                        <tr className="middleUpperTR">
                          <td className="middleUpperTD width1TH">
                            <input
                              type="text"
                              defaultValue={pricingDetails.name ? pricingDetails.name : "NA"}
                              className="inputDealPrice"
                              onBlur={e => this.handleInputBlur(pricingDetails, e, "name")}
                            />
                          </td>
                          <td className="middleUpperTD width2TH">
                            <input
                              type="text"
                              defaultValue={this.props.data.price_details.warranty ? this.props.data.price_details.warranty : "NA"}
                              className="inputDealPrice"
                              onBlur={e => this.handleInputBlur(pricingDetails, e, "warranty")}
                            />
                          </td>
                          <td className="middleUpperTD width3TH">
                            <input
                              type="text"
                              defaultValue={tyreSize}
                              className="inputDealPrice"
                              onBlur={e => this.handleInputBlur(pricingDetails, e, "tyre_size")}
                            />
                          </td>
                          <td className="middleUpperTD width3TH">
                            <input
                              type="text"
                              defaultValue={materialCost}
                              className="inputDealPrice"
                              onBlur={e => this.handleInputBlur(pricingDetails, e, "material_cost")}
                            />
                          </td>
                          <td className="middleUpperTD width2TH">
                            {brandName}
                          </td>
                          <td className="middleUpperTD width2TH">
                            <input
                              type="text"
                              defaultValue={this.props.data.price_details.brand_1_price ? this.props.data.price_details.brand_1_price : "NA"}
                              className="inputDealPrice"
                              onBlur={e => this.handleInputBlur(pricingDetails, e, "brand_1_price")}
                            />
                          </td>
                          <td className="middleUpperTD width6TH">
                            {workDone}
                          </td>
                        </tr>
                      ): 
                        category == 'Tyre with size' ? (
                          <tr className="middleUpperTR">
                            <td className="middleUpperTD width1TH">
                              <input
                                type="text"
                                defaultValue={this.props.data.price_details.name ? this.props.data.price_details.name : "NA"}
                                className="inputDealPrice"
                                onBlur={e => this.handleInputBlur(pricingDetails, e, "name")}
                              />
                            </td>
                            <td className="middleUpperTD width2TH">
                              <input
                                type="text"
                                defaultValue={this.props.data.price_details.warranty ? this.props.data.price_details.warranty : "NA"}
                                className="inputDealPrice"
                                onBlur={e => this.handleInputBlur(pricingDetails, e, "warranty")}
                              />
                            </td>
                            <td className="middleUpperTD width3TH">
                              <input
                                type="text"
                                defaultValue={this.props.data.price_details.tyre_size ? this.props.data.price_details.tyre_size : "NA" }
                                className="inputDealPrice"
                                onBlur={e => this.handleInputBlur(pricingDetails, e, "tyre_size")}
                              />
                            </td>
                            <td className="middleUpperTD width3TH">
                              <input
                                type="text"
                                defaultValue={materialCost}
                                className="inputDealPrice"
                                onBlur={e => this.handleInputBlur(pricingDetails, e, "material_cost")}
                              />
                            </td>
                            <td className="middleUpperTD width2TH">
                              {this.props.data.price_details.brand ? this.props.data.price_details.brand : "NA"}
                            </td>
                            <td className="middleUpperTD width2TH">
                              <input
                                type="text"
                                defaultValue={this.props.data.price_details.brand_1_price ? this.props.data.price_details.brand_1_price : "NA"}
                                className="inputDealPrice"
                                onBlur={e => this.handleInputBlur(pricingDetails, e, "brand_1_price")}
                              />
                            </td>
                            <td className="middleUpperTD width6TH">
                              {workDone}
                            </td>
                          </tr>
                        ) : 

                        category == 'Battery' ? (
                          <tr className="middleUpperTR">
                            <td className="middleUpperTD width1TH">
                              <input
                                type="text"
                                defaultValue={this.props.data.price_details.name ? this.props.data.price_details.name : "NA"}
                                className="inputDealPrice"
                                onBlur={e => this.handleInputBlur(pricingDetails, e, "name")}
                                />
                            </td>
                            <td className="middleUpperTD width2TH">
                              <input
                                type="text"
                                defaultValue={warranty}
                                className="inputDealPrice"
                                onBlur={e => this.handleInputBlur(pricingDetails, e, "warranty")}
                              />
                            </td>
                            <td className="middleUpperTD width1TH">
                              <input
                                type="text"
                                defaultValue={amp}
                                className="inputDealPrice"
                                onBlur={e => this.handleInputBlur(pricingDetails, e, "ampere")}
                              />
                            </td>
                            <td className="middleUpperTD width3TH">
                              <input
                                type="text"
                                defaultValue={materialCost}
                                className="inputDealPrice"
                                onBlur={e => this.handleInputBlur(pricingDetails, e, "material_cost")}
                              />
                            </td>
                            <td className="middleUpperTD width2TH">
                              {this.props.data.price_details.brand ? this.props.data.price_details.brand : "NA"}
                            </td>
                            <td className="middleUpperTD width2TH">
                              <input
                                type="text"
                                defaultValue={brand1_price}
                                className="inputDealPrice"
                                onBlur={e => this.handleInputBlur(pricingDetails, e, "brand_1_price")}
                              />
                            </td>
                            <td className="middleUpperTD width6TH">
                              {workDone}
                            </td>
                          </tr>
                        ) :
                          category == 'Battery with amp' ? (
                          <tr className="middleUpperTR">
                            <td className="middleUpperTD width1TH">
                              <input
                                type="text"
                                defaultValue={this.props.data.price_details.name ? this.props.data.price_details.name : "NA"}
                                className="inputDealPrice"
                                onBlur={e => this.handleInputBlur(pricingDetails, e, "name")}
                              />
                            </td>
                            <td className="middleUpperTD width2TH">
                              <input
                                type="text"
                                defaultValue={warranty}
                                className="inputDealPrice"
                                onBlur={e => this.handleInputBlur(pricingDetails, e, "warranty")}
                              />
                            </td>
                            <td className="middleUpperTD width1TH">
                              <input
                                type="text"
                                defaultValue={this.props.data.price_details.ampere ? this.props.data.price_details.ampere : "NA"}
                                className="inputDealPrice"
                                onBlur={e => this.handleInputBlur(pricingDetails, e, "ampere")}
                              />
                            </td>
                            <td className="middleUpperTD width3TH">
                              <input
                                type="text"
                                defaultValue={materialCost}
                                className="inputDealPrice"
                                onBlur={e => this.handleInputBlur(pricingDetails, e, "material_cost")}
                              />
                            </td>
                            <td className="middleUpperTD width2TH">
                              {this.props.data.price_details.brand ? this.props.data.price_details.brand : "NA"}
                            </td>
                            <td className="middleUpperTD width2TH">
                              <input
                                type="text"
                                defaultValue={this.props.data.price_details.brand_1_price ? this.props.data.price_details.brand_1_price : "NA"}
                                className="inputDealPrice"
                                onBlur={e => this.handleInputBlur(pricingDetails, e, "brand_1_price")}
                              />
                            </td>
                            <td className="middleUpperTD width6TH">
                              {workDone}
                            </td>
                          </tr>
                        ) :
                         category == 'Accessories Product' ? (
                          <tr className="middleUpperTR">
                            <td className="middleUpperTH width2TH">
                            <input
                            type="text"
                            defaultValue={name}
                            className="inputDealPrice"
                            onBlur={e => this.handleInputBlur(pricingDetails, e, "name")}
                          /></td>
                          <td className="middleUpperTD width4TH">
                          <input
                            type="text"
                            defaultValue={materialCost}
                            className="inputDealPrice"
                            onBlur={e => this.handleInputBlur(pricingDetails, e, "material_cost")}
                          /></td>
                          <td className="middleUpperTD width4TH">
                          <input
                            type="text"
                            defaultValue={strike_through_price}
                            className="inputDealPrice"
                            onBlur={e => this.handleInputBlur(pricingDetails, e, "strike_through_price")}
                          /></td>
                          <td className="middleUpperTD-ic width4TH">
                            <div style={{padding:"25px"}}>
                              <input
                              // service_id
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                name={`toggleSwitchBanner${service_id}`}
                                id={`toggleSwitchBanner${service_id}`}
                                onChange={(e) => this.handleRadio(pricingDetails, e, "out_of_stock")}
                                // defaultChecked={out_of_stock}
                                checked={out_of_stock}
                              />
                              <label className="toggle-switch-label-inline-block" 
                              htmlFor={`toggleSwitchBanner${service_id}`}
                              >
                                <span className="toggle-switch-inner" />
                                <span className="toggle-switch-switch" style={{width:"0px"}} />
                              </label>
                            </div>
                          </td>
                          </tr>
                        ) :
                          (<tr className="middleUpperTR">
                            <td className="middleUpperTD width1TH">
                              {this.props.data.name ? this.props.data.name : "NA"}
                            </td>
                            <td className="middleUpperTD colorprim500span width2TH">
                              1
                            </td>
                            <td className="middleUpperTD width3TH">
                              <input
                                type="text"
                                defaultValue={materialCost}
                                className="inputDealPrice"
                                onBlur={e => this.handleInputBlur(pricingDetails, e, "material_cost")}
                              />
                              {/* {this.props.data.price_details.material_cost
                          ? this.props.data.price_details.material_cost
                          : "0"} */}
                            </td>
                            <td className="middleUpperTD width2TH">
                              <input
                                type="text"
                                defaultValue={labourCost}
                                className="inputDealPrice"
                                onBlur={e => this.handleInputBlur(pricingDetails, e, "labour_cost")}
                              />
                              {/* {this.props.data.price_details.labour_cost
                          ? this.props.data.price_details.labour_cost
                          : "NA"} */}
                            </td>
                            <td className="middleUpperTD width2TH">
                              {this.props.data.brand
                                ? this.props.data.brand.name
                                : "NA"}
                            </td>
                            <td className="middleUpperTD width6TH">
                              {workDone}
                            </td>
                            {/* <td className="middleUpperTD">
                        {this.props.data.fuel_type
                          ? this.props.data.fuel_type.name
                          : "NA"}
                      </td> */}
                            {/* <td className="middleUpperTD width7TH">
                        <div>
                         
                          <button
                            className="btn btn_small btncard move btndeleteright btnTable"
                            // onClick={(e) => this.handleButtonClick("delete", e)}
                          >
                            <img
                              style={{ margin: "-2px" }}
                              src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/delete.png"
                            />
                            <span style={{ paddingLeft: "5px" }}>Delete</span>
                          </button>
                        </div>
                      </td> */}
                          </tr>)
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div >
        );
      // Manage HomePage
      case "manage_homepage":
        let manageHomePageData = this.props.data;
        let manageHomePageKeys = manageHomePageData.position;
        let manageHomePageValues = manageHomePageData.name;

        return (
          < div >
            <div
            >
              <div className="textsize12 border-box weight500">
                <table className="stdtable middleUpperTable">
                  <tbody>
                      <tr className="middleUpperTR">
                        <td className="middleUpperTD width1TH">
                          <input
                            type="text"
                            defaultValue={manageHomePageKeys}
                            className="inputDealPrice"
                            onBlur={e => this.handleInputBlurForHomepage(manageHomePageData, e, "position")}
                          />

                        </td>
                        <td className="middleUpperTD width1TH">
                          <input
                            type="text"
                            defaultValue={manageHomePageValues}
                            className="inputDealPrice"
                            onBlur={e => this.handleInputBlurForHomepage(manageHomePageData, e, "category_name")}
                          />
                        </td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div >
        );
      default:
        return null;
    }
  }
}

export default Row;
