import React from 'react';
import Topbar from './topbar';
import SupportChat from './support_chat/support_chat';
import {Helmet} from "react-helmet";
import HttpsRedirect from 'react-https-redirect';

function App() {
  //console.log("protocol", window.location.protocol);
  //console.log("href", window.location.href);
  // if (window.location.protocol !== 'https:') {
  //   window.location = 'https:' + window.location.href.substring(window.location.protocol.length)
  //   return // to stop app from mounting
  // }
  return (
    <React.Fragment>
      <Helmet>
        <title>GoMechanic Support</title>
      </Helmet>
    <div id="containermain1">
    <Topbar />

    <SupportChat />
    </div>
    </React.Fragment>
  );
}

export default App;
