import React from "react";
import "./milesFilter.css";
import firebase from "../../../firebase";
import API from "../../../api";
import MultiSelect from "../../custom/MultiSelect/MultiSelect";

class MilesFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: [],
      filters: [],
      milesData: [],
      segments: [],
      country: "",
      isCarDetailsMultiple: true,
      filterData: {
        segments: [],
        mobile_number: "",
      },
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleApplyFilter = this.handleApplyFilter.bind(this);
    this.handelSearch = this.handelSearch.bind(this);
    this.afterFilterChange = this.afterFilterChange.bind(this);
    this.afterOptionChange = this.afterOptionChange.bind(this);
  }
  componentWillMount() {
    if (localStorage.getItem("segment_data") != undefined) {
      this.state.segments = JSON.parse(localStorage.getItem("segment_data"));
    }
    if (localStorage.getItem("country") != undefined) {
      this.state.country = localStorage.getItem("country");
    }
  }

  componentDidMount() {
    if (localStorage.getItem("filterData") != undefined) {
      this.setState({
        rateFilter: JSON.parse(localStorage.getItem("filterData")),
      });
    }
    if (localStorage.getItem("segment_data") == undefined) {
      firebase
        .database()
        .ref("/cms/segment/")
        .on(
          "value",
          (snapshot) => {
            var segments = [];
            segments = [
              { id: "select-all", name: "Select Segments" },
              ...snapshot.val(),
            ];
            this.setState({ segments: segments });
            localStorage.setItem(
              "segment_data",
              JSON.stringify(this.state.segments)
            );
          },
          (err) => {
            console.log("segments read failed from firebase", err.code);
          }
        );
    }
  }

  afterOptionChange() {
    // console.log(this.state.filters)
    this.props.setMilesFilter(this.state.filters)
    this.props.onApplyFilter(
      this.state.filters.id === 3 ? this.state.filters : null
    );
  }
  afterFilterChange() {
    console.log(this.state.filterData.segments)
    localStorage.setItem("filterData", JSON.stringify(this.state.filterData));
  }
  handleApplyFilter() {
    var that = this;
    that.props.onApplyFilter(this.state.filterData);
  }

  handelSearch() {
    console.log(this.state.filterData);
    this.props.onApplyFilter(this.state.filterData, "miles_filters");
    this.callSearchAPI(this.state.filterData.mobile_number);
  }
  handleInput(input) {
    console.log(input);
    this.setState({ filterData: { mobile_number: input ,segments:[]} });
  }

  callSearchAPI(phone_number) {
    localStorage.setItem(
      "miles_filters",
      JSON.stringify(this.state.filterData)
    );
    var that = this;
    API.getMilesUserDetail(phone_number)
      .then(function (response) {
        console.log("-----------", response);
        if (response.status) {
          // console.log(this.props)
          that.props.userInfoPass(response.data);
          that.setState({ milesData: response.data });
          localStorage.setItem("miles_data", JSON.stringify(response.data));
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  render() {
    const segments = this.state.segments;
    // const { mobile_number, filterData } = this.state;
    // const filters = this.state.filters;
    const milesType = [
      {
        id: 0,
        name: "Select Filter",
      },
      {
        id: 1,
        name: "Activate/Deactivate Miles",
      },
      {
        id: 2,
        name: "Miles Price List",
      },
      {
        id: 3,
        name: "Refund Miles",
      },
    ];
    return (
      <div className="mainFilterContainer">
        <div className="row-5 margin_10 margin_b_20">
          <div className="margin_r_16">
            <MultiSelect
              options={milesType}
              labelKey="name"
              checkbox_id="name"
              placeholder="Miles Type"
              selectedOptions={this.state.filters}
              onChange={(value) =>
                this.setState(
                  {
                    filters: value,
                  },
                  () => this.afterOptionChange()
                )
              }
            />
          </div>
          {this.state.filters && this.state.filters.id === 1 && (
            <React.Fragment>
              <div className="margin_r_16">
                <input
                  className="input-box"
                  type="text"
                  placeholder=" Enter Mobile Number"
                  value={this.state.filterData.mobile_number}
                  onChange={(e) => this.handleInput(e.target.value)}
                  style={{ height: "40px" }}
                />
              </div>
              {((this.state.filterData.mobile_number.length === 10 && this.state.country === "india") 
              || (this.state.filterData.mobile_number.length >= 8 && this.state.filterData.mobile_number.length <= 12  && this.state.country === "malaysia"))
              && (
                <div className="row-5">
                  <div className="blackspan65 weight700 floatright addContentBtnMargin">
                    <table className="stdtable">
                      <tbody>
                        <tr>
                          <td valign="center">
                            <button
                              id="addleadbtn"
                              className="btn btn_small btncolorprim btncard move"
                              onClick={this.handelSearch}
                            >
                              Search
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
          {this.state.filters && this.state.filters.id === 2 && (
            <>
              <div className="margin_r_16">
                <MultiSelect
                  multiple
                  options={segments}
                  labelKey="name"
                  checkbox_id="name"
                  placeholder="Select Segments"
                  // disabledItem is required prop for multi-select
                  disabledItems={[]}
                  selectedOptions={this.state.filterData.segments}
                  onChange={(value) =>
                    this.setState(
                      {
                        filterData: {
                          ...this.state.filterData,
                          segments: value,
                        },
                      },
                      () => this.afterFilterChange()
                    )
                  }
                />
              </div>
            </>
          )}
          {this.state.filters &&
            this.state.filters.id === 2 &&
            this.state.filterData.segments &&
            this.state.filterData.segments.length > 0 && (
            <div className="row-5">
                <div className="blackspan65 weight700 floatright addContentBtnMargin">
                  <table className="stdtable">
                    <tbody>
                      <tr>
                        <td valign="center">
                          <button
                            id="addleadbtn"
                            className="btn btn_small btncolorprim btncard move"
                            onClick={this.handleApplyFilter}
                          >
                            Apply Filter
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default MilesFilter;
