import React from "react";
import "./bookingFilter.css";
import firebase from "../../../firebase";
import MultiSelect from "../../custom/MultiSelect/MultiSelect";
import Constants from "../../../constants";

class BookingFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channel: [{
        "id" : 'select',
        "name" : "Select Channel"
      },{
        "id" : 1,
        "name" : "Website"
      }, {
        "id" : 2,
        "name" : "Retail App"
      }],
      type : [{
        "id" : 'select',
        "name" : "Select Type"
      },{
        "id" : "general",
        "name" : "General"
      }, {
        "id" : "luxury",
        "name" : "Luxury"
      }, {
        "id" : "accessories",
        "name" : "Accessories"
      }],
      city:[],
      bookingFilter: {
        channelType: [],
        type: [],
        cities:[],
      },
      showFilterDiv: false,
    };

    this.handleApplyFilter = this.handleApplyFilter.bind(this);
    this.afterFilterChange = this.afterFilterChange.bind(this);
  }
  componentWillMount() {
      this.state.showFilterDiv = true;
      
    if(localStorage.getItem("city_data") != undefined){  
        this.state.city = Constants.getCityData();
    }
  }
  componentDidMount() {
    /* Setting Filters Data from localstorage*/
    if (localStorage.getItem("booking_filters") != undefined) {
      this.setState({
        bookingFilter: JSON.parse(localStorage.getItem("booking_filters")),
      });
    }
    if(localStorage.getItem("city_data") == undefined){
      firebase.database().ref('/cms/cities').on(
          'value',
          (snapshot) => {
              var cityOptions = [];
              cityOptions = [{id:'select-all',name:'Select All'}, ...snapshot.val()]
              this.setState({city:cityOptions});
              localStorage.setItem("city_data",JSON.stringify(this.state.city));
          },
          (err) => {
          console.log('cities read failed from firebase', err.code);
          },
      );
    }
    
  }
  afterFilterChange(field) {
    field = field || null;
    
    localStorage.setItem(
      "booking_filters",
      JSON.stringify(this.state.bookingFilter)
    );
  }


  handleApplyFilter() {
    this.props.onApplyFilter(this.state.bookingFilter, 'booking_filters');
  }
  render() {
    const {
      showFilterDiv,
      channel,
      type,
      city,
      bookingFilter,
    } = this.state;

    return (
      <div className="mainFilterContainer">
        <div
          className="row-5 margin_10 margin_b_20"
          style={{ gridTemplateColumns: "repeat(6,15%)" }}
        >
          <div className="margin_r_16">
            <MultiSelect
              options={channel}
              labelKey="name"
              checkbox_id="name"
              placeholder="Channel Type"
              disabledItems={[]}
              selectedOptions={this.state.bookingFilter.channelType}
              onChange={(value) =>
                this.setState(
                  {
                    bookingFilter: {
                      ...this.state.bookingFilter,
                      channelType: value,
                    },
                    showFilterDiv: true,
                  },
                  () => this.afterFilterChange()
                )
              }
            />
          </div>
          {showFilterDiv && (
            <React.Fragment>
              {bookingFilter.channelType && bookingFilter.channelType.name && (
                <React.Fragment>
                     <React.Fragment>
                        <div className="margin_r_16">
                          <MultiSelect
                            options={type}
                            labelKey="name"
                            checkbox_id="name"
                            placeholder="Select Type"
                            disabledItems={[]}
                            selectedOptions={this.state.bookingFilter.type}
                            onChange={(value) =>
                              this.setState(
                                {
                                  bookingFilter: {
                                    ...this.state.bookingFilter,
                                    type: value,
                                  },
                                },
                                () => this.afterFilterChange()
                              )
                            }
                          />
                        </div>
                    </React.Fragment>
                    <React.Fragment>
                        <div className="margin_r_16">
                          <MultiSelect
                            multiple
                            options={city}
                            labelKey="name"
                            checkbox_id="id"
                            placeholder="Select Cities"
                            disabledItems={[]}
                            selectedOptions={this.state.bookingFilter.cities}
                            onChange={(value) =>
                              this.setState(
                                {
                                  bookingFilter: {
                                    ...this.state.bookingFilter,
                                    cities: value,
                                  },
                                },
                                () => this.afterFilterChange()
                              )
                            }
                          />
                        </div>
                    </React.Fragment>
                </React.Fragment>
              )}
              
              
              
              <div className="blackspan65 weight700 floatright addContentBtnMargin">
                <button
                  style={{
                    background: "rgb(105,118,148,1)",
                    color: "rgb(255, 255, 255)",
                  }}
                  id="addleadbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={this.handleApplyFilter}
                >
                  Apply Filter
                </button>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default BookingFilter;
