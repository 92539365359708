import React from "react";
import Topbar from "./topbar";
// import Dashboard from './content_website/dashboard';
import { Helmet } from "react-helmet";
import HttpsRedirect from "react-https-redirect";
import Dashboard from "./pricing_cms/dashboard";
import Footer from "./Footer";

function App() {
  return (
    <React.Fragment>
      <Helmet>
        <title>GoMechanic Dashboard</title>
      </Helmet>
      <div id="containermain1">
        <Topbar />

        <Dashboard />

        <Footer />
      </div>
    </React.Fragment>
  );
}

export default App;
