import React from "react";
import firebase from "../../../firebase";
import "./WhatsappTemplateFilter.css";
import MultiSelect from "../../custom/MultiSelect/MultiSelect";

class WhatsappTemplateFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channel: [{
        "id" : 'select',
        "name" : "Select Channel"
      },{
        "id" : "ml",
        "name" : "ML"
      }, {
        "id" : "wf",
        "name" : "WF"
      }],
      city:[],
      category : [{
        "id" : 'select',
        "name" : "Select Category"
      },{
        "id" : "periodic_services",
        "name" : "Periodic Service"
      }, {
        "id" : "normal_services",
        "name" : "Normal Service"
      },{
        "id" : "batteries",
        "name" : "Batteries"
      }, {
        "id" : "tyres",
        "name" : "Tyres"
      },{
        "id" : "default",
        "name" : "Default"
      }],
      templateFilter: {
        channelType: [],
        categories:[],
      },
      showFilterDiv: false,
    };

    this.handleApplyFilter = this.handleApplyFilter.bind(this);
    this.afterFilterChange = this.afterFilterChange.bind(this);
  }
  componentWillMount() {
      this.state.showFilterDiv = true;
   
      if(localStorage.getItem("city_data") != undefined){  
        this.state.city = JSON.parse(localStorage.getItem("city_data"));
    }
  }
  componentDidMount() {
    /* Setting Filters Data from localstorage*/
    if (localStorage.getItem("template_filters") != undefined) {
      this.setState({
        templateFilter: JSON.parse(localStorage.getItem("template_filters")),
      });
    }
  }
  afterFilterChange(field) {
    field = field || null;
    
    localStorage.setItem(
      "template_filters",
      JSON.stringify(this.state.templateFilter)
    );
  }


  handleApplyFilter() {
    this.props.onApplyFilter(this.state.templateFilter, 'template_filters');
  }
  render() {
    const {
      channel,
      city,
      category
    } = this.state;

    return (
      <div className="mainFilterContainer">
        <div
          className="row-5 margin_10 margin_b_20"
          style={{ gridTemplateColumns: "repeat(6,15%)" }}
        >
          <div className="margin_r_16">
            <MultiSelect
              options={channel}
              labelKey="name"
              checkbox_id="name"
              placeholder="Channel Type"
              disabledItems={[]}
              selectedOptions={this.state.templateFilter.channelType}
              onChange={(value) =>
                this.setState(
                  {
                    templateFilter: {
                      ...this.state.templateFilter,
                      channelType: value,
                    },
                    showFilterDiv: true,
                  },
                  () => this.afterFilterChange()
                )
              }
            />
          </div>
          {this.state.templateFilter.channelType.id && (
             <React.Fragment>
                <React.Fragment>
                    <div className="margin_r_16">
                      <MultiSelect
                        // multiple
                        options={category}
                        labelKey="name"
                        checkbox_id="name"
                        placeholder="Select Category"
                        disabledItems={[]}
                        selectedOptions={this.state.templateFilter.categories}
                        onChange={(value) =>
                          this.setState(
                            {
                              templateFilter: {
                                ...this.state.templateFilter,
                                categories: value,
                              },
                            },
                            () => this.afterFilterChange()
                          )
                        }
                      />
                    </div>
                </React.Fragment>
                <React.Fragment>
                    <div className="blackspan65 weight700 floatright addContentBtnMargin">
                      <button
                        style={{
                          background: "rgb(105,118,148,1)",
                          color: "rgb(255, 255, 255)",
                        }}
                        id="addleadbtn"
                        className="btn btn_small btncolorprim btncard move"
                        onClick={this.handleApplyFilter}
                      >
                        Apply Filter
                      </button>
                    </div>
                </React.Fragment>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default WhatsappTemplateFilter;
