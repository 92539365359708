import React from 'react'
// import "./dashboard/dashboard.css"

import Constants from "../constants";

class Row extends React.Component {
    constructor(props) {
      super(props);
  
      this.handleClick = this.handleClick.bind(this);
    }

    // componentDidMount() {
    //     console.log("chatRef1");
    //     // console.log(this.props.ref.current);
    //     console.log("referring");
    //     console.log(this.ref);
    //     // this.scrollToBottom();
    //     this.props.scrollChat();
    //   }
  
      handleClick() {
        //   console.log("clicking");
          this.props.onRowClick(this.props.data, this.props.tag);
          /*this.setState(state => ({
            isToggleOn: !state.isToggleOn
          }));*/
      }
  
    render() {
        // console.log(this.props);
        // console.log(this.props.tag);
        // console.log(Constants.orderTypes()[this.props.data.status_id]);
        var date = new Date(this.props.data.time);

        var dateString = Constants.get_TimeString(date);

        const se_users = JSON.parse(sessionStorage.getItem("se_users"));
        var senderInternalID = parseInt(this.props.data.senderInternalID);
        var senderInternalName = null;
        if (senderInternalID in se_users){
            senderInternalName = se_users[senderInternalID]['name'];
        }
        // console.log("senderInternalID", senderInternalID);
        // console.log("senderInternalName", senderInternalName);
        // const 

        // var textH = ""; var textM = "";

        // if (this.props.data.notification){
        //     textH = this.props.data.type.toUpperCase();
        //     textM = this.props.data.text.toUpperCase();
        // } else{
        //     textH = '';
        //     textM = this.props.data.text;
        // }

        // console.log("botting");
        // console.log(this.props.data.senderName);
        
        if(!this.props.data.senderName){
            dateString = Constants.get_DateString1Date(date);
            return(
                // <div className="message clearfix border-box">
                //     <div className="divider centeralign border-box clearfix weight700">
                //     <table className="stdtable"><tr>
                //         <td valign="center"><div className="dividerline bgblack7"></div></td>
                //         <td valign="center" style={{width : "auto", padding : "0px 20px"}}>{dateString}</td>
                //         <td valign="center"><div className="dividerline bgblack7"></div></td>
                //     </tr></table>
                //     </div>
                // </div>
                <div className="message clearfix border-box">
                    <div className="buffer" />
                    <div className="divider centeralign border-box clearfix weight700">
                        <div className="dividerline bgblack9"></div>
                        <div className="dividertext blackspan7">{dateString}</div>
                        <div className="dividerline bgblack9"></div>
                    </div>
                    <div className="buffer" />
                </div>
            );
        }
        else if(this.props.data.senderName != 'bot'){
            if(this.props.prevModel && this.props.prevModel.senderUserID == this.props.data.senderUserID){
                if(Constants.get_TimeComparison1(this.props.prevModel.time, this.props.data.time)){
                    return(
                        <div className="message clearfix border-box">
                        <div className="others leftalign border-box">
                        {/* <div className="sender border-box clearfix">
                        <div className="senderimage border-box">
                            <img height="10px" width="10px" src="images/face_blue.png" />
                        </div>
                        </div> */}
                        <div className={this.props.searchText && this.props.data.textM.toLowerCase().indexOf(this.props.searchText.toLowerCase()) != -1 ? "text border-box clearfix leftalign bgcolorprim500 whitespan" : "text border-box clearfix leftalign bgwhite1 blackspan4"}><span>
                        {this.props.data.textM}
                        </span></div>
                        {this.props.nextModel && this.props.data.senderUserID == this.props.nextModel.senderUserID ? null : (<div className="buffer" />) }
                        </div>
                        </div>
                    );
                }else{
                    return(
                        <div className="message clearfix border-box">
                        <div className="others leftalign border-box">
                        <div className="sender border-box clearfix">
                        <div className="senderimage border-box">
                            <img height="10px" width="10px" src="images/face_blue.png" />
                        </div>
                        <div className="sendertext border-box">
                        <div className="sendertime border-box ccspan leftalign">
                            {dateString}
                        </div>
                        </div>
                        </div>
                        <div className={this.props.searchText && this.props.data.textM.toLowerCase().indexOf(this.props.searchText.toLowerCase()) != -1 ? "text border-box clearfix leftalign bgcolorprim500 whitespan" : "text border-box clearfix leftalign bgwhite1 blackspan4"}><span>
                        {this.props.data.textM}
                        </span></div>
                        {this.props.nextModel && this.props.data.senderUserID == this.props.nextModel.senderUserID ? null : (<div className="buffer" />) }
                        </div>
                        </div>
                    );
                }
            }else{
                return(
                    <div className="message clearfix border-box">
                    <div className="others leftalign border-box">
                    <div className="sender border-box clearfix">
                    <div className="senderimage border-box">
                        <img height="30px" width="30px" src="images/face_blue.png" />
                    </div>
                    <div className="sendertext border-box">
                    <div className="sendername border-box blackspan leftalign">
                        {this.props.data.senderName}
                    </div>
                    <div className="sendertime border-box ccspan leftalign">
                        {dateString}
                    </div>
                    </div>
                    </div>
                    <div className={this.props.searchText && this.props.data.textM.toLowerCase().indexOf(this.props.searchText.toLowerCase()) != -1 ? "text border-box clearfix leftalign bgcolorprim500 whitespan" : "text border-box clearfix leftalign bgwhite1 blackspan4"}><span>
                    {this.props.data.textM}
                    </span></div>
                    {this.props.nextModel && this.props.data.senderUserID == this.props.nextModel.senderUserID ? null : (<div className="buffer" />) }
                    </div></div>	
                );
            }
        }else{
            if(true){
                if(this.props.prevModel && this.props.prevModel.senderUserID == this.props.data.senderUserID && this.props.prevModel.senderInternalID == this.props.data.senderInternalID){
                    if(Constants.get_TimeComparison1(this.props.prevModel.time, this.props.data.time)){
                        return(
                            <div className="message clearfix border-box">
                            <div className="self rightalign border-box">
                            
                
                                <div className={this.props.searchText && this.props.data.textM.toLowerCase().indexOf(this.props.searchText.toLowerCase()) != -1 ? "text border-box clearfix rightalign bgcolorprim500 whitespan" : "text border-box clearfix rightalign bgwhite7 blackspan4"}>
                                {this.props.data.textM}
                                </div>

                                {this.props.nextModel && this.props.data.senderUserID == this.props.nextModel.senderUserID ? null : (<div className="buffer" />) }
                            </div></div>
                        );
                    }else{
                        if(this.props.data.notification){
                            return(
                                <div className="message clearfix border-box">
                                <div className="self rightalign border-box">
                                <div className="sender border-box clearfix">
                                <div className="sendertext border-box clearfix">
                                
                                <div className="sendertime border-box ccspan rightalign">
                                {dateString}
                                </div>
                                </div>
                                <div className="senderimage border-box">
                                    <img height="10px" width="10px" src="images/app_icon_rounded-1.png" />
                                </div>
                                </div>
                    
                                <div className="textNotif border-box clearfix rightalign">
                                <div className="textNotifHeader border-box clearfix">
                                {this.props.data.textH}
                                </div>
                                <div className="textNotifMessage border-box clearfix">
                                {this.props.data.textM}
                                </div>
                                </div>

                                    {this.props.nextModel && this.props.data.senderUserID == this.props.nextModel.senderUserID ? null : (<div className="buffer" />) }
                                </div></div>
                                );
                        }else{
                            return(
                                <div className="message clearfix border-box">
                                <div className="self rightalign border-box">
                                <div className="sender border-box clearfix">
                                <div className="sendertext border-box clearfix">
                                
                                <div className="sendertime border-box ccspan rightalign">
                                {dateString}
                                </div>
                                </div>
                                <div className="senderimage border-box">
                                    <img height="10px" width="10px" src="images/app_icon_rounded-1.png" />
                                </div>
                                </div>
                    
                                    <div className={this.props.searchText && this.props.data.textM.toLowerCase().indexOf(this.props.searchText.toLowerCase()) != -1 ? "text border-box clearfix rightalign bgcolorprim500 whitespan" : "text border-box clearfix rightalign bgwhite7 blackspan4"}>
                                    {this.props.data.textM}
                                    </div>

                                    {this.props.nextModel && this.props.data.senderUserID == this.props.nextModel.senderUserID ? null : (<div className="buffer" />) }
                                </div></div>
                            );
                        }
                    }
                }else{
                    if(this.props.data.notification){
                        return(
                            <div className="message clearfix border-box">
                            <div className="self rightalign border-box">
                            <div className="sender border-box clearfix">
                            <div className="sendertext border-box clearfix">
                            <div className="sendername border-box blackspan rightalign">
                            {/* if (child.val().senderInternalID != null){ */}
                                {/* innerHTML += "Derbii Team (" + internalIDs[child.val().senderInternalID] + ")"; */}
                            {/* }else{ */}
                            {senderInternalName ? (senderInternalName) : "GoMechanic"}
                            {/* } */}
                            </div>
                            <div className="sendertime border-box ccspan rightalign">
                            {dateString}
                            </div>
                            </div>
                            <div className="senderimage border-box">
                                <img height="30px" width="30px" src="images/app_icon_rounded-1.png" />
                            </div>
                            </div>
                
                            <div className="textNotif border-box clearfix rightalign">
                            <div className="textNotifHeader border-box clearfix">
                            {this.props.data.textH}
                            </div>
                            <div className="textNotifMessage border-box clearfix">
                            {this.props.data.textM}
                            </div>
                            </div>
    
                                {this.props.nextModel && this.props.data.senderUserID == this.props.nextModel.senderUserID ? null : (<div className="buffer" />) }
                            </div></div>
                        );
                    }else{
                        return(
                            <div className="message clearfix border-box">
                            <div className="self rightalign border-box">
                            <div className="sender border-box clearfix">
                            <div className="sendertext border-box clearfix">
                            <div className="sendername border-box blackspan rightalign">
                            {/* if (child.val().senderInternalID != null){ */}
                                {/* innerHTML += "Derbii Team (" + internalIDs[child.val().senderInternalID] + ")"; */}
                            {/* }else{ */}
                            {senderInternalName ? (senderInternalName) : "GoMechanic"}
                            {/* } */}
                            </div>
                            <div className="sendertime border-box ccspan rightalign">
                            {dateString}
                            </div>
                            </div>
                            <div className="senderimage border-box">
                                <img height="30px" width="30px" src="images/app_icon_rounded-1.png" />
                            </div>
                            </div>
                
                                <div className={this.props.searchText && this.props.data.textM.toLowerCase().indexOf(this.props.searchText.toLowerCase()) != -1 ? "text border-box clearfix rightalign bgcolorprim500 whitespan" : "text border-box clearfix rightalign bgwhite7 blackspan4"}>
                                {this.props.data.textM}
                                </div>
    
                                {this.props.nextModel && this.props.data.senderUserID == this.props.nextModel.senderUserID ? null : (<div className="buffer" />) }
                            </div></div>
                        );
                    }
                }
            }
            
        }
        
        // $(".chat").append(innerHTML);

        // $('.chat').scrollTop($('.chat').prop("scrollHeight"));
        
    }
  }

export default Row;