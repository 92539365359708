// import React from 'react';
import Constants from './constants';
import ChatUtilsArchive from './support_chat/chat_utils_archive';
import { ENGINE_METHOD_NONE } from 'constants';
import { isEmptyObject } from 'jquery';
// import { channel } from 'diagnostics_channel';

const axios = require('axios');

function getConfig(token) {
	return { headers: { Authorization: "Bearer " + token } };
}

function getConfigWithParams(token, params) {
	return { headers: { Authorization: "Bearer " + token }, params: params };
}

class API {
    getSubServicesDataAPI(subService) {
        return axios.get(Constants.getBaseURL() + `api/v1/oauth/web_content/cms_category_sub_category_data?category_id=${subService}`, getConfig(localStorage.getItem("access_token")));
    }

    getMilesUserDetail(phone_number) {
        return axios.get(Constants.getBaseURL() + `api/v1/oauth/web_content/get_user_cars_by_phone?phone=${phone_number}`, getConfig(localStorage.getItem("access_token")));
    }

    changeMileStatus(data) {
        // 
        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/miles_module', data, getConfig(localStorage.getItem("access_token")));
    }

    cancleMiles(data) {
        // order_id
        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/customer/refund_only_miles_order', data, getConfig(localStorage.getItem("access_token")));
    }

    getCMSPageType() {
        // console.log("req", data);
        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_select_page_path', getConfig(localStorage.getItem("access_token")));
    }
    getCategoryWiseServices() {
        let data = Constants.getFilterData('table');
        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_get_slug', {
            data
        }, getConfig(localStorage.getItem("access_token")));
    }
    getTagCategoryWiseServices() {
        let data = { "data": { "page_type": { "id": 4, "name": "service-detail" }, "service": "", "city": [11], "brands": [], "fuel_type": [], "models": [], "table": "table" } };
        let filters = JSON.parse(localStorage.getItem("service_filters"));
        let service = filters && filters.services ? filters.services : "";

        data['service'] = service;

        console.log('======>', data);
        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_get_slug', {
            data
        }, getConfig(localStorage.getItem("access_token")));
    }
    updateContentWebsite(data) {
        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_update', {
            data
        }, getConfig(localStorage.getItem("access_token")));
    }

    addContentWebsite(data) {
        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_save', {
            data
        }, getConfig(localStorage.getItem("access_token")));
    }

    addRetailAppFaqList(data) {
        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_retail_faq/', {
            data
        }, getConfig(localStorage.getItem("access_token")));
    }

    deleteContentWebsite(data) {
        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_delete', {
            data
        }, getConfig(localStorage.getItem("access_token")));
    }

    updateDealPricingDetails(that, pricingData) {
        let dealPricingData = Constants.getPricingData();
        dealPricingData['data'] = pricingData;
        console.log(that, "req", dealPricingData);

        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_save_retail_prices', {
            dealPricingData
        }, getConfig(localStorage.getItem("access_token")));
    }

    updateSlotBookingDetails(bookingData, milesTextData) {
        let slotBookingData = Constants.getSlotBookingData();
        slotBookingData['data'] = bookingData;
        slotBookingData['milesTextEnabled'] = milesTextData;
        console.log("req", slotBookingData);

        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_post_time_slot', {
            slotBookingData
        }, getConfig(localStorage.getItem("access_token")));
    }
    leadStatusAssignment(garage_id, isLeadAssignment) {
        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/garages/ga_wallet_update',
            {
                "garage_id": garage_id,
                "lead_assignment_enabled": isLeadAssignment
            }
            , getConfig(localStorage.getItem("access_token")));
    }
    updateGarageMasking(garage_id, isMasked) {
        return axios.put(Constants.getBaseURL() + 'api/v1/oauth/garages/update_mask_garage',
            {
                "garage_id": garage_id,
                "mask_required": isMasked
            }
            , getConfig(localStorage.getItem("access_token")));
    }
    updateCarouselImages(data) {
        console.log(data, "req");

        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/update_cms_carousel',
            data
            , getConfig(localStorage.getItem("access_token")));
    }
    updateCarouselImagesForCombosAndTopseller(data) {
        console.log(data, "req");

        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_update_combos_and_topseller_data',
            data
            , getConfig(localStorage.getItem("access_token")));
    }
    updateSectionTitles(data) {
        return axios.post(
            Constants.getBaseURL() + "api/v1/oauth/web_content/cms_update_sectionwise_title_and_subtitle",
            data,
            getConfig(localStorage.getItem("access_token"))
        );
    }
    updateCatBannerImages(data) {

        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_update_sub_category_image',
            data
            , getConfig(localStorage.getItem("access_token")));
    }
    updateRatePrices(that, pricingData) {
        let rateContrlData = Constants.getRateControlData('post');
        console.log("req", rateContrlData);

        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_update_max_service_prices', {
            "data": rateContrlData, "price_data": pricingData
        }, getConfig(localStorage.getItem("access_token")));
    }
    updateDriverOTP(garage_id, isOTPEnabled) {
        return axios.put(Constants.getBaseURL() + 'api/v1/oauth/garages/otp_enabled_garage',
            {
                "garage_id": garage_id,
                "otp_enabled": isOTPEnabled
            }
            , getConfig(localStorage.getItem("access_token")));
    }
    updateClosedOrders(garage_id, isClosed) {
        return axios.put(Constants.getBaseURL() + 'api/v1/oauth/garages/order_disabled_garage',
            {
                "garage_id": garage_id,
                "ordering_disabled": isClosed
            }
            , getConfig(localStorage.getItem("access_token")));
    }
    tabClickCallContentWebsite(tag, that, table, filter) {
        // console.log(that,"tabbing");
        table = table || null;
        switch (tag) {
            // add price tag case
            case 'manage_service_tag':
                if (localStorage.getItem("service_filters") !== undefined) {
                    let filters = JSON.parse(localStorage.getItem("service_filters"));
                    let id = filters && filters.subService ? filters.subService.id : "";
                    let channel = filters && filters.channel ? filters.channel.id : ""; 
                    let category_id = filters && filters.services ? filters.services.id : "";
                    let service_id = channel && (channel == 1 || channel == 6) ? category_id : id;
                    if (channel == 3) {
                        service_id = filters.product.id;
                    }
                    console.log('web_filters', filter)
                    if(channel !== 4 && channel !== 5){
                        return axios.get(Constants.getBaseURL() + `api/v1/oauth/web_content/service_level_get_update?service_id=${service_id}&channel=${channel}`
                            , getConfigWithParams(localStorage.getItem("access_token")))
                            .then(function (response) {
                                if (response.data.status)
                                    that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag, showLoader: false });
                                else
                                    that.setState({ showMiddleContent: true, middleContent: [], middleTag: tag, showLoader: false });
                            }).catch(function (error) {
                                that.setState({ showLoader: false });
                                console.log(error, 'Error in getting sub service tag');
                            })
                    } 
                    else if (channel === 4 && filter) {
                        let id = 47;
                        return axios.get(Constants.getBaseURL() + `api/v1/oauth/web_content/cms_get_ext_warranty_page?id=${id}`, getConfig(localStorage.getItem("access_token")))
                            .then(function (response) {
                                if (response.data.status)
                                    that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag, showLoader: false });
                                else
                                    that.setState({ showMiddleContent: true, middleContent: [], middleTag: tag, showLoader: false });
                            }).catch(function (error) {
                                that.setState({ showLoader: false });
                                console.log(error, 'Error in getting sub service tag');
                            })
                    } 
                    else if (channel === 5 && filter) {
                        let warrant_service_id = filter && filter.services && filter.services.id ? filter.services.id : ""
                        let payload = {
                        	"data": {
                                "id": warrant_service_id,
                        	}
                        };
                        if (warrant_service_id){
                            return axios.post(Constants.getBaseURL() + `api/v1/oauth/web_content/cms_get_ext_warr_coverage_section`, payload, getConfig(localStorage.getItem("access_token")))
                                .then(function (response) {
                                    if (response.data.status)
                                        that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag, showLoader: false });
                                    else
                                        that.setState({ showMiddleContent: true, middleContent: [], middleTag: tag, showLoader: false });
                                }).catch(function (error) {
                                    that.setState({ showLoader: false });
                                    console.log(error, 'Error in getting warranty coverage');
                                })
                        }else{
                            that.setState({ showMiddleContent: true, middleContent: {}, middleTag: tag, showLoader: false });
                        }
                    }
                    else{
                        that.setState({ showMiddleContent: true, middleContent: {}, middleTag: tag, showLoader: false });
                    }
                } else {
                    that.setState({ showMiddleContent: true, middleContent: {}, middleTag: tag, showLoader: false });
                }
                break;
            case 'whatsapp_template':
                if (localStorage.getItem("template_filters") != undefined) {
                    let filters = JSON.parse(localStorage.getItem("template_filters"));
                    let channel = filters && filters.channelType ? filters.channelType.id : "";
                    let category = filters && filters.categories ? filters.categories.id : "";
                    return axios.get(Constants.getBaseURL() + `api/v1/oauth/web_content/cms_get_whatsapp_text?channel=${channel}&category=${category}`
                        , getConfigWithParams(localStorage.getItem("access_token")))
                        .then(function (response) {
                            if (response.data.status)
                                that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag, showLoader: false });
                            else
                                that.setState({ showMiddleContent: true, middleContent: [], middleTag: tag, showLoader: false });
                        }).catch(function (error) {
                            that.setState({ showLoader: false });
                            console.log(error, 'Error in getting whatsapp template');
                        })
                } else {
                    that.setState({ showMiddleContent: true, middleContent: {}, middleTag: tag, showLoader: false });
                }
                break;
            case 'rate_control':
                axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_get_max_service_prices', {
                    "data": Constants.getRateControlData()
                }, getConfig(localStorage.getItem("access_token")))
                    .then(function (response) {
                        if (response.data.status)
                            that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag, showLoader: false });
                        else
                            that.setState({ showMiddleContent: true, middleContent: [], middleTag: tag, showLoader: false });
                    }).catch(function (error) {
                        // handle error
                        that.setState({ showLoader: false });
                        console.log(error, 'Error in deal pricing listing');
                    })
                break;
            case 'coupon_control':
                let discount_on_str = filter && filter.discountOn ? filter.discountOn.map((obj) => obj.id).join(","):"";   
                let filterObj = filter ? {...filter,discountOn:discount_on_str,discount_type : filter.discount_type ? filter.discount_type.id : '',offset : filter.offset ? filter.offset.id : '0'} : {limit:20 , offset: '0'};
                axios.get(Constants.getCentralBaseURL() + 'orders/coupons', getConfigWithParams(localStorage.getItem("access_token"), filterObj))
                .then(function (response) {
                    // console.clear()
                    // console.log("coupon_control",response.data.data.coupons);
                    // console.log("Api response", response.data.data);
                    that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag, showLoader: false });
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
                break;    
            case 'cms_carousel':
                let filters = JSON.parse(localStorage.getItem("image_filters"));
                let channel = filters && filters.channelType && filters.channelType.name ? filters.channelType.name.toLowerCase() : "";
                let version = filters && filters.version && filters.version.id ? filters.version.id : "";
                let city_id = [];
                let seg = "";
                let channel_sub_type = filters && filters.serviceComboChannels && filters.serviceComboChannels.channel_sub_type ? filters.serviceComboChannels.channel_sub_type : "";
                // let segment =  filters && filters.segment && filters.segment.name ? filters.segment.name : '';
                if (filters && filters.segment.length > 0) {
                     let newArr = filters.segment.filter(opt => opt.name!="Select All");
                     seg = Array.prototype.map.call(newArr, function(item) { return item.name; }).join(", ");
                }
                if (filters && filters.cities.length > 0) {
                    let temp = [];

                    filters.cities.filter((city) => {
                        if (city.id != 'select-all') {
                            temp.push(city.id);
                        }
                    })
                    city_id = temp;
                }
               

                let obj = {};
                obj['version'] = "3";
                obj['channel'] = channel;
                obj['city_id'] = city_id;

                if (
                    // channel != "vertical page" && 
                    channel != "square banner") {
                    obj['carousel'] = version;
                }

                if (channel == "vertical page") {
                    let size = filters && filters.size && filters.size.name ? filters.size.name : "";
                    obj['vertical_size'] = size;
                }
                if (channel == "category section") {
                    let size = filters && filters.size && filters.size.name ? filters.size.name : "";
                    obj['size'] = size;
                }

                if (channel == "service combos") {
                    obj['channel_sub_type'] = channel_sub_type;
                }

                if (channel == "middle banner") {
                    let type = filters && filters.carType && filters.carType.name ? filters.carType.name : "";
                    type = type.toLowerCase();
                    obj['type'] = type;
                }
                if(channel == "home bottom strip") {
                    obj['city_id'] = [1];
                }
                if (channel == 'app') {
                    obj['segment'] = seg;
                }
                
                // let city_id = filters && filters.cities && filters.cities.id ? filters.cities.id : "";
                axios.post(Constants.getBaseURL() + `api/v1/oauth/web_content/get_cms_carousel`, {
                    "data": obj
                }
                    , getConfigWithParams(localStorage.getItem("access_token")))
                    .then(function (response) {
                        if (response.data.status)
                            that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag, showLoader: false });
                        else
                            that.setState({ showMiddleContent: true, middleContent: [], middleTag: tag, showLoader: false });
                    }).catch(function (error) {
                        that.setState({ showLoader: false });
                        console.log(error, 'Error in getting carousel images');
                    })
                break;
            case 'workshop_masking':
                let obj1 = {}
                if (localStorage.getItem("workshop_filters") != undefined) {
                    let filters = JSON.parse(localStorage.getItem("workshop_filters"));
                    let garage_id = [];
                    if (filters.isRetailer && filters.isRetailer.id === '1') {
                        filters.workshops.filter((w) => {
                            if (w.id != 'select-all') {
                                garage_id.push(w.id);
                            }
                        })
                        obj1 = { "garage_id": garage_id, "channel": 1 };
                    }
                    else if (filters.isRetailer && filters.isRetailer.id === '2') {
                        filters.retailers.filter((w) => {
                            if (w.id != 'select-all') {
                                garage_id.push(w.id);
                            }
                        })
                        obj1 = { "garage_id": garage_id, "channel": 2 };
                    }
                    return axios.post(Constants.getBaseURL() + `api/v1/oauth/garages/get_garage_by_id`, obj1
                        , getConfigWithParams(localStorage.getItem("access_token")))
                        .then(function (response) {
                            if (response.data.status)
                                that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag, showLoader: false, web_filters: filters });
                            else
                                that.setState({ showMiddleContent: true, middleContent: [], middleTag: tag, showLoader: false });
                        }).catch(function (error) {
                            that.setState({ showLoader: false });
                            console.log(error, 'Error in getting booking slots');
                        })
                } else {
                    that.setState({ showMiddleContent: true, middleContent: {}, middleTag: tag, showLoader: false });
                }

                break;
            case 'activate_products':
                if (localStorage.getItem("products_filters")) {
                    let filters = JSON.parse(localStorage.getItem("products_filters"));
                    let cities_id = [];
                    filters.cities.filter((w) => {
                        if (w.id !== 'select-all') {
                            cities_id.push(w.id);
                        }
                        return 1
                    })
                    return (axios.get(Constants.getBaseURL() + `api/v1/oauth/web_content/cms_get_update_enable_disable_keys?city_id=${cities_id[0]}`, getConfigWithParams(localStorage.getItem("access_token")))
                            .then(function (response) {
                                if (response.data.status)
                                    that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag, showLoader: false, web_filters: filters });
                                else
                                    that.setState({ showMiddleContent: true, middleContent: {}, middleTag: tag, showLoader: false });
                            }).catch(function (error) {
                                that.setState({ showLoader: false });
                                console.log(error, 'Error in getting booking slots');
                            }))
                } else {
                    that.setState({ showMiddleContent: true, middleContent: {}, middleTag: tag, showLoader: false });
                }
                break;
            case 'slot_booking':
                let slotBookingData = Constants.getSlotBookingData();
                console.log(slotBookingData, "tabbing");

                axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_get_time_slot', {
                    slotBookingData
                }, getConfig(localStorage.getItem("access_token")))
                    .then(function (response) {
                        if (response.data.status)
                            that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag, showLoader: false });
                        else
                            that.setState({ showMiddleContent: true, middleContent: [], middleTag: tag, showLoader: false });
                    }).catch(function (error) {
                        that.setState({ showLoader: false });
                        console.log(error, 'Error in getting booking slots');
                    })
                break;
            case 'deals_pricing':
                let dealPricingData = Constants.getPricingData(table);
                axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_get_retail_prices', {
                    dealPricingData
                }, getConfig(localStorage.getItem("access_token")))
                    .then(function (response) {
                        if (response.data.status)
                            that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag, showLoader: false });
                        else
                            that.setState({ showMiddleContent: true, middleContent: [], middleTag: tag, showLoader: false });
                    }).catch(function (error) {
                        // handle error
                        that.setState({ showMiddleContent: true, middleContent: [], middleTag: tag, showLoader: false });
                        console.log(error, 'Error in deal pricing listing');
                    })
                break;
            case 'page_path_mapping':
                axios.get(Constants.getBaseURL() + 'api/v1/oauth/web_content/get_page_path_mapping/', getConfig(localStorage.getItem("access_token")))
                    .then(function (response) {
                        for (var i = 0; i < response.data.data.length; i++) {
                            response.data.data[i].id = response.data.data[i].page_id;

                            response.data.data[i].page_url =  `https:///gomechanic.in/${response.data.data[i].page_path}`;
                        }

                        that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag, showLoader: false });
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
                break;
            case 'faq_near_me':
            case 'testimonials':
            case 'service_guide':
            case 'seo_management':
                let data = Constants.getFilterData(table);
                let web_filters = JSON.parse(localStorage.getItem("web_filters"));
                let page_type = web_filters && web_filters.pageType ? web_filters.pageType.id : "";
                if(page_type === 5) {
                    axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_retail_faq/', {
                        data
                    }, getConfig(localStorage.getItem("access_token")))
                        .then(function (response) {
                            that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag, showLoader: false });
                        })
                        .catch(function (error) {
                            that.setState({ showMiddleContent: true, middleContent: [], middleTag: tag, showLoader: false });
                        })
                        .finally(function () {
                            // always executed
                        });
                }
                else {
                    axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_select', {
                        data
                    }, getConfig(localStorage.getItem("access_token")))
                        .then(function (response) {
    
                            for (var i = 0; i < response.data.data.length; i++) {
                                let page_url = Constants.getWebsiteUrl(response.data.data[i]);
                                let page_type = response.data.data[i].page_type.name;
    
                                response.data.data[i].page_path = page_type + page_url;
    
                                response.data.data[i].page_url = page_type == 'website' ? `https:///gomechanic.in${page_url}` : `https://gomechanic.in${page_url}`;
                            }
    
                            that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag, showLoader: false });
                        })
                        .catch(function (error) {
                            // that.setState({ showMiddleContent: true, middleContent: [], middleTag: tag, showLoader: false });
                            console.log(error, 'error message');
                        })
                        .finally(function () {
                            // always executed
                        });
                }
                break;
            case 'price_list':
                // console.log("tabbing_faq");
                axios.get(Constants.getBaseURL() + 'api/v1/oauth/web_content/get_nearme_price_list', getConfig(localStorage.getItem("access_token")))
                    .then(function (response) {
                        that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag, showLoader: false });
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
                break;
            case 'price_comparison':
                // console.log("tabbing_faq");
                axios.get(Constants.getBaseURL() + 'api/v1/oauth/web_content/get_nearme_price_comparison', getConfig(localStorage.getItem("access_token")))
                    .then(function (response) {
                        that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag, showLoader: false });
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
                break;
            case 'testimonials':
                // console.log("tabbing_faq");
                axios.get(Constants.getBaseURL() + 'api/v1/oauth/web_content/get_nearme_testimonial', getConfig(localStorage.getItem("access_token")))
                    .then(function (response) {
                        that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag, showLoader: false });
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
                break;
            case 'garages':
                axios.get(Constants.getBaseURL() + 'api/v1/oauth/garages/get_garages', getConfig(localStorage.getItem("access_token")))
                    .then(function (response) {
                        // console.log(response.data);
                        that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag });
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
                break;
            case "training_module":
            if (localStorage.getItem("training_filters") != undefined) {
                let filters = JSON.parse(localStorage.getItem("training_filters"));
                return axios
                    .get(
                        Constants.getBaseURL() +
                        `api/v1/oauth/cms/training/get_update_training_levels?workshop_id=${filters.workshop.id}&user_id=${filters.user.id}`,
                        getConfigWithParams(localStorage.getItem("access_token"))
                    )
                    .then(function (response) {
                        if (response.data.status)
                            that.setState({
                                showMiddleContent: true,
                                middleContent: response.data.data,
                                middleTag: tag,
                                showLoader: false,
                                web_filters: filters,
                            });
                        else
                            that.setState({
                                showMiddleContent: true,
                                middleContent: [],
                                middleTag: tag,
                                showLoader: false,
                            });
                    })
                    .catch(function (error) {
                        that.setState({
                            showLoader: false,
                            showMiddleContent: true,
                            middleContent: [],
                            middleTag: tag,
                        });
                        console.log(error, "Error in getting users");
                    });
            } else {
                that.setState({
                    showMiddleContent: true,
                    middleContent: {},
                    middleTag: tag,
                    showLoader: false,
                });
            }
            break;
            case 'manage_homepage':
                if (localStorage.getItem('homepage_filter') != undefined) {
                    let localStorageFilterData = JSON.parse(localStorage.getItem('homepage_filter'));
                    let subChannel = localStorageFilterData.subChannel;

                    if (localStorageFilterData && localStorageFilterData.subChannel && localStorageFilterData.subChannel.id === "2") {
                        axios.get(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_app_web_homepage_categories_position_update', getConfig(localStorage.getItem("access_token")))
                            .then(function (response) {
                                that.setState({
                                    showMiddleContent: true,
                                    middleContent: response.data.data,
                                    middleTag: tag,
                                    showLoader: false
                                });
                            })
                            .catch(function (error) {
                                // handle error
                                console.log(error);
                                that.setState({
                                    showLoader: false
                                });
                            })
                            .finally(function () {
                                // always executed
                            });
                    } else if (localStorageFilterData && localStorageFilterData.subChannel && localStorageFilterData.subChannel.id === "1") {
                        let channel = localStorageFilterData.channel.name;
                        let section = localStorageFilterData.section.name;
                        let section_type = section.toLowerCase();

                        axios.get(Constants.getBaseURL() + `api/v1/oauth/web_content/cms_app_web_homepage_section_update?channel=${channel}&section_type=${section_type}`,
                            getConfig(localStorage.getItem("access_token")))
                            .then(function (response) {
                                that.setState({
                                    showMiddleContent: true,
                                    middleContent: response.data.data,
                                    middleTag: tag,
                                    showLoader: false
                                });
                            })
                            .catch(function (error) {
                                // handle error
                                console.log(error);
                                that.setState({
                                    showLoader: false
                                });
                            })
                            .finally(function () {
                                // always executed
                            });
                    }		
                } else {
                    that.setState({
                        showMiddleContent: true,
                        middleContent: {},
                        middleTag: tag,
                        showLoader: false,
                    });
                }
            break;
            default:
            break;
    }
    }

    tabClickCall(tag, that) {
        switch (tag) {
            case 'orders':
                axios.get(Constants.getBaseURL() + 'api/v1/oauth/orders/get_orders', getConfig(localStorage.getItem("access_token")))
                    .then(function (response) {
                        // console.log(response.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            if (response.data.data[i].pick_date)
                                response.data.data[i].pick_date = (new Date(response.data.data[i].pick_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                            if (response.data.data[i].delivery_date)
                                response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                            if (response.data.data[i].estimated_delivery)
                                response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                        }
                        that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag });
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
                break;
            case 'fleets':
                axios.get(Constants.getBaseURL() + 'api/v1/oauth/fleets/get_fleets', getConfig(localStorage.getItem("access_token")))
                    .then(function (response) {
                        // console.log(response.data);
                        that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag });
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
                break;
            case 'garages':
                axios.get(Constants.getBaseURL() + 'api/v1/oauth/garages/get_garages', getConfig(localStorage.getItem("access_token")))
                    .then(function (response) {
                        // console.log(response.data);
                        that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag });
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
                break;
            default:
                break;
        }
    }



    tabSearchCall(tag, that, args) {
        switch (tag) {
            case 'orders':
                //delete args['fleets'];delete args['garages'];
                axios.post(Constants.getBaseURL() + 'api/v1/oauth/orders/search_orders',
                    args, getConfig(localStorage.getItem("access_token")))
                    .then(function (response) {
                        // console.log(response.data);
                        //that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
                        for (var i = 0; i < response.data.data.length; i++) {
                            // console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
                            if (response.data.data[i].pick_date)
                                response.data.data[i].pick_date = (new Date(response.data.data[i].pick_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                            if (response.data.data[i].delivery_date)
                                response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                            if (response.data.data[i].estimated_delivery)
                                response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                        }
                        that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag });
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
                break;
            case 'fleets':
                axios.get(Constants.getBaseURL() + 'api/v1/oauth/fleets/get_fleets', getConfig(localStorage.getItem("access_token")))
                    .then(function (response) {
                        // console.log(response.data);
                        that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag });
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
                break;
            case 'garages':
                axios.get(Constants.getBaseURL() + 'api/v1/oauth/garages/get_garages', getConfig(localStorage.getItem("access_token")))
                    .then(function (response) {
                        // console.log(response.data);
                        that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag });
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
                break;
            default:
                break;
        }
    }

    getStatuses(config1 = null) {
        if (config1 != null) {
            return axios.get(Constants.getBaseURL() + 'api/v1/oauth/orders/get_order_statuses', config1)
        } else {
            return axios.get(Constants.getBaseURL() + 'api/v1/oauth/orders/get_order_statuses', getConfig(localStorage.getItem("access_token")))
        }

    }
    get_fleets_summary1(config1 = null) {
        if (config1 != null) {
            return axios.get(Constants.getBaseURL() + 'api/v1/oauth/fleets/get_fleets_summary', config1)
        } else {
            return axios.get(Constants.getBaseURL() + 'api/v1/oauth/fleets/get_fleets_summary', getConfig(localStorage.getItem("access_token")))
        }

    }
    get_garages_summary1(config1 = null) {
        if (config1 != null) {
            return axios.get(Constants.getBaseURL() + 'api/v1/oauth/garages/get_garages_summary', config1)
        } else {
            return axios.get(Constants.getBaseURL() + 'api/v1/oauth/garages/get_garages_summary', getConfig(localStorage.getItem("access_token")))
        }

    }

    sendOTP(phone) {
        // return axios.post(Constants.getBaseURL()+'api/send_otp', {
        return axios.post(Constants.getBaseURL("otp") + 'api/send_otp/', {
            number: phone,
            source: "admindash"
        })
    }
    verifyLogin(phone, otp) {
        return axios.post(Constants.getBaseURL() + 'api/verify_login_web', {
            number: phone,
            otp: otp,
            session_key: sessionStorage.getItem("session_key"),
            source: "admindash_support"
        })
    }
    logout(phone, otp) {
        //console.log("access_token");
        // console.log(getConfig(localStorage.getItem("access_token")));
        return axios.post(Constants.getBaseURL() + 'api/logout', {

        }, getConfig(localStorage.getItem("access_token")))
    }

    get_fleets_summary() {
        axios.get(Constants.getBaseURL() + 'api/v1/oauth/fleets/get_fleets_summary', getConfig(localStorage.getItem("access_token")))
            .then(function (response) {
                //     console.log("fleeting");
                // console.log(response.data);
                Constants.setFleetsSummary(response.data.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }

    get_garages_summary() {
        axios.get(Constants.getBaseURL() + 'api/v1/oauth/garages/get_garages_summary', getConfig(localStorage.getItem("access_token")))
            .then(function (response) {
                // console.log(response.data);
                Constants.setGaragesSummary(response.data.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }

    getChatUserDetails(tag, that) {
        that.setState({ showLoader: true, showRightContent1: false, showRightContent2: false, rightContent1: NaN, rightContent2: NaN });
        axios.get(Constants.getBaseURL() + 'api/v1/oauth/profile/get_user_profile_chat', getConfigWithParams(localStorage.getItem("access_token"), { user_id: tag }))
            .then(function (response) {
                // console.log(response.data);
                //that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
                // for (var i = 0; i < response.data.data.length; i++) {
                //     console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
                //     if(response.data.data[i].pick_date)
                //         response.data.data[i].pick_date = (new Date(response.data.data[i].pick_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                //     if(response.data.data[i].delivery_date)
                //         response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                //     if(response.data.data[i].estimated_delivery)
                //         response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                //   }
                if (response.data.status)
                    that.setState({ showRightContent1: true, rightContent1: response.data.data, showLoader: false });
                else
                    that.setState({ showRightContent1: false, rightContent1: "no_user", showLoader: false });
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }

    getOrderDetailsPaginate(tag, data, that) {
        if (data.length != 0) {
            that.setState({ showLoader: true });
        }
        axios.get(Constants.getBaseURL() + 'api/v1/oauth/order/order_details_paginate', getConfigWithParams(localStorage.getItem("access_token"), { user_id: tag, limit: 5, offset: data.length }))
            .then(function (response) {
                // console.log(response.data);
                //that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
                // for (var i = 0; i < response.data.data.length; i++) {
                //     console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
                //     if(response.data.data[i].pick_date)
                //         response.data.data[i].pick_date = (new Date(response.data.data[i].pick_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                //     if(response.data.data[i].delivery_date)
                //         response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                //     if(response.data.data[i].estimated_delivery)
                //         response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                //   }
                if (response.data.status) {
                    if (data.length == 0) {
                        that.setState({ showRightContent2: true, rightContent2: response.data.data, showLoader: false });
                    } else {
                        if (response.data.data.length == 0)
                            that.setState({ showLoader: false });
                        else
                            that.setState({ showRightContent2: true, rightContent2: data.concat(response.data.data), showLoader: false });
                    }
                } else {
                    that.setState({ showLoader: false });
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }

    getSupportChatArchives(supportchat, offset, assigned_executive, that) {
        if (offset != 0) {
            that.setState({ showLeftLoader: true });
        }
        var params = {};
        if (assigned_executive) {
            params = { assigned_executive: localStorage.getItem("user_id"), limit: 15, offset: offset }
        } else {
            params = { limit: 15, offset: offset }
        }
        axios.get(Constants.getBaseURL() + 'api/v1/oauth/support/get_support_chat_archives', getConfigWithParams(localStorage.getItem("access_token"), params))
            .then(function (response) {
                // console.log(response.data);
                //that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
                // for (var i = 0; i < response.data.data.length; i++) {
                //     console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
                //     if(response.data.data[i].pick_date)
                //         response.data.data[i].pick_date = (new Date(response.data.data[i].pick_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                //     if(response.data.data[i].delivery_date)
                //         response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                //     if(response.data.data[i].estimated_delivery)
                //         response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
                //   }
                that.setState({ showLoader: false, showLeftLoader: false });
                if (response.data.status) {
                    if (offset == 0) {
                        ChatUtilsArchive.loadInitial(supportchat, response.data.data, false);
                    } else {
                        if (response.data.data.length == 0)
                            // that.setState({showLoader : false});
                            ChatUtilsArchive.loadInitial(supportchat, response.data.data, false);
                        else
                            // that.setState({showRightContent2 : true, rightContent2: data.concat(response.data.data), showLoader : false});
                            ChatUtilsArchive.loadInitial(supportchat, response.data.data, false);
                    }
                } else {
                    // that.setState({showLoader : false});
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }
    getRetailersByLocation(location_id) {
        return axios.get(Constants.getBaseURL() + `api/v1/oauth/garages/get_garages_by_location?location_id=${location_id}&channel=2`, getConfigWithParams(localStorage.getItem("access_token")))
    }
    getGaragesByLocation(location_id) {
        return axios.get(Constants.getBaseURL() + `api/v1/oauth/garages/get_garages_by_location?location_id=${location_id}&channel=1`, getConfigWithParams(localStorage.getItem("access_token")))
    }
    getLineItemForRateControl(term) {
        return axios.get(Constants.getBaseURL() + `api/v1/oauth/web_content/cms_get_part_name?line_item=${term}`, getConfig(localStorage.getItem("access_token")))
    }

    getWhatsappTemplate() {
        return axios.get(Constants.getBaseURL() + `api/v1/oauth/web_content/cms_get_whatsapp_text?channel=ml&category=batteries`, getConfigWithParams(localStorage.getItem("access_token")))
    }

    saveWhatsappTemplate(data) {
        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_update_whatsapp_text', {
            data
        }, getConfig(localStorage.getItem("access_token")))
    }
    updateGarageWalletDetails(data) {
        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/garages/ga_wallet_update',
            data, getConfig(localStorage.getItem("access_token")))
    }
    updateWalletDetails(data) {
        return axios.post('https://comms.gomechanic.app/api/v1/money/wallet/edit_by_user_segment',
            data, getConfig(localStorage.getItem("access_token")))
    }
    bulkUploadWalletCSV(data) {
        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_get_update_goapp_money',
            data, getConfig(localStorage.getItem("access_token")))
    }
    updateSingleUserWallet(data) {
        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_get_update_goapp_money',
            data, getConfig(localStorage.getItem("access_token")))
    }
    getSingleUserWalletAmount(data) {
        return axios.get(
            Constants.getBaseURL() + `api/v1/oauth/web_content/cms_get_update_goapp_money?mobile=${data}`,
            getConfigWithParams(localStorage.getItem("access_token"))
        );
    }
    getWeekDays() {
        return axios.get(Constants.getBaseURL() + `api/v1/oauth/web_content/cms_get_offer_weekdays`, getConfigWithParams(localStorage.getItem("access_token")))
    }
    saveWeekDays(data) {
        return axios.post(Constants.getBaseURL() + `api/v1/oauth/web_content/cms_post_offer_weekdays`,
            { "data": data },
            getConfigWithParams(localStorage.getItem("access_token")))
    }
    updateServiceTagDetails(data) {
        return axios.post(Constants.getBaseURL() + 'api/v1/oauth/web_content/service_level_get_update',
            data, getConfig(localStorage.getItem("access_token")))
    }
    updateServiceDetails(data) {
        return axios.post(
            Constants.getBaseURL() +
            "api/v1/oauth/web_content/cms_update_service_detail_page",
            data,
            getConfig(localStorage.getItem("access_token"))
        );
    }
    updateAccessoriesDetails(data) {
        return axios.post(
            Constants.getBaseURL() +
            "api/v1/oauth/web_content/cms_update_accessories_detail_page",
            data,
            getConfig(localStorage.getItem("access_token"))
        ); 
    }
    delServiceDetails(data) {
        return axios.post(
            Constants.getBaseURL() +
            "api/v1/oauth/web_content/cms_delete_service_inclusions",
            data,
            getConfig(localStorage.getItem("access_token"))
        );
    }

    removeStoryImage(data) {
        return axios.post(
            Constants.getBaseURL() +
            "api/v1/oauth/web_content/cms_delete_accessories_detail_page",
            data,
            getConfig(localStorage.getItem("access_token"))
        );
    }
    getTyreBatteryData(id) {
        return axios.get(Constants.getBaseURL() + `api/v1/oauth/web_content/cms_get_tyre_battery_detail?service_id=${id}`, getConfigWithParams(localStorage.getItem("access_token")))
    }

    getAccessoriesProductCat(data) {
        return axios.post(Constants.getBaseURL() + `api/v1/oauth/web_content/cms_get_accessories_product_data`,data,getConfig(localStorage.getItem("access_token")))
    }

    updateMilesSegment(data) {
        return axios.post(
            Constants.getBaseURL() +
            "api/v1/oauth/web_content/cms_update_segment_wise_material_cost",
            data,
            getConfig(localStorage.getItem("access_token"))
        );
    }

    getMilesSegmentInfo(name) {
        return axios.get(Constants.getBaseURL() + `api/v1/oauth/web_content/cms_update_segment_wise_material_cost?name=${name}`, getConfigWithParams(localStorage.getItem("access_token")))
    }  
    updateActiveProducts(data) {
        return axios.post(
          Constants.getBaseURL() +
            "api/v1/oauth/web_content/cms_get_update_enable_disable_keys",
          data,
          getConfig(localStorage.getItem("access_token"))
        );
    }
    firebaseData(that){
        axios.get(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_get_firebase_data_through_api', getConfig(localStorage.getItem("access_token")))
                    .then(function (response) {
                        if(response.data.status && !isEmptyObject(response.data.data) ){
                            localStorage.setItem('deal_type',JSON.stringify(response.data.data.deal_zone && response.data.data.deal_zone.length ? response.data.data.deal_zone : undefined));
                            localStorage.setItem('category_data',JSON.stringify( response.data.data.pricing_categories && response.data.data.pricing_categories.length? response.data.data.pricing_categories : undefined));
                            localStorage.setItem('segment_wise_models',JSON.stringify( response.data.data && response.data.data.segmentWiseModel? response.data.data.segmentWiseModel : undefined));
                            localStorage.setItem('brand_data',JSON.stringify( response.data.data.car_brands && response.data.data.car_brands.length? response.data.data.car_brands : undefined));
                            localStorage.setItem('model_data',JSON.stringify( response.data.data && response.data.data.brandWiseModel? response.data.data.brandWiseModel : undefined));
                            localStorage.setItem('segment_data',JSON.stringify( response.data.data.segment && response.data.data.segment.length? response.data.data.segment : undefined));
                            localStorage.setItem('fuel_data',JSON.stringify( response.data.data.fuelTypes && response.data.data.fuelTypes.length? response.data.data.fuelTypes : undefined));
                            that.setState({firebaseData:{...response.data.data}})}
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
    }

	getTrainingLevel(workshop_id,user_id) {
		return axios.get(
			Constants.getBaseURL() +
			`api/v1/oauth/cms/training/get_update_training_levels?workshop_id=${workshop_id}&user_id=${user_id}`,
			getConfigWithParams(localStorage.getItem("access_token"))
		);
	}

	getUsersByGarage(workshop_id) {
		return axios.get(
			Constants.getBaseURL() +
			`api/v1/oauth/cms/training/get_users?workshop_id=${workshop_id}`,
			getConfigWithParams(localStorage.getItem("access_token"))
		);
	}

	postAddNextLevelData(data) {
		return axios.post(
			Constants.getBaseURL() +
			`api/v1/oauth/cms/training/get_update_training_levels`,  data, getConfig(localStorage.getItem("access_token"))
		);
	}
	getMCQ(user_id, level_id, type) {
		return axios.get(
			Constants.getBaseURL() +
			`api/v1/oauth/cms/training/get_update_training_test_wise_data?user_id=${user_id}&level_id=${level_id}&type=${type}`,
			getConfig(localStorage.getItem("access_token"))
		);
	}
	postMCQ_VideoUpdate(data) {
		return axios.post(
		Constants.getBaseURL() +
			'api/v1/oauth/cms/training/get_update_training_test_wise_data', 
			data, 
			getConfig(localStorage.getItem("access_token"))
		)
	}
	deleteQuestionVideos(data){
		return axios.post(
		Constants.getBaseURL() +
			"api/v1/oauth/cms/training/delete_questions_videos",
			data,
			getConfig(localStorage.getItem("access_token"))
		);
	}

    getWarrantyPageFilterData(data) {
        let id = data.id;
        return axios.get(Constants.getBaseURL() + `api/v1/oauth/web_content/cms_get_ext_warranty_page?id=${id}`, getConfig(localStorage.getItem("access_token")))
    }

	handleMangaHomepagePosition(data) {
		return axios.post(
			Constants.getBaseURL() + 
			"api/v1/oauth/web_content/cms_app_web_homepage_categories_position_update",
			data, 
			getConfig(localStorage.getItem("access_token"))
		)
	}
	getHomepagePositionData() {
		axios.get(Constants.getBaseURL() + 'api/v1/oauth/web_content/cms_app_web_homepage_categories_position_update', 
			getConfig(localStorage.getItem("access_token")))
			.catch(function (error) {
				// handle error
				console.log(error);
			})
			.finally(function () {
				// always executed
			});
	}

	handleHomepageSectionUpdate(data) {
		return axios.post(
			Constants.getBaseURL() +
			"api/v1/oauth/web_content/cms_app_web_homepage_section_update",
			data,
			getConfig(localStorage.getItem("access_token"))
		)
	}

    getCouponDetails(id){
        return axios.get( Constants.getCentralBaseURL() + `orders/coupon/detail/${id}/?channel=cms`, getConfig(localStorage.getItem("access_token")));
    }
    updateCoupon(data){
        return axios.put(
          Constants.getCentralBaseURL() + `orders/coupon/detail/${data.id}/`,
          data,
          getConfig(localStorage.getItem("access_token"))
        );
    }
    createCoupon(data){
        return axios.post(
          Constants.getCentralBaseURL() + `orders/coupons/`,
          data,
          getConfig(localStorage.getItem("access_token"))
        );
    }
    getCouponPackages(){
        return axios.get( Constants.getCentralBaseURL() + `central/packages/`, getConfig(localStorage.getItem("access_token")));
    }
    getCouponServices(str){
        return axios.get( Constants.getCentralBaseURL() + `central/services/`, getConfig(localStorage.getItem("access_token")));
    }
    getCouponCities(){
        return axios.get( Constants.getCentralBaseURL() + `garages/cities/`, getConfig(localStorage.getItem("access_token")));
    }
    updateWarrantyPageData(data) {
        return axios.post(
            Constants.getBaseURL() + `api/v1/oauth/web_content/cms_get_ext_warranty_page`,
            data,
            getConfig(localStorage.getItem("access_token"))
        );
    }
    getWarrantyCoverageFilterData() {
        return axios.get(Constants.getBaseURL() + `api/v1/oauth/web_content/cms_get_ext_warr_coverage_section`, 
        getConfig(localStorage.getItem("access_token")))
    }
    updateWarrantyCoverageData(data) {
        return axios.post(
            Constants.getBaseURL() + `api/v1/oauth/web_content/cms_update_ext_warr_coverage_section`,
            data,
            getConfig(localStorage.getItem("access_token"))
        );
    }
}

export default new API();
