import React from "react";
import "./serviceFilter.css";
import firebase from "../../../firebase";
import MultiSelect from "../../custom/MultiSelect/MultiSelect";
import API from "../../../api";
import Constants from "../../../constants"

class ServiceFilter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filters: [],
			channel: [{ "id": "select", "name": "Select Options" }, { "id": 1, "name": "Category Page" }, { "id": 2, "name": "Service Page" }, { "id": 3, "name": "Accessories Page" }, { "id": 4, "name": "Warranty Page" }, { "id": 5, "name": "Warranty Coverage" }, { "id": 6, "name": "Accessories Tags" }],
			services: [],
			subService: [],
			categories: {},
			product: [],
			warrantyServices: [],
			warrantySubServices: [],
			warrantyData: {},
			warrantyCoverage: [],
			warrantyCoverageData: [],
			isCarDetailsMultiple: true,
			showServiceFilter: true,
			filterData: {
				channel: [],
				services: [],
				subService: [],
				categories: [],
				product: [],
				warrantyServices: [],
				warrantySubServices: [],
				warrantyCoverage: [],
			},
		};

		this.handleApplyFilter = this.handleApplyFilter.bind(this);
		this.afterFilterChange = this.afterFilterChange.bind(this);
		this.handleCategoryChange = this.handleCategoryChange.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.getAccessoriesPageData = this.getAccessoriesPageData.bind(this);
		this.getAccessoriesPageProductData = this.getAccessoriesPageProductData.bind(this);
		this.getWarrantyPageData = this.getWarrantyPageData.bind(this);
		this.getWarrantyCoveragePageData = this.getWarrantyCoveragePageData.bind(this);
	}
	componentWillMount() {
		if (localStorage.getItem("service_filters") != undefined) {
			let filters = JSON.parse(localStorage.getItem("service_filters"));
			let channel = filters && filters.channel ? filters.channel.id : "";
			let key = channel == 3 ? localStorage.getItem("accessories_categories") : localStorage.getItem("service_data");

			if (key!= undefined && key != "4") {
				this.state.services = JSON.parse(key);
			}
			if(channel == 6){
				let val = filters && filters.channel ? filters.channel : "";
				this.getAccessoriesPageData(val)
			}
		}
		if (localStorage.getItem("sub_service") != undefined) {
			this.state.subService = JSON.parse(localStorage.getItem("sub_service"));
		}
		if (localStorage.getItem("accessories_serivce_product") != undefined) {
			this.state.product = JSON.parse(localStorage.getItem("accessories_serivce_product"));
		}
		if (localStorage.getItem("warranty_coverage_categories") != undefined) {
			this.state.warrantyCoverage = JSON.parse(localStorage.getItem("warranty_coverage_categories"))
		}
	}
	componentDidMount() {
		/* Setting Filters Data from localstorage*/
		if (localStorage.getItem("service_filters") != undefined) {
			let filters = JSON.parse(localStorage.getItem("service_filters"));
			let channel = filters && filters.channel ? filters.channel.id : ""; 
			if(channel!= 4){
				this.setState({
					filterData: JSON.parse(localStorage.getItem("service_filters")),
				});
			}
		}
		if (localStorage.getItem("service_data") == undefined) {
			firebase.database().ref('/cms/service_type').on(
				'value',
				(snapshot) => {
					var serviceOptions = [];
					serviceOptions = [{ id: 'select', name: 'Select Service' }, ...snapshot.val()]
					this.setState({ services: serviceOptions });
					localStorage.setItem("service_data", JSON.stringify(this.state.services));
				},
				(err) => {
					console.log('services read failed from firebase', err.code);
				},
			);
		}
	}
	afterFilterChange(field) {
		field = field || null;
		localStorage.setItem("service_filters", JSON.stringify(this.state.filterData));
		this.getCategoryWiseServices();
	}
	getCategoryWiseServices() {
		var that = this;
		if (this.state.filterData.channel.id === 3) {
			let subService = [];
			subService = [{ id: 'select', name: 'Select Services' }];
			that.setState({ subService: that.state.filterData.services.sub_categories });
			localStorage.setItem("sub_service", JSON.stringify(this.state.filterData.services.sub_categories));
		}
		else if (this.state.filterData.channel.id !== 4 || this.state.filterData.channel.id !== 5){
			API.getTagCategoryWiseServices().then(function (response) {
				// console.log('-----------', response);
				if (response.status) {
					let subService = [];
					subService = [{ id: 'select', name: 'Select Services' }];
					response.data.data.map((option, index) => {
						subService.push({ id: option.id, name: option.name });
					})
					console.log(subService, "######");
					that.setState({ subService: subService });
					localStorage.setItem("sub_service", JSON.stringify(that.state.subService));
				}
			}).catch(function (error) {
				// handle error
				console.log(error);
			});
		}
	}

	handleApplyFilter() {
		this.props.onApplyFilter(this.state.filterData, "service_filters");
	}
	handleInput(input) {
		let path = { id: null, name: input }
		this.setState({ filterData: { ...this.state.filterData, pageUrl: path } })
	}
	handleCategoryChange(value) {
		if (this.state.filterData.channel.id === 5) {
			this.setState({ filterData: { ...this.state.filterData, services: value, subService: {} } });
		} else {
			this.setState({ filterData: { ...this.state.filterData, services: value, subService: {} } }, () => this.afterFilterChange());
		}
	}
	getAccessoriesPageData(val) {
		if (val.id === 3 || val.id === 6 ) {
			var that = this;
			let data = {}
			data.service_id = 1000;
			API.getAccessoriesProductCat(data).then(function (response) {
				// console.log('-----------', response);
				if (response.data.status) {
					that.setState({ services: [{ id: 'select', name: 'Select Categories' }, ...response.data.data], subService: [] });
					localStorage.setItem("accessories_categories", JSON.stringify(that.state.services));
				}
			}).catch(function (error) {
				// handle error
				console.log(error);
			});
		}
	}

	getAccessoriesPageProductData(val) {
		var that = this;
		let data = {}
		data.service_id = 1001;
		API.getAccessoriesProductCat(data).then(function (response) {
			// console.log('---------->', response);
			let productData = response.data.data;
			let filterProductData = productData.filter((value) => {
				if (value.sub_category_id == that.state.filterData.subService.id) {
					return value;
				}
			})
			that.setState({ product: filterProductData[0].products })
			localStorage.setItem("accessories_serivce_product", JSON.stringify(that.state.product));
		}).catch(function (error) {
			// handle error
			console.log(error);
		});
	}

	getWarrantyPageData(value) {
		var that = this;
		let id  = 47;
		let data = {};
		data.id = id;
		API.getWarrantyPageFilterData(data).then(function (response) {
			let arr = [];
			let obj = {};
			let name = "name";
			let id = "id";

			if(response.data.status) {
				let tempObj = response.data.data;
				let objectKeys = Object.keys(tempObj);
				objectKeys.map((data, index) => {
					obj = {...obj};
					obj[id] = `${index+1}`;
					if(data === "warranty_ext") {
						obj[name] = "Extended";
					} else if (data === "warranty_content") {
						obj[name] = "Content";
					} else if (data === "warranty_faqs") {
						obj[name] = "FAQs";
					}
					arr = [...arr];
					arr.push(obj);
					that.setState({ warrantyServices: [{ id: 'select', name: 'Select Categories' }, ...arr, { id: '4', name: 'Warranty Carousel' }]});
				})
				let data = response.data.data;
				that.setState({ warrantyData: {...data}})
			}
		})
	}

	handleWarrantyServiceChange(value) {
		let arr = [];
		let obj = {};
		let name = "name";
		let id = "id";
		let subServiceData;
		let subServiceDropDownOptions;

		this.setState({ filterData: { ...this.state.filterData, warrantyServices: value }});
  
		if (value.name == "Extended" && this.state.warrantyData && this.state.warrantyData["warranty_ext"]) {
			subServiceData = this.state.warrantyData["warranty_ext"];
			subServiceDropDownOptions = Object.keys(subServiceData);
			subServiceDropDownOptions.map((data, index) => {
				obj = { ...obj };
				obj[id] = `${index + 1}`;
				obj[name] = data;
				arr = [...arr];
				arr.push(obj);
				this.setState({ warrantySubServices: [{ id: 'select', name: 'Select Service' }, ...arr]})
			})
		}
		if (value.name == "Content" && this.state.warrantyData && this.state.warrantyData["warranty_content"]) {
			subServiceData = this.state.warrantyData["warranty_content"];
			subServiceDropDownOptions = Object.keys(subServiceData);
			subServiceDropDownOptions.map((data, index) => {
				if (data !== "identifier") {
					obj = { ...obj };
					obj[id] = `${index}`;
					switch (data){
						case 'extended_warranty': 
							obj[name] = "Extended Warranty";
							break;
						case 'warranty_savings':
							obj[name] = "Warranty Savings";
							break;
						case 'warranty_claim':
							obj[name] = "Warranty Claim";
							break;
						case 'warranty_numbers':
							obj[name] = "Warranty Numbers";
							break;
						default:
							break;
					}
					arr = [...arr];
					arr.push(obj);
				}
				this.setState({ warrantySubServices: [{ id: 'select', name: 'Select Service' }, ...arr] })
			})
		}
		if (value.name == "FAQs") {
			this.setState({ warrantySubServices: [] })
		}
	}

	handleWarrantySubServiceChange(value) {
		this.setState({ filterData: { ...this.state.filterData, warrantySubServices: value}});
	}

	getWarrantyCoveragePageData() {
		let that = this;
		API.getWarrantyCoverageFilterData().then(function (response) {
			if (response.data.status) {
				that.setState({ warrantyCoverage: [{ id: 'select', name: 'Select Categories' }, ...response.data.data]});
				localStorage.setItem("warranty_coverage_categories", JSON.stringify(that.state.warrantyCoverage));
			}
		}).catch(function (error) {
			// handle error
			console.log(error);
		});
	}
	render() {
		const { services, subService, channel, filterData, categories, product, warrantyServices, warrantySubServices, warrantyCoverage } = this.state;
		const filters = this.state.filters;
		return (
			<div className="mainFilterContainer">
				<div className="row-5 margin_10 margin_b_20">
					{
						<React.Fragment>
							<div className="margin_r_16">
								<MultiSelect
									labelKey="name"
									checkbox_id="name"
									options={channel}
									placeholder="Select Page Type"
									selectedOptions={this.state.filterData.channel}
									onChange={(value) => {
										const selectedServiceData = JSON.parse(localStorage.getItem("service_data"))
										const selectedServicesData = filterData && filterData.warrantyServices;
										this.setState({
											filterData: {
												...this.state.filterData,
												channel: value,
												services: [],
												subService : [],
												product : [],
												warrantyServices: [],
												warrantySubServices: [],
												warrantyCoverage: [],
											}
										}
										)
										if(value.id === 3 || value.id === 6) {
											this.getAccessoriesPageData(value)
											this.setState({product: []})
										} else if(value.id === 4) {
											this.getWarrantyPageData(value)
											this.setState({ warrantyServices: selectedServicesData})
										} else if(value.id === 5) {
											this.getWarrantyCoveragePageData(value)
										}
										else {
											this.setState({ services: selectedServiceData, subService: []})
										}
										return
									}}
								></MultiSelect>
							</div>
							{this.state.filterData.channel && this.state.filterData.channel.id && this.state.filterData.channel.id !== 4 && this.state.filterData.channel.id !== 5 &&
								<div className="margin_r_16">
									<MultiSelect
										options={services}
										labelKey="name"
										checkbox_id="id"
										placeholder="Select Category"
										disabledItems={[]}
										selectedOptions={this.state.filterData.services}
										onChange={(value) => this.handleCategoryChange(value)}
									/>
								</div>
							}

							{this.state.filterData.channel && this.state.filterData.channel.id && this.state.filterData.channel.id === 5 &&
								<div className="margin_r_16">
									<MultiSelect
										options={warrantyCoverage}
										labelKey="name"
										checkbox_id="id"
										placeholder="Select Category"
										disabledItems={[]}
										selectedOptions={this.state.filterData.services}
										onChange={(value) => {
											const localData = JSON.parse(localStorage.getItem("warranty_coverage_categories"))
											this.setState({ warrantyCoverage: localData })
											this.handleCategoryChange(value)
										}}
									/>
								</div>
							}
							{warrantyServices && warrantyServices.length > 0 && this.state.filterData.channel && this.state.filterData.channel.id && this.state.filterData.channel.id === 4 &&
								<div className="margin_r_16">
									<MultiSelect
										options={warrantyServices}
										labelKey="name"
										checkbox_id="id"
										placeholder="Select Section"
										disabledItems={[]}
										selectedOptions={this.state.filterData.warrantyServices}
										onChange={(value) => {
											const selectedSubServicesData = filterData && filterData.warrantySubServices;
											this.handleWarrantyServiceChange(value)
										}}
									/>
								</div>
							}
							{this.state.filterData.channel && this.state.filterData.channel.id && this.state.filterData.channel.id === 4 && (this.state.warrantySubServices.length > 0) && this.state.filterData.warrantyServices && this.state.filterData.warrantyServices.id && this.state.filterData.warrantyServices && this.state.filterData.warrantyServices.id && this.state.filterData.warrantyServices.id !== "4" &&
								<div className="margin_r_16">
									<MultiSelect
										options={warrantySubServices}
										labelKey="name"
										checkbox_id="id"
										placeholder="Select Sub Section"
										disabledItems={[]}
										selectedOptions={this.state.filterData.warrantySubServices}
										onChange={(value) => {
											this.handleWarrantySubServiceChange(value)
										}}
									/>
								</div>
							}
							{this.state.filterData.channel && (this.state.filterData.channel.id == 2 || this.state.filterData.channel.id == 3) && this.state.filterData.services && this.state.filterData.services.name &&
								<div className="margin_r_16">
									<MultiSelect
										options={subService}
										labelKey="name"
										checkbox_id="id"
										placeholder="Select Service"
										disabledItems={[]}
										selectedOptions={this.state.filterData.subService}
										onChange={(value) => (
											this.setState({
												filterData: {
													...this.state.filterData,
													subService: value
												}
											}),
											this.getAccessoriesPageProductData(value)
										)
										}
									/>
								</div>
							}
							{this.state.filterData.channel && this.state.filterData.channel.id == 3 && this.state.filterData.services && this.state.filterData.services.name && this.state.filterData.subService && this.state.filterData.subService.name &&
								<div className="margin_r_16">
									<MultiSelect
										options={product}
										labelKey="name"
										checkbox_id="id"
										placeholder="Select Product"
										disabledItems={[]}
										selectedOptions={this.state.filterData.product}
										onChange={(value) => (
											this.setState({
												filterData: {
													...this.state.filterData,
													product: value
												}
											}))
										}
									/>
								</div>
							}
							{((this.state.filterData.channel && this.state.filterData.channel.id && this.state.filterData.services && this.state.filterData.services.name && ((this.state.filterData.channel.id == 2 && this.state.filterData.subService && this.state.filterData.subService.name) || this.state.filterData.channel.id == 1 || (this.state.filterData.channel.id == 3 && this.state.filterData.subService && this.state.filterData.subService.name && this.state.filterData.product))) || (this.state.filterData.channel.id == 4 && this.state.filterData.warrantyServices && this.state.filterData.warrantyServices.id && this.state.filterData.warrantySubServices && this.state.filterData.warrantySubServices.id) || (this.state.filterData.channel.id == 4 && this.state.filterData.warrantyServices && this.state.filterData.warrantyServices.id === "3") || (this.state.filterData.channel.id == 4 && this.state.filterData.warrantyServices && this.state.filterData.warrantyServices.id === "4") || (this.state.filterData.channel.id == 5 && this.state.filterData.services && this.state.filterData.services.id) || (this.state.filterData.channel.id == 6 && this.state.filterData.services && this.state.filterData.services.name)) &&
								<div className="row-5">
									<div className="blackspan65 weight700 floatright addContentBtnMargin" >
										<table className="stdtable">
											<tbody>
												<tr>
													<td valign="center">
														<button id="addleadbtn" className="btn btn_small btncolorprim btncard move" onClick={this.handleApplyFilter}>Apply Filter</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							}
						</React.Fragment>
					}
				</div>
			</div>
		);
	}

}

export default ServiceFilter;