import React from "react";
// import "./dashboard/dashboard.css"
import Row from "./row";
import API from "../api";
import { NotificationManager } from "react-notifications";

class MiddleMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pricingData: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }
  handleChange(rowData) {
    this.setState({ pricingData: { ...this.state.pricingData, ...rowData } });
  }
  handleSaveClick() {
    this.props.onSaveClick(this.state.pricingData);
  }

  render() {
    var that = this;
    let filterData = JSON.parse(localStorage.getItem("pricing_filters"));
    let category = filterData && filterData.categories && filterData.categories.name ? filterData.categories.name : "";
    const rows = this.props.content.map((obj, index) => (
      <Row
        key={index}
        data={obj}
        selectedRow={this.props.selectedRow}
        tag={that.props.tag}
        actionButton={this.props.onAction}
        handleInputChange={this.handleChange}
        category={category}
      />
    ));

    return (
      <div>
        {/* Middle Upper Start */}
        <React.Fragment>
          <div
            className="top border-box clearfix leftalign"
            style={{ marginTop: "15px", marginBottom: "15px"  }}
          >
            <div className="border-box clearfix">
              <div
                className="blackspan65 weight700 floatright"
                style={{ marginRight: "3%" }}
              >
                {/* <button
                style={{ background: "rgb(105, 118, 148)", color: "#ffffff" }}
                id="addleadbtn"
                className="btn btn_small btncard move"
                onClick={this.handleAddClick}
              >
                Add {this.props.label}
              </button> */}
                <button
                  className="btn btn_small btncard move"
                  style={{
                    color: "rgb(255, 255, 255)",
                    backgroundColor: "#5cb85c",
                    borderColor: "#4cae4c",
                  }}
                  onClick={this.handleSaveClick}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          {/* Below button */}
          {/* <Package /> */}
          <div
            className="widthMainComp border-box clearfix leftalign"
            // style={(category !== "Accessories Products")?{ top: "125px" }:{}}
          >
            <div className="border-box blackspan65 weight700">
              <table className="middleUpperTable stdtable">
                <thead>

                  {category == 'Package' ? (
                    <tr>
                      <th className="middleUpperTH width6TH">Name</th>
                      <th className="middleUpperTH width3TH">Material Cost</th>
                      <th className="middleUpperTH width2TH">Labour</th>
                      <th className="middleUpperTH width3TH">Brand 1</th>
                      <th className="middleUpperTH width2TH">Brand 1 Price</th>
                      <th className="middleUpperTH width3TH">Brand 2</th>
                      <th className="middleUpperTH width2TH">Brand 2 Price</th>
                      <th className="middleUpperTH width3TH">Work Done</th>
                    </tr>
                  ) :
                    category == 'Tyre' ? (
                      (<tr>
                        <th className="middleUpperTH width1TH">Name</th>
                        <th className="middleUpperTH width2TH">Warranty (months)</th>
                        <th className="middleUpperTH width3TH">Size</th>
                        <th className="middleUpperTH width3TH">Material Cost</th>
                        <th className="middleUpperTH width2TH">Brand</th>
                        <th className="middleUpperTH width2TH">Brand 1 Price</th>
                        <th className="middleUpperTH width6TH">Work Done</th>
                      </tr>)
                    ) :

                      category == 'Tyre with size' ? (
                        (<tr>
                          <th className="middleUpperTH width1TH">Name</th>
                          <th className="middleUpperTH width2TH">Warranty (months)</th>
                          <th className="middleUpperTH width3TH">Size</th>
                          <th className="middleUpperTH width3TH">Material Cost</th>
                          <th className="middleUpperTH width2TH">Brand</th>
                          <th className="middleUpperTH width2TH">Brand 1 Price</th>
                          <th className="middleUpperTH width6TH">Work Done</th>
                        </tr>)
                      ) :

                      category == 'Battery' ? (
                        <tr>
                          <th className="middleUpperTH width1TH">Name</th>
                          <th className="middleUpperTH width2TH">Warranty (months)</th>
                          <th className="middleUpperTH width1TH">AMP</th>
                          <th className="middleUpperTH width3TH">Material Cost</th>
                          <th className="middleUpperTH width2TH">Brand</th>
                          <th className="middleUpperTH width2TH">Brand 1 Price</th>
                          <th className="middleUpperTH width6TH">Work Done</th>
                        </tr>
                      ) :
                        category == 'Battery with amp' ? (
                          <tr>
                            <th className="middleUpperTH width1TH">Name</th>
                            <th className="middleUpperTH width2TH">Warranty (months)</th>
                            <th className="middleUpperTH width1TH">AMP</th>
                            <th className="middleUpperTH width3TH">Material Cost</th>
                            <th className="middleUpperTH width2TH">Brand</th>
                            <th className="middleUpperTH width2TH">Brand 1 Price</th>
                            <th className="middleUpperTH width6TH">Work Done</th>
                          </tr>
                      ) :
                      category === 'Accessories Product' ? (
                        <tr>
                          <th className="middleUpperTH width2TH">Name</th>
                          <th className="middleUpperTH width4TH">Material Cost</th>
                          <th className="middleUpperTH width4TH">Strike Through</th>
                          <th className="middleUpperTH width4TH">Out Of Stock</th>
                        </tr>
                      )
                        :
                        (<tr>
                          <th className="middleUpperTH width1TH">Name</th>
                          <th className="middleUpperTH width2TH">Qty</th>
                          <th className="middleUpperTH width3TH">Material Cost</th>
                          <th className="middleUpperTH width2TH">Labour</th>
                          <th className="middleUpperTH width2TH">Brand</th>
                          <th className="middleUpperTH width6TH">Work Done</th>
                        </tr>)
                  }

                  {/* <th className="middleUpperTH"></th> */}
                  {/* <th className="middleUpperTH width7TH">Actions</th> */}

                </thead>
              </table>
            </div>
          </div>
        </React.Fragment>
        {/* Middle Upper End */}

        {/* Middle Main Start */}
        <div
          className="main border-box leftalign"
          style={{ overflowY: "scroll", marginBottom: "50px" }}
        >
          {!this.props.showContent ? (
            <React.Fragment />
          ) : this.props.content.length == 0 ? (
            <div className="empty centeralign weight400 blackspan56">
              No Data Found
            </div>
          ) : (
            rows
          )}
        </div>
        {/* Middle Main End */}
      </div>
    );
  }
}

export default MiddleMain;
