import React, { useState, useEffect, useRef } from "react";
import { NotificationManager } from "react-notifications";
// import API from "../../api";
import Loader from "../../loader";
import "./ManageHomePage.css";
import ManageHomePageFilter from "./ManageHomePageFilter/ManageHomePageFilter";
import Row from "../row";
import API from "../../api.js";

const ManageHomePage = (props) => {
  const [loader, setLoader] = useState(false);
  const [homepageData, setHomepageData] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [bgImage, setBgImage] = useState(null);

  const child = useRef();
  const imageReset = useRef();

  const reset = () => {
    imageReset.current.value = ""
  }
  let optionClicked;
  let selectedSectionDetails = { ...selectedSection }
  let rows;

  if (props.filters && props.filters.subChannel && props.filters.subChannel.id === "1" && props.filters.section !== undefined && props.filters.section.id) {
    optionClicked = homepageData;
  }

  useEffect(() => {
    if (props.loader === true) {
      setLoader(true);
    } else if (props.loader === false) {
      setLoader(false);
    }
  }, [props.loader])

  useEffect(() => {
    if (props.content) {
      setHomepageData(props.content);
    } 
    if(homepageData) {
      setBgImage(`${homepageData.bg_image}?version=${new Date().getTime()}`);
    }
  }, [props.content, homepageData])

  useEffect(() => {
    setSelectedSection(optionClicked);
  }, [optionClicked])

  if (props.content && props.filters && props.filters.subChannel && props.filters.subChannel.id === "2" && props.content !== {}) {

    rows = Array.isArray(props.content) ? props.content.map((data, index) => (
      <Row
        key={data.position + data.name}
        data={data}
        tag="manage_homepage"
        handleInputChange={() => handleChange(data)}
      />
    )) :null;
  }

  function handleChange(data) {
    setHomepageData(data);
  }

  function handleSaveButton() {
    if (props.filters.subChannel.id === "2") {
      handlePositionValidations();
      return (() => homepageData([]), []);
    } else {
      handleSectionValidations();
    }
  }

  function handlePositionValidations() {
    let maxValue = null;
    let minValue = null;
    const duplicates = homepageData.filter((data) =>
      homepageData.some((each) => parseInt(each.position) === parseInt(data.position) && each.name !== data.name)
    )

    let duplicatesLength = duplicates.length;

    switch (duplicates.length) {
      case 0:
        let values = homepageData.map(data => data.position)
        maxValue = Math.max(...values);
        minValue = Math.min(...values);

        if (maxValue < 13 && minValue > 0) {
          let payload = {};
          payload[`posData`] = homepageData;
          setLoader(true)
          API.handleMangaHomepagePosition(payload)
            .then(function (response) {
              if (response.data.status) {
                NotificationManager.success(
                  "Data saved",
                  "Successfully",
                  5000
                );
                props.onApplyFilter(props.filters, "service_filters");
                setLoader(false);
              } else {
                NotificationManager.error(
                  "Please try again",
                  "Error",
                  5000
                );
                setLoader(false);
              }
            })
            .catch((err) => {
              NotificationManager.error(
                "Something went wrong",
                "Error",
                5000
              );
              setLoader(false);
            })
        } else {
          NotificationManager.error(
            "Position out of range",
            "Error",
            5000
          );
        }
        break;
      default:
        NotificationManager.error(
          `${duplicatesLength} duplicate positions`,
          "Error",
          5000
        );
        break;
    }
  }

  function handleSectionValidations() {
    let validateForm = [];
    let validateSection = [];
    const required_keys = ['title', 'title_color', 'sub_title', 'sub_title_color', 'bg_color'];

    required_keys.map((data) => {
      if (selectedSection[data].trim().length === 0) {
        validateForm.push(data);
        if (validateForm.length === 5) {
          NotificationManager.error("Form cannot be empty! ");
        }
      }
    })

    required_keys.map((data) => {
      if (selectedSection[data].trim().length === 0) {
        validateSection.push(data);

        if (validateSection.length < 5 && validateSection.length > 0 && validateForm.length < 5) {
          let notificationString = "";

          switch (data) {
            case "title":
              notificationString = "Title"
              break
            case "title_color":
              notificationString = "Title Color"
              break
            case "sub_title":
              notificationString = "Sub Title"
              break
            case "sub_title_color":
              notificationString = "Sub Title Color"
              break
            case "bg_color":
              notificationString = "Background Color"
              break
          }
          NotificationManager.error(notificationString + " cannot be empty! ");
        } else if (validateSection.length === 5) {
          return 0
        }
      }
    })

    if (validateSection.length === 0 && validateForm.length === 0) {
      let obj = {};
      obj = { ...selectedSection };
      obj['channel'] = props.filters.channel.name;
      setLoader(true);
      API.handleHomepageSectionUpdate(obj)
        .then(function (response) {
          if (response.data.status) {
            NotificationManager.success(
              "Data saved",
              "Successfully",
              5000
            );
            props.onApplyFilter(props.filters, "service_filters");
            setLoader(false);
          } else {
            NotificationManager.error(
              "Please try again",
              "Error",
              5000
            );
            setLoader(false);
          }
        })
        .catch((err) => {
          NotificationManager.error(
            "Something went wrong",
            "Error",
            5000
          );
          setLoader(false);
        })
    } else {
      return 0;
    }
  }

  function handleInputBlur(e, key) {
    setSelectedSection({ ...selectedSection, [key]: e.target.value });
  }

  function handleSectionImageSubmit(e) {
    e.preventDefault();
    if (uploadFile) {
      var file = uploadFile[0];
      if (!file.type.match("image.*")) {
        NotificationManager.error("The file is not an image", "Error", 5000);
        return;
      }
    }

    let formData = new FormData();
    formData.append("file", file);
    
    let sectionName = props.filters.section.name;
    let sectionType = sectionName.toLowerCase();
    let channelName = props.filters.channel.name;

    formData.append("section_type", sectionType);
    formData.append("channel", channelName);

    setLoader(true);
    API.handleHomepageSectionUpdate(formData)
      .then(function (response) {
        if (response.status) {
          props.onApplyFilter(props.filters, "service_filters");
          setLoader(false);
          setUploadFile(null);
          reset();
        }
        NotificationManager.success(
          `Image uploaded`,
          "Successfully",
          5000
        );
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating background image");
        setUploadFile(null);
        reset();
      });
  };

  return (
    <>
      {loader ? (
        <>
          <div className="modal-sb"></div>
          <div className="sub-modal-sb"></div>
        </>
      ) : (
        ""
      )}
      <>
        <div style={{ marginTop: "20px" }}>
          <ManageHomePageFilter ref={child} onApplyFilter={props.onApplyFilter} />
        </div>
        {props.filters ?
          (<>
            {/* Save button */}
            {props.filters.subChannel !== undefined && props.filters.subChannel.id !== undefined &&
              <div
                className="top border-box clearfix leftalign"
                style={{ marginTop: "54px" }}
              >
                <div className="border-box clearfix">
                  <div
                    className="blackspan65 weight700 floatright"
                    style={{ marginRight: "3%" }}
                  >
                    <button
                      className="btn btn_small btncard move"
                      style={{
                        color: "rgb(255, 255, 255)",
                        backgroundColor: "#5cb85c",
                        borderColor: "#4cae4c",
                      }}
                      onClick={handleSaveButton}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            }

            {/* Show Change Position data */}
            {props.filters !== undefined && props.filters.subChannel !== undefined && props.filters.subChannel.id === "2" &&
              <div>
                <div className="mainImageContainer-ic manageHomePageContainer">
                  <div className="border-box clearfix leftalign">
                    <div className="border-box blackspan65 weight700">
                      <table className="middleUpperTable-ic stdtable">
                        <thead>
                          <tr>
                            <th className="middleUpperTH-ic width1TH-ic">Position</th>
                            <th className="middleUpperTH-ic width1TH-ic">Category Name</th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  className="imageCarouselMiddleMain-ic border-box leftalign"
                  style={{ height: "fit-content", overflowY: "hidden", marginBottom: '4rem' }}
                >
                  {rows}
                </div>
              </div>
            }
            {/* Update Section Data */}
            {props.filters !== undefined && props.filters.subChannel !== undefined && props.filters.subChannel.id === "1" && props.filters.section !== undefined && props.filters.section.id &&
              <>
                <div className="mainContainer" style={{width: "50%", maxWidth: "50%"}}>
                  <div className="manageTimeSlot">
                    {homepageData.title}
                  </div>
                  <div className="innerBox" style={{ padding: '1rem' }}>
                    <div style={{ margin: ".5rem 0rem 1rem 0rem" }}>
                      <div>
                        <p style={{ margin: ".5rem 0rem" }}
                          className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Title
                          <span style={{ color: "red" }}> *</span>
                        </p>
                        <input
                          type="text"
                          defaultValue={selectedSectionDetails.title}
                          onBlur={(e) =>
                            handleInputBlur(e, "title")
                          }
                        />
                      </div>
                      <div>
                        <p style={{ margin: "1rem 0rem .5rem 0rem" }}
                          className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Title Color
                          <span style={{ color: "red" }}> *</span>
                        </p>
                        <input
                          type="text"
                          defaultValue={selectedSectionDetails.title_color}
                          onBlur={(e) =>
                            handleInputBlur(e, "title_color")
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="innerBox" style={{ padding: '1rem' }}>
                    <div style={{ margin: ".5rem 0rem 1rem 0rem" }}>
                      <div>
                        <p style={{ margin: "1rem 0rem .5rem 0rem" }}
                          className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Sub Title
                          <span style={{ color: "red" }}> *</span>
                        </p>
                        <input
                          type="text"
                          defaultValue={selectedSectionDetails.sub_title}
                          onBlur={(e) =>
                            handleInputBlur(e, "sub_title")
                          }
                        />
                      </div>
                      <div>
                        <p style={{ margin: "1rem 0rem .5rem 0rem" }}
                          className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Sub Title Color
                          <span style={{ color: "red" }}> *</span>
                        </p>
                        <input
                          type="text"
                          defaultValue={selectedSectionDetails.sub_title_color}
                          onBlur={(e) =>
                            handleInputBlur(e, "sub_title_color")
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="innerBox" style={{ padding: '0rem 1rem' }}>
                    <div style={{ margin: ".5rem 0rem 1rem 0rem" }}>
                      <div>
                        <p style={{ margin: "1rem 0rem .5rem 0rem" }}
                          className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Background Color
                          <span style={{ color: "red" }}> *</span>
                        </p>
                        <input
                          type="text"
                          defaultValue={selectedSectionDetails.bg_color}
                          onBlur={(e) =>
                            handleInputBlur(e, "bg_color")
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                  <p style={{ margin: "1rem 0rem .5rem 0rem" }}
                    className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                    Background Image
                  </p>
                    <div className="innerBox">
                      <form onSubmit={handleSectionImageSubmit}>
                        <div className="video-card">
                          <img
                            src={
                            bgImage
                              ? bgImage
                                : `https://via.placeholder.com/580x261?text=Image+Not+Uploaded`
                            }
                            alt=""
                            title="image-preview"
                          />
                          <div></div>
                          <div>
                            <input
                              type="file"
                              name="media"
                              // defaultValue={uploadFile}
                              ref={imageReset}
                              onChange={
                                (e) => setUploadFile(e.target.files)
                              }
                            />
                          </div>
                          <button class="button-update"  disabled={!uploadFile} style={(!uploadFile) ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                          >Update</button>
                        </div>
                      </form>
                    </div>
                  </div>

                </div>
              </>
            }
          </>)
          : <div className="emptyMiddleMain centeralign weight400 blackspan56">
            Please apply filters to view details
          </div>
        }
      </>
    </>
  )
};

export default ManageHomePage;