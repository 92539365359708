import React from "react";
import "./productsFilter.css";
import MultiSelect from "../../custom/MultiSelect/MultiSelect";
import Constants from "../../../constants";

class ProductsFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channel: [
        {
          id: "select",
          name: "Select Channel",
        },
        {
          id: "is_obd_enabled",
          name: "OBD",
        },
        {
          id: "is_sos_enabled",
          name: "SOS",
        },
        {
          id: "is_amc_enabled",
          name: "AMC",
        },
        {
          id: "is_accessories_enabled",
          name: "Accessories",
        },
      ],
      city: [],
      productFilter: {
        channelType: [],
        cities: [],
      },
      showFilterDiv: false,
    };

    this.handleApplyFilter = this.handleApplyFilter.bind(this);
    this.afterFilterChange = this.afterFilterChange.bind(this);
  }
  componentWillMount() {
    this.state.showFilterDiv = true;
    if (localStorage.getItem("city_data") != undefined) {
      this.state.city = Constants.getCityData();
    }
  }
  componentDidMount() {
    /* Setting Filters Data from localstorage*/
    if (localStorage.getItem("products_filters") != undefined) {
      this.setState({
        productFilter: JSON.parse(localStorage.getItem("products_filters")),
      });
    }
    // var cityOptions = [];
    // cityOptions = [{id:'select-all',name:'Select All'}, ...firebaseData.cities]
    // this.setState({city:cityOptions});
  }
  afterFilterChange(field) {
    field = field || null;

    localStorage.setItem(
      "products_filters",
      JSON.stringify(this.state.productFilter)
    );
  }

  handleApplyFilter() {
    this.props.onApplyFilter(this.state.productFilter, "products_filters");
  }
  render() {
    const { channel, city } = this.state;

    return (
      <div className="mainFilterContainer">
        <div
          className="row-5 margin_10 margin_b_20"
          style={{ gridTemplateColumns: "repeat(6,15%)" }}
        >
          <div className="margin_r_16">
            <MultiSelect
              options={channel}
              labelKey="name"
              checkbox_id="name"
              placeholder="Channel Type"
              disabledItems={[]}
              selectedOptions={this.state.productFilter.channelType}
              onChange={(value) =>
                this.setState(
                  {
                    productFilter: {
                      ...this.state.productFilter,
                      channelType: value,
                    },
                    showFilterDiv: true,
                  },
                  () => this.afterFilterChange()
                )
              }
            />
          </div>
          {this.state.productFilter.channelType.id && (
            <>
              <>
                <div className="margin_r_16">
                  <MultiSelect
                    multiple
                    options={city}
                    labelKey="name"
                    checkbox_id="name"
                    placeholder="Select City"
                    disabledItems={[]}
                    selectedOptions={this.state.productFilter.cities}
                    onChange={(value) =>
                      this.setState(
                        {
                          productFilter: {
                            ...this.state.productFilter,
                            cities: value,
                          },
                        },
                        () => this.afterFilterChange()
                      )
                    }
                  />
                </div>
              </>
              {this.state.productFilter.cities &&
                this.state.productFilter.cities.length !== 0 && (
                  <>
                    <div className="blackspan65 weight700 floatright addContentBtnMargin">
                      <button
                        style={{
                          background: "rgb(105,118,148,1)",
                          color: "rgb(255, 255, 255)",
                        }}
                        id="addleadbtn"
                        className="btn btn_small btncolorprim btncard move"
                        onClick={this.handleApplyFilter}
                      >
                        Apply Filter
                      </button>
                    </div>
                  </>
                )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ProductsFilter;
