import React from "react";
// import "./dashboard/dashboard.css"
import "./middleupper.css";
class MiddleUpper extends React.Component {
  constructor(props) {
    super(props);
    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.handleSummaryClick = this.handleSummaryClick.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  handleFilterClick() {
    //console.log("clicking");
    this.props.onFilterClick();
  }

  handleSummaryClick() {}

  handleAddClick() {
    this.props.onAddClick(this.props.tag);
  }
  handleFilterChange(filters) {
    this.props.onFilterChange(filters);
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="top border-box clearfix leftalign"
          style={{ marginTop: "112px" }}
        >
          <div className="border-box clearfix">
            <div className="blackspan65 weight700 floatright">
              <table className="stdtable">
                <tbody>
                  <tr>
                    <td valign="center">
                      <button
                        id="addleadbtn"
                        className="btn btn_small btncolorprim btncard move"
                        onClick={this.handleAddClick}
                      >
                        Add {this.props.label}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {this.props.web_filters.pageType.name !== "app" && 
        <div className="middle border-box clearfix leftalign" style={{marginTop: "12px"}}>
          <div className="border-box blackspan65 weight700 width5Row">
            <table className="stdtable">
              <tbody>
                <tr>
                  <td valign="center"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="border-box blackspan65 weight700 width15Row">
            <table className="stdtable">
              <tbody>
                <tr>
                  <th>Path</th>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="border-box blackspan65 weight700 width10Row">
            <table className="stdtable">
              <tbody>
                <tr>
                  <td valign="center">City</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="border-box blackspan65 weight700 width10Row">
            <table className="stdtable">
              <tbody>
                <tr>
                  <td valign="center">Region</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="border-box blackspan65 weight700 width15Row">
            <table className="stdtable">
              <tbody>
                <tr>
                  <td valign="center">Service</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="border-box blackspan65 weight700 width10Row">
            <table className="stdtable">
              <tbody>
                <tr>
                  <td valign="center">Brand</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="border-box blackspan65 weight700 width10Row">
            <table className="stdtable">
              <tbody>
                <tr>
                  <td valign="center">Model</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="border-box blackspan65 weight700 width10Row">
            <table className="stdtable">
              <tbody>
                <tr>
                  <td valign="center">Fuel</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="border-box blackspan65 weight700 width15Row">
            <table className="stdtable">
              <tbody>
                <tr>
                  <td valign="center">Actions</td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div
            className="border-box blackspan65 weight700"
            style={{ width: "10%" }}
          >
            <table className="stdtable">
              <tbody>
                <tr>
                  <td valign="center"></td>
                </tr>
              </tbody>
            </table>
          </div> */}
        </div>}
      </React.Fragment>
    );
  }
}

export default MiddleUpper;
