import React from 'react';
import Constants from '../constants';

var divM= {};
var divH= {};
var divK= {};
var div_lastkeys= {};
var div_lasttimes= {};
var div_lastmessages= {};
var div_lastmessagetimes = {};

var thread_codes = [];

var threads_list = [];

var all_models = {};
// var childAddedListeners = [];

class ChatUtilsArchive{

    loadInitial(supportchat, chats, returnNow){
        console.log("loadingInitial");

        console.log("chats", chats);

        // chatRef.orderByChild('last_updated').limitToLast(50).on('child_added', function(data) {
        
        chats.forEach(function(item, index) {
            // console.log("consoling", item.name, item.mobile);
            console.log("item", item);
            console.log("item", item.key);

            if (thread_codes.indexOf(item.key) != -1){
                item['key'] = item['key']+"_"+index;
            }
                // var childref= chatRef.child(item.key);

            // //console.log(data.child("details").val());

            var models= [];
            var texts = [];
            var textHs = [];
            var mKeys = [];


            var textH = '';
            var textM = "Hey there! Welcome to GoMechanic. It's great to have you on board. You can chat with our support team here. Click on the + (plus) button in the chat-box to send a quick message. We hope you enjoy your time Cheers!";
                
            
            var message= {
                        senderName:"bot",
                        senderUserID:"bot",
                        time:(new Date()).getTime(),
                        notification:false,
                        textH : textH,
                        textM : textM
                        }
                models.push(message);

            


            textHs.push(textH);
            texts.push(textM);
            mKeys.push(null);
            div_lastkeys[item.key] = null;
            div_lasttimes[item.key] = message['time'];
            div_lastmessages[item.key] = textM;
            div_lastmessagetimes[item.key] = message['time'];

            
            var currentModel = null;

            var messageCount = 0;

            console.log("item_chat", item.chat);

            var chat_array = Object.keys(item.chat) // ["Music", "The Arts", "Social"]
            .sort(function(a, b) {
                return item.chat[a].time - item.chat[b].time; // Organize the category array
            })
            .map(function(key) {
                item.chat[key]["key"] = key;
                return item.chat[key]; // Convert array of categories to array of objects
            });

            chat_array.forEach(function(item1, index1){
                // console.log("consoling1", item1, item1.key);

                if(!currentModel || (currentModel && !Constants.get_TimeComparison(currentModel['time'], item1.time))){
                    // if(!Constants.get_TimeComparison(currentModel['time'], item1.time)){
                        var textH = "";
                        var textM = "";
                            
                        
                        var message= {
                                    senderName:null,
                                    senderUserID:null,
                                    time:item1.time,
                                    notification:false,
                                    textH : textH,
                                    textM : textM
                                    };

                            models.push(message);

                        


                        textHs.push(textH);
                        texts.push(textM);
                        mKeys.push(null);
                        div_lastkeys[item.key] = null;
                        div_lasttimes[item.key] = message['time'];
                        div_lastmessages[item.key] = textM;
                        div_lastmessagetimes[item.key] = message['time'];
                    // }
                }

                //console.log(child);
                var textH = '';
                var textM = '';
                  
                if (item1.notification){
                    textH = item1.type.toUpperCase();
                    textM = item1.text.toUpperCase();
                    
                } else{
                    textH = '';
                    textM = item1.text;
                }

                var message= {orderID:item1.orderID,
                            senderName:item1.senderName,
                            senderUserID:item1.senderUserID,
                            time:item1.time,
                            notification:item1.notification,
                            type:item1.type,
                            senderInternalID : item1.senderInternalID,
                            textH : textH,
                            textM : textM
                            }
                  models.push(message);

                currentModel = message;


                textHs.push(textH);
                texts.push(textM);
                mKeys.push(item1.key);
                div_lastkeys[item.key] = item1.key;
                div_lasttimes[item.key] = item1.time;
                div_lastmessages[item.key] = textM;
                div_lastmessagetimes[item.key] = item1.time;
                

                messageCount += 1;
            });
            divM[item.key] = texts;
            divH[item.key] = textHs;
            divK[item.key] = mKeys;

            var code1 = item.key;

            console.log("consoling2", item.name, item.mobile);
            thread_codes.push(code1);

            // let obj = derbiis_list.find(o => o.code === code1);

            let obj = {'name' : item.name, 'mobile' : item.mobile, 'assigned_executive' : item.assigned_executive, 'new' : (messageCount == 1) ? true : false};
            console.log("consoling3", item.name, item.mobile);
            threads_list.push(obj);
            console.log(threads_list.length, "length");

            var thread_title = item.key;

            if(obj != null && obj.name != null){
                thread_title = obj.name;
            }

            if(obj != null){
                var indexObj = threads_list.indexOf(obj);
                obj['last_time'] = div_lasttimes[code1];
                obj['last_message'] = div_lastmessages[code1];
                obj['last_messagetime'] = div_lastmessagetimes[code1];
                obj['id'] = item.key;
                obj['unread'] = 0;
                threads_list[indexObj] = obj;
                
            }


            threads_list.sort(function(a, b) {
                    return b.last_time - a.last_time;
            });
            threads_list.sort(function(a, b) {
                    return b.last_time - a.last_time;
            });
            
            
            all_models[code1] = models;

            console.log("consoling4", item.name, item.mobile);
            supportchat.setState({archived_threads_list : threads_list, archived_all_models : all_models, archived_divK : divK, archived_divM : divM, archived_divH : divH});
            
                var chat_start_key = "";
                if(item.key in div_lastkeys && div_lastkeys[item.key] != null){
                    chat_start_key = div_lastkeys[item.key];
                }
                
            
            
        });
    }


    clickTab(tag, supportchat, threads_array){
        let obj = threads_array.find(o => o.id === tag);
        var date = new Date();
        var time = date.getTime();

        div_lasttimes[tag] = time;
		if(obj != null){
			var indexObj = threads_list.indexOf(obj);
            obj['unread'] = 0;
            obj['last_time'] = div_lasttimes[tag];
			threads_list[indexObj] = obj;
        }
        if(obj != null){
			var indexObj = threads_array.indexOf(obj);
            obj['unread'] = 0;
            obj['last_time'] = div_lasttimes[tag];
			threads_array[indexObj] = obj;
        }
        supportchat.setState({archived_threads_list : threads_array, selectedTab : tag, rightOrder : NaN});
    }





    searchThreads(val, supportchat){
        //console.log("val", val);
        if (val != null && val.trim() != "") {
    
    
            //console.log("thread_listing", threads_list.length);
    
    
            let obj1 = threads_list.filter(o => (o.name && o.name.toLowerCase().indexOf(val.toLowerCase()) != -1));
            let obj2 = threads_list.filter(o => (o.mobile ? (o.mobile.toLowerCase().indexOf(val.toLowerCase()) != -1) : false ));
            // let obj3 = thread_people.filter(o => (o.people == "" ? false : (o.people.toLowerCase().indexOf(val.toLowerCase()) != -1) ));


            var codes = [];

            obj1.forEach(function(obj) { 
                codes.push(obj.id);
            });
            obj2.forEach(function(obj) {
                codes.push(obj.id);
            });
            // obj3.forEach(function(obj) {
            //     codes.push(obj.code);
            // });

            for (var key in divM) {
                var texts = divM[key];
                if (texts.find(function(str) {return (str && str.toLowerCase().indexOf(val.toLowerCase()) != -1);})){
                    codes.push(key);
                }
            }

            for (var key in divH) {
                var textHs = divH[key];
                if (texts.find(function(str) {return (str && str.toLowerCase().indexOf(val.toLowerCase()) != -1);})){
                    codes.push(key);
                }
            }

            var new_codes = codes.filter(function(item, pos, self) {
                  return self.indexOf(item) == pos;
            });

            var newArray = threads_list.filter(function(item) {
                return new_codes.includes(item.id);    
            });

            // $(".leftmain > .second").html('');

            // new_codes.forEach(function(code) {
            //     $(".leftmain > .second").append(thread_boxes[code]);
            // });
            supportchat.setState({archived_threads_list : newArray, searchText : val});
        } else {
            //console.log("thread_listing1", threads_list.length);

            threads_list.sort(function(a, b) {
                  return b.last_time - a.last_time;
            });
            threads_list.sort(function(a, b) {
                  return b.last_time - a.last_time;
            });

            supportchat.setState({archived_threads_list : threads_list, searchText : NaN});

            // threads_list.forEach(function(derbii){
            //     if (derbii.code in thread_boxes){
            //         $(".leftmain > .second").append(thread_boxes[derbii.code]);
            //     }
            // });
/*			thread_codes.forEach(function(code) {
                $(".leftmain > .second").append(thread_boxes[code]);
            });*/
        }
    }



}

export default new ChatUtilsArchive();

