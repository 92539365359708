import React from 'react'
import { useState, useEffect } from 'react';
import API from '../../../api';
import deleteIcon from "../../../images/delete_icon.png";
import { useParams } from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import './videoEdit.css'

function VideosEdit({ filters }) {
	const [videoData, setVideoData] = useState([])
	const [loader, setLoader] = useState(false)
	const [selectedType, setSelectedType] = useState({})
	const [addVideoCount, setAddVideoCount] = useState(0)
	const { level_id, type_id } = useParams()
	let arrayId = []
	let temp = filters?.multipleUsers.filter((user) => {
		if (user.id != 'select-all') {
			arrayId.push(user.id);
		}
	})

	useEffect(() => {
		setLoader(true)
		API.getMCQ(filters.user.id, level_id, type_id).then(function (response) {
			if (response.data.status)
			{
				NotificationManager.success(
                    response.data.message,
                    "Successfully",
                    5000
                )
				setVideoData(response.data.data)
			}
			else{
			   NotificationManager.error("Please try again", "Error", 5000);
			}
				setLoader(false)
		})
		.catch(function (error) {
			NotificationManager.error("Please try again", "Error", 5000);
		});
	}, [])

	const handleChange = (id, key, value) => {
		const updatedValue = videoData.map((each) => {
			if (each.id === id) {
				return { ...each, [key]: value }
			}
			else
				return each
		})
		setVideoData(updatedValue)
	}

	const handleUpload = (file, id) => {
		(selectedType[id]?.type) ? setSelectedType((prev) => (
			{
				...prev,
				[id]: {
					...selectedType[id], [selectedType[id].type]: file[0]
				}
			})) :
			setSelectedType((prev) => (
				{
					...prev,
					[id]: {
						...selectedType[id], type: 'image', image: file[0]
					}
				}))
	}

	const handleSave = (video) => {

		if (!(video.thumbnail_image && video.video_url) && !(selectedType[video.id]?.image && selectedType[video.id]?.video)) {
			if ((!video.thumbnail_image) && (!selectedType[video.id]?.image))
				NotificationManager.error("Please add the Video Thumbnail", "Error", 5000);
			if ((!video.video_url) && (!selectedType[video.id]?.video))
				NotificationManager.error("Please add the Training Video", "Error", 5000);
			return
		}
		if(!video.title){
			NotificationManager.error("Video Title is required", "Error", 5000);
			return 
		}
		if(selectedType[video.id]?.image)
		{
			const uploadedFile = selectedType[video.id]?.image
			if (!uploadedFile.type.match("image.*")) {
				NotificationManager.error("The file uploaded is not an image", "Error", 5000);
				return;
			  }		
		}
		if(selectedType[video.id]?.video)
		{
			const uploadedFile = selectedType[video.id]?.video
			if (!uploadedFile.type.match("video.*")) {
				NotificationManager.error("The file uploaded is not a video", "Error", 5000);
				return;
			  }
		}

		var formData = new FormData();
		let updatedVideo = {
			type: parseInt(type_id),
			thumbnail_image: video.thumbnail_image,
			level_id: parseInt(level_id),
			test_id: 2,
			title: video.title,
			duration: video.duration,
			video_url: video.video_url,
			is_active: video.is_active,
			is_mandatory: video.is_mandatory,
			user_id: video.user_id,
			multiple_user_id: arrayId,
			user_role: video.user_role,
			workshop_id: video.workshop_id
		}

		if (typeof video.id === 'string' && video.id.includes('new'))
			updatedVideo = [updatedVideo]
		else
			updatedVideo = [{ ...updatedVideo, id: video.id }]

		formData.append("type", "1")
		formData.append("data", JSON.stringify(updatedVideo));
		selectedType[video.id]?.image && formData.append("image", selectedType[video.id].image)
		selectedType[video.id]?.video && formData.append("video", selectedType[video.id].video)
		setLoader(true)
		API.postMCQ_VideoUpdate(formData).then((res) =>
		{
		if(res.status){
			NotificationManager.success(
				res.data.message,
				"Successfully",
				5000
			)
			API.getMCQ(filters.user.id, level_id, type_id).then(function (response) {
				if (response.status){
					setVideoData(response.data.data)
				}
				else{
         			NotificationManager.error("Please try again", "Error", 5000);
				}
				setLoader(false)
			})}
		}) .catch(function (error) {
			NotificationManager.error("Please try again", "Error", 5000);
			setLoader(false)
		});
	}

	const handleAddVideo = () => {
		const newVideoData = {
			duration: "",
			id: `${addVideoCount}_new`,
			is_active: true,
			is_mandatory: true,
			level_id,
			test_id: 2,
			title: "",
			type: type_id,
			user_id: filters.user.id,
			user_role: filters.user.user_type,
			workshop_id: filters.workshop.id
		}
		setVideoData((prev) => [...prev, newVideoData])
		setAddVideoCount((prev) => prev + 1)
	}

	const handleDelete = (id, userId) => {
		setLoader(true)
		const videoDetails = {
			data: [
				{
					type: 1,
					id,
					user_id: userId,
					multiple_user_id: arrayId,
				}
			]
		}
		if (typeof id === 'string' && id.includes('new')) {
			const updatedVideos = videoData.filter((question) => !(question.id === id))
			setVideoData(updatedVideos)
			setLoader(false)
		}
		else
			API.deleteQuestionVideos(videoDetails).then((response) => {
				if (response.status) {
					NotificationManager.success(
						response.data.message,
						"Successfully",
						5000
					)
					const updatedVideos = videoData.filter((question) => !(question.id === id))
					setVideoData(updatedVideos)
				}
				else{
					NotificationManager.error("Please try again", "Error", 5000);
				}
				setLoader(false)
			})
			.catch(function (error) {
				setLoader(false)
				NotificationManager.error("Please try again", "Error", 5000);
			});
	}
	return (
		<>
			{loader ? (
				<>
					<div className="modal-sb"></div>
					<div className="sub-modal-sb"></div>
				</>
			) : (
				""
			)}
			<div className='video-edit-final-child'>
				<div className="mt-54">
					<div className="training-levels-mb-0">
						<p className="sub-heading">Training videos  
						<span className='validation-warning'>Title, Thumbnail and Video are mandatory.</span>	
						</p>
						<div className="flex-video">
							<button className="ml-24 edit-button bg-blue width-small" onClick={() => handleAddVideo()}>
								Add videos
							</button>
						</div>
					</div>
				</div>

				{/* Video map */}
				{videoData.map((video,index) => {
					return (<>
						<div key={video.id} className="form-wrapper-video">
							<div className="edit-single-video">
								{video.thumbnail_image &&
									<div className="edit-video-box-video">
										<img src={video.thumbnail_image} style={{ height: "100%", width: "100%" }} />
									</div>}
								<div className='edit-form-video'>
									<label className='select-label-video'>Select Media Type:</label>
									<select
										style={{ appearance: "auto" }}
										className='dropdown-image-video'
										value={(selectedType[video.id]?.type) || 'image'}
										name="type"
										onChange={(e) => {
											const type = e.target.value
											setSelectedType((prev) => (
												{
													...prev,
													[video.id]: {
														...selectedType[video.id], type
													}
												}))
										}
										}
									>
										<option value="image">Image</option>
										<option value="video">Video</option>
									</select>
									<label className='label-sub-heading-video'>
										Upload {""}
										{selectedType[video.id] && selectedType[video.id].type === 'video'
											? "Video File"
											: "Video Thumbnail"}
									</label>
									<input
										className='upload-file'
										type="file"
										name="media"
										onChange={(e) =>
											handleUpload(e.target.files, video.id)
										}
									/>
								</div>
								<input
									className="question-text-video mt-negative-38"
									type="text"
									name="firstVideoText"
									placeholder={`Video Title ${index+1}`}
									id="firstVideoText"
									value={video.title}
									onChange={(e) => handleChange(video.id, "title", e.target.value)}
								/>
								<div className="toggle-delete-wrapper-video">
									<div className="flex-video">
										<input
											type="checkbox"
											value={video.is_mandatory}
											className="ta-toggle-switch-checkbox"
											name={video.id}
											onChange={(e) => handleChange(video.id, "is_mandatory", !video.is_mandatory)}
											id={video.id}
										/>
										<label className="ta-toggle-switch-label" htmlFor={video.id}>
											<span className={
												video.is_mandatory === true
													? "ta-toggle-switch-inner"
													: "set-false-toggle-value"
											} />
											<span className="ta-toggle-switch-switch" />
										</label>
										{/* delete button  */}
										<button type="submit" className="delete-icon-video" onClick={() => handleDelete(video.id,video.user_id)}>
											<img src={deleteIcon} alt="Delete Icon" />
										</button>
									</div>
									<button onClick={() => handleSave(video)} className="ml-24 edit-button bg-blue width-small save-button-video">
										Save
									</button>
								</div>
							</div>
						</div>
					</>
					)
				})}
			</div>
		</>
	)
}

export default VideosEdit
