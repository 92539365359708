import React, { useState } from "react";
import API from "../../api";
import { NotificationManager } from "react-notifications";
import Constants from "../../constants";

const Row = (props) => { 
  const { data, srNum } = props;
  const [tempText, setTempText] = useState({
    text: "",
    id: "",
    channel: "",
    category: "",
  });

  const setNewData = () => {
    const temp = {
        templateId: tempText.id,
        template_offer_text: tempText.text,
        channel: tempText.channel,
        category: tempText.category,
    };

    API.saveWhatsappTemplate(temp).then(function (response) {
      if (response.data.status) {
        NotificationManager.success("Template Text saved", "Successfully", 5000);
      } else {
        NotificationManager.success("Something went wrong", "Unsuccessfully", 5000);
      }
    }).catch(err => console.log(err));
  }
        
  return (
    <>
      <div
        className={
          // this.props.selectedRow === this.props.data.uuid
             "boxes orders border-box clearfix leftalign blackspan9 selected"
            // : "boxes orders border-box clearfix leftalign blackspan9"
        }
      >
        <div className="textsize12 border-box weight500">
          <table className="stdtable middleUpperTable-ic">
            <tbody>
              
              <tr className="middleUpperTR">
                <td className="middleUpperTD-ic width1TH-ic">
                  {srNum + 1}
                </td>
                <td className="middleUpperTD-ic colorprim500span width2TH-ic" style={{padding:"5px"}}>
                  {/* <input type="text" value={data.templateId} /> */}
                  <div>{data.template_name}</div>
                </td>
                <td className="middleUpperTD-ic width3TH-ic">
                  <textarea
                    defaultValue={data.template_offer_text} 
                    className="inputDealPrice"
                    style={{ resize: 'none', textAlign: 'center' }}
                    onChange={(e) => setTempText({text: e.target.value, id: data.templateId, channel: data.channel, category: data.category})}
                  />
                </td>
                <td className="middleUpperTD-ic width2TH-ic">
                  <div role="button">
                    <div className="btn btn_small btncolorprim btncard move" onClick={() => setNewData()}>Save</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Row;
