import React from "react";
import Constants from "../constants";

class Row extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
  }
  handleButtonClick(type, event) {
    event.stopPropagation();
    // this.props.onRowClick(this.props.data, this.props.tag);

    this.props.actionButton(
      type,
      this.props.tag,
      this.props.data.uuid,
      this.props.data,
      this.props.data
    );
    /*this.setState(state => ({
          isToggleOn: !state.isToggleOn
        }));*/
  }
  handleClick(pageUrl) {
    //   this.props.onRowClick(this.props.data, this.props.tag);
    /*this.setState(state => ({
            isToggleOn: !state.isToggleOn
          }));*/
    //console.log()
    window.open(pageUrl);
  }
  handleInputBlur(rowData, e, type) {
    console.log(rowData, 'value', e.target.value);
    let obj = rowData;
    if (type in obj) {
      obj[type] = e.target.value ? parseInt(e.target.value) : null;
      this.props.handleInputChange(obj);
    }
  }


  render() {
    switch (this.props.tag) {
      case "rate_control":
        
        let rateDetails = this.props.data && this.props.data ? this.props.data : {};
        let service = this.props.data && this.props.data.service ? this.props.data.service : "";
        let workDone = this.props.data && this.props.data.work_done_name ? this.props.data.work_done_name : "";
        let materialCost = this.props.data && this.props.data.max_price ? this.props.data.max_price : "";
        let salesPrice = this.props.data && this.props.data.sale_price ? this.props.data.sale_price : "NA";
       
        return (
          <div>
            <div
              // style={{ background: "#eee" }}
              className={
                this.props.selectedRow === this.props.data.uuid
                  ? "boxes orders border-box clearfix leftalign blackspan9 selected"
                  : "boxes orders border-box clearfix leftalign blackspan9"
              }
            >
              <div className="textsize12 border-box weight500">
                <table className="stdtable middleUpperTable">
                  <tbody>
                    
                      <tr className="middleUpperTR">
                        <td className="middleUpperTD width6TH">
                          {service}
                        </td>
                        <td className="middleUpperTD width3TH">
                          {workDone}
                        </td>
                        <td className="middleUpperTD width2TH">
                          {salesPrice}
                        </td>
                        <td className="middleUpperTD width2TH">
                          <input
                            type="text"
                            placeholder="Maximum Rate"
                            defaultValue={materialCost}
                            className="inputDealPrice"
                            onBlur={e => this.handleInputBlur(rateDetails, e, "max_price")}
                            style={{textAlign:'center'}}
                          />
                        </td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }
}

export default Row;
