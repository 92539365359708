import React, { useState, useEffect, useRef } from "react";
import { NotificationManager } from "react-notifications";
import API from "../../api";
import Loader from "../../loader";
import ServiceFilter from "./ServiceFilter/serviceFilter";
import "flatpickr/dist/themes/material_green.css";
import "./ServiceTag.css";
import { useDispatch } from "react-redux";
import WarrantyPage from "./WarrantyPage.js";
import WarrantyCoverage from "./WarrantyCoverage.js";

const ServiceTag = (props) => {
  const [service_tag, setServiceTag] = useState("");
  const [initialLoader, setInitialLoader] = useState(false);
  const [goMechanicBenefits, setGoMechanicBenefits] = useState(null);
  const [whatIsIncluded, setWhatIsIncluded] = useState(null);
  const [wIITitle, setWIITitle] = useState(null);
  const [essentialServices, setEssentialServices] = useState(null);
  const [soloEssentialService, setEssentialSoloService] = useState(null);
  const [soloPerformanceSoloService, setPerformanceSoloService] = useState(null);
  const [performanceServices, setPerformanceServices] = useState(null);
  const [serviceSectionInfo, setServiceSectionInfo] = useState(null);
  const [servicePreview, setServicePreview] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [delImageCard, setDelImageCard] = useState(null);
  const [delAfterSuccess, setDelAfterSuccess] = useState(null);
  const [gEID, setGEID] = useState(null);
  const [gPID, setGPID] = useState(null);
  const [description, setDescription] = useState(null);
  const [specification, setSpecification] = useState();
  const [descriptionPreview, setDescriptionPreview] = useState(null);
  const [obdCarouselImage, setOBDCarouselImage] = useState(null);
  // const [warrantyExt, setWarrantyExt] = useState(null);

  const child = useRef();

  // const [greatestperformanceTD, setgratestPerformanceID] = useState(null);
  // const [loader, setLoader] = useState(false);
  const [serviceStoriesImages, setServiceStoriesImages] = useState(null);
  // const [serviceStoriesVideos, setServiceStoriesVideos] = useState(null);
  // const [faqs, setFAQ] = useState(null);

  useEffect(() => {
    if (props.content) {
      setServiceTag(props.content.service_tag ? props.content.service_tag : "");
      setServiceStoriesImages(
        props.content.service_detail_data ? props.content.service_detail_data.service_story_data.images : ""
      );
      // setServiceStoriesVideos(
      //   props.content.service_detail_data.service_story_data.videos
      // );
      setGoMechanicBenefits(
        props.content.service_detail_data
          ? props.content.service_detail_data.gomechanic_benefits
            .service_banner_image
          : null
      );
      setOBDCarouselImage(
        props.content.service_detail_data
          ? props.content.carousel_obd_url
          : null
      );
      // setFAQ(props.content.service_detail_data.faqs);
      setServiceSectionInfo(
        props.content.service_detail_data
          ? props.content.service_detail_data.service_pros.desc_details
          : null
      );
      setWhatIsIncluded(
        props.content.service_detail_data ? (
          props.content.service_detail_data.what_is_included ?
            (props.content.service_detail_data.what_is_included.additional_services_inclusions ? props.content.service_detail_data.what_is_included.additional_services_inclusions.inclusion : props.content.service_detail_data.service_pros.desc_details.inclusion) : null) : null
      );
      setWIITitle(
        props.content.service_detail_data ? (
          props.content.service_detail_data.what_is_included ? ((
            props.content.service_detail_data.what_is_included.additional_services_inclusions ? props.content.service_detail_data.what_is_included.additional_services_inclusions.title : `What's Included`
          )) : null
        ) : null
      );
      setEssentialServices(
        props.content.service_detail_data ? (props.content.service_detail_data
          .what_is_included ? props.content.service_detail_data
            .what_is_included.essential_services_inclusions : null) : null
      );
      setPerformanceServices(
        props.content.service_detail_data ? (props.content.service_detail_data
          .what_is_included ? props.content.service_detail_data
            .what_is_included.performance_services_inclusions : null) : null
      );
      setServicePreview(
        props.content.service_detail_data ? props.content.service_detail_data
          .preview_service_pros.desc_snippets : null
      );
      setEssentialSoloService(null);

      setGEID(() => {
        let arr = [];
        if (
          props.content.service_detail_data &&
          props.content.service_detail_data.what_is_included && props.content.service_detail_data.what_is_included
            .essential_services_inclusions
        ) {
          props.content.service_detail_data.what_is_included.essential_services_inclusions.inclusions.map(
            (data) => {
              arr.push(data.id);
            }
          );
          arr.sort().reverse();
          return arr[0];
        } else {
          return 0;
        }
      });
      setGPID(() => {
        let arr = [];
        if (
          props.content.service_detail_data &&
          props.content.service_detail_data.what_is_included && props.content.service_detail_data.what_is_included
            .performance_services_inclusions
        ) {
          props.content.service_detail_data.what_is_included.performance_services_inclusions.inclusions.map(
            (data) => {
              arr.push(data.id);
            }
          );
          arr.sort().reverse();
          return arr[0];
        } else {
          return 0;
        }
      });

      setDescription(
        props.content && props.content.service_detail_data && props.content.service_detail_data.service_description
          ? props.content.service_detail_data.service_description.description.split('<br/>')
          : null
      )

      setSpecification(
        props.content && props.content.service_detail_data && props.content.service_detail_data.service_specification
          ? props.content.service_detail_data.service_specification.product_specifications
          : null
      )

      setDescriptionPreview(
        props.content && props.content.service_detail_data && props.content.service_detail_data.preview_service_pros && props.content.service_detail_data.preview_service_pros.desc_snippets
          ? props.content.service_detail_data.preview_service_pros.desc_snippets : null
      )
      // setWarrantyExt(
      //   props.content
      // )

    }
  }, [props.content]);

  function handleSaveButtonClick() {
    let obj = {};
    let channel = props.filters && props.filters.channel ? props.filters.channel.id : "";
    obj["service_id"] = Number(props.content.id); //add service tag id
    obj["service_tag"] = service_tag;
    obj["channel"] = channel;
    let data = {};
    data["data"] = obj;
    setInitialLoader(true);
    API.updateServiceTagDetails(data)
      .then(function (response) {
        if (response.status) {
          setInitialLoader(false);
        }
        NotificationManager.success(`Data saved`, "Successfully", 5000);
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating the serviceTag details");
      });
  }

  const handelServicePreview = (event, key) => {
    let newData = [...servicePreview];
    servicePreview.map((data, index) => {
      if (key === index) newData[index] = event.target.value;
    });
    setServicePreview(newData);
  };

  function handelSavePreviewData(event) {
    event.preventDefault();
    let data = {};
    data["service_id"] = Number(props.content.id);
    let nestObj = {};
    nestObj["desc_snippets"] = servicePreview;
    data["preview_service_pros"] = nestObj;
    setInitialLoader(true);
    API.updateServiceDetails(data)
      .then(function (response) {
        if (response.status) {
          setInitialLoader(false);
        }
        NotificationManager.success(`Data saved`, "Successfully", 5000);
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating the serviceTag details");
      });
  }

  function handleDescriptionData() {
    let data = {};
    data["service_id"] = Number(props.content.id);
    data[`service_description`] = {}
    let tempArr = [];
    let newStringData = "";
    description.map((data, index) => {

      if (data.charAt(0) === '"' || data.charAt(data.length - 1) === '"'){
        data = data.replace(/\s/, "")
        tempArr.push(data)
      } else {
        tempArr.push(data)
      }
    })

    newStringData = tempArr.join("<br/>");
    console.log(newStringData);
    data.service_description[`description`] = newStringData;
    
    setInitialLoader(true);
    console.log(data, `payload data`);
    API.updateAccessoriesDetails(data)
      .then(function (response) {
        if (response.status) {
          setInitialLoader(false);
        }
        NotificationManager.success(`Data saved`, "Successfully", 5000);
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating the description details");
      });
  }

  const handelServiceInfo = (event, key2Check) => {
    let newData = { ...serviceSectionInfo };
    Object.keys(serviceSectionInfo).map((key) => {
      if (key === key2Check) newData[key] = event.target.value;
    });
    setServiceSectionInfo(newData);
  };

  function handelSaveServicePros(event) {
    event.preventDefault();
    let data = {};
    data["service_id"] = Number(props.content.id);
    let nestObj = {};
    nestObj["desc_details"] = serviceSectionInfo;
    data["service_pros"] = nestObj;
    setInitialLoader(true);
    API.updateServiceDetails(data)
      .then(function (response) {
        if (response.status) {
          setInitialLoader(false);
        }
        NotificationManager.success(`Data saved`, "Successfully", 5000);
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating the serviceTag details");
      });
  }

  const handelWhatsIncluded = (event, key) => {
    let newData = [...whatIsIncluded];
    whatIsIncluded.map((data, index) => {
      if (key === index) newData[index] = event.target.value;
    });
    setWhatIsIncluded(newData);
  };

  function handelSaveWhatsIncluded(event, key) {
    event.preventDefault();
    let data = {},
      nestObj = {},
      l2nestObj = {};
    data["service_id"] = Number(props.content.id);
    if (key === "Additional Services") {
      l2nestObj["title"] = key;
      l2nestObj["inclusion"] = whatIsIncluded;
      nestObj["additional_services_inclusions"] = l2nestObj;
      data["what_is_included"] = nestObj;
      setInitialLoader(true);
      API.updateServiceDetails(data)
        .then(function (response) {
          if (response.status) {
            setInitialLoader(false);
          }
          NotificationManager.success(`Data saved`, "Successfully", 5000);
        })
        .catch(function (error) {
          // handle error
          NotificationManager.error("Please try again", "Error", 5000);
          console.log(error, "Error in updating the serviceTag details");
        });
    } else {
      serviceSectionInfo.inclusion = whatIsIncluded;
      handelSaveServicePros(event);
    }
  }
  // GoMechanic Benefits Banner
  const handleBenefitsBannerSubmit = (e) => {
    e.preventDefault();
    if (uploadFile) {
      var file = uploadFile[0];
      if (!file.type.match("image.*")) {
        NotificationManager.error("The file is not an image", "Error", 5000);
        return;
      }
    }
    var formData = new FormData();
    formData.append("file", file);
    formData.append("service_id", Number(props.content.id));
    let obj = {};
    obj["service_banner_image_title"] =
      props.content.service_detail_data.gomechanic_benefits.service_banner_image_title;
    formData.append("gomechanic_benefits", JSON.stringify(obj));
    setInitialLoader(true);
    API.updateServiceDetails(formData)
      .then(function (response) {
        if (response.status) {
          setGoMechanicBenefits(response.data.image_url);
          setUploadFile(null);
          setInitialLoader(false);
        }
        NotificationManager.success(
          `Image uploaded data saved`,
          "Successfully",
          5000
        );
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating the serviceTag details");
        setUploadFile(null);
      });
  };

  const handleOBDImageSubmit = (e) => {
    e.preventDefault();
    if (uploadFile) {
      var file = uploadFile[0];
      if (!file.type.match("image.*")) {
        NotificationManager.error("The file is not an image", "Error", 5000);
        return;
      }
    }
    var formData = new FormData();
    formData.append("file", file);
    formData.append("service_id", Number(props.content.id));
    let obj = {};
    obj["carousel_obd_url"] =
    obdCarouselImage;
    formData.append("carousel_obd_url", JSON.stringify(obj));
    setInitialLoader(true);
    API.updateServiceDetails(formData)
      .then(function (response) {
        if (response.status) {
          console.log('response.data',response.data)
          setOBDCarouselImage(response.data.data.image_url);
          setUploadFile(null);
          setInitialLoader(false);
        }
        NotificationManager.success(
          `Image uploaded data saved`,
          "Successfully",
          5000
        );
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating the serviceTag details");
        setUploadFile(null);
      });
  };
  
  const handleImageSories = (e, image) => {
    e.stopPropagation();
    e.preventDefault();
    let file = uploadFile[0];
    if (uploadFile) {
      if (!file.type.match("image.*")) {
        NotificationManager.error("The file is not an image", "Error", 5000);
        return;
      }
    }
    let formData = new FormData();
    let obj = {};
    if (image) {
      obj["image_url"] = image;
      console.log(image);
    } else {
      obj["image_url"] = ``;
    }
    formData.append("service_id", Number(props.content.id));
    formData.append("service_story_data", JSON.stringify(obj));
    formData.append("file", file);
    setInitialLoader(true);
    API.updateAccessoriesDetails(formData).then((response)=>{
        if(response.data.status) {
          setServiceStoriesImages(response.data.data.service_story_data.images)
          setUploadFile(null);
          setInitialLoader(false);
        return NotificationManager.success(
          `Image uploaded data saved`,
          "Successfully",
          5000
        );
        }
      }).catch(function (error) {
        // handle error
        console.log(error,'entered catch')
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating story images");
        setUploadFile(null);
        setInitialLoader(false);
      });
  };

  const handelSoloServiceChange = (file, key, list, index) => {
    let obj = {};
    let nestObj = {};
    let arrayBojIndex =
      key === "essential_services_inclusions"
        ? { ...soloEssentialService }
        : { ...soloPerformanceSoloService };
    obj["service_id"] = Number(props.content.id);
    obj["file"] = file[0];
    nestObj[key] = list;
    obj["what_is_included"] = nestObj;
    arrayBojIndex[index] = obj;
    if (key === "essential_services_inclusions")
      setEssentialSoloService(arrayBojIndex);
    else setPerformanceSoloService(arrayBojIndex);
  };
  const handelOnSoloServiceTitleChange = (e, key, list, index) => {
    let obj = {};
    let nestObj = {};
    let arrayBojIndex =
      key === "essential_services_inclusions"
        ? { ...soloEssentialService }
        : { ...soloPerformanceSoloService };
    const file = index in arrayBojIndex ? arrayBojIndex[index].file : null;
    obj["service_id"] = Number(props.content.id);
    obj["file"] = file;
    list.name = e.target.value;
    nestObj[key] = list;
    obj["what_is_included"] = nestObj;
    arrayBojIndex[index] = obj;
    if (key === "essential_services_inclusions")
      setEssentialSoloService(arrayBojIndex);
    else setPerformanceSoloService(arrayBojIndex);
  };

  function handelSoloServiceCardUpdate(key, id, domIndex) {
    setInitialLoader(true);
    let mKey =
      key === "essential_services_inclusions"
        ? "essential_services_inclusions"
        : "performance_services_inclusions";
    let data =
      key === "essential_services_inclusions"
        ? { ...soloEssentialService[domIndex] }
        : { ...soloPerformanceSoloService[domIndex] };
    var formData = new FormData();

    if (data.file) formData.append("file", data.file);

    if (data.service_id) formData.append("service_id", data.service_id);

    if (data.what_is_included) {
      data.what_is_included[mKey].id =
        id === ""
          ? key === "essential_services_inclusions"
            ? gEID + 1
            : gPID + 1
          : data.what_is_included[mKey].id;
    }
    formData.append("what_is_included", JSON.stringify(data.what_is_included));
    console.log(data)
    API.updateServiceDetails(formData)
      .then(function (response) {
        let message = "Text Updated";
        if (response.status) {
          if (key === "essential_services_inclusions")
            if (response.data.image_url.length !== 0) {
              essentialServices.inclusions[domIndex].image =
                response.data.image_url;
              message = "Image Updated";
            } else {
              if (response.data.image_url.length !== 0) {
                performanceServices.inclusions[id].image =
                  response.data.image_url;
                message = "Image Updated";
              }
            }
          if (key === "essential_services_inclusions") {
            setGEID(data.what_is_included[mKey].id);
          } else {
            setGPID(data.what_is_included[mKey].id);
          }
          setUploadFile(null);
          setInitialLoader(false);
          child.current.handleApplyFilter();
        }
        NotificationManager.success(message, "Successfully", 5000);
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating the serviceTag details");
        setUploadFile(null);
      });
  }

  function handelDynamicInput(stateData, stateToSet) {
    let values;
    if (!Array.isArray(stateData)) {
      values = { ...stateData };
      let keyName = Object.keys(stateData).length;
      values["New_" + keyName] = "";
    } else {
      values = [...stateData];
      values.push("");
    }
    dynamicUpdate(values, stateToSet);
  }
  
  function handelDynamicInputSpec(stateData, stateToSet) {
    let values = [...stateData];
    let keyIndex = Object.keys(stateData).length;
    keyIndex = ++keyIndex;
    let keyName = `New_${keyIndex}`
    let newObj = {};
    newObj[keyName] = "";
    values.push(newObj);
    dynamicUpdate(values, stateToSet);
  }
  
  function handleRemove(i, stateData, stateToSet) {
    let values;
    if (!Array.isArray(stateData)) {
      values = { ...stateData };
      delete values[i];
    } else {
      values = [...stateData];
      values.splice(i, 1);
    }
    dynamicUpdate(values, stateToSet);
  }

  function handelDynamicImageInput(stateData, stateToSet) {
    if (!!Array.isArray(stateData)) {
      let values = [...stateData];
      values.push("");
      dynamicUpdate(values, stateToSet)
    } else {
      const pValues = { ...stateData };
      const values = pValues.inclusions;
      const obj = { name: '', id: '', image: '' }
      values.push(obj);
      pValues.inclusions = values
      dynamicUpdate(pValues, stateToSet);
    }
  }

  function handleDynamicImageRemove(i, id, stateData, stateToSet) {
    let element = document.getElementById("modal");
    const pValues = { ...stateData };
    const values = pValues.inclusions;
    if (id !== "") {
      element.style.display = "block";
      let obj = {
        id: id,
        key:
          stateToSet === "essentialServices"
            ? "essential_services_inclusions"
            : "performance_services_inclusions",
      };

      setDelImageCard(obj);
      obj = {
        i: i,
        id: id,
        stateData: stateData,
        stateToSet: stateToSet,
      };
      setDelAfterSuccess(obj);
    } else {
      values.splice(i, 1);
      pValues.inclusions = values;
      dynamicUpdate(pValues, stateToSet);
    }
  }

  function delSoloService() {
    let id = delImageCard.id;
    let key = delImageCard.key;
    let element = document.getElementById("modal");
    element.style.display = "none";
    let cObj = {};
    cObj["id"] = id;
    let pObj = {};
    pObj[key] = cObj;
    setInitialLoader(true);
    let service_id = Number(props.content.id);
    var formData = new FormData();
    if (service_id) formData.append("service_id", service_id);

    if (props.filters.channel.id !== 3){
    formData.append("what_is_included", JSON.stringify(pObj));
    API.delServiceDetails(formData)
      .then(function (response) {
        if (response.status) {
          const pValues = { ...delAfterSuccess.stateData };
          const values = pValues.inclusions;
          values.splice(delAfterSuccess.i, 1);
          pValues.inclusions = values;
          dynamicUpdate(pValues, delAfterSuccess.stateToSet);
          let message = "Item Removed";
          NotificationManager.success(message, "Successfully", 5000);
          child.current.handleApplyFilter();
        }
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating the serviceTag details");
      });

    } else if (props.filters.channel.id === 3){
      let service_id = Number(props.content.id);
      let data = {};
      data[`service_id`] = service_id;
      data[`service_story_data`] = {}
      // let updatedImageArray = [...delAfterSuccess.stateData];
      // updatedImageArray.splice(delAfterSuccess.i, 1);
      // data.service_story_data[`images`] = [updatedImageArray];

      let showData = [...delAfterSuccess.stateData];
      showData.splice(delAfterSuccess.i, 1);
      data.service_story_data[`images`] = showData;
      console.log(data);
      API.removeStoryImage(data)
        .then(function (response) {
          if (response.status) {
            dynamicUpdate(showData, delAfterSuccess.stateToSet);
            let message = "Removed Story Image";
            NotificationManager.success(message, "Successfully", 5000);
            child.current.handleApplyFilter();
          }
        })
        .catch(function (error) {
          // handle error
          NotificationManager.error("Please try again", "Error", 5000);
          console.log(error, "Error in updating the serviceTag details");
        });
    }
    setInitialLoader(false);
  }
  function closeModal() {
    let element = document.getElementById("modal");
    element.style.display = "none";
  }

  function handleDescription(event, key) {
    let newData = [...description];
    description.map((data, index) => {
      if (key === index) newData[index] = event.target.value;
    });
    setDescription(newData);
  }

  function dynamicUpdate(stateData, stateToSet) {
    switch (stateToSet) {
      case "servicePreview":
        setServicePreview(stateData);
        break;
      case "whatIsIncluded":
        setWhatIsIncluded(stateData);
        break;
      case "serviceSectionInfo":
        setServiceSectionInfo(stateData);
        break;
      case "performanceServices":
        setPerformanceServices(stateData);
        break;
      case "essentialServices":
        setEssentialServices(stateData);
        break;
      case "description":
        setDescription(stateData);
        break;
      case "serviceStoriesImages":
        setServiceStoriesImages(stateData);
        break;
      case "specification":
        setSpecification(stateData);
        break;
      default:
        break;
    }
  }
  // this function replaces object key with new key and puts it back on same position
  function handleInputBlur(stateData, e, key) {
    const values = { ...stateData };
    if (e.target.value !== key) {
      let original_key_index = Object.keys(values).indexOf(key)
      let keyValues = Object.entries(values); //convert object to keyValues ["key1", "value1"] ["key2", "value2"]
      keyValues.splice(original_key_index, 1, [e.target.value, values[key]]); // insert key value at the index you want like 1.
      let newObj = Object.fromEntries(keyValues); // convert key values to obj {key1: "value1", newKey: "newValue", key2: "value2"}
      delete newObj[key];
      setServiceSectionInfo(newObj);
    }
  }
  function handleSpecificationInputBlur(stateData, e, key, index, actionString, unchangedValue) {
    let values = [...stateData];
    console.log(values, 'payload data');
    let newObj = {};
    if (actionString === "specKeyData") {
      let objUpdatedKey = e.target.value;
      newObj[objUpdatedKey] = unchangedValue;
      values[index] = newObj;
      dynamicUpdate(values, "specification");
      console.log(values, "payload data");
    } else if (actionString === "specValueData"){
      let objUpdatedKey = e.target.value;
      newObj[unchangedValue] = objUpdatedKey;
      values[index] = newObj;
      dynamicUpdate(values, "specification");
      console.log(values, "payload data");
    }
  }

  function handelSpecificationPros(event) {
   console.log(`update specification`);
    let data = {};
    data["service_id"] = Number(props.content.id);
    data["service_specification"] = {}
    data.service_specification["product_specifications"] = specification;
    console.log(data);
    console.log(specification);

    setInitialLoader(true);
    API.updateAccessoriesDetails(data)
      .then(function (response) {
        if (response.status) {
          setInitialLoader(false);
        }
        NotificationManager.success(`Data saved`, "Successfully", 5000);
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in updating the serviceTag details");
      });
  }
  
  function handleSpecificationRemove(i, stateData, stateToSet) {
    let values;
    values = [...stateData];
    delete values[i];
    values.splice(i, 1);
    dynamicUpdate(values, stateToSet);
  }

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <ServiceFilter ref={child} onApplyFilter={props.onApplyFilter} />
      </div>
      {initialLoader ? (
        <div id="websiteMainmiddleloader" className="bgwhite centeralign">
          <Loader showLoader={props.loader} />
        </div>
      ) : props.filters && props.filters.channel && ((props.filters.channel.id == 2 && props.filters.subService) || (props.filters.channel.id == 1 && props.filters.services && props.filters.services.name) || (props.filters.channel.id == 3 && props.filters.subService) || (props.filters.channel.id == 3 && props.filters.services && props.filters.services.name) || (props.filters.channel.id == 4 && props.filters.warrantyServices && props.filters.warrantyServices.id) || (props.filters.channel.id == 5 && props.filters.services && props.filters.services.id)  || (props.filters.channel.id == 6 && props.filters.services && props.filters.services.id)) ? (
        <>
          {props.loader ? (
            <>
              <div className="modal-sb"></div>
              <div className="sub-modal-sb"></div>
            </>
          ) : (
            ""
          )}
          <div className="mainContainer">
            {props.filters.channel.id !== 3 && props.filters.channel.id !== 4 && props.filters.channel.id !== 5 &&
              <div className="manageTimeSlot">Manage Service Tag</div>
            }
            {props.filters.channel.id !== 3 && props.filters.channel.id !== 4 && props.filters.channel.id !== 5 &&
              <div className="innerBox">
                <div className="serviceTagContainer">
                  <div className="serviceTagDetailsContainer">
                    New Service Tag Lable :
                  </div>
                  <div className="serviceTagDetailsContainer maskingWalletContainer">
                    <div>
                      <input
                        type="text"
                        maxLength="30"
                        placeholder="New Tag"
                        className={`dateInputBox field border-box small`}
                        value={service_tag}
                        onChange={(e) => {
                          setServiceTag(
                            e.target.value ? String(e.target.value) : ""
                          );
                        }}
                        style={{ width: "20rem" }}
                      />
                    </div>
                  </div>
                  <div
                    className="serviceTagDetailsContainer"
                    role="button"
                    style={{ justifyContent: "center" }}
                  >
                    <div
                      className="btn btn_small btncolorprim btncard move"
                      onClick={() => {
                        handleSaveButtonClick();
                      }}
                    >
                      Save Details
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
            {props.filters.channel.id !== 3 && props.filters.channel.id !== 4 && props.filters.channel.id !== 5 &&
            <hr />
          }
          {/* story video */}
          {/* <div className="mainContainer">
            <div className="manageTimeSlot">Stories Video</div>
            <div className="innerBox">
              <form>
                {serviceStoriesVideos &&
                  serviceStoriesVideos.map((video) => (
                    <div key={video.video_id} className="video-card">
                      {
                        <video
                          src=""
                          height="240"
                          poster={
                            video.video_url
                              ? video.video_url
                              : `https://via.placeholder.com/580x261?text=Video+Not+Uploaded`
                          }
                          controls
                        >
                          <source
                            src={
                              video.video_url
                                ? video.video_url
                                : `https://via.placeholder.com/580x261?text=Video+Not+Uploaded`
                            }
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      }
                      <div>
                        <input type="file" name="media" />
                      </div>
                      <button>Update</button>
                    </div>
                  ))}
              </form>
            </div>
          </div> */}
          {/* stroy video ends */}
          {/* <hr/> */}
          {props.filters.channel.id == 3 &&
            <div className="mainContainer" style={{ width: "60vw" }}>
              <div className="manageTimeSlot">Story Images
                  <span style={{ textAlign: "right" }}>
                    <button
                      id="addleadbtn"
                      className="btn btn_small btncolorprim btncard move"
                      style={{
                        background: "rgb(105, 118, 148)",
                        color: "rgb(255, 255, 255)",
                      }}
                      onClick={() =>
                        handelDynamicImageInput(
                          serviceStoriesImages,
                          "serviceStoriesImages"
                        )
                      }
                    >
                      +
                    </button>
                  </span>
              </div>
              <div className="innerBox">
                  
                  {serviceStoriesImages && serviceStoriesImages.map((image, index) => (
                    <div key={index} className="video-card">
                      <button
                        type="button"
                        className="closeVideoCard"
                        onClick={() =>
                          handleDynamicImageRemove(
                            index,
                            image.id,
                            serviceStoriesImages,
                            "serviceStoriesImages"
                          )
                        }
                      >
                        X
                      </button>
                      <img
                        src={
                          image
                            ? image
                            : `https://via.placeholder.com/580x261?text=Image+Not+Uploaded`
                        }
                        alt=""
                        title="image-preview"
                      />
                      <div></div>
                      <div>
                        <input 
                          type="file" 
                          name="media" 
                          onChange={
                            (e) => setUploadFile(e.target.files)
                          }
                        />
                      </div>
                      
                      <button onClick={(e) => handleImageSories(e, image)} class="button-update" disabled={!uploadFile} style={(!uploadFile) ? { cursor: "not-allowed" } : { cursor: "pointer" }}>Update</button>
                    </div>
                  ))}
              </div>
            </div>
          }
          {/* Service / Accessories preview */}
          {props.filters.channel && (props.filters.channel.id == 2 
          || props.filters.channel.id == 3) ? (
            <React.Fragment>
              {(props.filters.channel.id == 2|| props.filters.channel.id == 3) &&
              <div className="mainContainer">
                <div className="manageTimeSlot">
                        {props.filters.channel.id == 2 ? "Service Preview Description" :
                          props.filters.channel.id == 3 ? "Accessories Preview Description" : ""}
                  <span style={{ textAlign: "right" }}>
                    <button
                      id="addleadbtn"
                      className="btn btn_small btncolorprim btncard move"
                      style={{
                        background: "rgb(105, 118, 148)",
                        color: "rgb(255, 255, 255)",
                      }}
                      onClick={() =>
                        handelDynamicInput(servicePreview, "servicePreview")
                      }
                    >
                      +
                    </button>
                  </span>
                </div>
                <div className="innerBox">
                  {/* {serviceSectionpreview} */}
                  <form onSubmit={handelSavePreviewData}>
                    {servicePreview &&
                      servicePreview.map((service, index) => (
                        <>
                          <div key={index} style={{ margin: "1rem 0" }}>
                            <button
                              type="button"
                              className="closeButton"
                              onClick={() =>
                                handleRemove(
                                  index,
                                  servicePreview,
                                  "servicePreview"
                                )
                              }
                            >
                              X
                            </button>
                            <input
                              type="text"
                              value={
                                (service.charAt(0) === '"' || service.charAt(service.length - 1) === '"')
                                  ? service.substring(1, service.length - 1)
                                  : service
                                }
                              name="title"
                              onChange={(e) => handelServicePreview(e, index)}
                            />
                          </div>
                        </>
                      ))}
                    <div
                      style={{
                        textAlign: "right",
                        marginBottom: "1rem",
                        marginTop: "1rem",
                      }}
                    >
                      <button className="button-update">Update</button>
                    </div>
                  </form>
                </div>
              </div>
              }
              {/* Service preview end */}

              {/* Service Info */}
              <div className="mainContainer">
                <div className="manageTimeSlot">
                  {(props.filters.channel.id == 2) ? "Service Section Info" : (props.filters.channel.id == 3) ? `Accessories Section Info` : ""}
                  {/* <span style={{ textAlign: "right" }}>
                    <button
                      id="addleadbtn"
                      className="btn btn_small btncolorprim btncard move"
                      style={{
                        background: "rgb(105, 118, 148)",
                        color: "rgb(255, 255, 255)",
                      }}
                      onClick={() =>
                        handelDynamicInput(serviceSectionInfo, "serviceSectionInfo")
                      }
                    >
                      +
                    </button>
                  </span> */}
                </div>
                <div className="innerBox">
                  {/* {serviceSectionInfo} */}
                  <form onSubmit={handelSaveServicePros}>
                    {serviceSectionInfo &&
                      Object.keys(serviceSectionInfo).map((key) =>
                        key !== "inclusion" ? (
                          <>
                            <div key={key} style={{ margin: "1rem 0" }}>
                              <button
                                type="button"
                                className="closeButton"
                                onClick={() =>
                                  handleRemove(
                                    key,
                                    serviceSectionInfo,
                                    "serviceSectionInfo"
                                  )
                                }
                              >
                                X
                              </button>
                              {(props.filters.channel.id == 2) 
                                ? <input
                                  type="text"
                                  defaultValue={key}
                                  className="inputKeys"
                                  onBlur={(e) =>
                                    handleInputBlur(serviceSectionInfo, e, key)
                                  }
                                /> 
                                : (props.filters.channel.id == 3) 
                                ? <span className="accesooriesSectionInfoTitle">{key}</span>
                                : ""
                              }
                              <input
                                type="text"
                                value={serviceSectionInfo[key]}
                                name="title"
                                onChange={(e) => handelServiceInfo(e, key)}
                              />
                            </div>
                          </>
                        ) : (
                          // ) : (
                          //   <></>
                          // )
                          <></>
                        )
                      )}
                    <div
                      style={{
                        textAlign: "right",
                        marginBottom: "1rem",
                        marginTop: "1rem",
                      }}
                    >
                      <button className="button-update">Update</button>
                    </div>
                  </form>
                </div>
              </div>
              {/* Service Info end */}

              {/* Specification Start */}
              {props.filters.channel.id === 3 &&
                <div className="mainContainer">
                  <div className="manageTimeSlot">
                    Product Specifications
                    <span style={{ textAlign: "right" }}>
                      <button
                        id="addleadbtn"
                        className="btn btn_small btncolorprim btncard move"
                        style={{
                          background: "rgb(105, 118, 148)",
                          color: "rgb(255, 255, 255)",
                        }}
                        onClick={() =>
                          handelDynamicInputSpec(specification, "specification")
                        }
                      >
                        +
                      </button>
                    </span>
                  </div>
                  <div className="innerBox">
                      {
                      specification && (specification.length > 0) &&
                        specification.map((key, index) =>(
                          <>
                          {
                            key && Object.keys(key).map((spec, i) => (
                                  <div key={spec} style={{ margin: "1rem 0" }}>
                                    <button
                                      type="button"
                                      className="closeButton"
                                      onClick={() =>
                                        handleSpecificationRemove(
                                          index,
                                          specification,
                                          "specification"
                                        )
                                      }
                                    >
                                      X
                                    </button>
                                    <input
                                      type="text"
                                      defaultValue={spec}
                                      className="inputKeys"
                                      name="text"
                                      onBlur={(e) => handleSpecificationInputBlur(specification, e, spec, index, "specKeyData", specification[index][spec])}
                                    />
                                    <input
                                      type="text"
                                      defaultValue={specification[index][spec]}
                                      name="title"
                                      onBlur={(e) => handleSpecificationInputBlur(specification, e, spec, index, "specValueData", spec)}
                                    />
                                  </div>
                            )) 
                          }
                          </>
                        ))}
                      <div
                        style={{
                          textAlign: "right",
                          marginBottom: "1rem",
                          marginTop: "1rem",
                        }}
                      >
                          <button className="button-update" onClick={(e) => handelSpecificationPros(e)}>Update</button>
                      </div>
                  </div>
                </div>
              }
              {/* Specification End */}

              {/* essential service */}
              {essentialServices && (
                <div className="mainContainer" style={{ width: "80vw" }}>
                  <div className="manageTimeSlot">
                    {essentialServices.title}
                    <span style={{ textAlign: "right" }}>
                      <button
                        id="addleadbtn"
                        className="btn btn_small btncolorprim btncard move"
                        style={{
                          background: "rgb(105, 118, 148)",
                          color: "rgb(255, 255, 255)",
                        }}
                        onClick={() =>
                          handelDynamicImageInput(
                            essentialServices,
                            "essentialServices"
                          )
                        }
                      >
                        +
                      </button>
                    </span>
                  </div>
                  <div className="innerBox">
                    <form>
                      {essentialServices.inclusions &&
                        essentialServices.inclusions.map((list, index) => (
                          <div key={index} className="video-card">
                            <button
                              type="button"
                              className="closeVideoCard"
                              onClick={() =>
                                handleDynamicImageRemove(
                                  index,
                                  list.id,
                                  essentialServices,
                                  "essentialServices"
                                )
                              }
                            >
                              X
                            </button>
                            <img
                              src={
                                list.image
                                  ? list.image
                                  : `https://via.placeholder.com/580x261?text=Image+Not+Uploaded`
                              }
                              alt=""
                              title="image-preview"
                            />
                            <div>
                              <label>Title</label>
                              <input
                                type="text"
                                name="categoryVideoThumbnail"
                                value={list.name}
                                onChange={(e) =>
                                  handelOnSoloServiceTitleChange(
                                    e,
                                    "essential_services_inclusions",
                                    list,
                                    index
                                  )
                                }
                              />
                            </div>
                            <div>
                              <input
                                type="file"
                                name="media"
                                onChange={(e) =>
                                  handelSoloServiceChange(
                                    e.target.files,
                                    "essential_services_inclusions",
                                    list,
                                    index
                                  )
                                }
                              />
                            </div>
                            <button
                              type="button"
                              onClick={() =>
                                handelSoloServiceCardUpdate(
                                  "essential_services_inclusions",
                                  list.id,
                                  index
                                )
                              }
                            >
                              Update
                            </button>
                          </div>
                        ))}
                    </form>
                  </div>
                </div>
              )}
              {/* end essential service */}
              {/* performanceServices service */}
              {performanceServices && (
                <div className="mainContainer" style={{ width: "80vw" }}>
                  <div className="manageTimeSlot">
                    {performanceServices.title}
                    <span style={{ textAlign: "right" }}>
                      <button
                        id="addleadbtn"
                        className="btn btn_small btncolorprim btncard move"
                        style={{
                          background: "rgb(105, 118, 148)",
                          color: "rgb(255, 255, 255)",
                        }}
                        onClick={() =>
                          handelDynamicImageInput(
                            performanceServices,
                            "performanceServices"
                          )
                        }
                      >
                        +
                      </button>
                    </span>
                  </div>

                  <div className="innerBox">
                    <form>
                      {performanceServices.inclusions &&
                        performanceServices.inclusions.map((list, index) => (
                          <div key={index} className="video-card">
                            <button
                              type="button"
                              className="closeVideoCard"
                              onClick={() =>
                                handleDynamicImageRemove(
                                  index,
                                  list.id,
                                  performanceServices,
                                  "performanceServices"
                                )
                              }
                            >
                              X
                            </button>
                            <img
                              src={
                                list.image
                                  ? list.image
                                  : `https://via.placeholder.com/580x261?text=Image+Not+Uploaded`
                              }
                              alt=""
                              title="image-preview"
                            />
                            <div>
                              <label>Title</label>
                              <input
                                type="text"
                                name="categoryVideoThumbnail"
                                value={list.name}
                                onChange={(e) =>
                                  handelOnSoloServiceTitleChange(
                                    e,
                                    "performance_services_inclusions",
                                    list,
                                    index
                                  )
                                }
                              // name="title"
                              />
                            </div>
                            <div>
                              <input
                                type="file"
                                name="media"
                                onChange={(e) =>
                                  handelSoloServiceChange(
                                    e.target.files,
                                    "performance_services_inclusions",
                                    list,
                                    index
                                  )
                                }
                              />
                            </div>
                            <button
                              type="button"
                              onClick={() =>
                                handelSoloServiceCardUpdate(
                                  "performance_services_inclusions",
                                  list.id,
                                  index
                                )
                              }
                            >
                              Update
                            </button>
                          </div>
                        ))}
                    </form>
                  </div>
                </div>
              )}
              {/* end performanceServices service */}
              {/* What's Included */}
              {props.filters.channel.id !== 3 &&
                <div className="mainContainer">
                  <div className="manageTimeSlot">
                    {wIITitle} Section
                    <span style={{ textAlign: "right" }}>
                      <button
                        id="addleadbtn"
                        className="btn btn_small btncolorprim btncard move"
                        style={{
                          background: "rgb(105, 118, 148)",
                          color: "rgb(255, 255, 255)",
                        }}
                        onClick={() =>
                          handelDynamicInput(whatIsIncluded, "whatIsIncluded")
                        }
                      >
                        +
                      </button>
                    </span>
                  </div>
                  <div className="innerBox">
                    <form onSubmit={(e) => handelSaveWhatsIncluded(e, wIITitle)}>
                      {whatIsIncluded &&
                        whatIsIncluded.map((list, index) => (
                          <>
                            <div key={index}>
                              <label>Point {index + 1}</label>
                              <button
                                type="button"
                                className="closeButton"
                                onClick={() =>
                                  handleRemove(
                                    index,
                                    whatIsIncluded,
                                    "whatIsIncluded"
                                  )
                                }
                              >
                                X
                              </button>
                              <input
                                type="text"
                                value={list}
                                placeholder="No Title Added"
                                name="title"
                                onChange={(e) => handelWhatsIncluded(e, index)}
                              />
                            </div>
                          </>
                        ))}
                      <div
                        style={{
                          textAlign: "right",
                          marginBottom: "1rem",
                          marginTop: "1rem",
                        }}
                      >
                        <button className="button-update">Update</button>
                      </div>
                    </form>
                  </div>
                </div>
              }
              {/* What's Included end */}
              {/* faq */}
              {/* <div className="mainContainer" style={{ width: "80vw" }}>
            <div className="manageTimeSlot">FAQ Section</div>
            <div className="innerBox">
              <form>
                {faqs &&
                  faqs.map((faq, index) => (
                    <>
                      <div key={index}>
                        <label>Question {index + 1}</label>
                        <input
                          type="text"
                          value={faq.question}
                          placeholder={"" ? "" : "No Title Added"}
                          name="title"
                          // onChange={(e) => handelInput(e)}
                        />
                      </div>
                      <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                        <label> Answer {index + 1}</label>
                        <textarea>{faq.answer}</textarea>
                      </div>
                    </>
                  ))}
                <div
                  style={{
                    textAlign: "right",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  <button className="button-update">Update</button>
                </div>
              </form>
            </div>
          </div> */}
          {/* faq end */}
              {/* Description Start */}
              {props.filters.channel.id === 3 &&
              <div className="mainContainer">
                <div className="manageTimeSlot">
                  Product Description
                  <span style={{ textAlign: "right" }}>
                    <button
                      id="addleadbtn"
                      className="btn btn_small btncolorprim btncard move"
                      style={{
                        background: "rgb(105, 118, 148)",
                        color: "rgb(255, 255, 255)",
                      }}
                      onClick={() =>
                        handelDynamicInput(description, "description")
                      }
                    >
                      +
                    </button>
                  </span>
                </div>
                <div className="innerBox">
                  {/* Add Description on submimt */}
                    {description &&
                      description.map((key, index) =>
                          <>
                            <div key={key} style={{ margin: "1rem 0" }}>
                              <button
                                type="button"
                                className="closeButton"
                                onClick={
                                  () =>
                                    handleRemove(
                                      index,
                                      description,
                                      "description"
                                    )
                                }
                              >
                                X
                              </button>
                              <input
                                type="text"
                                defaultValue = {
                                  (key.charAt(0) === '"' || key.charAt(0) === `\"` || key.charAt(0) === ',')
                                  ? key.substring(1) 
                                    : (key.charAt(key.length - 1) === '"' || key.charAt(key.length - 1) === `\"`)
                                      ? key.substring(0, key.length - 1)
                                      : key
                                }
                                name="title"
                                onBlur={(e) => handleDescription(e, index)}
                              />
                            </div>
                          </>
                      )}
                    <div
                      style={{
                        textAlign: "right",
                        marginBottom: "1rem",
                        marginTop: "1rem",
                      }}
                    >
                          <button className="button-update" onClick={handleDescriptionData}>Update</button>
                    </div>
                </div>
              </div>
              }
              {/* Description End */}
              {/* GoConnect Banner Image */}
              {obdCarouselImage &&
              <div className="mainContainer">
                <div className="manageTimeSlot">Carousel OBD Image</div>
                <div className="innerBox">
                  <form onSubmit={handleOBDImageSubmit}>
                    <div className="video-card">
                      <img
                        src={
                          obdCarouselImage
                            ? obdCarouselImage
                            : `https://via.placeholder.com/580x261?text=Image+Not+Uploaded`
                        }
                        alt=""
                        title="image-preview"
                      />
                      <div></div>
                      <div>
                        <input
                          type="file"
                          name="media"
                          onChange={
                            (e) => setUploadFile(e.target.files)
                          }
                        />
                      </div>
                          <button class="button-update" disabled={!uploadFile} style={(!uploadFile) ? { cursor: "not-allowed"} : {cursor: "pointer"}}>Update</button>
                    </div>
                  </form>
                </div>
              </div>
              }
              {/* GoMechanic Benefits */}
              <div className="mainContainer">
                <div className="manageTimeSlot">GoMechanic Benefits Section</div>
                <div className="innerBox">
                  <form onSubmit={handleBenefitsBannerSubmit}>
                    <div className="video-card">
                      <img
                        src={
                          goMechanicBenefits
                            ? goMechanicBenefits
                            : `https://via.placeholder.com/580x261?text=Image+Not+Uploaded`
                        }
                        alt=""
                        title="image-preview"
                      />
                      <div></div>
                      <div>
                        <input
                          type="file"
                          name="media"
                          onChange={
                            (e) => setUploadFile(e.target.files)
                          }
                        />
                      </div>
                          <button class="button-update" disabled={!uploadFile} style={(!uploadFile) ? { cursor: "not-allowed"} : {cursor: "pointer"}}>Update</button>
                    </div>
                  </form>
                </div>
              </div>
              {/* modal box */}
              <div id="modal" className="modal">
                <div className="mainContainer modal-container">
                  <div className="manageTimeSlot">
                    Sure You Want To Remove Item ?
                  </div>
                  <div className="innerBox" style={{ display: "flex" }}>
                    <button
                      className="modal-buttons button-delete"
                      onClick={() => delSoloService()}
                    >
                      Remove
                    </button>
                    <button
                      className="modal-buttons button-basic"
                      onClick={() => closeModal()}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
              {/* modal box end */}
              {/* GoMechanic Benefits end */}
              {/* important note */}
              {/* <div className="mainContainer">
            <div className="manageTimeSlot">Important Note Section</div>
            <div className="innerBox"></div>
          </div> */}
              {/* important note end */}
              <br />
              <br />
              <br />
            </React.Fragment>
          ) : ""
          }

          {props.filters.channel && props.filters.channel.id == 4 &&
            <WarrantyPage onApplyFilter={props}/>
          }
          {props.filters.channel && props.filters.channel.id == 5 &&
            <WarrantyCoverage onApplyFilter={props} />
          }
        </>
      ) : (
        <div className="emptyMiddleMain centeralign weight400 blackspan56">
          Please apply filters to view details
        </div>
      )}
    </>
  );
};

export default ServiceTag;