import React from "react";
// import "./dashboard/dashboard.css"

const tabList = [
  { tag: "rate_control", title: "Manage Rates", table: "cms_faq" },
];

class PanelLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showContent: false };
  }

  render() {
    const tabs = tabList.map((tab, index) => (
      <Tab
        key={index}
        tag={tab.tag}
        title={tab.title}
        table={tab.table}
        onTabClick={this.props.onTabClick}
        selectedTab={this.props.selectedTab}
      />
    ));

    return (
      <div
        id="dashboardmainleft"
        className="border-box bgwhite leftalign floatleft shadow-box widthDashboard100"
      >
        <div className="first border-box clearfix leftalign shadow-box">
          <div className="border-box clearfix flexInternal">
            <div className="dashboardmainleft1boxesinner clearfix whitespan weight900 shadow-text flexInternal">
              GoMechanic
              <br />
              Internal
            </div>
          </div>
        </div>

        <div className="second border-box leftalign">
          <div className="floatleft leftalign blackspan7 weight400">{tabs}</div>
        </div>
      </div>
    );
  }
}

class Tab extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showContent: false };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.onTabClick(this.props);
    /*this.setState(state => ({
            isToggleOn: !state.isToggleOn
          }));*/
  }

  render() {
    return (
      <div
        className={
          this.props.selectedTab === this.props.tag
            ? "tabs bgwhite border-box shadow-text selected"
            : "tabs bgwhite border-box shadow-text"
        }
        data-type={this.props.tag}
        onClick={this.handleClick}
        style={{ display: "flex", alignItems: "center" }}
      >
        <div>{this.props.title}</div>
        {/* <table className="stdtable">
                <tbody>
                  <tr>
                    <td valign="center">
                    {this.props.title}
                    </td>
                  </tr>
                </tbody>
              </table> */}
      </div>
    );
  }
}

export default PanelLeft;
