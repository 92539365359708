import React, { useState, useEffect } from "react";
import "./ImageCarousel.css";
import { NotificationManager } from "react-notifications";
import API from "../../api";
import { functions } from "firebase";

const MainTitles = (props) => {
  const [citiesList, setCitiesList] = useState("");
  const [title, setTitle] = useState("");
  const [subTitle, setSubtitle] = useState("");
  useEffect(() => {
    setOnfilterChange(props);
    setTitle(props.mainTitle);
    setSubtitle(props.subTitle);
  }, [props.cityArray]);
  const setOnfilterChange = (props) => {
    let city_id = "";
    if (props.cityArray && props.cityArray.length > 0) {
      let temp = [];

      props.cityArray.filter((city) => {
        if (city.id != "select-all") {
          temp.push(city.id);
        }
      });
      city_id = temp.join();
    }
    setCitiesList(city_id);
  };
  function handelTitleChange(e) {
    setTitle(e.target.value);
  }
  function handelSubTitleChange(e) {
    setSubtitle(e.target.value);
  }

  function handelUpdateTitles() {
    // var formData = new FormData();
    // formData.append("channel", props.channelType);
    // formData.append("main_sub_title", subTitle);
    // formData.append("main_title", title);
    // if (props.channelType == "Service Combos") {
    //  formData.append("city_id", JSON.stringify(citiesList));
    //  formData.append("channel_sub_type", props.channelSubType);
    // } else {
    //   formData.append("city_id", citiesList);
    // }

    let titleData = {};
    titleData["channel"] = props.channelType;
    titleData["main_sub_title"] = subTitle;
    titleData["main_title"] = title;
    if (props.channelType == "Service Combos") {
      titleData["channel_sub_type"] = props.channelSubType;
      titleData["city_id"] = citiesList;
    } else {
      titleData["city_id"] = citiesList;
    }

    API.updateSectionTitles(titleData)
      .then(function (response) {
        NotificationManager.success("Title Updated", "Successfully", 5000);
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in uploading image");
      });
  }

  return (
    <>
      <div className="mainContainer">
        <div className="manageTimeSlot">Section Title</div>
        <div className="innerBox">
          <div>
            <label> Main Title</label>
            <input
              type="text"
              value={title}
              placeholder={title ? "" : "No Title Added"}
              onChange={(e) => handelTitleChange(e)}
              name="title"
            />
          </div>
          <div style={{ marginTop: "1rem" }}>
            <label> Main Sub-Title</label>
            <input
              type="text"
              value={subTitle}
              placeholder={subTitle ? "" : "No Sub-Title Added"}
              name="sub_title"
              onChange={(e) => handelSubTitleChange(e)}
            />
          </div>
          <div
            style={{
              textAlign: "right",
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
          >
            <button className="button-update" onClick={handelUpdateTitles}>
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainTitles;
