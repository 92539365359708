import React from "react";
import API from "../../api";
import { NotificationManager } from "react-notifications";

import Constants from "../../constants";
import Loader from "../../loader";

class Row extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: " ",
      extension: " ",
      id: " ",
      link:"",
      title: "",
      smallBanner: "",
      isDelete: "",
      headerBanner: "",
      description: "",
      weatherStatus : "",
      bgColor: "",
      titleColor: "",
      subTitleColor: "",
      combo_off: "",
      circleLoader: false,
      button_text: "",
      name: "",
      milesBanner: "",
    };

    this.handleUpload = this.handleUpload.bind(this);
    this.handleRadio = this.handleRadio.bind(this);
  }
  // componentWillMount() {
  //   this.setState({ verticalPageFiles: [...this.props.data.icon] });
  // }

  handleButtonClick(type, event) {
    event.stopPropagation();
    this.props.actionButton(
      type,
      this.props.tag,
      this.props.data.uuid,
      this.props.data,
      this.props.data
    );
  }
  handleRadio(event, channel, column) {
    if(channel == "Climate Banner")
      this.setState({weatherStatus : event.target.checked})
    else {
      if (column === 'smallBanner') this.setState({smallBanner : event.target.checked})
      else this.setState({milesBanner : event.target.checked})
    }
  }

  handleUpload(e, details, string=""){
    let that=this
    let filters = JSON.parse(localStorage.getItem("image_filters"));
    var notifyStr = e.target.files ? 'Image Uploaded' : isDelete ? 'Image Deleted' : 'Image Added';
    let link = this.state.link ? this.state?.link.trim() : details.links;
    let title = this.state.title ? this.state?.title.trim() : details.title
    let version = filters && filters.version && filters.version.id ? filters.version.id : "";
    let carousel = this.state.smallBanner !== "" ? this.state.smallBanner == true ? 1 : 0 : details.is_small_banner == "1" ? 1 : 0;
    let channel = filters && filters.channelType && filters.channelType.name ? filters.channelType.name.toLowerCase() : "";
    let id = details ? (channel == "middle banner" || channel == "climate banner" || channel === "home bottom strip" ||  channel === "category section") || channel === "vertical page" ? details.id : details.serial_no : 0;
    let bannerFor = details ? details.title : "";
    let city_id = [];
    let objectId = this.props.data.id;
    var formData = new FormData();
    let serialNo = this.props?.data?.serial_no ? this.props?.data?.serial_no : "";
    let carType = this.props?.filters?.carType?.name ? this.props?.filters?.carType?.name : "";
    let middleBannerFor = this.props?.data?.car_type ? this.props?.data?.car_type  : "";
    let name = this.state.title ? this.state?.title.trim() : details.name;
    let sizeDpi = this.props?.filters?.size?.name ? this.props?.filters?.size?.name : "";
    let milesBanner = this.state.milesBanner !== "" ? this.state.milesBanner == true ? 1 : 0 : details.is_miles_banner == "1" ? 1 : 0;
    
    if(e.target.files){
      function getExtension(fileName) {
        var parts = fileName?.name ? fileName?.name.split('.') : "";
        return parts[parts.length - 1];
      }
      var file = e.target.files[0];
      var extension = e.target.files ? getExtension(file).toLowerCase() : "";
      if (!file.type.match('image.*') && channel !== 'vertical page' && channel !== 'middle banner') {
        NotificationManager.error("The file is not an image", "Error", 5000);
        return;
      }
      if ((channel == "website") && (Math.round(e.target.files[0].size / 1024) > 150) && !file.type.match('gif.*')) {
        NotificationManager.error("Image is too large", "Error", 5000);
        e.target.value = "";
        return;
      }
      const { name } = e.target.files[0];
      const lastDot = name.lastIndexOf('.');
      const ext = name.substring(lastDot + 1);
      if(extension === 'jpeg' || extension === 'png' || extension === 'svg' || extension === 'webp' || extension === 'gif') {
        this.setState({
          preview: URL.createObjectURL(file),
          raw: file,
          extension: ext,
          id: id,
        });
      }
    }else{
      var isDelete = e.target.checked;
      this.setState({isDelete:isDelete});
    }

    if (filters && filters.cities.length > 0) {
        let temp = [];

        filters.cities.filter((city) => {
            if (city.id != 'select-all') {
                temp.push(city.id);
            }
        })
        city_id = temp;
    }

    if(e.target.files)
      formData.append('file', file);
    else
      formData.append('is_delete', isDelete);

    formData.append('id', id);
    formData.append('link', link);
    formData.append('channel', channel);
    formData.append('city_id', city_id);
    formData.append('object_id', objectId);
    
    if(version == "new" && channel != "website" && channel != "square banner"  && channel != "climate banner")
      formData.append('is_small_banner', carousel);

    if(version == "new" && (channel == "app" || channel == "luxury app"))
      formData.append('is_miles_banner', milesBanner);

    if(version == "new" && (channel == "app" || channel == "luxury app")){
      // let segment = filters && filters.segment && filters.segment.name ? filters.segment.name : "";
      let seg = "";
      if (filters && filters.segment.length > 0) {
        let newArr = filters.segment.filter(opt => opt.name!="Select All");
        seg = Array.prototype.map.call(newArr, function(item) { return item.name; }).join(", ");
      }
      formData.append('segment', seg);  
    }

    if (channel == "vertical page" && version === "old"){
      let size  = filters && filters.size && filters.size.name ? filters.size.name : "";
      formData.append('links', link);
      formData.append('vertical_size', size);
    }
    if(channel === "vertical page" && version === "new") {
      let size = filters && filters.size && filters.size.name ? filters.size.name : "";
      let bg_color = this.state.bgColor ? this.state.bgColor : details?.bg_color;
      let title_color = this.state.titleColor ? this.state.titleColor : details?.title_color;
      let sub_title_color = this.state.subTitleColor ? this.state.subTitleColor : details?.sub_title_color;
      let index = (details.index || details.index === 0) ? details.index : "";
      let videoURL = details?.video ? details?.video : "";

      if(e.target.files && string==='thumb-nail') {
        if(extension === 'jpeg' || extension === 'png' || extension === 'svg' || extension === 'webp') {
          formData.append("video", videoURL);
          formData.append('file_upload', 1);
        }
        else{
          NotificationManager.error("Please Select An Image", "Error", 5000);
          return   
        }
      } else if(e.target.files && string==='video-file') {
        if(extension === 'webm' || extension === 'ogg' || extension === 'mp4') {
          formData.append("video", videoURL);
          formData.append('file_upload', 1);
        } else {
          formData.append("video", false);
        }
      }
      if (!e.target.files) {
        formData.append("video", false);
      } else if(e.target.files && (extension === 'webm' || extension === 'ogg' || extension === 'mp4')) {
        formData.append("video", videoURL);
        formData.append('file_upload', 1);
      } else if (e.target.files && (extension !== 'webm' || extension !== 'ogg' || extension !== 'mp4') && string !=='thumb-nail') {
        formData.append("video", false);
        formData.append('file_upload', 1);
      } 

      formData.delete('link');
      formData.delete('is_small_banner');

      formData.append('links', link);
      formData.append('vertical_size', size);
      formData.append('bg_color', bg_color);
      formData.append('title_color', title_color);
      formData.append('sub_title_color', sub_title_color);
      formData.append("index", index);
      formData.append("version", version);
    }

    if(
      // channel != "vertical page" && 
      channel != "square banner"){
      formData.append('carousel', version);
    } 
    
    if(channel == "square banner"){
      formData.append('square_banner_for', bannerFor);
    }

    if(channel == "middle banner" && version !== "v2"){
      formData.append('middle_banner_for', bannerFor);
    }

    if (channel === "middle banner" && version === "v2") {
      let index = (details.index || details.index === 0) ? details.index : "";
      let videoURL = details?.video ? details?.video : "";
      carType = carType.toLowerCase();

      if(e.target.files && string==='thumb-nail') {
        if(extension === 'jpeg' || extension === 'png' || extension === 'svg' || extension === 'webp') {
          formData.append("video", videoURL);
          formData.append('file_upload', 1);
        }
        else {
          NotificationManager.error("Please Select An Image", "Error", 5000);
          return   
        }
      } else if(e.target.files && string==='video-file') {
        if(extension === 'webm' || extension === 'ogg' || extension === 'mp4') {
          formData.append("video", videoURL);
          formData.append('file_upload', 1);
        } else {
          formData.append("video", false);
        }
      }
      if (!e.target.files) {
        formData.append("video", false);
      } else if(e.target.files && (extension === 'webm' || extension === 'ogg' || extension === 'mp4')) {
        formData.append("video", videoURL);
        formData.append('file_upload', 1);
      } else if (e.target.files && (extension !== 'webm' || extension !== 'ogg' || extension !== 'mp4') && string !=='thumb-nail') {
        formData.append("video", false);
        formData.append('file_upload', 1);
      } 
      formData.append("index", index);
      formData.append("version", version);
      formData.append('type', carType);
      formData.append('middle_banner_for', middleBannerFor);
    }

    if(channel == "climate banner"){
      let description   = this.state.description ? this.state.description : details  && details.description ? details.description : "";
      let weatherStatus      = this.state.weatherStatus !== "" ? this.state.weatherStatus : details && details.is_weather_status  === true ? true : false;

      formData.append('climate_banner_for', bannerFor);
      formData.append('is_weather_status', weatherStatus);
      formData.append('description', description);
    }
    if (channel == "performance banner") {
      formData.append('title',title)
    }

    if (channel == "accessories best sellers") {
      let description = this.state.description ? this.state.description : details && details.description ? details.description : "";
      formData.append('description', description);
      formData.append('title', title);
      formData.append('links', link);
      formData.delete('is_delete');
      formData.delete('link');
      formData.delete('carousel');
    }

    if (channel == "service combos") {
      let description = this.state.description ? this.state.description : details && details.description ? details.description : "";
      let comboOff = this.state.combo_off ? this.state.combo_off : "";
      let channelSubType = this.props.filters.serviceComboChannels.channel_sub_type;
      formData.append('description', description);
      formData.append('title', title);
      formData.append('combo_off', comboOff);
      formData.append('channel_sub_type', channelSubType);
      formData.delete('is_delete');
      formData.delete('link');
      formData.delete('carousel');
      formData.append('links', link);
    }
    if (channel === "home bottom strip"){
      if(city_id.length == 0) {
        let cityIdArr = [1];
        formData.delete("city_id");
        formData.append("city_id", cityIdArr);
      }
      let bottomStripButtonText = this?.state?.button_text ? this?.state?.button_text.trim() : details.button_text;
      formData.append("title", title);
      formData.append("button_text", bottomStripButtonText);
      formData.delete("object_id");
      formData.delete('carousel');
    }
    if (channel === "category section"){
      if(city_id.length == 0) {
        let cityIdArr = [1];
        formData.delete("city_id");
        formData.append("city_id", cityIdArr);
      }
      formData.append("name", name);
      formData.append('size', sizeDpi);
      formData.delete("link");    
      formData.delete("object_id");
      formData.delete("is_delete");
      formData.delete('carousel');
    }
    // return;
    if (channel == "service combos" || channel == "accessories best sellers") {
      API.updateCarouselImagesForCombosAndTopseller(formData).then(function (response) {
        NotificationManager.success(notifyStr, "Successfully", 5000);
      })
        .catch(function (error) {
          // handle error
          NotificationManager.error("Please try again", "Error", 5000);
          console.log(error, "Error in uploading image");
        });
    } else {
      that.setState({circleLoader: true});
      API.updateCarouselImages(formData).then(function (response) {
        NotificationManager.success(notifyStr, "Successfully", 5000);
        that.props.onApplyFilter(that.props.filters, 'image_filters')
        that.setState({circleLoader: false});
      })
        .catch(function (error) {
          // handle error
          NotificationManager.error("Please try again", "Error", 5000);
          console.log(error, "Error in uploading image");
          that.setState({circleLoader: false});
        });
    }
  }

  render() {
        let version = this.props.filters && this.props.filters.version && this.props.filters.version.id ? this.props.filters.version.id : "";
        let channel = this.props.filters && this.props.filters.channelType && this.props.filters.channelType.name ? this.props.filters.channelType.name : "";
        let imageDetails  =  this.props.data ? this.props.data : {};
        let src           =  imageDetails ? imageDetails.icon : "";
        let links         = imageDetails ? imageDetails.links : "Not Available";
        let title         = imageDetails ? imageDetails.title : "Not Available";
        let id            = imageDetails ? (channel == "Middle Banner" || channel == "Climate Banner" || channel === "Vertical Page" || channel === "Home Bottom Strip" || channel === "Category Section") ? imageDetails.id : imageDetails.serial_no : 0;
        let smallBanner   = this.state.smallBanner!== "" ? this.state.smallBanner : imageDetails  && imageDetails.is_small_banner == "1" ? true : false;
        let isDelete      = this.state.isDelete!== "" ? this.state.isDelete : imageDetails  && imageDetails.is_delete === true ? true : false;
        let bannerFor     = imageDetails ? imageDetails.title : "";
        let squareBannerFor = bannerFor ? bannerFor.charAt(0).toUpperCase() + bannerFor.slice(1) : ""
        let text         = imageDetails ? imageDetails.description : "Not Available";
        let weatherStatus   = this.state.weatherStatus!== "" ? this.state.weatherStatus : imageDetails  && imageDetails.is_weather_status == true ? true : false;
        let verticalPageBgColor = (this.props.data.bg_color !== "" || this.props.data.bg_color.trim() !== "")? this.props.data.bg_color : "NA";
        let titleColor = (this.props.data.title_color != "" || this.props.data.title_color.trim() != "") ? this.props.data.title_color : "NA";
        let subTitleColor = (this.props.data.sub_title_color != "" || this.props.data.sub_title_color.trim() != "") ? this.props.data.sub_title_color : "NA";
        // let newImageSrc = this.state.verticalPageFiles ? this.state.verticalPageFiles : "";
        let combo_off = imageDetails ? imageDetails.combo_off : "";
        let dataType =  imageDetails?.type;
        let uploadIndex = channel && version && ((channel == "Vertical Page" && version == "new") || (channel == "Middle Banner" && version === "v2")) ? id+'-'+imageDetails.index : id;
        let bottomStripButtonText = imageDetails?.button_text ? imageDetails?.button_text : "";
        let name = imageDetails ? imageDetails.name : "Not Available";
        let imageSource = imageDetails ? imageDetails.image : "";
        let milesBanner = this.state.milesBanner!== "" ? this.state.milesBanner : imageDetails  && imageDetails.is_miles_banner == "1" ? true : false;
        return (
          <>
          {this.state.circleLoader ? (
            <>
              <div className="modal-sb"></div>
              <div className="sub-modal-sb"></div>
            </>
          ) : 
          <div>
            <div
              // style={{ background: "#eee" }}
              className={
                this.props.selectedRow === this.props.data.uuid
                  ? "boxes orders border-box clearfix leftalign blackspan9 selected"
                  : "boxes orders border-box clearfix leftalign blackspan9"
              }
            >
              <div className="textsize12 border-box weight500">
                <table className="stdtable middleUpperTable-ic">
                  <tbody>
                    
                    <tr className="middleUpperTR">
                    {channel !== "Category Section" &&
                      <td className="middleUpperTD-ic width1TH-ic">
                          {squareBannerFor && (channel !== "Performance Banner" && channel !== "Accessories Best Sellers" && channel !== "Service Combos" && channel != "Middle Banner" && channel != "Home Bottom Strip" && channel != "Category Section") ? squareBannerFor : id}
                      </td>
                    }
                      {channel == "Middle Banner" &&
                        <td className="middleUpperTD-ic colorprim500span width1TH-ic" style={{ padding: "5px" }}>
                          {(channel === "Middle Banner" && version === "v2") ? this.props?.data?.car_type : this.props?.data?.middle_banner_for}
                        </td>
                      }
                      {channel !== "Vertical Page" && channel !== "Middle Banner" && channel !== "Category Section" &&
                        <td className="middleUpperTD-ic colorprim500span width2TH-ic" style={{ padding: "5px" }}>
                          <img src={`${src}?${new Date().getTime()}`} alt="Girl in a jacket" style={{ opacity: isDelete ? ".5" : "" }} width="180" height="120" />
                        </td>
                      }
                      {channel === "Category Section" &&
                        <td className="middleUpperTD-ic colorprim500span width2TH-ic" style={{ padding: "5px" }}>
                          <img src={`${imageSource}?${new Date().getTime()}`} alt="Girl in a jacket" style={{ opacity: isDelete ? ".5" : "" }} width="180" height="120" />
                        </td>
                      }
                      {((version === "old" || version == "new" && channel === "Vertical Page" && dataType !== "video") || ((version === "v2" || version === "new") && channel === "Middle Banner" && dataType !== "video")) && channel !== "Category Section" && channel !== "Website" && channel !== "App" && channel !== "Luxury App" && channel !== "Performance Banner" && channel !== "Square Banner" &&
                        <td className="middleUpperTD-ic colorprim500span width2TH-ic" style={{ padding: "5px" }}>
                          <img src={`${src}?${new Date().getTime()}`} alt="Girl in a jacket" style={{ opacity: isDelete ? ".5" : "" }} width="180" height="120" />
                        </td>
                      }
                      {(((version === "old" || version == "new") && channel === "Vertical Page" && dataType === "video") || (version === "v2" && channel === "Middle Banner" && dataType === "video")) &&
                        <td className="middleUpperTD-ic colorprim500span width2TH-ic">
                          <video 
                          className="vertical-page-video"
                          src={imageDetails?.video ? imageDetails?.video : ""}
                          height="120px"
                          width="180px"
                          poster={
                            typeof imageDetails?.icon === "string"
                              ? imageDetails?.icon
                              : `https://via.placeholder.com/580x261?text=Image+Not+Uploaded`
                          }
                          controls
                          >
                          <source src={""} type="video/mp4" />
                          Your browser does not support the video tag.
                          </video>
                        </td>
                      }
                      {/* {version === "new" && channel === "Vertical Page" &&
                          <td className="middleUpperTD-ic colorprim500span width2TH-ic" style={{ padding: "5px" }}>                        
                          {newImageSrc.map((image) =>
                            <div>
                              <img src={`${image}?${new Date().getTime()}`} alt="Girl in a jacket" width="180" height="120" />
                            </div>                          
                          )}
                          </td>
                      } */}
                      {channel != "Category Section" &&
                        <td className="middleUpperTD-ic width2TH-ic">
                          <textarea
                            defaultValue={links} 
                            className="inputDealPrice"
                            style={{ resize: 'none', textAlign: 'center' }}
                            onChange={(e) => this.setState({link : `${e.target.value} `})}
                            />
                        </td>
                     }
                      {(channel != "Vertical Page" && channel != "Square Banner" && channel != "Middle Banner" && channel != "Climate Banner" && channel != "Performance Banner" && channel != "Accessories Banner" && channel != "Service Combos"  && channel != "Accessories Best Sellers"  && channel != "Accessories Best Sellers" && channel !== "Home Bottom Strip" && channel !== "Category Section") &&
                      <td className="middleUpperTD-ic width1TH-ic">
                            <div style={{padding:"35px"}}>
                              <input
                                type="checkbox"
                                className="toggle-switch-checkbox-ic"
                                name={`toggleSwitchDeletion`}
                                id={`toggleSwitchDeletion${id}`}
                                onChange={(e) => this.handleUpload(e, imageDetails)}
                                defaultChecked={false}
                                checked={isDelete}
                              />
                              <label className="toggle-switch-label-ic" htmlFor={`toggleSwitchDeletion${id}`}>
                                <span className="toggle-switch-inner-ic" />
                                <span className="toggle-switch-switch-ic" style={{width:"0px"}} />
                              </label>
                            </div>
                          </td>
                      }
                      { channel == "Climate Banner" &&
                        <td className="middleUpperTD-ic width6TH-ic">
                          <textarea
                            defaultValue={text} 
                            className="inputDealPrice"
                            style={{ resize: 'none', textAlign: 'center' }}
                            onChange={(e) => this.setState({description : e.target.value})}
                          />
                        </td>
                      }
                      {(channel === "Performance Banner" || channel === "Accessories Best Sellers" || channel === "Home Bottom Strip") &&
                      <td className="middleUpperTD-ic width1TH-ic">
                        <textarea
                          defaultValue={title} 
                          className="inputDealPrice"
                          style={{ resize: 'none', textAlign: 'center' }}
                          onChange={(e) => this.setState({title : `${e.target.value} `})}
                        />
                      </td>
                      }
                      {(channel === "Category Section") &&
                      <td className="middleUpperTD-ic width1TH-ic">
                        <textarea
                          defaultValue={name} 
                          className="inputDealPrice"
                          style={{ resize: 'none', textAlign: 'center' }}
                          onChange={(e) => this.setState({title : `${e.target.value} `})}
                        />
                      </td>
                      }
                      {(channel == "Accessories Best Sellers" || channel == "Service Combos") &&
                        <td className="middleUpperTD-ic width6TH-ic">
                          <textarea
                            defaultValue={text}
                            className="inputDealPrice"
                            style={{ resize: 'none', textAlign: 'center' }}
                            onChange={(e) => this.setState({ description: e.target.value })}
                          />
                        </td>
                      }
                      {channel == "Service Combos" &&
                        <td className="middleUpperTD-ic width1TH-ic">
                          <textarea
                            defaultValue={combo_off}
                            className="inputDealPrice"
                            style={{ resize: 'none', textAlign: 'center' }}
                            onChange={(e) => this.setState({ combo_off: e.target.value })}
                          />
                        </td>
                      }
                      { ((((version == "new" && channel != "Website" && channel != "Square Banner" && channel != "Middle Banner" && channel != "Performance Banner" && channel !== "Accessories Best Sellers"  && channel !== "Service Combos" && channel != "Vertical Page" && channel !== "Home Bottom Strip" && channel != "Category Section") ||  (channel == "Vertical Page" && version == "old") || channel == "Climate Banner" )) || channel == "Accessories Banner") &&
                          <td className="middleUpperTD-ic width1TH-ic">
                            <div style={{padding:"25px"}}>
                              <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                name={`toggleSwitchBanner${id}`}
                                id={`toggleSwitchBanner${id}`}
                                onChange={(e) => this.handleRadio(e, channel, 'smallBanner')}
                                defaultChecked={false}
                                disabled={channel == "Vertical Page"? "disabled":""}
                                checked={channel == "Climate Banner" ? weatherStatus : smallBanner}
                              />
                              <label className="toggle-switch-label" htmlFor={`toggleSwitchBanner${id}`}>
                                <span className="toggle-switch-inner" />
                                <span className="toggle-switch-switch" style={{width:"0px"}} />
                              </label>
                            </div>
                          </td>
                      }
                      { (version == "new" && (channel === 'App' || channel === 'Luxury App')) &&
                          <td className="middleUpperTD-ic width1TH-ic">
                            <div style={{padding:"25px"}}>
                              <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                name={`toggleSwitchMiles${id}`}
                                id={`toggleSwitchMiles${id}`}
                                onChange={(e) => this.handleRadio(e, channel, 'milesBanner')}
                                defaultChecked={false}
                                checked={milesBanner}
                              />
                              <label className="toggle-switch-label" htmlFor={`toggleSwitchMiles${id}`}>
                                <span className="toggle-switch-inner" />
                                <span className="toggle-switch-switch" style={{width:"0px"}} />
                              </label>
                            </div>
                          </td>
                      }
                      {version === "new" && channel === "Vertical Page" && 
                      <>
                        <td  className="width1TH-ic">
                          <div style={{padding: "10px"}}>
                            <textarea
                              defaultValue={verticalPageBgColor}
                              className="inputDealPrice"
                              style={{ resize: 'none', textAlign: 'center' }}
                              onChange={(e) => this.setState({ bgColor: `${e.target.value} ` })}
                            />
                          </div>
                        </td>
                        <td className="middleUpperTD-ic width1TH-ic">
                          <div style={{ padding: "10px" }}>
                            <textarea
                              defaultValue={titleColor}
                              className="inputDealPrice"
                              style={{ resize: 'none', textAlign: 'center' }}
                              onChange={(e) => this.setState({ titleColor: `${e.target.value} ` })}
                            />
                          </div>
                        </td>
                        <td className="middleUpperTD-ic width1TH-ic">
                          <div style={{ padding: "10px" }}>
                            <textarea
                              defaultValue={subTitleColor}
                              className="inputDealPrice"
                              style={{ resize: 'none', textAlign: 'center' }}
                              onChange={(e) => this.setState({ subTitleColor: `${e.target.value} ` })}
                            />
                          </div>
                        </td>
                            </>
                      }

                      {channel === "Home Bottom Strip" &&
                        <td className="middleUpperTD-ic width1TH-ic">
                          <textarea
                            defaultValue={bottomStripButtonText} 
                            className="inputDealPrice"
                            style={{ resize: 'none', textAlign: 'center' }}
                            onChange={(e) => this.setState({button_text : `${e.target.value} `})}
                          />
                        </td>
                      }

                      {(((version === "old" || version === "new") && ((channel === "Middle Banner" &&  version !== "v2")|| channel === "Website" || channel === "App" || channel === "Luxury App" || channel === "Performance Banner")) || (channel === "Square Banner" || channel === "Climate Banner" || channel === "Accessories Banner"  || channel === "Accessories Best Sellers" || channel === "Service Combos" || channel === "Home Bottom Strip" || channel === "Category Section") || (channel === "Vertical Page" && version === "old")) &&
                        <td className="middleUpperTD-ic width1TH-ic">
                          <div role="button">
                            <label htmlFor={`files${bannerFor ? bannerFor : id}`}>
                              <div
                                id="imagesUpload"
                                className="btn btn_small btncolorprim btncard move"
                                style={{ background: 'rgb(105, 118, 148)', color: 'rgb(255, 255, 255)', padding: "5px" }}
                              >
                                Upload Image
                              </div>
                            </label>
                            <input
                              id={`files${bannerFor ? bannerFor : id}`}
                              name="upload"
                              type="file"
                              onChange={(e) => { this.handleUpload(e, imageDetails) }}
                              style={{ display: 'none' }}
                            />
                          </div>
                          <div role="button">
                            <div className="btn btn_small btncolorprim btncard move" onClick={(e) => { this.handleUpload(e, imageDetails) }} style={{ width: "5rem" }}>Save</div>
                          </div>

                          {this.state.preview !== " " ?
                            <img style={{ width: '5rem', height: '4rem' }} src={this.state.preview} alt="img" />
                            : null}
                        </td>
                      }

                      {((channel === "Vertical Page" && version === "new") || (channel === "Middle Banner" && version === "v2")) &&
                        <td className="middleUpperTD-ic width1TH-ic">
                          {dataType === "video" ? 
                          <>
                            <div role="button"> 
                              <label htmlFor={`files${uploadIndex}_1`}>
                                <div
                                  id="imagesUpload1"
                                  className="btn btn_small btncolorprim btncard move"
                                  style={{ background: 'rgb(105, 118, 148)', color: 'rgb(255, 255, 255)', padding: "5px", 
                                  fontSize: "12px"}}
                                >
                                  Upload Thumbnail
                                </div>
                              </label>
                              <input
                                id={`files${uploadIndex}_1`}
                                name="file_upload_1"
                                type="file"
                                onChange={(e) => { this.handleUpload(e, imageDetails, 'thumb-nail') }}
                                style={{ display: 'none' }}
                              />
                          </div>
                          <div role="button">
                            <label htmlFor={`files${uploadIndex}_2`}>
                              <div
                                id="imagesUpload2"
                                className="btn btn_small btncolorprim btncard move"
                                style={{ width: "5.8rem", background: 'rgb(105, 118, 148)', color: 'rgb(255, 255, 255)', padding: "5px, 10px", fontSize: "12px" }}
                              >
                                Upload File
                              </div>
                            </label>
                            <input
                              id={`files${uploadIndex}_2`}
                              name="file_upload_2"
                              type="file"
                              onChange={(e) => { this.handleUpload(e, imageDetails, 'video-file') }}
                              style={{ display: 'none' }}
                            />
                          </div>
                        </>
                        :
                          <div role="button">
                            <label htmlFor={`files${uploadIndex}_3`}>
                              <div
                                id="imagesUpload2"
                                className="btn btn_small btncolorprim btncard move"
                                style={{ width: "5.8rem", background: 'rgb(105, 118, 148)', color: 'rgb(255, 255, 255)', padding: "5px, 10px", fontSize: "12px" }}
                              >
                                Upload File
                              </div>
                            </label>
                            <input
                              id={`files${uploadIndex}_3`}
                              name="file_upload_2"
                              type="file"
                              onChange={(e) => { this.handleUpload(e, imageDetails) }}
                              style={{ display: 'none' }}
                            />
                          </div>
                        }
                          <div role="button">
                            <div className="btn btn_small btncolorprim btncard move" onClick={(e) => { this.handleUpload(e, imageDetails) }} style={{ width: "5.8rem", fontSize: "12px" }}>Save</div>
                          </div>

                          {this.state.preview !== " " ?
                            <img style={{ width: '5rem', height: '4rem' }} src={this.state.preview} alt="img" />
                            : null}
                        </td>
                      }
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          }
          </>);
  }
}

export default Row;
