import React from 'react';
// import "../dashboard/dashboard.css"
import MiddleMain from './middlemain';
import ChatUtils from './chat_utils';


class PanelMiddle extends React.Component {
    constructor(props) {
      super(props);
  //    this.handleTabClick = this.handleTabClick.bind(this);
  //    this.state = {showContent: false};
      this.sendieRef = React.createRef();
      this.chatRef = React.createRef();
      this.scrollToBottom = this.scrollToBottom.bind(this);

      this.handleAIClick = this.handleAIClick.bind(this);
      this.handleSendClick = this.handleSendClick.bind(this);
      this.handleTextChange = this.handleTextChange.bind(this);
      this.handleAIMessageClick = this.handleAIMessageClick.bind(this);

      this.state = {showAI : false, text : ""};
    }
  
    componentDidMount() {
      ////console.log("refreshing");
      // //console.log("chatRef");
      // //console.log(this.chatRef.current);
      // this.scrollToBottom();
    }

    scrollToBottom() {
      //console.log("scrolling");
      this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
    }

    handleAIClick() {
      this.setState({showAI : !this.state.showAI});
    }

    handleSendClick(){
      var message = this.state.text;
      if(message.trim() != ""){
        // this.sendieRef.current.value = "";
        this.setState({text : ""});
        this.props.onGlobalSend(message, this.props.selectedTab);
      }
      
    }

    _handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        var message = this.state.text;
        if(message.trim() != ""){
          // this.sendieRef.current.value = "";
          this.setState({text : ""});
          this.props.onGlobalSend(message, this.props.selectedTab);
          
        } 
      }
    }

    handleAIMessageClick(message, type){
      //console.log(message, type);
      this.setState({text : ""});
      this.props.onGlobalAIMessageClick(message, type, this.props.selectedTab);
      // ChatUtils.sendAIMessage(message, type, this.props.selectedTab);
      this.setState({showAI : !this.state.showAI});
    }

    handleTextChange(e){
      //console.log(e.target.value);
      this.setState({text : e.target.value});
      if(e.target.value.trim() != ""){
        ChatUtils.isTypingAlert(this.props.selectedTab, true);
      }else{
        ChatUtils.isTypingAlert(this.props.selectedTab, false);
      }
    }
  
    render() {
      // //console.log("rendering11", this.props.threadsList);
      var props = this.props;
      var quick_messages = [];
      // //console.log("threads", this.props.threadsList);
      let thread = this.props.threadsList.find(o => o.id === this.props.selectedTab);
      var quickM = JSON.parse(sessionStorage.getItem("quick_messages_internal"));
      // if(thread != null && thread.assigned_executive == localStorage.getItem("user_id")){
      //   let closeMessage = quickM.find(o => (o.type === "notification" && o.notif_type.toLowerCase() == "conversation closed"));
      //   if(closeMessage != null){
      //     quickM.splice(quickM.indexOf(closeMessage),1);
      //   }
      // }
      if(sessionStorage.getItem("quick_messages_internal") !== null){
        quick_messages = quickM.map((qm) =>
          <QuickMessage message={qm} onClick={this.handleAIMessageClick}/>
        );
      }
      

      if(this.props.selectedTab == 0 || (!(this.props.selectedTab in this.props.allModels) && this.props.live) || (!(this.props.selectedTab in this.props.archivedAllModels) && !this.props.live)){
        return (
          <div className="middlemain border-box bgwhite centeralign">
          <div className="chat shadow-box" id="messageContainer" ref={this.chatRef}><MiddleMain {...props} scrollChat={this.scrollToBottom} /></div>
          
          {/* <div className={this.state.showAI ? "chatai showing shadow-box bgwhite" : "chatai shadow-box bgwhite"} id="messageContainerAI">
            <div className="chataitop shadow-box bgwhite colorprim500span leftalign clearfix">Click to send a quick message</div>
          
            {quick_messages}

          </div> */}
          
          
          <div className="chatinput border-box bgwhite leftalign clearfix">
  
            
          </div>
          </div>
        );
      }else{
        return (
          <div className="middlemain border-box bgwhite centeralign">
          <div className="chat shadow-box" id="messageContainer" ref={this.chatRef}><MiddleMain {...props} scrollChat={this.scrollToBottom} /></div>
          
          {(this.props.selectedTab == 0 || (!(this.props.selectedTab in this.props.allModels) && this.props.live) || (!(this.props.selectedTab in this.props.archivedAllModels) && !this.props.live)) ? 
            (<React.Fragment />) : (thread && thread.is_typing_customer ? (<div className="chattyping shadow-box bgwhite colorprim500span leftalign clearfix">Customer is typing ...</div>) : (<React.Fragment />))
            }

          <div className={this.state.showAI ? "chatai showing shadow-box bgwhite" : "chatai shadow-box bgwhite"} id="messageContainerAI">
            <div className="chataitop shadow-box bgwhite colorprim500span leftalign clearfix">Click to send a quick message</div>
            
            {quick_messages}

          </div>
          
          
          <div className="chatinput border-box bgwhite leftalign clearfix">
            {this.props.live ? (<div className="border-box clearfix">
                <form method="post" action="" className="chatform" autocomplete="off" id="chatform" data-type='chat'>
                  <div className="backbuttonbox border-box centeralign clearfix">
                      <table className="stdtable"><tr><td valign="center">
                        <button type="button" id="back" name="chatback_btn" className="btn btn_small btnwhite btncard move" data-type="">Back</button>
                      </td></tr></table>
                    </div>
                    <div className="aibuttonbox border-box leftalign clearfix">
                    <table className="stdtable"><tr><td valign="center">
                        <button type="button" id="ai" name="ai_btn" className="btn btn_small btnwhite btncard move" data-type="" style={{fontSize:'1.3rem'}} onClick={this.handleAIClick}>{this.state.showAI ? "-" : "+"}</button>
                      </td></tr></table>
                    </div>
                  <div className="inputbox border-box leftalign clearfix">
                    <table className="stdtable"><tr><td valign="center">
                        {/* <input type="text" name="sendie" id="sendie" placeholder="Type a message" className="field border-box small"/> */}
                        <textarea name="sendie" id="sendie" placeholder="Type a message" className="field border-box small" value={this.state.text} ref={this.sendieRef} onKeyDown={this._handleKeyDown} onChange={(e) => this.handleTextChange(e)}></textarea>
                      </td></tr></table>
                    </div>
                    <div className="inputbuttonbox border-box centeralign clearfix">
                      <table className="stdtable"><tr><td valign="center">
                        <button type="button" id="send" name="chatsend_btn" className="btn btn_small btnwhite btncard move" data-type="" onClick={this.handleSendClick}>Send</button>
                      </td></tr></table>
                    </div>
                </form>
            </div>) : (<React.Fragment />)}
  
            
          </div>
          </div>
        );
      }

        
    }
  }



  class QuickMessage extends React.Component {
    constructor(props) {
      super(props);
      this.handleClick = this.handleClick.bind(this);
    }
  
      handleClick(message, type) {
          this.props.onClick(message, type);
          /*this.setState(state => ({
            isToggleOn: !state.isToggleOn
          }));*/
      }
  
    render() {
      var that = this;
      if (this.props.message.type == "notification"){
          return(
            <div className="aimessage clearfix border-box"><div className="leftalign border-box"><div className="text notification border-box clearfix leftalign" 
              onClick = {(e) => this.handleClick({message : that.props.message.message, title : that.props.message.title, notif_type:that.props.message.notif_type}, that.props.message.type)}>{that.props.message.title}</div></div></div>
          );
      }else{
        return(
          <div className="aimessage clearfix border-box"><div className="leftalign border-box"><div className="text border-box clearfix leftalign" onClick = {(e) => this.handleClick(e.target.innerHTML, that.props.message.type)} >{that.props.message.message}</div></div></div>
        );
      }
      
      
    }
  }


  // const WrappedPanelMiddle = React.forwardRef((props, ref)  => {return (<PanelMiddle {...props} forwardedRef = {chatRef}/>) });
  export default PanelMiddle;