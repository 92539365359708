import React from "react";
import { NotificationManager } from "react-notifications";
// import "./dashboard/dashboard.css"
import Row from "./row";
import { Editor } from "@tinymce/tinymce-react";
import API from "../api";
import Constants from "../constants";
import { isEmptyObject } from "jquery";

class MiddleExtra extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
    }
    this.state = this.props.content.content ? this.props.content.content : {};
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTinyMCEChange = this.handleTinyMCEChange.bind(this);
    this.syntaxHighlight = this.syntaxHighlight.bind(this);
    this.isJsonString = this.isJsonString.bind(this);
  }
  syntaxHighlight = (json) => {
    if (typeof json != 'string') {
         json = JSON.stringify(json, undefined, 2);
    }
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        var cls = 'number';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            } else {
                cls = 'string';
            }
        } else if (/true|false/.test(match)) {
            cls = 'boolean';
        } else if (/null/.test(match)) {
            cls = 'null';
        }
        return  '' + match + '';
    });
  }
  faqSection = () => {
    if (this.props.web_filters.pageType.name === "app") {
      let text = this.props?.content?.content?.list ? this.props?.content?.content?.list : "";
      return (<>
        {this.state.loader ? (
          <>
            <div className="modal-sb"></div>
            <div className="sub-modal-sb"></div>
          </>
        ) : (
          ""
        )}
        <div className="border-box clearfix fields">
          <div className="border-box clearfix field1">
            <textarea 
              style={{height: "700px", overflowY: "scroll"}} 
              rows="4" 
              cols="50"
              placeholder={`Enter FAQS in JSON format like {"answer":"text","question":"text"}`}
              onBlur={(e) => this.handleAppFaqChange(e)}
              defaultValue={this.props?.content?.content?.list ? this.syntaxHighlight(text) : text}
              >
              </textarea>
          </div>
        </div>
      </>)
    } else {
      const temp = [];
      [...Array(10)].map((rate, index) => {
        index = index + 1;
        var question = this.state.content;
        var answer = this.state.content;
        // console.log('11111'+JSON.stringify(this.state.content));
  
        if (this.props.content.type == "edit") {
          let content = this.state.content ? this.state.content[index] : "";
  
          question = content && content.Q ? content.Q : undefined;
          answer = content && content.A ? content.A : undefined;
        }
  
        temp.push(
          <React.Fragment key={"Q" + index}>
            <div className="border-box clearfix fields">
              <div className="border-box clearfix field1">
                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                  Question {index}
                </div>
                <input
                  key={index}
                  type="text"
                  placeholder="Question"
                  defaultValue={question}
                  className="field border-box small"
                  onBlur={(e) => this.handleChange("Q", e, index)}
                />
              </div>
            </div>
            <div className="border-box clearfix fields">
              <div className="border-box clearfix field1">
                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                  Answer {index}
                </div>
                <textarea
                  className="field border-box small"
                  placeholder="Answer"
                  defaultValue={answer}
                  onBlur={(e) => this.handleChange("A", e, index)}
                ></textarea>
              </div>
            </div>
          </React.Fragment>
        );
      });
      return temp;
    }
  };

  priceSection = () => {
    const temp = [];
    [...Array(3)].map((rate, index) => {
      index = index + 1;
      temp.push(
        <React.Fragment key={"P" + index}>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Car type
              </div>
              <input
                type="text"
                placeholder="Car type"
                value={this.state.car_type}
                className="field border-box small"
                onBlur={(e) => this.handleChange("car_type", e, index)}
              />
            </div>
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Authorized Mechanic price
              </div>
              <input
                type="text"
                placeholder="Authorized Mechanic price"
                value={this.state.authorized_mec_price}
                className="field border-box small"
                onBlur={(e) =>
                  this.handleChange("authorized_mec_price", e, index)
                }
              />
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Local Mechanic price
              </div>
              <input
                type="text"
                placeholder="Local Mechanic price"
                value={this.state.local_mec_price}
                className="field border-box small"
                onBlur={(e) => this.handleChange("local_mec_price", e, index)}
              />
            </div>
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                GoMechanic price
              </div>
              <input
                type="text"
                placeholder="GoMechanic price"
                value={this.state.go_mec_price}
                className="field border-box small"
                onBlur={(e) => this.handleChange("go_mec_price", e, index)}
              />
            </div>
          </div>
        </React.Fragment>
      );
    });
    return temp;
  };

  testimonials = () => {
    const temp = [];
    // console.log('11111'+JSON.stringify(this.state.content));
    [...Array(6)].map((rate, index) => {
      index = index + 1;

      var customer_name = this.state.content;
      var source = this.state.content;
      var garage = this.state.garage;
      var heading = this.state.content;
      var test_content = this.state.content;

      if (this.props.content.type == "edit") {
        let content = this.state.content ? this.state.content[index] : "";

        customer_name =
          content && content.customer_name ? content.customer_name : undefined;
        source = content && content.source ? content.source : undefined;
        garage = content && content.garage ? content.garage : undefined;
        heading = content && content.heading ? content.heading : undefined;
        test_content = content && content.content ? content.content : undefined;
      }

      temp.push(
        <React.Fragment key={"Testimonial" + index}>
          {/* <div className={`tesimonial-container-${index}`}> */}

          <div
            style={{ padding: "20px" }}
            class="border-box floatleft leftalign blackspan2 weight900 shadow-text"
          >
            Testimonials {index}
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Customer Name <span style={{ color: "red" }}>*</span>
              </div>
              <input
                type="text"
                placeholder="Customer Name"
                // value={this.state.customer_name}
                defaultValue={customer_name}
                className="field border-box small"
                onBlur={(e) => this.handleChange("customer_name", e, index)}
              />
            </div>
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Source <span style={{ color: "red" }}>*</span>
              </div>
              <input
                type="text"
                placeholder="Source"
                // value={this.state.source}
                required
                defaultValue={source}
                className="field border-box small"
                onBlur={(e) => this.handleChange("source", e, index)}
              />
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Garage <span style={{ color: "red" }}>*</span>
              </div>
              <input
                type="text"
                placeholder="Garage"
                defaultValue={garage}
                // value={this.state.garage}
                className="field border-box small"
                onBlur={(e) => this.handleChange("garage", e, index)}
              />
            </div>
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Heading <span style={{ color: "red" }}>*</span>
              </div>
              <input
                type="text"
                placeholder="Heading"
                defaultValue={heading}
                value={this.state.heading}
                required
                className="field border-box small"
                onBlur={(e) => this.handleChange("heading", e, index)}
              />
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field1">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Content <span style={{ color: "red" }}>*</span>
              </div>
              <textarea
                className="field border-box small"
                placeholder="Content"
                defaultValue={test_content}
                required
                onBlur={(e) => this.handleChange("content", e, index)}
              >
                {/* {this.state.content} */}
              </textarea>
            </div>
          </div>
          {/* </div> */}
        </React.Fragment>
      );
    });
    return temp;
  };
  createNotification = (type, msg) => {
    switch (type) {
      case "success":
        return NotificationManager.success(`${msg}`, "Successfully", 5000);
      default:
        return false;
    }
  };
  handleButtonClick(type) {
    var that = this;
    if (type === "save") {
      if (this.props.content.type === "edit") {
        let cms_data = {};
        cms_data["table"] = this.props.content.table;
        cms_data["uuid"] = this.props.content.row;
        cms_data["content"] = this.state.content;
        // console.log(JSON.stringify(this.props.content)+'@@@@@FAQ SAVE'+JSON.stringify(cms_data));

        API.updateContentWebsite(cms_data)
          .then(function (response) {
            // console.log("response" + response);
            if (response.data.status) {
              that.props.onMiddleExtraButtonClick(
                type,
                that.state,
                that.props.selectedRow
              );
            }
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .finally(function () {
            // always executed
          });
      } else if (this.props.content.type === "add") {
        let cms_data = Constants.getFilterData(this.props.content.table);
        cms_data["content"] = this.props.content.tag === "service_guide" ? this.state.content : this.state;
        let validate = []
        // console.log(cms_data["content"]);
        if(this.props.content.tag && this.props.content.tag == "testimonials"){
            for (const question in cms_data['content']) {
              if (Object.hasOwnProperty.call(cms_data['content'], question)) {
                const element = cms_data['content'][question];
                const required_keys = ['content', 'heading', 'source','customer_name','garage'];
                let required = required_keys.map(
                  (keys => {
                    if (element.hasOwnProperty(keys)) {
                      if (element[keys].trim().length === 0)
                      {
                        NotificationManager.error(keys + " cannot be empty! ", "Testimonials " + question);
                        return 0
                      }
                      else
                      return 1
                    }
                    else 
                      NotificationManager.error(keys + " required", "Testimonials " + question);
                    return 0
                    
                  })
                )
                  validate=validate.concat(required);
              }
            }
        }
        // console.log(![...new Set(validate)].includes(0));
        // console.log(
        //   JSON.stringify(this.props.content) +
        //     "@@@@@FAQ SAVE" +
        //     JSON.stringify(cms_data)
        // );
        // return;
        if (
          ![...new Set(validate)].includes(0) &&
          !isEmptyObject(cms_data.content) && 
          this.props.web_filters.pageType.name !== "app"
        ) {
          API.addContentWebsite(cms_data)
            .then(function (response) {
              if (response.data.status) {
                setTimeout(function () {
                  that.props.onMiddleExtraButtonClick(type, that.state, null);
                  NotificationManager.success(
                    "Data saved",
                    "Successfully",
                    5000
                  );
                }, 1000);
              } else {
              }
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .finally(function () {
              // always executed
            });
        } 
        else if (
          ![...new Set(validate)].includes(0) &&
          !isEmptyObject(cms_data.content) && 
          this.props.web_filters.pageType.name === "app"
        ) {
          this.setState({loader:true})
          API.addRetailAppFaqList(cms_data)
            .then(function (response) {
              if (response.data.status) {
                setTimeout(function () {
                  that.props.onMiddleExtraButtonClick(type, that.state, null);
                  NotificationManager.success(
                    "Data saved",
                    "Successfully",
                    5000
                  );
                }, 1000);
                this.setState({loader:false})
              }
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .finally(function () {
              // always executed
            });
        } 
        else {
          NotificationManager.warning("Form Is Empty", "Warning");
        }
      } else if (this.props.content.type === "delete") {
        let cms_data = {};
        cms_data["table"] = this.props.content.table;
        cms_data["uuid"] = this.props.content.row;
        API.deleteContentWebsite(cms_data)
          .then(function (response) {
            if (response.data.status) {
              that.props.onMiddleExtraButtonClick(type, that.state, null);
            } else {
            }
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .finally(function () {
            // always executed
          });
      }
    } else {
      this.props.onMiddleExtraButtonClick(type, null);
    }
  }

  handleChange(type, e, index) {
    index = index || null;
    let tag = this.props.content.tag;
    let value = e.target.value;
    if (index) {
      var obj =
        this.props.content.type === "edit" ? this.state.content : this.state;
      let content = {};
      content[type] = value;

      if (obj[index] !== undefined) {
        var indexObj = obj[index];
        indexObj[type] = value;
        obj[index] = indexObj;

        if (indexObj.A == "" && indexObj.Q == "") {
          delete obj[index];
        }
      } else if (value != "") {
        obj[index] = content;
      }
      this.setState(obj);
    } else {
      var obj = this.state;
      obj[type] = e.target.value;
      if (obj.content && obj.content[type]) obj.content[type] = e.target.value;

      this.setState(obj);
      // console.log(this.state);
    }
  }
  handleAppFaqChange(e) {
    var faqObj = this.state;
    let value = e.target.value.trim();
    if(this.isJsonString(value)) {
      value = JSON.parse(value);
      faqObj["list"] = value;
      this.setState(faqObj);
    }
  }

  isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  handleTinyMCEChange(type, e) {
    // console.log(e);
    // console.log(this.state,'----------------',e.target.getContent());
    var obj = this.state;
    let content = e.target.getContent().replace(/\n/g, "");
    // content = content.replace(/\\/g, "");
    obj[type] = content;
    this.setState(obj);
    // console.log(e.target.getContent());
    // console.log(type);
    // console.log(this.state);
    // console.log(e);
  }

  render() {
    var that = this;
    if (that.props.content.type == "delete") {
      return (
        <div
          id="dashboardprimeextra"
          style={{ width: "100%" }}
          className="border-box bgwhite1 shadow-box"
        >
          <div className="extramainpanel border-box leftalign">
            <div className="border-box clearfix title">
              <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
                Delete
              </div>
              <div className="border-box floatright rightalign">
                <button
                  className="btn btn_small btncolorprim btncard move btncloseextra"
                  onClick={() => this.handleButtonClick("close")}
                >
                  Close
                </button>
              </div>
            </div>

            <div className="border-box clearfix fields">
              <div className="border-box clearfix field1">
                {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7 rightalign">
                  Are you sure you want to delete this entry?
                </div>
                {/* <input type="text" placeholder="Heading" value={this.state.heading} className="field border-box small" onChange={(e) => this.handleChange("heading", e)}/> */}
              </div>
              {/* <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Content</div>
                    <input type="text" placeholder="Page path" value={this.state.content} className="field border-box small" onChange={(e) => this.handleChange("content", e)}/>
                  </div> */}
            </div>
            <div className="border-box clearfix footer">
              {/* <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">Edit details</div> */}
              <div className="border-box floatright rightalign">
                <button
                  className="btn btn_small btncolorprim btncard move btncloseextra"
                  onClick={() => this.handleButtonClick("save")}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <div
            id="dashboardprimeextra"
            style={{ width: "100%" }}
            className="border-box bgwhite1 shadow-box"
          >
            <div className="extramainpanel border-box leftalign">
              <div
                className="border-box clearfix title"
                style={{
                  position: "fixed",
                  width: "86%",
                  background: "#F9F9F9",
                  height: "auto",
                }}
              >
                <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
                  {this.props.content.type == "add" ? "Add" : "Edit"}{" "}
                  {this.props.content.label} Details
                </div>
                <div className="border-box floatright rightalign">
                  <button
                    className="btn btn_small btncolorprim btncard move btncloseextra"
                    onClick={() => this.handleButtonClick("close")}
                  >
                    Close
                  </button>
                </div>
                <div className="border-box floatright rightalign">
                  <button
                    className="btn btn_small btncolorprim btncard move btncloseextra"
                    onClick={() => this.handleButtonClick("save")}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            <div
              className="extramainpanel border-box leftalign"
              style={{ marginTop: "4rem" }}
            >
              {(() => {
                switch (this.props.content.tag) {
                  case "page_path_mapping":
                    return (
                      <React.Fragment>
                        <div className="border-box clearfix fields">
                          <div className="border-box clearfix field2">
                            {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Content Heading
                            </div>
                            <input
                              type="text"
                              placeholder="Content heading"
                              value={this.state.content_heading}
                              className="field border-box small"
                              onChange={(e) =>
                                this.handleChange("content_heading", e)
                              }
                            />
                          </div>
                          <div className="border-box clearfix field2">
                            {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Page path
                            </div>
                            <input
                              type="text"
                              placeholder="Page path"
                              value={this.state.page_path}
                              className="field border-box small"
                              onChange={(e) =>
                                this.handleChange("page_path", e)
                              }
                            />
                          </div>
                        </div>
                        <div className="border-box clearfix fields">
                          <div className="border-box clearfix field2">
                            {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Title
                            </div>
                            <input
                              type="text"
                              placeholder="Title"
                              value={this.state.title}
                              className="field border-box small"
                              onChange={(e) => this.handleChange("title", e)}
                            />
                          </div>
                          <div className="border-box clearfix field2">
                            {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Heading
                            </div>
                            <input
                              type="text"
                              placeholder="Heading"
                              value={this.state.heading}
                              className="field border-box small"
                              onChange={(e) => this.handleChange("heading", e)}
                            />
                          </div>
                        </div>
                        <div className="border-box clearfix fields">
                          <div className="border-box clearfix field2">
                            {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Page type
                            </div>
                            <input
                              type="text"
                              placeholder="Page type"
                              value={this.state.page_type}
                              className="field border-box small"
                              onChange={(e) =>
                                this.handleChange("page_type", e)
                              }
                            />
                          </div>
                          <div className="border-box clearfix field2">
                            {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Hosted on
                            </div>
                            <input
                              type="text"
                              placeholder="Page type"
                              value={this.state.hosted_on}
                              className="field border-box small"
                              onChange={(e) =>
                                this.handleChange("hosted_on", e)
                              }
                            />
                          </div>
                        </div>
                        <div className="border-box clearfix fields">
                          <div className="border-box clearfix field2">
                            {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Meta description
                            </div>
                            <input
                              type="text"
                              placeholder="Meta description"
                              value={this.state.meta_description}
                              className="field border-box small"
                              onChange={(e) =>
                                this.handleChange("meta_description", e)
                              }
                            />
                          </div>
                          <div className="border-box clearfix field2">
                            {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                            {/* <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Hosted on</div>
                                          <input type="text" placeholder="Page type" value={this.state.hosted_on} className="field border-box small" onChange={(e) => this.handleChange("hosted_on", e)}/> */}
                          </div>
                        </div>
                        <div className="border-box clearfix fields">
                          <div className="border-box clearfix field1">
                            {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Content
                            </div>
                            {/* <textarea class="field border-box small" name="commentsstartaddress" placeholder="Start address">{this.props.content.content.content}</textarea> */}
                            <Editor
                              apiKey="bsxx6zgh764x32s7aq19x52goeo0tqbo7z9l2mcagxrpijfr"
                              initialValue={this.state.content}
                              init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar:
                                  "undo redo | formatselect | bold italic backcolor | \
                                              alignleft aligncenter alignright alignjustify | \
                                              bullist numlist outdent indent | removeformat | help",
                              }}
                              onBlur={(e) =>
                                this.handleTinyMCEChange("content", e)
                              }
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  case "faq_near_me":
                    return (
                      <React.Fragment>
                        {/* {this.props.content.type == "add" ? (<div className="border-box clearfix fields">
                                    <div className="border-box clearfix field2">
                                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Page ID</div>
                                      <input type="text" placeholder="Content heading" value={this.state.page_id} className="field border-box small" onChange={(e) => this.handleChange("page_id", e)}/>
                                    </div>
                                  </div>) : (<React.Fragment />)} */}

                        {this.faqSection()}
                      </React.Fragment>
                    );
                  case "price_list":
                    return (
                      <React.Fragment>
                        {this.props.content.type == "add" ? (
                          <div className="border-box clearfix fields">
                            <div className="border-box clearfix field2">
                              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                                Page ID
                              </div>
                              <input
                                type="text"
                                placeholder="Content heading"
                                value={this.state.page_id}
                                className="field border-box small"
                                onChange={(e) =>
                                  this.handleChange("page_id", e)
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          <React.Fragment />
                        )}

                        <div className="border-box clearfix fields">
                          <div className="border-box clearfix field2">
                            {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Heading
                            </div>
                            <input
                              type="text"
                              placeholder="Heading"
                              value={this.state.heading}
                              className="field border-box small"
                              onChange={(e) => this.handleChange("heading", e)}
                            />
                          </div>
                          <div className="border-box clearfix field2">
                            {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Service
                            </div>
                            <input
                              type="text"
                              placeholder="Service"
                              value={this.state.service}
                              className="field border-box small"
                              onChange={(e) => this.handleChange("service", e)}
                            />
                          </div>
                        </div>
                        <div className="border-box clearfix fields">
                          <div className="border-box clearfix field2">
                            {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Price
                            </div>
                            <input
                              type="text"
                              placeholder="Price"
                              value={this.state.price}
                              className="field border-box small"
                              onChange={(e) => this.handleChange("price", e)}
                            />
                          </div>
                          {/* <div className="border-box clearfix field2">
                                          <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Heading</div>
                                          <input type="text" placeholder="Heading" value={this.state.heading} className="field border-box small" onChange={(e) => this.handleChange("heading", e)}/>
                                        </div> */}
                        </div>
                      </React.Fragment>
                    );
                  case "price_comparison":
                    return (
                      <React.Fragment>{this.priceSection()}</React.Fragment>
                    );
                  case "testimonials":
                    return (
                      <React.Fragment>{this.testimonials()}</React.Fragment>
                    );
                  case "service_guide":
                    return (
                      <React.Fragment>
                        <div className="border-box clearfix fields">
                          <div className="border-box clearfix field1">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Content
                            </div>
                            {/* <textarea class="field border-box small" name="commentsstartaddress" placeholder="Start address">{this.props.content.content.content}</textarea> */}
                            <Editor
                              apiKey="bsxx6zgh764x32s7aq19x52goeo0tqbo7z9l2mcagxrpijfr"
                              initialValue={this.state.content}
                              init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar:
                                  "undo redo | formatselect | bold italic backcolor | \
                                              alignleft aligncenter alignright alignjustify | \
                                              bullist numlist outdent indent | removeformat | help",
                              }}
                              onBlur={(e) =>
                                this.handleTinyMCEChange("content", e)
                              }
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  case "seo_management":
                    var title = this.state.content;
                    var heading = this.state.content;
                    var metaDescription = this.state.content;
                    var keyword = this.state.top_content;

                    var web_filters = localStorage.getItem("web_filters") != undefined ? JSON.parse(localStorage.getItem("web_filters")) : "";
                    var isTopContent = web_filters && web_filters.pageType && web_filters.pageType.name == "website" &&  web_filters.services.id == undefined && web_filters.brands.length == 0 && web_filters.models.length == 0 && web_filters.fuelType.length == 0 ? true : false;
                    
                    if (this.props.content.type == "edit") {
                      let content = this.state.content
                        ? this.state.content
                        : "";

                      title =
                        content && content.title ? content.title : undefined;
                      heading =
                        content && content.heading
                          ? content.heading
                          : undefined;
                      metaDescription =
                        content && content.meta_description
                          ? content.meta_description
                          : undefined;
                      keyword =
                        content && content.top_content
                          ? content.top_content
                          : undefined;
                    }
                    return (
                      <React.Fragment>
                        <div className="border-box clearfix fields">
                          <div className="border-box clearfix field1">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              H1 Tag
                            </div>
                            <input
                              type="text"
                              placeholder="H1"
                              defaultValue={heading}
                              className="field border-box small"
                              onBlur={(e) => this.handleChange("heading", e)}
                            />
                          </div>
                        </div>
                        <div className="border-box clearfix fields">
                          <div className="border-box clearfix field1">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Page Title
                            </div>
                            <input
                              type="text"
                              placeholder="Title"
                              defaultValue={title}
                              className="field border-box small"
                              onChange={(e) => this.handleChange("title", e)}
                            />
                          </div>
                        </div>
                        <div className="border-box clearfix fields">
                          <div className="border-box clearfix field1">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Meta description
                            </div>
                            <textarea
                              placeholder="Description"
                              defaultValue={metaDescription}
                              className="field border-box small"
                              onChange={(e) =>
                                this.handleChange("meta_description", e)
                              }
                            />
                          </div>
                        </div>
                        { isTopContent &&
                            <div className="border-box clearfix fields">
                              <div className="border-box clearfix field1">
                                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                                  Top Content
                                </div>
                                <textarea
                                  placeholder="Top Content"
                                  defaultValue={keyword}
                                  className="field border-box small"
                                  onChange={(e) => this.handleChange("top_content", e)}
                                />
                              </div>
                            </div>
                        }
                      </React.Fragment>
                    );
                  default:
                    return <React.Fragment />;
                }
              })()}
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default MiddleExtra;
