import React from "react";
import "../dashboard/dashboard.css";
import PanelLeft from "./leftpanel";
import MiddleUpper from "./middleupper";
import MiddleMain from "./middlemain";
import Loader from "../loader";
import PricingFilter from "./PricingFilter/pricingFilter";
import API from "../api";
import Constants from "../constants";
import { Redirect } from "react-router-dom";
import "./middleupper.css";
import { NotificationManager } from "react-notifications";
import SlotBooking from "./SlotBooking/slotBooking";
import BookingFilter from "./SlotBooking/BookingFilter/bookingFilter";
import WorkshopMasking from "./WorkshopMasking/workshopMasking";
import WorkshopFilter from "./WorkshopMasking/WorkshopFilter/workshopFilter";
import ImageCarousel from "./ImageCarousel/ImageCarousel";
import WhatsappTemplate from "./WhatsappTemplate/WhatsappTemplate";
import UserWallet from "./UserWallet/UserWallet";
import ServiceTag from "./ServiceTag/ServiceTag";
import Miles from "./Miles/Miles";
import PageBanner from "./PageBanner/PageBanner";
import ProductsFilter from './ActivateProducts/ProductsFilter/productsFilter';
import ActiveProducts from './ActivateProducts/activateProducts';
import TrainingLevelCms from "../TrainingLevelCms"
import TrainingFilter from "./TrainingModule/TrainingFilter/TrainingFilter";
import ManageHomePage from "./ManageHomePage/ManageHomePage";
// add service tag config file

const axios = require("axios");

class PanelMiddle extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		if (this.props.showLoader) {
			// if(true){
			return (
				<div id="websiteMainmiddleloader" className="bgwhite centeralign">
					<Loader showLoader={this.props.showLoader} />
				</div>
			);
		} else {
			return (
				<React.Fragment>
					{this.props.webFilters ? (
						[
							// <MiddleUpper
							//   tag={this.props.tag}
							//   label={this.props.label}
							//   onFilterClick={this.props.onFilterClick}
							//   onAddClick={this.props.onAddClick}
							//   onFilterChange={this.props.onFilterChange}
							// />,
							this.props.showContent ? (
								<MiddleMain
									tag={this.props.tag}
									selectedRow={this.props.selectedRow}
									content={this.props.content}
									showContent={this.props.showContent}
									onAction={this.props.rightOnRightButtonClick}
									onSaveClick={this.props.onSavePricingDetails}
									webFilters={this.props.webFilters}
								/>
							) : (
								<React.Fragment />
							),
						]
					) : (
						<div className="emptyMiddleMain centeralign weight400 blackspan56">
							Please apply filters to view records
						</div>
					)}
				</React.Fragment>
			);
		}
	}
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "logged in",
      rightContent: NaN,
      middleContent: NaN,
      showMiddleContent: false,
      showRightContent: false,
			showAddPart:false,
      middleExtraContent: NaN,
      selectedTab: localStorage.getItem("user_type") && localStorage.getItem("user_type") == "Training Admin" ? "training_module" : "deals_pricing",
      // selectedTab: "activate_products",
      selectedRow: "",
      web_filters: undefined,
      tableName: "cms_faq",
      label: "Deals",
      firebaseData:{
        deal_zone:[],
        brandWiseModel:[],
        pricing_categories:[],
        segment:[],
        car_brands:[],
        segmentWiseModel:[],
        fuelTypes:[],
      },
			batteryTyreData: false,
    };

		this.handleTabClick = this.handleTabClick.bind(this);
		this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
		this.udpateFilterState = this.udpateFilterState.bind(this);
		this.handlePageChange =  this.handlePageChange.bind(this);
	}

	handleSaveButtonClick(data) {
		var that = this;

		this.setState({
			showLoader: true,
		});
		console.log("---------", data);
		API.updateDealPricingDetails(that, data)
			.then(function (response) {
				let categoryId = (that.state && that.state.web_filters && that.state.web_filters.categories.id)
						? that.state.web_filters.categories.id
						: "";
				if (categoryId === -4.1 || categoryId === 21.1) {
					setTimeout(() => {
						that.setState({ batteryTyreData: true }, () => { that.setState({ batteryTyreData: false })});
						NotificationManager.success("Details saved", "Successfully", 5000);
						that.setState({ showLoader: false });
					}, "1000")
				}else{ 		
					NotificationManager.success("Details saved", "Successfully", 5000);
					that.setState({ showLoader: false });
				}
			})
			.catch(function (error) {
				// handle error
				NotificationManager.error("Please try again", "Error", 5000);
				console.log(error, "Error in updating deal pricing data");
				that.setState({ showLoader: false });
			})
		// this.udpateFilterState();
	}

	udpateFilterState() {
		let that = this;
		let categoryId = (that.state && that.state.web_filters && that.state.web_filters.categories.id)
						? that.state.web_filters.categories.id
						: "";
		if (categoryId === -4.1 || categoryId === 21.1) {
			setTimeout(() => {
				this.setState({ batteryTyreData: true }, () => { this.setState({ batteryTyreData: false })});
			}, "500")
		} 
	} 

	handleEditButtonClick(type, tag, row, content, data) {
		this.setState({
			selectedRow: data.uuid,
			showRightContent: true,
			rightContent: data,
		});
		this.setState({
			middleExtraContent: {
				type: type,
				tag: tag,
				table: this.state.tableName,
				label: this.state.label,
				row: row,
				content: content,
			},
		});
	}

	handleTabClick(tab) {
		var that = this;

		that.setState({
			selectedTab: tab.tag,
			label: tab.title,
			tableName: tab.table,
			showMiddleContent: false,
			showRightContent: false,
			rightContent: NaN,
			showLoader: true,
			middleExtraContent: NaN,
		});
		if (
			tab.tag === "manage_service_tag" &&
			localStorage.getItem("service_filters") !== undefined
		) {
			let filters = JSON.parse(localStorage.getItem("service_filters"));
			let channel = filters && filters.channel ? filters.channel.id : "";
			if(channel!=4 && channel != 5){
				that.setState({
					web_filters: JSON.parse(localStorage.getItem("service_filters")),
				});
			}
		}
		if (
			tab.tag === "manage_wallet" &&
			localStorage.getItem("wallet_filters") !== undefined
		) {
			that.setState({
				web_filters: JSON.parse(localStorage.getItem("wallet_filters")),
			});
		}
		if (
			tab.tag === "cms_carousel" &&
			localStorage.getItem("image_filters") !== undefined
		) {
			that.setState({
				web_filters: JSON.parse(localStorage.getItem("image_filters")),
			});
		}
		if (
			tab.tag === "slot_booking" &&
			localStorage.getItem("booking_filters") !== undefined
		) {
			that.setState({
				web_filters: JSON.parse(localStorage.getItem("booking_filters")),
			});
		}
		if (
			tab.tag === "page_banner" &&
			localStorage.getItem("page_banner_filters") !== undefined
		) {
			that.setState({
				web_filters: JSON.parse(localStorage.getItem("page_banner_filters")),
			});
		}
		if (tab.tag === "activate_products" && localStorage.getItem("activate_products_filters") !== undefined) {
      that.setState({ web_filters: JSON.parse(localStorage.getItem("activate_products_filters")) })
    } 
		if (
			tab.tag === "training_module" &&
			localStorage.getItem("training_filters") !== undefined
		) {
			that.setState({
				web_filters: JSON.parse(localStorage.getItem("training_filters")),
			});
		}

		if (
			tab.tag === "manage_homepage" &&
			localStorage.getItem("homepage_filter") !== undefined
		) {
			that.setState({
				web_filters: JSON.parse(localStorage.getItem("homepage_filter")),
			});
		}

		API.tabClickCallContentWebsite(tab.tag, that, tab.table, that.web_filters);
	}
	handleFilterChange(filters, filterType) {
		var that = this;
		this.setState({
			web_filters: filters,
			showLoader: true,
			showAddPart: false
		});
		localStorage.setItem(filterType, JSON.stringify(filters));
		API.tabClickCallContentWebsite(
			that.state.selectedTab,
			that,
			that.state.tableName,
			filters
		);
	}

	handlePageChange(value){
		this.setState({ showAddPart:value});
	}

	hideMenuBTN() {
		var element1 = document.getElementById("dashboardmainleft");
		var rightImg = document.getElementById("rotate");
		// if (element1.style.display == "none") {
		//   element1.style.display = "block";
		//   rightImg.style.transform = "rotate(0deg)";
		// } else {
		//   element1.style.display = "none";
		//   rightImg.style.transform = "rotate(180deg)";
		// }
		if (element1.style.width == "0%") {
			element1.style.width = "100%";
			element1.style.animation = "slide 2s";
			rightImg.style.transform = "rotate(0deg)";
		} else {
			element1.style.animation = "slider 0.25s";
			element1.style.width = "0%";
			rightImg.style.transform = "rotate(180deg)";
		}
	}

	componentWillMount() {
		
		if (localStorage.getItem("access_token") === null) {
			this.setState({ status: "not logged in" });
		} else {
			var that = this;
			that.setState({ showLoader: true });
      if(that.state.firebaseData.deal_zone.length === 0)
        {API.firebaseData(that)}
			if (
				localStorage.getItem("pricing_filters") ||
				localStorage.getItem("booking_filters")
			) {
				API.tabClickCallContentWebsite(
					that.state.selectedTab,
					that,
					that.state.tableName
				);
			} else {
				that.setState({ showLoader: false });
			}
		this.setState({userType:localStorage.getItem("user_type")})		
		}
	}

	render() {
		if (
			this.state.status === "not logged in" ||
			new Date(localStorage.getItem("token_expiration_date")) <= new Date()
		) {
			localStorage.removeItem("access_token");
			localStorage.removeItem("token_expiration_date");
			// localStorage.setItem("user_id");
			localStorage.removeItem("user_id");
			localStorage.removeItem("phone");
			localStorage.removeItem("custom_token");
			localStorage.removeItem("user_roles");
			return <Redirect to="/login" />;
		} else if (this.state.selectedTab === "support_chat") {
			return <Redirect to="/chat" />;
		} else {
			// return <Redirect to="/dashboard"/>
			return (
				<div id="containermainouter1" className="MainGridComponent">
					{/* <PanelRight content={this.state.rightContent} showContent={this.state.showRightContent} tag={this.state.selectedTab} selectedRow={this.state.selectedRow} onRightButtonClick={this.handleEditButtonClick}/>
					 */}
					<PanelLeft
						onTabClick={this.handleTabClick}
						selectedTab={this.state.selectedTab}
						userType={this.state.userType}
					/>

					<div
						id="dashboardmainmiddle"
						style={{ overflowY: "scroll" }}
						className="bgwhite leftalign websiteContentDash marginDashboard1 scrollMiddle"
					>
						<div
							id="dashboardprime"
							className="leftalign"
							style={{
								overflowY: "scroll",
								minHeight: "100vh",
								height: "auto",
							}}
						>
							{this.state.selectedTab == "slot_booking" && (
								<React.Fragment>
									<div style={{ marginTop: "20px" }}>
										<BookingFilter onApplyFilter={this.handleFilterChange} />
									</div>
									{this.state.showLoader ? (
										<div
											id="websiteMainmiddleloader"
											className="bgwhite centeralign"
										>
											<Loader showLoader={this.state.showLoader} />
										</div>
									) : this.state.middleContent.length > 0 ? (
										<SlotBooking
											content={this.state.middleContent}
											filters={this.state.web_filters}
										/>
									) : (
										<div className="emptyMiddleMain centeralign weight400 blackspan56">
											Please apply filters to view records
										</div>
									)}
								</React.Fragment>
							)}

							{this.state.selectedTab == "deals_pricing" && (
								<React.Fragment>
									<div style={{ marginTop: "20px" }}>
										<PricingFilter onApplyFilter={this.handleFilterChange} fbData={this.state.firebaseData} batteryTyreData={this.state.batteryTyreData} />
									</div>
									<PanelMiddle
										tag={this.state.selectedTab}
										label={this.state.label}
										webFilters={this.state.web_filters}
										selectedRow={this.state.selectedRow}
										showLoader={this.state.showLoader}
										content={this.state.middleContent}
										showContent={this.state.showMiddleContent}
										rightOnRightButtonClick={this.handleEditButtonClick}
										onSavePricingDetails={this.handleSaveButtonClick}
									/>
								</React.Fragment>
							)}

							{this.state.selectedTab == "workshop_masking" && (
								<React.Fragment>
									<div style={{ marginTop: "20px" }}>
										<WorkshopFilter onApplyFilter={this.handleFilterChange} handlePageChange={this.handlePageChange}/>
									</div>
									{this.state.showLoader ? (
										<div
											id="websiteMainmiddleloader"
											className="bgwhite centeralign"
										>
											<Loader showLoader={this.state.showLoader} />
										</div>
									) : ((this.state.middleContent && this.state.web_filters) || this.state.showAddPart) ? (
										<WorkshopMasking
											content={this.state.middleContent}
											filters={this.state.web_filters}
											showAddPart={this.state.showAddPart}
											handlePageChange={this.handlePageChange}
										/>
									) : (
										<div className="emptyMiddleMain centeralign weight400 blackspan56">
											Please apply filters to view records
										</div>
									)}
								</React.Fragment>
							)}
              {this.state.selectedTab === "activate_products" &&
                <>
                  <div style={{ marginTop: "20px" }}>
                    <ProductsFilter onApplyFilter={this.handleFilterChange} />
                  </div>
                  {this.state.showLoader ?
                    <div id="websiteMainmiddleloader" className="bgwhite centeralign">
                      <Loader showLoader={this.state.showLoader} />
                    </div> :
                    this.state.middleContent && this.state.web_filters ?
                      <ActiveProducts
                        content={this.state.middleContent}
                        filters={this.state.web_filters}
                      />
                      :
                      <div className="emptyMiddleMain centeralign weight400 blackspan56">
                        Please apply filters to view records
                      </div>
                  }
                </>
              }
							{this.state.selectedTab == "cms_carousel" && (
								<React.Fragment>
									<ImageCarousel
										content={this.state.middleContent}
										onApplyFilter={this.handleFilterChange}
										loader={this.state.showLoader}
										filters={this.state.web_filters}
									/>
								</React.Fragment>
							)}

							{this.state.selectedTab == "whatsapp_template" && (
								<React.Fragment>
									<WhatsappTemplate
										content={this.state.middleContent}
										onApplyFilter={this.handleFilterChange}
										loader={this.state.showLoader}
									/>
								</React.Fragment>
							)}
							{this.state.selectedTab === "manage_wallet" && (
								<React.Fragment>
									<UserWallet
										content={this.state.middleContent}
										onApplyFilter={this.handleFilterChange}
										loader={this.state.showLoader}
										filters={this.state.web_filters}
									/>
								</React.Fragment>
							)}
							{this.state.selectedTab === "manage_service_tag" && (
								<React.Fragment>
									<ServiceTag
										content={this.state.middleContent}
										onApplyFilter={this.handleFilterChange}
										loader={this.state.showLoader}
										filters={this.state.web_filters}
									/>
								</React.Fragment>
							)}
							{this.state.selectedTab === "miles" && (
								<React.Fragment>
									<Miles
										content={this.state.middleContent}
										onApplyFilter={this.handleFilterChange}
										loader={this.state.showLoader}
										filters={this.state.web_filters}
									/>
								</React.Fragment>
							)}
							{this.state.selectedTab === "page_banner" && (
								<React.Fragment>
									<PageBanner
										content={this.state.middleContent}
										onApplyFilter={this.handleFilterChange}
										loader={this.state.showLoader}
										filters={this.state.web_filters}
									/>
								</React.Fragment>
							)}

							{/* Training Module  */}
							{this.state.selectedTab == "training_module" && (
								<React.Fragment>
									{this.state.showLoader ? (
										<>
										<div className="modal-sb"></div>
										<div className="sub-modal-sb"></div>
										</>
									) :"" }
									{this.state.middleContent && this.state.web_filters ? (
										<TrainingLevelCms
											content={this.state.middleContent}
											filters={this.state.web_filters}
											that={this}
										/>
									) : (
										<>
										<TrainingFilter onApplyFilter={this.handleFilterChange} />
										<div className="emptyMiddleMain centeralign weight400 blackspan56">
											Please apply filters to view records
										</div>
										</>
									)}
								</React.Fragment>
							)}

							{this.state.selectedTab === "manage_homepage" && (
								<React.Fragment>
									<ManageHomePage
										content={this.state.middleContent}
										onApplyFilter={this.handleFilterChange}
										loader={this.state.showLoader}
										filters={this.state.web_filters}
										// onSaveHomePageRows={this.handleSaveButtonClick}
									/>
								</React.Fragment>
							)}

						</div>
						<button
							className="bottomToggleButton btncard move"
							onClick={this.hideMenuBTN}
						>
							<img
								id="rotate"
								className="arrowToggle"
								src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/left.svg"
							/>
						</button>
					</div>
				</div>
			);
		}
	}
}

export default Dashboard;
