import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import API from "../../api";
import Loader from "../../loader";
import PageBannerFilter from "./PageBannerFilter/pageBannerFilter";
import "flatpickr/dist/themes/material_green.css";
import "./PageBanner.css";
const PageBanner = (props) => {
  const [uploadFile, setUploadFile] = useState();
  const [uploadData, setCatUploadData] = useState();
  const [catID, setCatID] = useState();
  const [pageBannerType, setPageBannerType] = useState();
  const [filter, setfilterData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [catUploadType, setCatUploadType] = useState("image");
  const [fields, setFields] = useState([
    { title: null, image: null, video: null, type: "image" },
  ]);

  function handleDynamicChange(i, event) {
    const values = [...fields];
    values[i].title = event.target.value;
    setFields(values);
  }
  function handleDynamicTypeChange(i, event) {
    const values = [...fields];
    values[i].type = event.target.value;
    setFields(values);
  }
  function handleDynamicUpload(i, file, type) {
    const values = [...fields];
    if (type === "image") values[i].image = file;
    else values[i].video = file;
    setFields(values);
  }

  function handleAdd() {
    const values = [...fields];
    values.push({ title: null, image: null, video: null, type: "image" });
    setFields(values);
  }

  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  }

  const filterData = (data) => {
    // console.log(data)
    setfilterData(data);
    if (data && data.cat_data && data.cat_data.category_videos) {
      const arr = data.cat_data.category_videos.map((item) => {
        return {
          title: item.title,
          image: item.image_url,
          video: item.video_url,
          type: "image",
        };
      });
      console.log(arr);

      setFields(arr);
    }
  };
  const selectedCategory = (data) => {
    setCatID(data);
  };
  const handleChange = (event) => {
    setCatUploadType(event.target.value);
  };
  const handelInput = (event) => {
    console.log(event.target.value);
  };
  // sub category banner logic
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(uploadFile);
    console.log(uploadData);
    if (uploadFile) {
      var file = uploadFile[0];
      if (!file.type.match("image.*")) {
        NotificationManager.error("The file is not an image", "Error", 5000);
        return;
      }
      // if (Math.round(uploadFile[0].size / 1024) > 150) {
      //   NotificationManager.error("Image is too large", "Error", 5000);
      //   e.target.value = "";
      //   return;
      // }
    }
    var notifyStr = uploadFile ? "Image Uploaded" : "Please Select Image First";

    var formData = new FormData();
    formData.append("file", file);
    if (uploadData) {
      formData.append("category_id", uploadData.category_id);
      formData.append("sub_category_id", uploadData.id);
      console.log(formData, "formData");
    } else {
      NotificationManager.error("Please Select Image First", "Error", 5000);
      return;
    }
    setLoader(true);
    API.updateCatBannerImages(formData)
      .then(function (response) {
        NotificationManager.success(notifyStr, "Successfully", 5000);
        setLoader(false);
        setUploadFile(null);
        setCatUploadData(null);
        props.onApplyFilter();
        getSubServicesDataAPI(catID);
        // image_path response path to be set as new image url
      })
      .catch(function (error) {
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in uploading image");
        setLoader(false);
        setUploadFile(null);
        setCatUploadData(null);
      });
  };

  //  video title logic
  const handelTitleSubmit = (event) => {
    event.preventDefault();
    var formData = new FormData();
    formData.append("category_id", catID);
    formData.append("title", event.target.title.value);
    formData.append("sub_title", event.target.sub_title.value);
    setLoader(true);
    API.updateCatBannerImages(formData)
      .then(function (response) {
        NotificationManager.success("Titles Updated", "Successfully", 5000);
        getSubServicesDataAPI(catID);
        setLoader(false);
      })
      .catch(function (error) {
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in Updating Title image");
        setLoader(false);
      });
  };

  // video media logic
  function handelDynamicVideoImageSection(e, id) {
    e.preventDefault();
    // let index = filter.cat_data.category_videos.length;
    let file =
      fields[id].type === "image" ? fields[id].image : fields[id].video;
    if (file && file[0].type != undefined) {
      if (file[0].type.match("image.*") && fields[id].type != "image") {
        NotificationManager.error("The file is not an image", "Error", 5000);
        return;
      } else if (file[0].type.match("video.*") && fields[id].type != "video") {
        NotificationManager.error("The file is not a video", "Error", 5000);
        return;
      }
    }
    var formData = new FormData();
    setLoader(true);
    formData.append("category_id", catID);
    formData.append("title", fields[id].title);
    formData.append("id", id);
    var notifyStr = 'Video Title Updated'
    if (typeof file === 'object') {
      formData.append("file", file[0]);
      formData.append("type", fields[id].type);
      notifyStr = fields[id].type === "image" ? "Image Updated" : "Video updated in background. check after 10 mins";
    }
    // else {
    //   setLoader(false);
    //   NotificationManager.error("Please Select Upload Media", "Error", 5000);
    //   return;
    // }
    // for (var value of formData.values()) {
    //   console.log(value);
    // }
    API.updateCatBannerImages(formData)
      .then(function (response) {
        NotificationManager.success(notifyStr, "Successfully", 5000);
        getSubServicesDataAPI(catID);
        setLoader(false);
        // image_path response path to be set as new image url
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Please try again", "Error", 5000);
        console.log(error, "Error in uploading Media");
        setLoader(false);
      });
  }

  const getSubServicesDataAPI = (service_id) => {
    setLoader(true);
    // localStorage.setItem("page_banner_filters", JSON.stringify(this.state.filterData));
    API.getSubServicesDataAPI(service_id)
      .then(function (response) {
        console.log("-----------", response.data.data);
        if (response.status) {
          filterData(response.data.data);
          selectedCategory(service_id);
          setLoader(false);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoader(false);
      });
  };

  const applyFilter = (filterData) => {
    getSubServicesDataAPI(filterData.services.id);
    setPageBannerType(filterData.page_banner_type.id);
    // props.onApplyFilter(this.state.filterData, "page_banner_filters");
  };

  // useEffect(() => {
  //   if (filter) {
  //     setLoader(false)
  //   } else {
  //     setLoader(true);
  //   }
  // }, filter);

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <PageBannerFilter
          showLoader={(val) => setLoader(val)}
          applyFilter={(filterData) => {
            applyFilter(filterData);
          }}
          onDropDownChangeSetId={(val) => {
            selectedCategory(val);
          }}
          onDropDownChange={(val) => {
            filterData(val);
          }}
        />
      </div>
      {!filter && (
        <div className="emptyMiddleMain centeralign weight400 blackspan56">
          Please apply filters to view records
        </div>
      )}
      {loader ? (
        <>
          <div className="modal-sb"></div>
          <div className="sub-modal-sb"></div>
        </>
      ) : (
        ""
      )}
      {filter &&
        filter.service_sub_categories.map((sub_category, index) => (
          <div className="mainContainer pageBanner">
            <div key={index} className="manageTimeSlot">
              {sub_category.name} Service Banner
            </div>
            <div className="innerBox">
              <form onSubmit={handleSubmit}>
                <div className="category-card">
                  <img
                    src={
                      sub_category.sub_category_image
                        ? sub_category.sub_category_image
                        : `https://via.placeholder.com/580x261?text=Image+Not+Uploaded`
                    }
                    alt=""
                    title="image-preview"
                  />
                  <div>
                    <label>Banner Image</label>
                    <br />
                    <input
                      type="file"
                      name="image_banner"
                      onChange={(e) => {
                        setUploadFile(e.target.files);
                        setCatUploadData(sub_category);
                      }}
                    />
                  </div>
                  <div style={{ textAlign: "right", marginBottom: "1rem" }}>
                    <button>Update</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ))}
      {filter && filter.cat_data && pageBannerType !== '2' && (
        <div className="mainContainer" style={{ width: "80vw" }}>
          <div className="manageTimeSlot">Category Video</div>
          <div className="innerBox">
            <form onSubmit={handelTitleSubmit}>
              <div>
                <label> Video Title</label>
                <input
                  type="text"
                  value={filter.cat_data.category_video_title}
                  placeholder={
                    filter.cat_data.category_video_title ? "" : "No Title Added"
                  }
                  name="title"
                  onChange={(e) => handelInput(e)}
                />
              </div>{" "}
              <div style={{ marginTop: "1rem" }}>
                <label> Video Sub-Title</label>
                <input
                  type="text"
                  defaultValue={filter.cat_data.category_video_sub_title}
                  placeholder={
                    filter.cat_data.category_video_title
                      ? ""
                      : "No Sub-Title Added"
                  }
                  name="sub_title"
                  onChange={(e) => handelInput(e)}
                />
              </div>
              <div
                style={{
                  textAlign: "right",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
              >
                <button className="button-update">Update</button>
              </div>
            </form>
          </div>
        </div>
      )}
      {filter && filter.cat_data && pageBannerType !== '2' && (
        <div className="mainContainer catVideos" style={{ width: "80vw" }}>
          <div className="manageTimeSlot">
            Category Video
            <span style={{ textAlign: "right" }}>
              <button
                id="addleadbtn"
                className="btn btn_small btncolorprim btncard move"
                style={{
                  background: "rgb(105, 118, 148)",
                  color: "rgb(255, 255, 255)",
                }}
                onClick={() => handleAdd()}
              >
                Add Video
              </button>
            </span>
          </div>
          <div
            className="innerBox"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            {fields.map((field, idx) => {
              return (
                <form
                  onSubmit={(e) => handelDynamicVideoImageSection(e, idx)}
                  key={`${field}-${idx}`}
                >
                  <div className="video-card">
                    {field && !(field.image || field.video) && (
                      <button
                        type="button"
                        className="closeVideoCard"
                        onClick={() => handleRemove(idx)}
                      >
                        X
                      </button>
                    )}
                    <img
                      src={
                        typeof field.image === "string"
                          ? field.image
                          : `https://via.placeholder.com/580x261?text=Image+Not+Uploaded`
                      }
                      alt=""
                      title="image-preview"
                    />
                    {
                      <video
                        src={field.video ? field.video : ""}
                        height="240"
                        poster={  
                          typeof field.image === "string"
                            ? field.image
                            : `https://via.placeholder.com/580x261?text=Image+Not+Uploaded`
                        }
                        controls
                      >
                        <source src={""} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    }
                    <div>
                      <label>Video title</label>
                      <input
                        type="text"
                        name="categoryVideoThumbnail"
                        value={field.title || ""}
                        onChange={(e) => handleDynamicChange(idx, e)}
                        name="title"
                      />
                    </div>
                    <div>
                      <label>Select Media Type:</label>
                      <select
                        style={{ appearance: "auto" }}
                        value={field.type}
                        name="type"
                        onChange={(e) => handleDynamicTypeChange(idx, e)}
                      >
                        <option value="image">Image</option>
                        <option value="video">Video</option>
                      </select>
                    </div>
                    <div>
                      <label>
                        Upload{""}
                        {field && field.type === "image"
                          ? "Video Thumbnail"
                          : "Video File"}
                      </label>
                      <input
                        type="file"
                        name="media"
                        onChange={(e) => {
                          handleDynamicUpload(idx, e.target.files, field.type);
                        }}
                      />
                    </div>
                    <button>Update</button>
                  </div>
                </form>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default PageBanner;
