import React, { useState } from "react";
import "./trainingModule.css";
import API from "../../api.js";
import arrowRight from "../../images/chevron_right.png"
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom"

function TrainingModule({content,filters,that}) {
	const history = useHistory();
	let arrayId = []
	let temp = filters?.multipleUsers.filter((user) => {
		if (user.id != 'select-all') {
			arrayId.push(user.id);
		}
	})
	function handleAddNextLevel() {
		const  newLevel = {
			user_id: filters.user.id,
			multiple_user_id: arrayId,
			user_role: filters.user.user_type,
			workshop_id: filters.workshop.id,
			is_mandatory: false,
			level_threshold: 1,
			level_data: [
					{
							name: "MCQ's Questions",
							passing_threshold: 1,
							pass_criteria: "Need to answer all questions correctly",
							user_id: filters.user.id,
							user_role: filters.user.user_type,
							workshop_id: filters.workshop.id,
							is_mandatory: false,
							type: 2
					},
					{
							name: "Training Videos",
							passing_threshold: 1,
							pass_criteria: "Watch at least 3 videos",
							user_id: filters.user.id,
							user_role: filters.user.user_type,
							workshop_id: filters.workshop.id,
							is_mandatory: false,
							type: 1
					}
			]
		}
		that.setState({showLoader:true})
		API.postAddNextLevelData(newLevel)
			.then(function (response) {
				if (response.status) {
					NotificationManager.success(
						response.data.message,
						"Successfully",
						5000
					);
					API.getTrainingLevel(filters.workshop.id, filters.user.id).then((res) => {
						if (res.data.status) 
							that.setState({ showLoader: false ,middleContent:res.data.data})
					}
					)
				}
				else{
					NotificationManager.error("Please try again", "Error", 5000);	
					that.setState({ showLoader: false })		
				}
			})
			.catch(function (error) {
				that.setState({ showLoader: false })
				NotificationManager.error("Please try again", "Error", 5000);
			});
	}

	return (
		<>
					<div className="add-next-level">
						<button type="submit" 
						onClick={() => handleAddNextLevel()}
						>+ Add next level</button>
					</div>
					<div>
						{Array.isArray(content) &&
							content.map((each) => {
								return (
									<div
									className="training-levels-final-child"
										onClick={() => {
											history.push(`/dashboard/miscellaneous/level/${each.id}`)
										}}
									>
										<div className="training-levels">
											<p className="select-training-level">{each.name} Training</p>
											<img className="select-next" src={arrowRight} alt="arrow right" />
										</div>
									</div>
								);
							})}
					</div>
			
		</>
	);
}

export default TrainingModule;
